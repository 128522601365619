<template>
    <div class="secret-page-container">
        <v-img src="../../assets/images/ghosts/secret/bune_header.jpg"></v-img>
        <h2>{{ $t('ghosts.secretPage.title') }}</h2>
        <div v-html="htmlContent" class="secret-page-content"></div>
    </div>
</template>
<script>
import axios from 'axios';
import currentGameMixin from '../../mixins/currentGameMixin';

export default {
    name: 'BuneSecretPage',
    mixins: [currentGameMixin],
    data() {
        return {
            htmlContent: ''
        }
    },
    computed: {
        htmlContentSrc: function () {
            return `${this.casePublicHtml}/tourist-office/bune_${this.$i18n.locale}.html`
        }
    },
    methods: {
        fetchHtmlContent: async function () {
            try {
                const { data } = await axios.get(this.htmlContentSrc);
                this.htmlContent = data;
            }
            catch (e) {
                console.error('Could not fetch html content', e);
            }
        }
    },
    mounted() { this.fetchHtmlContent(); }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap');

.secret-page-container {
    background-color: #000000;
    padding: 46px;
    text-align: center;
}

.secret-page-container img {
    height: 258px;
}

.secret-page-container h2 {
    font-family: Hina Mincho;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.065em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 86px;
    color: #ffffff;
}

.secret-page-container >>> h3 {
    margin-top: 90px;
    font-family: Hina Mincho;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ED1C24;
    text-transform: uppercase;
}

.secret-page-content {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.secret-page-content >>> strong {
    font-weight: 700;
}
</style>
