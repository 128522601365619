import { comparePersonChoices } from "../utils"

export default {
    protagonists: [
        { id: "you", localized: true },
        { id: "journalist", variable: true }
    ],
    dialogs: [
        {
            id: "journalist",
            entryPoint: true,
            choices: [
                {
                    value: "Marisa Anderson"
                },
                {
                    value: "Michael Breedlove"
                },
                {
                    value: "Paul Dornan"
                },
                {
                    value: "Martin Freeman"
                },
                {
                    value: "Gillian Gibson"
                },
                {
                    value: "Greta Grimly"
                },
                {
                    value: "Charlotte McMorgan",
                },
                {
                    value: "Linda Park"
                },
                {
                    value: "Archie Reed Smith"
                },
                {
                    value: "Molly Solverson"
                },
                {
                    value: "Lucy Stagg"
                },
                {
                    value: "Brian Tyler"
                }
            ],
            setVariable: "journalist",
            sorted: true,
            sortFunc: comparePersonChoices,
            outcomes: {
                "Charlotte McMorgan": "introduction",
                default: "introductionFail"
            }
        },
        { id: "introduction", protagonist: "you", next: "heardOfYou" },
        { id: "introductionFail", protagonist: "you", next: "notMyBusiness" },
        { id: "notMyBusiness", protagonist: "journalist", deadEnd: true },
        { id: "heardOfYou", protagonist: "journalist", next: "tarotQuestion" },
        { id: "tarotQuestion", protagonist: "journalist", next: "tarotChoice" },
        {
            id: "tarotChoice",
            protagonist: "you",
            choices: [

                {
                    value: "card0"
                },
                {
                    value: "card1"
                },
                {
                    value: "card2"
                },
                {
                    value: "card3"
                },
                {
                    value: "card4"
                },
                {
                    value: "card5"
                },
                {
                    value: "card6"
                },
                {
                    value: "card7"
                },
                {
                    value: "card8"
                },
                {
                    value: "card9"
                },
                {
                    value: "card10"
                },
                {
                    value: "card11"
                },
                {
                    value: "card12"
                },
                {
                    value: "card13"
                },
                {
                    value: "card14"
                },
                {
                    value: "card15"
                },
                {
                    value: "card16"
                },
                {
                    value: "sootheDemon"
                },
                {
                    value: "card17"
                },
                {
                    value: "card18"
                },
                {
                    value: "card19"
                },
                {
                    value: "card20"
                },
                {
                    value: "card21"
                },
                {
                    value: "card22"
                },
                {
                    value: "card23"
                },
                {
                    value: "card24"
                },
                {
                    value: "card25"
                },
                {
                    value: "card26"
                },
                {
                    value: "card27"
                },
                {
                    value: "card28"
                },
                {
                    value: "card29"
                },
                {
                    value: "card30"
                },
                {
                    value: "card31"
                },
                {
                    value: "card32"
                },
                {
                    value: "card33"
                },
                {
                    value: "card34"
                },
                {
                    value: "card35"
                }
            ],
            sorted: true,
            localizedChoices: true,
            outcomes: {
                sootheDemon: "responsibleQuestion",
                default: "tarotWrongAnswer"
            }
        },
        { id: "tarotWrongAnswer", protagonist: "journalist", next: "tarotChoice" },
        { id: "responsibleQuestion", protagonist: "journalist", next: "responsibleChoice" },
        {
            id: "responsibleChoice",
            protagonist: "you",
            choices: [
                {
                    value: "Lydia Reed"
                },
                {
                    value: "Robin Reed"
                },
                {
                    value: "May Wheathers"
                },
                {
                    value: "Larry Linton"
                },
                {
                    value: "Damian Hammer"
                },
                {
                    value: "Charlotte McMorgan"
                },
                {
                    value: "Kevin Freeman"
                },
                {
                    value: "Carrie Kryston",
                },
                {
                    value: "Cameron Gerbson"
                },
                {
                    value: "Lord Kryston"
                },
                {
                    value: "Branwell Linton"
                },
                {
                    value: "Maxime Mahonay"
                },
                {
                    value: "Rupert Kryston"
                },
                {
                    value: "Mariah Brontë"
                },
                {
                    value: "James Mahonay"
                },
            ],
            sorted: true,
            sortFunc: comparePersonChoices,
            outcomes: {
                "Carrie Kryston": "epilog",
                default: "responsibleWrongAnswer"
            }
        },
        { id: "responsibleWrongAnswer", protagonist: "journalist", next: "responsibleChoice" },
        {
            id: "keepInvestigating",
            protagonist: "journalist",
            deadEnd: true
        },
        {
            id: "epilog",
            protagonist: "journalist",
            finalDialog: true
        }
    ]
}