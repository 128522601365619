<template>
    <div>
        <img class="gallery-image" :src="imageSrc[imageIndex].src" v-if="imageSrc[imageIndex]"/>
    </div>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "MetroGallery",
  emits: ["onImageChange"],
  mixins: [currentGameMixin],
  data() {
    return {
      interval: null,
      imageSrc: [],
      imageIndex: -1,
    };
  },
  computed: {
   
  },
  props: {
    place: String,
    imageCount: Number,
    speed: Number
  },
  mounted() {
    for(let i=0; i<this.imageCount; i++) {
      let img = new Image();
      img.src = `${this.casePublicImages }/places/${this.place.toLowerCase()}_${i+1}.png`;
      this.imageSrc.push(img);
    }

    this.interval = setInterval(this.nextImage, this.speed);
    this.nextImage();
  },
  methods: {
    nextImage() {
      this.imageIndex = (this.imageIndex + 1) % this.imageCount;
      this.$emit("onImageChange");
    }
  }
}
</script>
<style scoped lang="scss">
  .gallery-image {
    width: 100%;
  }
</style>