<template>
  <v-container class="m-lines">
    <div class="m-lines-header">{{ $t("yakuza.metro.which_line") }}</div>
    <div class="m-lines-icons">
        <v-img v-for="(v, k) in $yakuzaStore.metro.lines" 
          :key="k" 
          :src="`${casePublicImages}/metro/metro_${k}.svg`"
          class="m-line-symbol"
          @click="$emit('onLineSelected', k)">
        </v-img>
    </div>
  </v-container>
</template>
<script>
import currentGameMixin from "@/mixins/currentGameMixin";

export default {
  name: "MetroLines",
  emits: ["onLineSelected"],
  mixins: [currentGameMixin],
  data() {
    return { };
  },
  computed: { },
  props: {
    text: String,
    show: Boolean,
    extraClass: String
  },
  mounted() { },
  methods: { }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/yakuza.scss';

  .m-lines {
    text-align: center;
    background-color: #004577;
    min-height: 320px;

    div.m-lines-header {
      color: #fff;
      font: 600 14pt "Arimo";
      margin: 15px auto 20px auto;
    }

    div.m-lines-icons {
      text-align: center;
      width: 300px;
      margin:auto;

      .m-line-symbol {
        @include size(48px, 48px);
        border-radius: 24px;
        border: 4px solid transparent;
        display: inline-block;
        transition: border 0.2s;
        &:hover {
          border: 4px solid #fff;
          cursor: pointer;
        }
      }
    }
  }
</style>