<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="py-4">
        <router-link to="." class="return-link">
          <img src="@/assets/images/mykonos/back.svg" class="return-icon" />
          &nbsp;
          <span>{{ $t("mykonos.research.links.searchAnotherPlace") }}</span>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <span class="nowhere-message">{{ $t("mykonos.research.messages.nowhere") }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
.return-link {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
}
.return-link:hover {
  color: #ffc122;
}
.return-icon {
  vertical-align: middle;
}

.nowhere-message {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
</style>