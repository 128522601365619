export default {
    id: "evidences",
    subsections: [
        {
            id: "ghostHunters",
            route: {
                name: "ghosts-ghosthunters-home"
            },
            lockable: false
        },
        {
            id: "touristOffice",
            route: {
                name: "ghosts-tourist-office-home"
            },
            lockable: false
        },
        {
            id: "tarot",
            lockable: true,
            locked: true,
            disabled: true,
            route: {
                name: "ghosts-tarot"
            },
        },
        {
            id: "horizon",
            lockable: true,
            locked: true,
            disabled: true,
            route: {
                name: "ghosts-horizon"
            }
        },
    ]
}