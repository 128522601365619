<template>
  <v-container>
    <police-header />
    <div class="pa-10">
      <router-link :to="{ name: 'maestro-police-home' }" class="home-link"
        ><v-img src="/maestro/assets/images/police/home.svg" class="icon" />{{
          $t(`${caseID}.police.buttons.home`)
        }}</router-link
      >
    </div>
    <v-card width="100%">
      <v-card-title
        ><v-icon>mdi-bank-transfer</v-icon
        >{{ $t(`${caseID}.bank.transactions`) }}</v-card-title
      >
      <v-autocomplete
        v-model="personFilter"
        @keypress.enter="searchReports()"
        :items="suspects"
        item-text="text"
        item-value="value"
        :label="$t(`${this.caseID}.bank.placeholders.name`)"
      />
      <v-btn v-bind:disabled="personFilter === ''" @click="searchReports()">{{
        $t(`${caseID}.bank.buttons.search`)
      }}</v-btn>
      <v-simple-table class="transactions">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t(`${caseID}.bank.headers.date`) }}</th>
              <th class="text-left">
                {{ $t(`${caseID}.bank.headers.location`) }}
              </th>
              <th class="text-left">
                {{ $t(`${caseID}.bank.headers.description`) }}
              </th>
              <th class="text-left">
                {{ $t(`${caseID}.bank.headers.amount`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in filteredReports.transactions"
              :key="transaction.timestamp"
            >
              <td>{{ `${transaction.date} ${transaction.hour}` }}</td>
              <td>
                {{
                  getTransactionLocation(transaction)
                }}
              </td>
              <td>
                {{
                  getTransactionDescription(transaction)
                }}
              </td>
              <td class="text-right">{{ transaction.amount }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import PoliceHeader from "../../components/maestro/PoliceHeader.vue";
import currentGameMixin from "../../mixins/currentGameMixin";
import bankMessages from "../../messages/maestro/bank.messages";
import axios from "axios";

export default {
  name: "Bank",
  i18n: {
    messages: bankMessages,
  },
  methods: {
    searchReports() {
      const filter = this.personFilter;

      let fr = this.reports.find(
        (r) =>
          r.lastName.toLowerCase() === filter.lastName.toLowerCase() &&
          r.firstName.toLowerCase() === filter.firstName.toLowerCase()
      );

      if (fr) this.filteredReports = fr;
      else this.filteredReports = {};
    },
    itemRowBackground: function (item) {
      return item.protein > 4.2 ? "style-1" : "style-2";
    },
    getTransactionDescription: function(transaction) {
      return transaction.description[this.currentLocale];
    },
    getTransactionLocation: function(transaction) {
      return transaction.location[this.currentLocale];
    }
  },
  data() {
    return {
      reports: [],
      suspects: [],
      filteredReports: [],
      personFilter: null,
    };
  },
  computed: {
    currentLocale: function() {
      return this.$i18n.locale
    },
    bankDataSrc: function() {
      return `${this.casePublicData}/bank.json`;
    }
  },
  components: {
    PoliceHeader,
  },
  mixins: [currentGameMixin],
  created: async function() {
    const { data } = await axios.get(this.bankDataSrc);

    this.reports = data.map((entry) => {
      return {
        firstName: entry.firstName,
        lastName: entry.lastName,
        fullName: `${entry.lastName} ${entry.firstName}`,
        reference: entry.reference,
        transactions: entry.transactions
          .map((transaction) => {
            return {
              id: transaction.id,
              date: new Date(transaction.timestamp).toLocaleDateString(
                this.$i18n.locale,
                {
                  day: "numeric", // numeric, 2-digit
                  month: "numeric", // numeric, 2-digit, long, short, narrow
                }
              ),
              hour: new Date(transaction.timestamp).toLocaleString(
                this.$i18n.locale,
                {
                  hour: "numeric", // numeric, 2-digit
                  minute: "numeric", // numeric, 2-digit
                }
              ),
              amount: Number(transaction.amount).toLocaleString(
                this.$i18n.locale,
                {
                  style: "currency",
                  currency: "EUR",
                }
              ),
              description: transaction.description,
              location: transaction.location
            };
          })
          .sort((t1, t2) => {
            return new Date(t1.timestamp) - new Date(t2.timestamp);
          }),
      };
    });

    this.suspects = require(`@/data/${this.caseID}/suspects.json`).map((s) => {
      return {
        text: s.lastname.toUpperCase() + " " + s.firstname,
        value: {
          firstName: s.firstname,
          lastName: s.lastname,
        },
      };
    });

    // *** CODE SNIPPET to generate localized bank data file
    // const data = require("@/data/bank.json");

    // const locales = ["fr", "en", "de", "es", "it"];

    // const translations = {};
    
    // locales.forEach((l) => {
    //   const ft = require(`@/locales/${l}.json`);
      
    //   translations[l] = ft.maestro.data.bank;
    // });

    // data.forEach(r => {
    //   r.transactions.forEach(t => {
    //     t.description = {};
    //     t.location = {};

    //     for (let l in translations) {
    //       const oTrans = translations[l].reports[r.reference].transactions[t.id];
    //       t.description[l] = oTrans.description;
    //       t.location[l] = oTrans.location;          
    //     }
    //   })
    // })

    // console.log(JSON.stringify(data));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-link {
  text-decoration: none;
  color: #1e43a3;
}

.home-link:hover {
  text-decoration: underline;
}

.icon {
  float: left;
  width: 32px;
  margin-right: 10px;
  position: relative;
  top: -5px;
}

.transactions {
  margin-bottom: 80px;
}
</style>
