<template>
  <div class="grow metro-container">
    <div id="metro-scroll-top"></div>
    <DialogOverlay :text="$t('yakuza.metro.tutorial')" :show="!$yakuzaStore.metroTutoDone" :OKtext="'common.buttons.close'" @onOk="closeTutorial()"></DialogOverlay>
    <DialogOverlay :text="$t('yakuza.metro.error_stop')" :show="showErrorDialogStop" @onOk="hideErrorDialog"></DialogOverlay>
    <DialogOverlay :text="$t('yakuza.metro.error_stop2')" :show="showErrorDialogStopNotYet" @onOk="hideErrorDialog"></DialogOverlay>    
    <DialogOverlay :text="$t('yakuza.metro.error_place')" :show="showErrorDialogPlace" @onOk="hideErrorDialog" extraClass="step-2"></DialogOverlay>    
    <DialogOverlay :text="successText" :show="showSuccess" @onOk="hideErrorDialog" extraClass="step-2" :hideOK="env !== 'A'"></DialogOverlay>

    <v-container class="m-help" v-if="currentStep === 0" >
      <div v-html="$t('yakuza.metro.help1').replace('{env}', env)"></div>
      <!--<div v-html='$t("yakuza.metro.help2").replace("{n}", $yakuzaStore.getRemainingSiteCount())'></div>-->
    </v-container>
    <v-container class="m-container" >
      <div class="m-header"  v-if="currentStep < 2">
        <img :src="casePublicImages + '/metro/logo.svg'" />
      </div>


      <MetroLines v-if="currentStep === 0" @onLineSelected="(l) => selectLine(l)"></MetroLines>
      <MetroLine v-if="currentStep === 1" :line="currentLine" @onBack="back()" @onStopSelected="(s) => selectStop(s)"></MetroLine>
      <MetroStop v-if="currentStep === 2 && showStop" :env="env" :stop="currentStop" @onBack="back()" @onPlaceSelected="(p) => selectPlace(p)"></MetroStop>
      <Sound :src="`${casePublicAudio}/metro/ding.mp3`" ref="dingSound" />
  </v-container>
  </div>
</template>
  
<script>
  import DialogOverlay from "@/components/DialogOverlay";
  import MetroLines from "@/components/yakuza/MetroLines";    
  import MetroLine from "@/components/yakuza/MetroLine";    
  import MetroStop from "@/components/yakuza/MetroStop";
  import Sound from "@/components/yakuza/Sound";
  import currentGameMixin from "@/mixins/currentGameMixin";

  export default {
    name: "Metro",
    components: {
      MetroLines,      
      MetroLine,      
      MetroStop,
      DialogOverlay,
      Sound,
    },
    mixins: [currentGameMixin],
    props: {
      env: String,
    },
    data: () => ({
      currentStep: 0,
      currentLine: '',
      currentStop: '',
      showErrorDialogStop: false,
      showErrorDialogStopNotYet: false,
      showErrorDialogPlace: false,
      showSuccess: false,
      showStop: true,
      successText: '',
    }),

    methods: {
      closeTutorial: function() {
        this.$yakuzaStore.metroTutoDone = true;
        this.$yakuzaStore.saveState();
      },
      selectLine(l) {
        this.currentLine = l;
        this.currentStep = 1;
      },
      selectStop(s) {
        if(this.$yakuzaStore.metro.correctStops[this.env].name === s) {
          this.currentStep = 2;
          this.currentStop = s;
          this.$yakuzaStore.setStopFound(this.env)
        } else {
          ['A', 'C', 'D', 'E', 'F'].forEach(e => {
            if(this.$yakuzaStore.metro.correctStops[e].name === s) {
              if(this.$yakuzaStore.findEnvelope(`enveloppe${e}`).locked) {
                this.showErrorDialogStopNotYet = true;
                return;                
              }
            }
          })

          this.showErrorDialogStop = true;
          this.scrollTop();  
        }
      },
      selectPlace(p) {
        if(this.$yakuzaStore.metro.correctPlaces.find(v => v.name === p) !== undefined) {
          let correctPlace = this.$yakuzaStore.metro.correctPlaces.find(v => v.name === p);

          this.successText = this.$t(`yakuza.metro.${correctPlace.textid}`);
          this.showSuccess = true;
          this.scrollTop();

          if(correctPlace.section_unlocked) {
            this.$yakuzaStore.unlockEnvelope(correctPlace.section_unlocked);
          }

          correctPlace.section_enabled.forEach(v => {
            if(v && v.includes('enveloppe')) {
              this.$yakuzaStore.enableEnvelope(v);
            }
            if(v && !v.includes('enveloppe')) {
              this.$yakuzaStore.enableEvidence(v);
            }
          })

          this.$yakuzaStore.setPlaceFound(p);

          this.$refs.dingSound.play();          
        } else {
          this.showErrorDialogPlace = true;
          this.scrollTop();         
        }
      },
      scrollTop() {
        setTimeout(() => {
          this.$nextTick(() =>
            document.getElementById('metro-scroll-top').scrollIntoView({behavior: 'smooth'})
          );
        });
      },
      back(){
        this.currentStep = 0;
      },
      hideErrorDialog() {
        if(this.showSuccess && this.env === 'A') {
          this.$router.push({ name: 'yakuza-elevator' })
        }        
        this.showErrorDialogStop = false;
        this.showErrorDialogPlace = false;
        this.showErrorDialogStopNotYet = false;
        this.showSuccess = false;
      },
      restoreState() {
        this.currentStep = 0
        this.showErrorDialogStop = false;
        this.showErrorDialogPlace = false;
        this.showSuccess = false;
        if(this.$yakuzaStore.metro.correctStops[this.env].found) {
          this.currentStep = 2
          this.currentStop = this.$yakuzaStore.metro.correctStops[this.env].name

          const place = this.$yakuzaStore.metro.correctPlaces.find(v => v.envelope === this.env)
          if(place && place.found) {
            this.successText = this.$t(`yakuza.metro.${place.textid}`)
            this.showSuccess = true

            if(this.showSuccess && this.env === 'A') {
              this.$router.push({ name: 'yakuza-elevator' })
            } else {
              this.$refs.dingSound.play(); 
            }
          }
        }
      }
    },
    watch: {
      env: function() {
        this.showStop = false
        this.$nextTick(() => {
          this.restoreState()
          this.showStop = true     
        })
      }        
    },
    computed: { },
    created() {
      this.showStop = false
        this.$nextTick(() => {
          this.restoreState()
          this.showStop = true     
        })
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';

  ::v-deep .v-overlay__content {
    @include absolute($top: 50px);
  }
  ::v-deep .step-2 .v-overlay__content {
    @media only screen and (max-width: 600px) {
      @include absolute($top: 50px);
    }
  }
  .m-help {
    @include small_white_uppercase_text();
    width: 100%;
    text-align: center;
    padding-top: 0px;
    ::v-deep .n { color: $accent-color-yellow; }
  }
  .m-container {
    @include base_container();
  }

  .metro-container {
    position: relative;
  }

  .m-header {
    height: 106px;
    background: #ffffff;
    text-align: center;
    padding-top: 20px;

    img {
      height: 66px;
      margin: auto;
    }
  }
  ::v-deep .hl {
    font-weight: 600;
    color: $accent-color-yellow;
  }
</style>
  