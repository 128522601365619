<template>
  <svg :height="svgHeight" :width="svgWidth" xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${svgWidth} ${svgHeight}`">
    <rect
      :width="cellWidth"
      :height="cellHeight"
      :x="typeColIndex * cellWidth"
      :y="typeRowIndex * cellHeight"
      class="type-cursor"
    ></rect>
    <text
      v-for="(row, rowIndex) in asciiData"
      :key="`row-${rowIndex}`"
      x="0"
      :y="rowIndex * cellHeight"
    >
      <tspan
        v-for="(ch, colIndex) in row"
        :key="`cell-${rowIndex}-${colIndex}`"
        :x="colIndex * cellWidth"
        class="ascii-char"
        :class="{
          typed: typeRowIndex > rowIndex || (typeRowIndex === rowIndex && typeColIndex > colIndex),
        }"
      >
        {{ ch }}
      </tspan>
    </text>
  </svg>
</template> 
<script>
export default {
  data() {
    return { svgHeight: 0, svgWidth: 0, typeRowIndex: 0, typeColIndex: 0, cellWidth: 7, cellHeight: 12 };
  },
  props: {
    ascii: String,
  },
  computed: {
    asciiData: function () {
      const data = [];

      if (this.ascii.trim() !== "") {
        let row = 0;

        data.push([]);

        for (let i = 0; i < this.ascii.length; i++) {
          const c = this.ascii.charAt(i);

          if (c === "\n") {
            data.push([]);
            row++;
          } else {
            data[row].push(c);
          }
        }
      }

      return data;
    },
  },
  watch: {
    asciiData: {
      deep: true,
      handler(newValue) {
       /* if (newValue === []) {
          this.svgHeight = 0;
          this.svgWidth = 0;
        } else {*/
          this.svgHeight = newValue.length * this.cellHeight;
          this.svgWidth =
            newValue.reduce(function (a, b) {
              if (b.length > a.length) return b;

              return a;
            }).length * this.cellWidth;

          this.$nextTick().then(() => {
            const handler = setInterval(() => {
              this.typeColIndex += Math.min(10, this.asciiData[this.typeRowIndex].length - this.typeColIndex);

              if (
                this.typeColIndex >= this.asciiData[this.typeRowIndex].length
              )
                if (++this.typeRowIndex >= this.asciiData.length) {
                  clearInterval(handler);
                  this.$emit("complete");
                }
                else this.typeColIndex = 0;
            }, 10);
          });
       /* }*/
      },
    },
  },
  mounted() {},
};
</script>
<style scoped>
.ascii-char {
  font: 10px courier;
  stroke: none;
}
.type-cursor {
  fill: #00ff29;
}
.typed {
  stroke: #00ff29;
}
</style>