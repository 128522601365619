<template>
  <div class="grow text-center">
    <div class="audio-title">
      {{ $t("yakuza.audio.title") }}
    </div>
    <div class="audio-subtitle">
      {{ $t("yakuza.audio.subtitle") }}
    </div>    
    <SoundPlayer :src="`${casePublicAudio}/emergencycall/R1.mp3`"></SoundPlayer>
  </div>
</template>
  
<script>
  import SoundPlayer from "@/components/yakuza/SoundPlayer";
  import currentGameMixin from "@/mixins/currentGameMixin";
  
  export default {
    name: "Audio",

    components: {
      SoundPlayer
    },

    mixins: [currentGameMixin],

    data: () => ({    
    }),

    methods: { },
    computed: { },
    mounted() { }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';

  .audio-title {
    color: #fff;
    font: 600 14pt "Arimo";
    margin: 15px auto 10px auto;
  }
  .audio-subtitle {
    color: #bbb;
    font: 600 12pt "Arimo";
    margin: 10px auto 20px auto;
  }

  .text-center {
    margin: auto;
  }
</style>
  