import montageAuthors from "./choices/montageAuthors";
import { comparePersonChoices } from "../utils";

export default {
    protagonists: [
        { id: "you", localized: true },
        { id: "officer", variable: true }
    ],
    dialogs: [
        {
            id: "policeman",
            entryPoint: true,
            choices: [
                {
                    value: "bond"
                },
                {
                    value: "danzig"
                },
                {
                    value: "mckaye"
                },
                {
                    value: "morello"
                },
                {
                    value: "scott"
                },
                {
                    value: "gadget"
                },
                {
                    value: "harry"
                },
                {
                    value: "malek"
                },
                {
                    value: "lambert"
                },
                {
                    value: "halpert"
                },
                {
                    value: "lynch",
                },
                {
                    value: "scully"
                },
                {
                    value: "bernard"
                },
                {
                    value: "white"
                }
            ],
            localizedChoices: true,
            sorted: true,
            setVariable: "officer",
            outcomes: {
                lynch: "introduction",
                default: "introductionFail"
            }
        },
        { id: "introduction", protagonist: "you", next: "heardOfYou" },
        { id: "introductionFail", protagonist: "you", next: "notInCharge" },
        { id: "notInCharge", protagonist: "officer", deadEnd: true },
        { id: "heardOfYou", protagonist: "officer", next: "whatHappenedQuestion" },
        { id: "whatHappenedQuestion", protagonist: "officer", next: "whatHappenedChoice" },
        {
            id: "whatHappenedChoice",
            protagonist: "you",
            choices: [
                {
                    value: "choice0"
                },
                {
                    value: "choice1"
                },
                {
                    value: "choice2"
                },
                {
                    value: "choice3"
                },
                {
                    value: "choice4"
                },
                {
                    value: "choice5"
                },
                {
                    value: "choice6"
                },
                {
                    value: "choice7",
                },
                {
                    value: "choice8"
                },
                {
                    value: "choice9"
                },
                {
                    value: "choice10"
                },
                {
                    value: "choice11"
                },
                {
                    value: "choice12"
                }
            ],
            localizedChoices: true,
            outcomes: {
                choice7: "authorQuestion",
                default: "whatHappenedWrongAnswer"
            }
        },
        { id: "whatHappenedWrongAnswer", protagonist: "officer", next: "whatHappenedChoice" },
        { id: "authorQuestion", protagonist: "officer", next: "authorChoice" },
        {
            id: "authorChoice",
            protagonist: "you",
            choices: montageAuthors,
            sorted: true,
            sortFunc: comparePersonChoices,
            outcomes: {
                "Damian Hammer": "partnerQuestion1",
                "Basil Hills": "partnerQuestion2",
                default: "authorWrongAnswer"
            }
        },
        { id: "authorWrongAnswer", protagonist: "officer", next: "authorChoice" },
        { id: "partnerQuestion1", protagonist: "officer", next: "partnerChoice1" },
        {
            id: "partnerChoice1",
            protagonist: "you",
            choices: montageAuthors,
            sorted: true,
            sortFunc: comparePersonChoices,
            outcomes: {
                "Basil Hills": "postalCardsQuestion",
                default: "partnerWrongAnswer1"
            }
        },
        { id: "partnerWrongAnswer1", protagonist: "officer", next: "partnerChoice1" },
        { id: "partnerQuestion2", protagonist: "officer", next: "partnerChoice2" },
        {
            id: "partnerChoice2",
            protagonist: "you",
            choices: montageAuthors,
            sorted: true,
            sortFunc: comparePersonChoices,
            outcomes: {
                "Damian Hammer": "postalCardsQuestion",
                default: "partnerWrongAnswer2"
            }
        },
        { id: "partnerWrongAnswer2", protagonist: "officer", next: "partnerChoice2" },
        { id: "postalCardsQuestion", protagonist: "officer", next: "postalCardsChoice" },
        {
            id: "postalCardsChoice",
            protagonist: "you",
            choices: [
                {
                    value: "choice1"
                },
                {
                    value: "choice2"
                },
                {
                    value: "choice3"
                },
                {
                    value: "choice4"
                },
                {
                    value: "choice5"
                },
                {
                    value: "choice6"
                },
                {
                    value: "choice7",
                },
                {
                    value: "choice8"
                },
                {
                    value: "choice9"
                },
                {
                    value: "choice10"
                }
            ],
            sorted: true,
            localizedChoices: true,
            outcomes: {
                choice8: "epilog",
                default: "postalCardsWrongAnswer"
            }
        },
        { id: "postalCardsWrongAnswer", protagonist: "officer", next: "postalCardsChoice" },
        { id: "keepInvestigating", protagonist: "officer", deadEnd: true },
        {
            id: "epilog",
            protagonist: "officer",
            finalDialog: true
        }
    ]
}