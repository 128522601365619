import Vue from 'vue'
import Vuex from 'vuex'
import { normalizeCompareStrings } from "@/util/string-utils";
import Cases from "@/enums/Cases";
import LookupResultEnum from "../enums/LookupResultEnum";
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import { cloneObject } from '../utils';
import createGhostsPlugin from './plugins/ghostsPlugin';

Vue.use(Vuex)

const pluginGhosts = createGhostsPlugin();

function getSection(state, gameID, id) {
    //TODO: pass gameID as argument
    let section = undefined;

    state[gameID].sections.forEach(s => {
        if (s.id === id)
            section = s;

        if (s.subsections && s.subsections.length > 0) {
            s.subsections.forEach(ss => {
                if (ss.id === id)
                    section = ss;
            })
        }
    });

    return section;
}

function getLockableSections(state) {
    const allSections = {};
    const keys = Object.keys(state);

    keys.forEach(key => {
        const prop = state[key];

        if (typeof prop === "object" && "sections" in prop) {
            const sections = [];

            prop.sections.forEach(s => {
                if (s.lockable)
                    sections.push({
                        id: s.id,
                        locked: s.locked,
                        disabled: ("disabled" in s) ? s.disabled : false
                    });

                if (s.subsections && s.subsections.length > 0) {
                    s.subsections.forEach(ss => {
                        sections.push({
                            id: ss.id,
                            locked: ss.locked,
                            disabled: ("disabled" in ss) ? ss.disabled : false
                        });
                    });
                }
            });

            allSections[key] = sections;
        }
    });

    return allSections;
}

function saveSectionsState(state) {
    localStorage.setItem('lockedSections', JSON.stringify(getLockableSections(state)));
}

function colossusGetNextCabinID(state, cabinID) {
    const subsections = getSection(state, Cases.COLOSSUS, "cabins").subsections;
    const index = subsections.findIndex(ss => ss.id === cabinID);

    if (index === -1 || index >= subsections.length - 1)
        return ""

    return subsections[index + 1].id;
}

function mykonosGetSearchedDestinations(state) {
    const searchedDestinations = {};

    state.mykonos.destinations.forEach(d => searchedDestinations[d.id] = d.searched);

    return searchedDestinations;
}

function mykonosParseCoords(coords) {
    return {
        x: coords.match(/([a-zA-Z])/)[0],
        y: coords.match(/(\d+)/)[0]
    };
}

function mykonosCompareCoords(coords1, coords2) {
    const parsed1 = mykonosParseCoords(coords1);
    const parsed2 = mykonosParseCoords(coords2);

    return (parsed1.x === parsed2.x && parsed1.y === parsed2.y);
}

export default new Vuex.Store({
    plugins: [pluginGhosts],
    state: {
        initialized: false,
        currentGame: "",
        maestro: {
            availableLocales: [],
            tutorialDone: false,
            evaluateUrl: "",
            sections: [],
            details: [],
            safeCode: "",
            safeError: false,
            phonePins: "",
            phoneError: false,
            dialPhoneNumber: ""
        },
        colossus: {
            availableLocales: [],
            tutorialDone: false,
            sections: [],
            details: [],
            cabins: {},
            webmail: {
                email: "",
                fullName: "",
                passwords: [],
                error: false
            }
        },
        mykonos: {
            availableLocales: [],
            tutorialDone: false,
            researchTutoDone: false,
            sections: [],
            details: [],
            destinations: [],
            targetDestination: "",
            researchDeadEnd: false,
            blogLogin: "",
            blogPassword: "",
            blogError: false,
            flightAway: {
                fullName: "",
                bookingName: "",
                flightNumber: "",
                cardNumber: "",
                lookupResult: LookupResultEnum.UNKNOWN,
                lookupErrors: {
                    name: false,
                    flightNumber: false,
                    cardNumber: false,
                    passportNumber: "",
                    dateOfBirth: new Date(),
                    placeOfBirth: ""
                }
            }
        },
        ghosts: {
            config: {},
            availableLocales: [],
            tutorialDone: false,
            sections: [],
            details: [],
            chats: {
                dailyYorkshire: {
                    protagonists: [],
                    dialogs: [],
                    variables: {},
                    currentDialogId: null,
                    log: [],
                    deadEnd: false
                },
                police: {
                    protagonists: [],
                    dialogs: [],
                    variables: {},
                    currentDialogId: null,
                    log: [],
                    deadEnd: false
                },
            },
            ouija: {
                solution: ""
            },
            tarot: {
                slotCount: 0,
                solution: [],
                defaultPositions: [],
                error: false
            },
            hotel: {
                spot: "",
                error: false
            },
            horizon: {
                questions: [],
                errors: []
            },
            touristOffice: {
                secretPlaces: {
                    password: '',
                    locked: true,
                    error: false
                }
            }
        },
        egypt: {
            config: {},
            availableLocales: [],
            tutorialDone: false,
            sections: [],
            details: [],
            security: {
                solution1: null,
                solution2: null,
                defaultPosition: 0,
                error: false,
                step: 1,
            },
            chats: {
                police_egypt: {
                    protagonists: [],
                    dialogs: [],
                    variables: {},
                    currentDialogId: null,
                    log: [],
                    deadEnd: false,
                }
            },
            suitcase: {
                solution1: null,
                solution2: null,
                leftCode: null,
                rightCode: null,
            },
            phone: {
                pins: "",
                error: false,
            },
            karnak: {
                correctAngles: [],
                startAngles: [],
                error: false,
                tolerance: null,
                step: 1,
                reactiveRanges: [],
                books: [],
            },
            webmail: {
                error: false,
                errorDetail: {
                    university: false,
                    name: false,
                    dob: false,
                    studentnum: false
                },
                correct: {
                    university: "",
                    name: "",
                    dob: "",
                    studentnum: ""
                }
            },
            call: {
                callNumber: "",
                secret: "",
            },
            resolve: {
                solutions: [],
                error: false,
                solved: false,
                step: 0,
            }
        },
        socialNetworks: []
    },
    getters: {
        initialized: (state) => {
            return state.initialized;
        },
        supportedLocales: () => {
            return getSupportedLocales();
        },
        gameAvailableLocales: (state) => (gameID) => {
            const supportedLocales = getSupportedLocales();
            console.log(supportedLocales)
            return state[gameID].availableLocales.map(l => supportedLocales.find(sl => sl.code === l));
        },
        sections: (state) => (gameID) => {
            return state[gameID].sections;
        },
        details: (state) => (gameID) => {
            return state[gameID].details;
        },
        getSectionById: (state) => (payload) => {
            return getSection(state, payload.gameID, payload.sectionID);
        },
        evaluateUrl: (state) => (gameID) => {
            if ("evaluateUrl" in state[gameID])
                return state[gameID].evaluateUrl;

            return undefined;
        },
        maestroSafeError: (state) => {
            return state.maestro.safeError;
        },
        maestroPhoneError: (state) => {
            return state.maestro.phoneError;
        },
        maestroPoliceLocked: (state) => {
            return getSection(state, Cases.MAESTRO, "police").locked;
        },
        maestroPhoneLocked: (state) => {
            return getSection(state, Cases.MAESTRO, "phone").locked;
        },
        maestroSafeLocked: (state) => {
            return getSection(state, Cases.MAESTRO, "safe").locked;
        },
        maestroDialPhoneNumber: (state) => {
            return state.maestro.dialPhoneNumber;
        },
        maestroSecurityLocked: (state) => {
            return getSection(state, Cases.MAESTRO, "security").locked;
        },
        maestroTutorialDone: (state) => {
            return state.maestro.tutorialDone;
        },

        colossusDarkwebSecretLength: (state) => {
            return state.colossus.darkwebSecret.length;
        },
        colossusDarkwebLocked: (state) => {
            return getSection(state, Cases.COLOSSUS, "darkweb").locked;
        },
        colossusCabinUnlockAllowed: (state) => (cabinID) => {
            const cabinsSection = state.colossus.sections.find(c => c.id === "cabins");
            const cabinIndex = cabinsSection.subsections.findIndex(ss => ss.id === cabinID);

            if (cabinIndex === 0)
                return true;

            const previousIndex = cabinIndex - 1;
            const previousSection = cabinsSection.subsections[previousIndex];

            return !previousSection.locked;
        },
        colossusCabinLocked: (state) => (cabinID) => {
            return getSection(state, Cases.COLOSSUS, cabinID).locked;
        },
        colossusCabinError: (state) => (payload) => {
            return state.colossus.cabins[payload.cabinID].errors[payload.field];
        },
        colossusCabinFullname: (state) => (cabinID) => {
            const cabinCfg = state.colossus.cabins[cabinID];

            return `${cabinCfg.firstName} ${cabinCfg.lastName}`;
        },
        colossusWebmailEmail: (state) => {
            return state.colossus.webmail.email;
        },
        colossusWebmailFullName: (state) => {
            return state.colossus.webmail.fullName;
        },
        colossusWebmailLocked: (state) => {
            return getSection(state, Cases.COLOSSUS, "webmail").locked;
        },
        colossusWebmailError: (state) => {
            return state.colossus.webmail.error;
        },
        colossusTutorialDone: (state) => {
            return state.colossus.tutorialDone;
        },

        mykonosDestinations: (state) => {
            return state.mykonos.destinations;
        },
        mykonosDestination: (state) => (destinationID) => {
            return state.mykonos.destinations.find(d => d.id === destinationID);
        },
        mykonosTargetDestination: (state) => {
            return state.mykonos.targetDestination;
        },
        mykonosResearchDeadEnd: (state) => {
            return state.mykonos.researchDeadEnd;
        },
        mykonosResearchCount: (state) => {
            return state.mykonos.destinations.filter(d => !d.searched && !d.bonusDestination).length;
        },
        mykonosDestinationError: (state) => (destinationID) => {
            return state.mykonos.destinations.find(d => d.id === destinationID).error;
        },
        mykonosDestinationSearched: (state) => (destinationID) => {
            return state.mykonos.destinations.find(d => d.id === destinationID).searched;
        },
        mykonosBlogLocked: (state) => {
            return getSection(state, Cases.MYKONOS, "blog").locked;
        },
        mykonosBlogLogin: (state) => {
            return state.mykonos.blogLogin;
        },
        mykonosBlogPassword: (state) => {
            return state.mykonos.blogPassword;
        },
        mykonosBlogError: (state) => {
            return state.mykonos.blogError;
        },
        mykonosFlightAwayLocked: (state) => {
            return getSection(state, Cases.MYKONOS, "flightaway").locked;
        },
        mykonosVoicesLocked: (state) => {
            return getSection(state, Cases.MYKONOS, "voices").locked;
        },
        mykonosHospitalIsPatient: (getters) => (fullName) => {
            const destinationID = "hospital";
            const destination = getters.mykonosDestination(destinationID);

            return (destination.patients.findIndex(dp => {
                const dTokens = dp.split(" ");
                const pTokens = fullName.split(" ");

                const tokensKO = dTokens.filter(dt => {
                    return (pTokens.findIndex(pt => (normalizeCompareStrings(pt, dt) === 0)) === -1);
                });

                return (tokensKO.length === 0);
            }) !== -1);
        },
        mykonosTutorialDone: (state) => {
            return state.mykonos.tutorialDone;
        },
        mykonosResearchTutoDone: (state) => {
            return state.mykonos.researchTutoDone;
        },
        ghostsChatProtagonists: (state) => (chatId) => {
            return state.ghosts.chats[chatId].protagonists;
        },
        ghostsChatVariables: (state) => (chatId) => {
            return state.ghosts.chats[chatId].variables;
        },
        ghostsChatInitialDialog: (state) => (chatId) => {
            return state.ghosts.chats[chatId].dialogs.find(d => d.entryPoint);
        },
        ghostsChatCurrentDialog: (state) => (chatId) => {
            return state.ghosts.chats[chatId].dialogs.find(d => d.id === state.ghosts.chats[chatId].currentDialogId);
        },
        ghostsChatLog: (state) => (chatId) => {
            return state.ghosts.chats[chatId].log;
        },
        ghostsChatDeadend: (state) => (chatId) => {
            return state.ghosts.chats[chatId].deadEnd;
        },
        ghostsChatComplete: (state) => (chatId) => {
            return (state.ghosts.chats[chatId].log.findIndex(e => e.finalDialog) !== -1);
        },
        ghostsTarotSlotCount: (state) => {
            return state.ghosts.tarot.slotCount;
        },
        ghostsTarotDefaultPositions: (state) => {
            return state.ghosts.tarot.defaultPositions;
        },
        ghostsTarotLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "tarot").locked;
        },
        ghostsTarotError: (state) => {
            return state.ghosts.tarot.error;
        },
        ghostsDailyYorkshireLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "daily").locked;
        },
        ghostsPoliceLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "police").locked;
        },
        ghostsHorizonLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "horizon").locked;
        },
        ghostsOuijaLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "ouija").locked;
        },
        ghostsOuijaShowCrosshair: (state) => {
            return state.ghosts.ouija.showCrosshair;
        },
        ghostsHotelLocked: (state) => {
            return getSection(state, Cases.GHOSTS, "hotel").locked;
        },
        ghostsHotelError: (state) => {
            return state.ghosts.hotel.error;
        },
        ghostsHorizonQuestions: (state) => {
            return state.ghosts.horizon.questions.map(q => q.id);
        },
        ghostsHorizonErrors: (state) => {
            return state.ghosts.horizon.errors;
        },
        ghostsSecretPlacesLocked: (state) => {
            return state.ghosts.touristOffice.secretPlaces.locked;
        },
        ghostsSecretPlacesError: (state) => {
            return state.ghosts.touristOffice.secretPlaces.error;
        },
        ghostsTutorialDone: (state) => {
            return state.ghosts.tutorialDone;
        },

        egyptSecurityDefaultPosition: (state) => {
            return state.egypt.security.defaultPosition;
        },
        egyptSecurityLocked: (state) => {
            return getSection(state, Cases.EGYPT, "security").locked;
        },
        egyptSecurityError: (state) => {
            return state.egypt.security.error;
        },
        egyptSecurityStep: (state) => {
            return state.egypt.security.step;
        },
        egyptChatProtagonists: (state) => (chatId) => {
            return state.egypt.chats[chatId].protagonists;
        },
        egyptChatVariables: (state) => (chatId) => {
            return state.egypt.chats[chatId].variables;
        },
        egyptChatInitialDialog: (state) => (chatId) => {
            return state.egypt.chats[chatId].dialogs.find(d => d.entryPoint);
        },
        egyptChatCurrentDialog: (state) => (chatId) => {
            return state.egypt.chats[chatId].dialogs.find(d => d.id === state.egypt.chats[chatId].currentDialogId);
        },
        egyptChatLog: (state) => (chatId) => {
            return state.egypt.chats[chatId].log;
        },
        egyptChatDeadend: (state) => (chatId) => {
            return state.egypt.chats[chatId].deadEnd;
        },
        egyptChatComplete: (state) => (chatId) => {
            return (state.egypt.chats[chatId].log.findIndex(e => e.finalDialog) !== -1);
        },
        egyptPoliceLocked: (state) => {
            return getSection(state, Cases.EGYPT, "police").locked;
        },
        egyptSuitcaseLocked: (state) => {
            return getSection(state, Cases.EGYPT, "suitcase").locked;
        },
        egyptSuitcaseLockedLeft: (state) => {
            return ![state.egypt.suitcase.solution1, state.egypt.suitcase.solution2].includes(state.egypt.suitcase.leftCode);
        },
        egyptSuitcaseLockedRight: (state) => {
            return ![state.egypt.suitcase.solution1, state.egypt.suitcase.solution2].includes(state.egypt.suitcase.rightCode);
        },
        egyptSuitcaseCodeLeft: (state) => {
            return state.egypt.suitcase.leftCode;
        },            
        egyptSuitcaseCodeRight: (state) => {
            return state.egypt.suitcase.rightCode;
        },
        egyptPhoneError: (state) => {
            return state.egypt.phone.error;
        },
        egyptPhoneLocked: (state) => {
            return getSection(state, Cases.EGYPT, "phone").locked;
        },
        egyptKarnakError: (state) => {
            return state.egypt.karnak.error;
        },
        egyptKarnakStartAngles: (state) => {
            return state.egypt.karnak.startAngles;
        },
        egyptKarnakTolerance: (state) => {
            return state.egypt.karnak.tolerance;
        },
        egyptKarnakCorrectAngles: (state) => {
            return state.egypt.karnak.correctAngles;
        },
        egyptKarnakLocked: (state) => {
            return getSection(state, Cases.EGYPT, "karnak").locked;
        },
        egyptKarnakStep: (state) => {
            return state.egypt.karnak.step;
        },
        egyptKarnakReactiveRanges: (state) => {
            return state.egypt.karnak.reactiveRanges;
        },
        egyptKarnakBooks: (state) => {
            return state.egypt.karnak.books;
        },
        egyptWebmailLocked: (state) => {
            return getSection(state, Cases.EGYPT, "webmail").locked;
        },
        egyptWebmailError: (state) => {
            return state.egypt.webmail.error;
        },
        egyptWebmailErrorDetail: (state) => {
            return state.egypt.webmail.errorDetail;
        },
        egyptWebmailCorrectInfos: (state) => {
            return state.egypt.webmail.correct;
        },
        egyptCallNumber: (state) => {
            return state.egypt.call.callNumber;
        },      
        egyptCallSecret: (state) => {
            return state.egypt.call.secret;
        },
        egyptCallLocked: (state) => {
            return getSection(state, Cases.EGYPT, "call").locked;
        },
        egyptResolveError: (state) => {
            return state.egypt.resolve.error;
        },
        egyptResolveStep: (state) => {
            return state.egypt.resolve.step;
        },
        egyptResolveSolutions: (state) => {
            return state.egypt.resolve.solutions;
        },
        egyptResolveSolved: (state) => {
            return state.egypt.resolve.solved;
        },
        egyptTutorialDone: (state) => {
            return state.egypt.tutorialDone;
        }
    },
    mutations: {
        initializeStore(state, config) {
            state.maestro.availableLocales = config.maestro.availableLocales;
            state.maestro.sections = config.maestro.sections;
            state.maestro.details = config.maestro.caseDetails;
            state.maestro.safeCode = config.maestro.safe.code;
            state.maestro.phonePins = config.maestro.phone.pins;
            state.maestro.dialPhoneNumber = config.maestro.dialPhone.number;
            state.maestro.evaluateUrl = config.maestro.evaluateUrl

            state.colossus.availableLocales = config.colossus.availableLocales;
            state.colossus.sections = config.colossus.sections;
            state.colossus.details = config.colossus.caseDetails;
            state.colossus.darkwebSecret = config.colossus.darkweb.secret;
            state.colossus.cabins = config.colossus.cabins;
            state.colossus.webmail.email = config.colossus.webmail.email;
            state.colossus.webmail.fullName = config.colossus.webmail.fullName;
            state.colossus.webmail.passwords = config.colossus.webmail.passwords;
            state.colossus.evaluateUrl = config.colossus.evaluateUrl

            state.mykonos.availableLocales = config.mykonos.availableLocales;
            state.mykonos.sections = config.mykonos.sections;
            state.mykonos.details = config.mykonos.caseDetails;
            state.mykonos.destinations = config.mykonos.research.destinations
                .map(d => {
                    let dc = {};

                    Object.assign(dc, d);
                    dc.searched = false;
                    dc.errors = [];

                    return dc;
                });
            state.mykonos.blogLogin = config.mykonos.blog.userLogin;
            state.mykonos.blogPassword = config.mykonos.blog.userPassword;
            state.mykonos.flightAway.fullName = config.mykonos.flightAway.fullName;
            state.mykonos.flightAway.name = config.mykonos.flightAway.name;
            state.mykonos.flightAway.flightNumbers = config.mykonos.flightAway.flightNumbers;
            state.mykonos.flightAway.cardNumber = config.mykonos.flightAway.cardNumber;
            state.mykonos.flightAway.passportNumber = config.mykonos.flightAway.passportNumber;
            state.mykonos.flightAway.dateOfBirth = new Date(Date.parse(config.mykonos.flightAway.dateOfBirth));
            state.mykonos.flightAway.placeOfBirth = config.mykonos.flightAway.placeOfBirth;
            state.mykonos.evaluateUrl = config.mykonos.evaluateUrl

            for (let cabinID in state.colossus.cabins) {
                state.colossus.cabins[cabinID].errors = {
                    lastName: false,
                    firstName: false,
                    cabinNumber: false
                };
            }

            state.ghosts.availableLocales = config.ghosts.availableLocales;
            state.ghosts.sections = cloneObject(config.ghosts.sections);
            state.ghosts.details = config.ghosts.caseDetails;
            state.ghosts.config = cloneObject(config.ghosts);

            for (let chatId in state.ghosts.chats) {
                const chatState = state.ghosts.chats[chatId];

                chatState.protagonists = config.ghosts.chats[chatId].protagonists;
                chatState.dialogs = config.ghosts.chats[chatId].dialogs;

                chatState.protagonists.forEach(p => {
                    if (p.variable) {
                        chatState.variables[p.id] = null;
                    }
                })
            }

            state.ghosts.ouija.solution = config.ghosts.ouija.solution;
            state.ghosts.ouija.showCrosshair = config.ghosts.ouija.showCrosshair;
            state.ghosts.tarot.slotCount = config.ghosts.tarot.slotCount;
            state.ghosts.tarot.defaultPositions = config.ghosts.tarot.defaultPositions;
            state.ghosts.tarot.solution = config.ghosts.tarot.solution;
            state.ghosts.hotel.spot = config.ghosts.hotel.spot;
            state.ghosts.horizon.questions = config.ghosts.horizon.questions;
            state.ghosts.touristOffice.secretPlaces.password = config.ghosts.touristOffice.secretPlaces.password;

            state.egypt.availableLocales = config.egypt.availableLocales;
            state.egypt.sections = cloneObject(config.egypt.sections);
            state.egypt.details = config.egypt.caseDetails;
            state.egypt.config = cloneObject(config.egypt);
            state.egypt.security.defaultPosition = config.egypt.security.defaultPosition;
            state.egypt.security.solution1 = config.egypt.security.solution1;
            state.egypt.security.solution2 = config.egypt.security.solution2;
            state.egypt.suitcase.solution1 = config.egypt.suitcase.solution1;
            state.egypt.suitcase.solution2 = config.egypt.suitcase.solution2;
            state.egypt.phone.pins = config.egypt.phone.pins;
            state.egypt.karnak.startAngles = config.egypt.karnak.startAngles;
            state.egypt.karnak.correctAngles = config.egypt.karnak.correctAngles;
            state.egypt.karnak.tolerance = config.egypt.karnak.tolerance;
            state.egypt.karnak.reactiveRanges = config.egypt.karnak.reactiveRanges;
            state.egypt.karnak.books = config.egypt.karnak.karnak_books.books;
            state.egypt.webmail.correct = config.egypt.webmail.correct;
            state.egypt.call.callNumber = config.egypt.call.callNumber;
            state.egypt.call.secret = config.egypt.call.secret;
            state.egypt.resolve.solutions = config.egypt.resolve.solutions;

            for (let chatId in state.egypt.chats) {
                const chatState = state.egypt.chats[chatId];

                chatState.protagonists = config.egypt.chats[chatId].protagonists;
                chatState.dialogs = config.egypt.chats[chatId].dialogs;

                chatState.protagonists.forEach(p => {
                    if (p.variable) {
                        chatState.variables[p.id] = null;
                    }
                })
            }

            const savedTutorialMaestro = localStorage.getItem('tutorialMaestroDone');
            if(savedTutorialMaestro) {
                state.maestro.tutorialDone = (savedTutorialMaestro === 'Y')
            }

            const savedTutorialColossus = localStorage.getItem('tutorialColossusDone');
            if(savedTutorialColossus) {
                state.colossus.tutorialDone = (savedTutorialColossus === 'Y')
            }

            const savedTutorialMykonos = localStorage.getItem('tutorialMykonosDone');
            if(savedTutorialMykonos) {
                state.mykonos.tutorialDone = (savedTutorialMykonos === 'Y')
            }

            const savedResearchTutoMykonos = localStorage.getItem('researchTutoMykonosDone');
            if(savedResearchTutoMykonos) {
                state.mykonos.researchTutoDone = (savedResearchTutoMykonos === 'Y')
            }
            const savedTutorialEgypt = localStorage.getItem('tutorialEgyptDone');
            if(savedTutorialEgypt) {
                state.egypt.tutorialDone = (savedTutorialEgypt === 'Y')
            }

            const savedTutorialGhosts = localStorage.getItem('tutorialGhostsDone');
            if(savedTutorialGhosts) {
                state.ghosts.tutorialDone = (savedTutorialGhosts === 'Y')
            }

            const savedSections = localStorage.getItem('lockedSections');

            if (savedSections) {
                try {
                    const lockedSections = JSON.parse(savedSections);

                    if (lockedSections) {
                        // Compatibility fix: import and normalize standalone maestro stored settings (one time fix)
                        if (Array.isArray(lockedSections)) {
                            lockedSections.forEach(ls => {
                                const storeSection = getSection(state, Cases.MAESTRO, ls.id);

                                if (storeSection) {
                                    storeSection.locked = ls.locked;
                                }
                            });
                            saveSectionsState(state);
                        }
                        // General mode: settings are stored on a per game basis
                        else {
                            for (const gameID in lockedSections) {
                                lockedSections[gameID].forEach(ls => {
                                    const storeSection = getSection(state, gameID, ls.id);

                                    if (storeSection) {
                                        storeSection.locked = ls.locked;

                                        if ("disabled" in ls)
                                            storeSection.disabled = ls.disabled;
                                    }
                                });
                            }
                        }

                    }
                }
                catch (e) {
                    console.error(`Can not load locked sections from local storage: ${e}`);
                }
            }

            // Enable colossus cabin unlock UI if previous cabin in list is already unlocked
            const colossusCabinsSection = getSection(state, Cases.COLOSSUS, "cabins")
            let previousCabinUnlocked = false;

            colossusCabinsSection.subsections.forEach(ss => {
                if (ss.disabled && previousCabinUnlocked)
                    ss.disabled = false;

                previousCabinUnlocked = !ss.locked
            });

            const savedDestinations = localStorage.getItem("mykonosSearchedDestinations");

            if (savedDestinations) {
                const searchedDestinations = JSON.parse(savedDestinations);

                state.mykonos.destinations.forEach(d => {
                    if (searchedDestinations && d.id in searchedDestinations) {
                        d.searched = searchedDestinations[d.id];
                    }
                    else {
                        d.searched = false;
                    }
                });
            } else {
                state.mykonos.destinations.forEach(d => d.searched = false);
            }

            state.socialNetworks = config.common.socialNetworks;
            state.initialized = true;
        },
        lockSection(state, payload) {
            const section = getSection(state, payload.gameID, payload.sectionID);

            if (section) section.locked = true;
        },
        unlockSection(state, payload) {
            const section = getSection(state, payload.gameID, payload.sectionID);

            if (section) section.locked = false;
        },
        enableSection(state, payload) {
            const section = getSection(state, payload.gameID, payload.sectionID);

            if (section) section.disabled = false;
        },
        saveSectionsState(state) {
            saveSectionsState(state);
        },
        resetGame(state, gameID) {
            const gameState = state[gameID];

            gameState.sections.forEach((section, sectionIndex) => {
                section.locked = section.lockable;

                if ("disabled" in section) {
                    section.disabled = gameState.config.sections[sectionIndex]?.disabled ?? false;
                }

                if (section.subsections) {
                    section.subsections.forEach((subsection, subSectionIndex) => {
                        subsection.locked = subsection.lockable;

                        if (gameID === Cases.COLOSSUS && section.id === "cabins") {
                            subsection.disabled = (subSectionIndex > 0);
                        }

                        if ("disabled" in subsection) {
                            if (gameID === Cases.MYKONOS) {
                                if (subsection.id === "blog")
                                    subsection.disabled = false;
                                else
                                    subsection.disabled = ("lockable" in subsection) ? subsection.lockable : false;
                            }
                            else
                                subsection.disabled = gameState.config?.sections[sectionIndex]?.subsections[subSectionIndex]?.disabled ?? false;
                        }
                    })
                }
            });

            if (gameID === Cases.MYKONOS) {
                state.mykonos.tutorialDone = false;
                state.mykonos.researchTutoDone = false;
                localStorage.setItem('tutorialMykonosDone', 'N')
                localStorage.setItem('researchTutoMykonosDone', 'N')

                state.mykonos.destinations.forEach(d => d.searched = false);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            } else if (gameID === Cases.COLOSSUS) {
                state.colossus.tutorialDone = false;
                localStorage.setItem('tutorialColossusDone', 'N')
            } else if (gameID === Cases.MAESTRO) {
                state.maestro.tutorialDone = false;
                localStorage.setItem('tutorialMaestroDone', 'N')
            } else if (gameID === Cases.GHOSTS) {
                state.ghosts.tutorialDone = false;
                localStorage.setItem('tutorialGhostsDone', 'N')

                state.ghosts.touristOffice.secretPlaces.locked = true;
                localStorage.setItem('ghostsSecretPlacesLocked', true);
            } else if (gameID === Cases.EGYPT) {
                state.egypt.security.step = 1;
                state.egypt.karnak.step = 1;
                this.dispatch("egyptResetChat", { chatId: 'police_egypt' });
                state.egypt.suitcase.leftCode = '';
                state.egypt.suitcase.rightCode = '';
                state.egypt.resolve.solved = false;
                state.egypt.resolve.step = 0;
                state.egypt.tutorialDone = false;
                localStorage.setItem('tutorialEgyptDone', 'N')
            }
        },
        maestroSetSafeError(state) {
            state.maestro.safeError = true;
        },
        maestroResetSafeError(state) {
            state.maestro.safeError = false;
        },
        maestroSetPhoneError(state) {
            state.maestro.phoneError = true;
        },
        maestroResetPhoneError(state) {
            state.maestro.phoneError = false;
        },
        maestroTutorialDone(state) {
            state.maestro.tutorialDone = true;
        },  

        colossusSetCabinError(state, payload) {
            state.colossus.cabins[payload.cabinID].errors[payload.field] = true;
        },
        colossusResetCabinError(state, payload) {
            state.colossus.cabins[payload.cabinID].errors[payload.field] = false;
        },
        colossusSetWebmailError(state) {
            state.colossus.webmail.error = true;
        },
        colossusResetWebmailError(state) {
            state.colossus.webmail.error = false;
        },
        colossusTutorialDone(state) {
            state.colossus.tutorialDone = true;
        },      

        mykonosSetResearchDestination(state, destinationID) {
            state.mykonos.targetDestination = destinationID;
        },
        mykonosSetResearchDeadEnd(state) {
            state.mykonos.researchDeadEnd = true;
        },
        mykonosResetResearch(state) {
            state.mykonos.researchDeadEnd = false;
            state.mykonos.targetDestination = "";
        },
        mykonosSetDestinationSearched(state, destinationID) {
            state.mykonos.destinations.find(d => d.id === destinationID).searched = true;
        },
        mykonosSetDestinationError(state, payload) {
            const destination = state.mykonos.destinations.find(d => d.id === payload.destinationID);

            if (destination.errors.indexOf(payload.errorID) === -1)
                destination.errors.push(payload.errorID);
        },
        mykonosResetDestinationErrors(state, destinationID) {
            const destination = state.mykonos.destinations.find(d => d.id === destinationID);

            destination.errors = [];
        },
        mykonosSetBlogError(state) {
            state.mykonos.blogError = true;
        },
        mykonosResetBlogError(state) {
            state.mykonos.blogError = false;
        },
        mykonosSetFlightAwayLookupError(state, fieldName) {
            state.mykonos.flightAway.lookupErrors[fieldName] = true;
        },
        mykonosResetFlightAwayLookup(state) {
            for (let prop in state.mykonos.flightAway.lookupErrors) {
                state.mykonos.flightAway.lookupErrors[prop] = false;
            }

            state.mykonos.flightAway.lookupResult = LookupResultEnum.UNKNOWN;
        },
        mykonosTutorialDone(state) {
            state.mykonos.tutorialDone = true;
        },  
        mykonosResearchTutoDone(state) {
            state.mykonos.researchTutoDone = true;
        }, 
        ghostsSetChatDeadend(state, chatId) {
            state.ghosts.chats[chatId].deadEnd = true;
        },
        ghostsResetChat(state, chatId) {
            const chatState = state.ghosts.chats[chatId];

            chatState.currentDialogId = chatState.dialogs.find(d => d.entryPoint).id;
            chatState.log = [];
            chatState.deadEnd = false;

            for (let key in chatState.variables)
                chatState.variables[key] = null;
        },
        ghostsLogChatStatement(state, chatId) {
            const chatState = state.ghosts.chats[chatId];

            const currentDialogId = chatState.currentDialogId;
            const dialog = chatState.dialogs.find(d => d.id === currentDialogId);
            chatState.log.push({ id: dialog.id, protagonist: dialog.protagonist, finalDialog: dialog.finalDialog || false, deadEnd: dialog.deadEnd || false });
        },
        ghostsLogChatQuestion(state, chatId) {
            const chatState = state.ghosts.chats[chatId];

            const currentDialogId = chatState.currentDialogId;
            const dialog = chatState.dialogs.find(d => d.id === currentDialogId);

            chatState.log.push({
                id: dialog.id,
                protagonist: dialog.protagonist,
                entryPoint: dialog.entryPoint || false,
                choices: (dialog.choices) ? dialog.choices.map(c => {
                    return {
                        value: c.value,
                        selected: false
                    }
                }) : undefined,
                localizedChoices: dialog.localizedChoices || false,
                sorted: dialog.sorted || false,
                sortFunc: dialog.sortFunc ?? undefined
            });
        },
        ghostsLogChatAnswer(state, payload) {
            const chatState = state.ghosts.chats[payload.chatId];

            const currentDialogId = chatState.currentDialogId;
            const dialog = chatState.dialogs.find(d => d.id === currentDialogId);
            const logEntry = chatState.log.slice(-1)[0];
            const choice = logEntry.choices.find(c => c.value === payload.answer);

            if (dialog.setVariable) {
                const key = dialog.setVariable;
                chatState.variables[key] = choice.value;
            }

            choice.selected = true;
        },
        ghostsSetChatCurrentDialog(state, payload) {
            console.log(`set current dialog : ${payload.dialogId} for chat : ${payload.chatId}`);
            state.ghosts.chats[payload.chatId].currentDialogId = payload.dialogId;
        },
        ghostsSetHorizonErrors(state, errors) {
            state.ghosts.horizon.errors = errors;
        },
        ghostsResetHorizonErrors(state) {
            state.ghosts.horizon.errors = [];
        },
        ghostsResetHorizonError(state, questionId) {
            const index = state.ghosts.horizon.errors.indexOf(questionId);

            if (index !== -1)
                state.ghosts.horizon.errors.splice(index, 1);
        },
        ghostsSetTarotError(state) {
            state.ghosts.tarot.error = true;
        },
        ghostsResetTarotError(state) {
            state.ghosts.tarot.error = false;
        },
        ghostsSetHotelError(state) {
            state.ghosts.hotel.error = true;
        },
        ghostsResetHotelError(state) {
            state.ghosts.hotel.error = false;
        },
        ghostsSetSecretPlacesError(state) {
            state.ghosts.touristOffice.secretPlaces.error = true;
        },
        ghostsResetSecretPlacesError(state) {
            state.ghosts.touristOffice.secretPlaces.error = false;
        },
        ghostsSetSecretPlacesLocked(state, locked) {
            state.ghosts.touristOffice.secretPlaces.locked = locked;
        },
        ghostsUnlockSecretPlaces(state) {
            state.ghosts.touristOffice.secretPlaces.locked = false;
        },
        ghostsTutorialDone(state) {
            state.ghosts.tutorialDone = true;
        },  

        egyptSecuritySetError(state) {
            state.egypt.security.error = true;
        },
        egyptSecurityResetError(state) {
            state.egypt.security.error = false;
        },
        egyptSecurityNextStep(state) {
            state.egypt.security.step = 2;
        },
        egyptSecurityReset(state) {
            state.egypt.security.step = 1;
        },
        egyptSetChatDeadend(state, chatId) {
            state.egypt.chats[chatId].deadEnd = true;
        },
        egyptResetChat(state, chatId) {
            const chatState = state.egypt.chats[chatId];

            chatState.currentDialogId = chatState.dialogs.find(d => d.entryPoint).id;
            chatState.log = [];
            chatState.deadEnd = false;

            for (let key in chatState.variables)
                chatState.variables[key] = null;
        },
        egyptLogChatStatement(state, chatId) {
            const chatState = state.egypt.chats[chatId];

            const currentDialogId = chatState.currentDialogId;
            const dialog = chatState.dialogs.find(d => d.id === currentDialogId);
            chatState.log.push({ id: dialog.id, protagonist: dialog.protagonist, finalDialog: dialog.finalDialog || false, deadEnd: dialog.deadEnd || false, typingId: dialog.typingId || undefined });
        },
        egyptLogChatQuestion(state, chatId) {
            const chatState = state.egypt.chats[chatId];

            var currentDialogId = chatState.currentDialogId;
            var dialog = chatState.dialogs.find(d => d.id === currentDialogId);
            chatState.log.push({
                id: dialog.id,
                protagonist: dialog.protagonist,
                entryPoint: dialog.entryPoint || false,
                choices: (dialog.choices) ? dialog.choices.map(c => {
                    return {
                        value: c.value,
                        selected: false
                    }
                }) : undefined,
                localizedChoices: dialog.localizedChoices || false,
                typingId: dialog.typingId || undefined,
                sorted: dialog.sorted || false,
                sortFunc: dialog.sortFunc ?? undefined
            });

            if(currentDialogId === chatState.dialogs.find(d => d.entryPoint === true).id) {
                while(!dialog.choices) {
                    currentDialogId = dialog.next;
                    dialog = chatState.dialogs.find(d => d.id === currentDialogId);                  
                    chatState.log.push({
                        id: dialog.id,
                        protagonist: dialog.protagonist,
                        entryPoint: dialog.entryPoint || false,
                        choices: (dialog.choices) ? dialog.choices.map(c => {
                            return {
                                value: c.value,
                                selected: false
                            }
                        }) : undefined,
                        localizedChoices: dialog.localizedChoices || false,
                        typingId: dialog.typingId || undefined,
                        sorted: dialog.sorted || false,
                        sortFunc: dialog.sortFunc ?? undefined
                    });
                }
            }
            
            chatState.currentDialogId = currentDialogId;
        },
        egyptLogChatAnswer(state, payload) {
            const chatState = state.egypt.chats[payload.chatId];

            const currentDialogId = chatState.currentDialogId;
            const dialog = chatState.dialogs.find(d => d.id === currentDialogId);
            const logEntry = chatState.log.slice(-1)[0];
            const choice = logEntry.choices.find(c => c.value === payload.answer);

            if (dialog.setVariable) {
                const key = dialog.setVariable;
                chatState.variables[key] = choice.value;
            }

            choice.selected = true;
        },
        egyptSetChatCurrentDialog(state, payload) {
            console.log(`set current dialog : ${payload.dialogId} for chat : ${payload.chatId}`);
            state.egypt.chats[payload.chatId].currentDialogId = payload.dialogId;
        },
        egyptSetSuitcaseLeftCode(state, code) {
            state.egypt.suitcase.leftCode = code;
        },
        egyptSetSuitcaseRightCode(state, code) {
            state.egypt.suitcase.rightCode = code;
        },
        egyptSetPhoneError(state) {
            state.egypt.phone.error = true;
        },
        egyptResetPhoneError(state) {
            state.egypt.phone.error = false;
        },
        egyptSetKarnakError(state) {
            state.egypt.karnak.error = true;
        },
        egyptResetKarnakError(state) {
            state.egypt.karnak.error = false;
        },
        egyptKarnakNextStep(state) {
            state.egypt.karnak.step = 2;
        },
        egyptKarnakResetError(state) {
            state.egypt.karnak.step = 1;
        },
        egyptResetWebmailError(state) {
            state.egypt.webmail.error = false;
        },
        egyptSetWebmailErrorDetail(state, payload) {
            state.egypt.webmail.errorDetail = payload;
        },        
        egyptSetWebmailError(state) {
            state.egypt.webmail.error = true;
        },
        egyptResetResolveError(state) {
            state.egypt.resolve.error = false;
        },
        egyptSetResolveError(state) {
            state.egypt.resolve.error = true;
        },
        egyptResolveNextStep(state) {
            state.egypt.resolve.step++;
        },
        egyptResolveSolve(state) {
            state.egypt.resolve.solved = true;
        },
        egyptTutorialDone(state) {
            state.egypt.tutorialDone = true;
        },                      
    },
    actions: {
        lockSection({ commit }, payload) {
            commit('lockSection', { gameID: payload.gameID, sectionID: payload.sectionID });
            commit("saveSectionsState");
        },
        unlockSection({ commit }, payload) {
            //TODO: pass gameID as argument
            commit('unlockSection', { gameID: payload.gameID, sectionID: payload.sectionID });
            commit("saveSectionsState");
        },
        enableSection({ commit }, payload) {
            //TODO: pass gameID as argument
            commit('enableSection', { gameID: payload.gameID, sectionID: payload.sectionID });
            commit("saveSectionsState");
        },
        maestroTryUnlockSafe({ state, commit }, code) {
            if (code.trim().toLowerCase() === state.maestro.safeCode.trim().toLowerCase()) {
                commit('unlockSection', { gameID: Cases.MAESTRO, sectionID: "safe" });
                commit("saveSectionsState");
            } else {
                commit('maestroSetSafeError');
            }
        },
        maestroResetSafe({ commit }) {
            commit('maestroResetSafeError');
        },
        resetGame({ state, commit, dispatch }, gameID) {
            //TODO: pass gameID as argument
            commit('resetGame', gameID);

            if (gameID === Cases.MAESTRO) {
                commit('maestroResetSafeError');
                commit("maestroResetPhoneError");
            } else if (gameID === Cases.GHOSTS) {
                for (let chatId in state.ghosts.chats) {
                    dispatch('ghostsResetChat', {chatId: chatId});
                }
            }

            commit("saveSectionsState");
        },
        maestroTryUnlockPhone({ state, commit }, pin) {
            if (state.maestro.phonePins.indexOf(pin) !== -1) {
                commit('unlockSection', { gameID: Cases.MAESTRO, sectionID: "phone" });
                commit("saveSectionsState");
            } else {
                commit('maestroSetPhoneError');
            }
        },
        maestroResetPhone({ commit }) {
            commit("maestroResetPhoneError");
        },
        maestroTutorialDone({ commit }) {
            commit("maestroTutorialDone");
            localStorage.setItem('tutorialMaestroDone', 'Y');
        },        
        colossusTryUnlockDarkweb({ state, commit }, code) {
            if (code === state.colossus.darkwebSecret) {
                commit("unlockSection", { gameID: Cases.COLOSSUS, sectionID: "darkweb" });
                commit("saveSectionsState");
            }
        },
        colossusTryUnlockCabin({ state, commit }, cabinDetails) {
            const cabinConfig = state.colossus.cabins[cabinDetails.cabinID];
            let errorFlag = false;

            if (normalizeCompareStrings(cabinDetails.lastName, cabinConfig.lastName) !== 0) {
                this.commit("colossusSetCabinError", { cabinID: cabinDetails.cabinID, field: "lastName" });
                errorFlag = true
            }

            if (normalizeCompareStrings(cabinDetails.firstName, cabinConfig.firstName) !== 0) {
                this.commit("colossusSetCabinError", { cabinID: cabinDetails.cabinID, field: "firstName" });
                errorFlag = true;
            }

            if (normalizeCompareStrings(cabinDetails.cabinNumber, cabinConfig.cabinNumber) !== 0) {
                this.commit("colossusSetCabinError", { cabinID: cabinDetails.cabinID, field: "cabinNumber" });
                errorFlag = true;
            }

            if (!errorFlag) {
                commit("unlockSection", { gameID: Cases.COLOSSUS, sectionID: cabinDetails.cabinID });
                commit("saveSectionsState");

                const nextCabinID = colossusGetNextCabinID(state, cabinDetails.cabinID);

                if (nextCabinID !== "") {
                    commit("enableSection", { gameID: Cases.COLOSSUS, sectionID: nextCabinID });
                }
            }

        },
        colossusResetCabinError({ commit }, payload) {
            commit("colossusResetCabinError", payload);
        },
        colossusTryUnlockWebmail({ state, commit }, credentials) {
            if (credentials.email.toLowerCase().trim() === state.colossus.webmail.email.toLowerCase().trim()
                && credentials.allowedPasswords.findIndex(p => p.toLowerCase().trim() === credentials.password.toLowerCase().trim()) !== -1) {
                commit("unlockSection", { gameID: Cases.COLOSSUS, sectionID: "webmail" });
                commit("saveSectionsState");
            }
            else {
                commit("colossusSetWebmailError");
            }
        },
        colossusResetWebmailError({ commit }) {
            commit("colossusResetWebmailError");
        },
        colossusTutorialDone({ commit }) {
            commit("colossusTutorialDone");
            localStorage.setItem('tutorialColossusDone', 'Y');
        },        
        mykonosSendResearchTeam({ getters, commit }, coords) {
            // Tolérance format coordonnées (ex: H10 ou 10H)
            const destination = getters.mykonosDestinations.find(d => mykonosCompareCoords(d.coords.toLowerCase().trim(), coords.toLowerCase().trim()));

            if (destination === undefined) {
                commit("mykonosSetResearchDeadEnd");
            }
            else {
                commit("mykonosSetResearchDestination", destination.id);
            }
        },
        mykonosResetResearch({ commit }) {
            commit("mykonosResetResearch");
        },
        mykonosCompleteDestination({ state, commit, getters }, destinationID) {
            const destination = getters.mykonosDestination(destinationID);

            commit("mykonosSetDestinationSearched", destinationID);
            commit("unlockSection", { gameID: Cases.MYKONOS, sectionID: destination.unlockedEvidence });
            commit("enableSection", { gameID: Cases.MYKONOS, sectionID: destination.unlockedEvidence });
            commit("saveSectionsState");
            localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
        },
        mykonosCompleteLuxuryEstate({ dispatch }) {
            const destinationID = "luxuryEstate";

            dispatch("mykonosCompleteDestination", destinationID)
        },
        mykonosTrySearchHospital({ state, commit, getters }, fullNames) {
            const destinationID = "hospital";
            const destination = getters.mykonosDestination(destinationID);

            fullNames.forEach((fn, index) => {
                const nTokens = fn.split(" ");

                const findIndex = destination.patients.findIndex(p => {
                    const pTokens = p.split(" ");

                    const tokensKO = nTokens.filter(nt => {
                        return (pTokens.findIndex(pt => (normalizeCompareStrings(pt, nt) === 0)) === -1);
                    })

                    return (tokensKO.length === 0);
                });

                if (findIndex === -1)
                    commit("mykonosSetDestinationError", { destinationID: destinationID, errorID: index })
            })

            if (destination.errors.length === 0) {
                commit("mykonosSetDestinationSearched", destinationID);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            }
        },
        mykonosResetDestinationErrors({ commit }, destinationID) {
            commit("mykonosResetDestinationErrors", destinationID);
        },
        mykonosTrySearchCasino({ state, commit, getters }, personName) {
            const destinationID = "casino";
            const destination = getters.mykonosDestination(destinationID);

            const pTokens = personName.split(" ");
            const dTokens = destination.personName.split(" ");

            const tokensKO = dTokens.filter(dt => {
                return (pTokens.findIndex(pt => (normalizeCompareStrings(pt, dt) === 0)) === -1);
            })

            if (tokensKO.length === 0) {
                commit("mykonosSetDestinationSearched", destinationID);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            }
            else {
                commit("mykonosSetDestinationError", { destinationID: destinationID, errorID: "" });
            }
        },
        mykonosTrySearchSanGiovani({ state, commit, getters }, spot) {
            const destinationID = "sanGiovani";
            const destination = getters.mykonosDestination(destinationID);

            if (spot === destination.spot) {
                commit("mykonosSetDestinationSearched", destinationID);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            }
            else {
                commit("mykonosSetDestinationError", { destinationID: destinationID, errorID: "" });
            }
        },
        mykonosCompleteAirport({ dispatch }) {
            const destinationID = "airport";

            dispatch("mykonosCompleteDestination", destinationID)
        },
        mykonosTrySearchPalaceHotel({ state, commit, getters }, safeCode) {
            const destinationID = "palaceHotel";
            const destination = getters.mykonosDestination(destinationID);

            if (safeCode.trim() === destination.safeCode.trim()) {
                commit("mykonosSetDestinationSearched", destinationID);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            }
            else {
                commit("mykonosSetDestinationError", { destinationID: destinationID, errorID: "" });
            }
        },
        mykonosTrySearchPoliceStation({ state, commit, getters }, caseNumber) {
            const destinationID = "policeStation";
            const destination = getters.mykonosDestination(destinationID);

            if (caseNumber.toLowerCase().trim() === destination.caseNumber.toLowerCase().trim()) {
                commit("mykonosSetDestinationSearched", destinationID);
                localStorage.setItem('mykonosSearchedDestinations', JSON.stringify(mykonosGetSearchedDestinations(state)));
            }
            else {
                commit("mykonosSetDestinationError", { destinationID: destinationID, errorID: "" });
            }
        },
        mykonosTryLoginBlog({ commit, getters }, password) {
            const sectionID = "blog";

            if (password.toLowerCase().replace(" ", "") === getters.mykonosBlogPassword.toLowerCase().replace(" ", "")) {
                commit("unlockSection", { gameID: Cases.MYKONOS, sectionID: sectionID });
                commit("saveSectionsState");
            }
            else {
                commit("mykonosSetBlogError");
            }
        },
        mykonosResetBlogError({ commit }) {
            commit("mykonosResetBlogError");
        },
        mykonosTryLookupFlightAway({ state, commit }, payload) {
            // Compare names regardless of first name / last name order/casing/special characters
            const pTokens = payload.name.split(" ");
            const sTokens = state.mykonos.flightAway.name.split(" ");

            const tokensKO = sTokens.filter(st => {
                return (pTokens.findIndex(pt => (normalizeCompareStrings(pt, st) === 0)) === -1);
            })

            if (tokensKO.length > 0)
                commit("mykonosSetFlightAwayLookupError", "name");

            // Ignore separators when comparing flight numbers
            if (state.mykonos.flightAway.flightNumbers.findIndex(n => n.replaceAll("-", "").toLowerCase().trim() === payload.flightNumber.replaceAll("-", "").toLowerCase().trim()) === -1)
                commit("mykonosSetFlightAwayLookupError", "flightNumber");

            // Ignore white spaces when comparing card numbers + accept hyphens
            if (payload.cardNumber.replaceAll(" ", "").replaceAll("-", "").toLowerCase() !== state.mykonos.flightAway.cardNumber.replaceAll(" ", "").replaceAll("-", "").toLowerCase())
                commit("mykonosSetFlightAwayLookupError", "cardNumber");

            if (state.mykonos.flightAway.lookupErrors.name ||
                state.mykonos.flightAway.lookupErrors.flightNumber ||
                state.mykonos.flightAway.lookupErrors.cardNumber) {
                state.mykonos.flightAway.lookupResult = LookupResultEnum.NORESULT;
            }
            else {
                state.mykonos.flightAway.lookupResult = LookupResultEnum.RESULT;
            }
        },
        mykonosResetLookupResult({ commit }) {
            commit("mykonosResetFlightAwayLookup");
        },
        mykonosCompleteChronakiBeachClub({ dispatch }) {
            const destinationID = "chronakiBeachClub";

            dispatch("mykonosCompleteDestination", destinationID)
        },
        mykonosCompleteArtemisClub({ dispatch }) {
            const destinationID = "artemisClub";

            dispatch("mykonosCompleteDestination", destinationID)
        },
        mykonosTutorialDone({ commit }) {
            commit("mykonosTutorialDone");
            localStorage.setItem('tutorialMykonosDone', 'Y');
        }, 
        mykonosResearchTutoDone({ commit }) {
            commit("mykonosResearchTutoDone");
            localStorage.setItem('researchTutoMykonosDone', 'Y');
        },        
        ghostsResetChat({ commit, getters }, payload) {
            commit("ghostsResetChat", payload.chatId);
            commit("ghostsLogChatQuestion", payload.chatId, getters.ghostsChatInitialDialog(payload.chatId));
        },
        ghostsTryAnswerChat({ getters, commit }, payload) {
            const dialog = getters.ghostsChatCurrentDialog(payload.chatId);
            const choice = dialog.choices.find(c => c.value === payload.answer);

            if (choice === undefined)
                commit("ghostsSetChatDeadend", payload.chatId);
            else {
                commit("ghostsLogChatAnswer", payload);

                const outcome = dialog.outcomes[payload.answer] ?? dialog.outcomes?.default;

                if (!outcome)
                    commit("ghostsSetChatDeadend", payload.chatId);
                else {
                    commit("ghostsSetChatCurrentDialog", { chatId: payload.chatId, dialogId: outcome });

                    let nextDialog = getters.ghostsChatCurrentDialog(payload.chatId);

                    while (nextDialog.choices === undefined) {
                        console.log(`next dialog for chat ${payload.chatId} is: ${nextDialog.next}`);
                        commit("ghostsLogChatStatement", payload.chatId);

                        if (nextDialog.finalDialog || nextDialog.deadEnd) break;

                        if (nextDialog.next) {
                            commit("ghostsSetChatCurrentDialog", { chatId: payload.chatId, dialogId: nextDialog.next });
                            nextDialog = getters.ghostsChatCurrentDialog(payload.chatId);
                        }
                    }

                    if (nextDialog.choices) {
                        commit("ghostsLogChatQuestion", payload.chatId);
                    } else if (nextDialog.deadEnd) {
                        commit("ghostsSetChatDeadend", payload.chatId);
                    }
                }
            }
        },
        ghostsTryUnlockTarot({ state, commit }, cards) {
            if (state.ghosts.tarot.solution.findIndex(c => cards.indexOf(c) === -1) === -1) {
                commit('unlockSection', { gameID: Cases.GHOSTS, sectionID: "tarot" });
                commit("saveSectionsState");
            }
            else
                commit("ghostsSetTarotError");
        },
        ghostsResetTarotError({ commit }) {
            commit("ghostsResetTarotError");
        },
        ghostsTryUnlockOuija({ state, commit }, word) {
            if (word.trim().toLowerCase() == state.ghosts.ouija.solution.trim().toLowerCase()) {
                commit('unlockSection', { gameID: Cases.GHOSTS, sectionID: "ouija" });
                commit('enableSection', { gameID: Cases.GHOSTS, sectionID: "tarot" });
                commit('enableSection', { gameID: Cases.GHOSTS, sectionID: "daily" });
                commit("saveSectionsState");
            }
        },
        ghostsTryUnlockHotel({ state, commit }, spotId) {
            if (spotId.trim().toLowerCase() == state.ghosts.hotel.spot.trim().toLowerCase()) {
                commit('unlockSection', { gameID: Cases.GHOSTS, sectionID: "hotel" });
                commit('enableSection', { gameID: Cases.GHOSTS, sectionID: "horizon" })
                commit("saveSectionsState");
            }
            else
                commit("ghostsSetHotelError");
        },
        ghostsResetHotelError({ commit }) {
            commit("ghostsResetHotelError");
        },
        ghostsTryRecoverHorizon({ state, commit }, answers) {
            const questions = state.ghosts.horizon.questions;
            const wrongAnswers = [];

            questions.forEach((q, index) => {
                const givenAnswer = answers[index];

                if (q.answers.findIndex(a => a.trim().toLowerCase() === givenAnswer.trim().toLowerCase()) === -1)
                    wrongAnswers.push(q.id);
            })

            if (wrongAnswers.length === 0) {
                commit("unlockSection", { gameID: Cases.GHOSTS, sectionID: "horizon" });
                commit("saveSectionsState");
            } else {
                commit("ghostsSetHorizonErrors", wrongAnswers);
            }
        },
        ghostsResetHorizonErrors({ commit }) {
            commit("ghostsResetHorizonErrors");
        },
        ghostsResetHorizonError({ commit }, questionId) {
            commit("ghostsResetHorizonError", questionId);
        },
        ghostsTryUnlockSecretPlaces({ state, commit }, password) {
            if (password.replace(' ', '').toLowerCase() === state.ghosts.touristOffice.secretPlaces.password.replace(' ', '').toLowerCase()) {
                commit('ghostsUnlockSecretPlaces');
            }
            else {
                commit("ghostsSetSecretPlacesError");
            }
        },
        ghostsResetSecretPlacesError({ commit }) {
            commit("ghostsResetSecretPlacesError");
        },
        ghostsTutorialDone({ commit }) {
            commit("ghostsTutorialDone");
            localStorage.setItem('tutorialGhostsDone', 'Y');
        },
        
        egyptTryUnlockSecurity1({ state, commit }, selected) {
            if (state.egypt.security.solution1 == selected) {
                commit("egyptSecurityNextStep");
            }
            else
                commit("egyptSecuritySetError");
        },
        egyptTryUnlockSecurity2({ state, commit }, selected) {
            if (state.egypt.security.solution2 == selected) {
                commit('unlockSection', { gameID: Cases.EGYPT, sectionID: "security" });
                commit('enableSection', { gameID: Cases.EGYPT, sectionID: "police" })
                commit("saveSectionsState");
            }
            else
                commit("egyptSecuritySetError");
        },
        egyptResetSecurityError({ commit }) {
            commit("egyptSecurityResetError");
        },
        egyptResetChat({ commit, getters }, payload) {
            commit("egyptResetChat", payload.chatId);
            commit("egyptLogChatQuestion", payload.chatId, getters.egyptChatInitialDialog(payload.chatId));

        },
        egyptTryAnswerChat({ getters, commit }, payload) {
            const dialog = getters.egyptChatCurrentDialog(payload.chatId);
            const choice = dialog.choices.find(c => c.value === payload.answer);

            if (choice === undefined)
                commit("egyptSetChatDeadend", payload.chatId);
            else {
                commit("egyptLogChatAnswer", payload);

                const outcome = dialog.outcomes[payload.answer] ?? dialog.outcomes?.default;

                if (!outcome)
                    commit("egyptSetChatDeadend", payload.chatId);
                else {
                    commit("egyptSetChatCurrentDialog", { chatId: payload.chatId, dialogId: outcome });

                    let nextDialog = getters.egyptChatCurrentDialog(payload.chatId);

                    while (nextDialog.choices === undefined) {
                        console.log(`next dialog for chat ${payload.chatId} is: ${nextDialog.next}`);
                        commit("egyptLogChatStatement", payload.chatId);

                        if (nextDialog.finalDialog || nextDialog.deadEnd) break;

                        if (nextDialog.next) {
                            commit("egyptSetChatCurrentDialog", { chatId: payload.chatId, dialogId: nextDialog.next });
                            nextDialog = getters.egyptChatCurrentDialog(payload.chatId);
                        }
                    }

                    if (nextDialog.choices) {
                        commit("egyptLogChatQuestion", payload.chatId);
                    } else if (nextDialog.deadEnd) {
                        commit("egyptSetChatDeadend", payload.chatId);
                    }
                }
            }
        },
        egyptTryUnlockSuitcaseLeft({ state, getters, commit }, code) {
            commit("egyptSetSuitcaseLeftCode", code);

            // Deux codes justes ET différents
            if(!getters.egyptSuitcaseLockedLeft && !getters.egyptSuitcaseLockedRight && state.egypt.suitcase.leftCode !=  state.egypt.suitcase.rightCode) {
                commit("unlockSection", { gameID: Cases.EGYPT, sectionID: "suitcase" });
                commit("saveSectionsState");
            }
        }, 
        egyptTryUnlockSuitcaseRight({ state, getters, commit }, code) {
            commit("egyptSetSuitcaseRightCode", code);

            // Deux codes justes ET différents
            if(!getters.egyptSuitcaseLockedLeft && !getters.egyptSuitcaseLockedRight && state.egypt.suitcase.leftCode !=  state.egypt.suitcase.rightCode) {
                commit("unlockSection", { gameID: Cases.EGYPT, sectionID: "suitcase" });
                commit("saveSectionsState");
            }
        },
        egyptTryUnlockPhone({ state, commit }, pin) {
            if (state.egypt.phone.pins.indexOf(pin) !== -1) {
                commit('unlockSection', { gameID: Cases.EGYPT, sectionID: "phone" });
                commit("saveSectionsState");
            } else {
                commit('egyptSetPhoneError');
            }
        },
        egyptResetKarnakError({ commit }) {
            commit("egyptResetKarnakError");
        },
        egyptTryUnlockKarnak1({ commit}) {
            commit("egyptKarnakNextStep");
        },
        egyptTryUnlockKarnak2({ getters, commit }, angles) {
            let correct = true;
            const correctAngles = getters.egyptKarnakCorrectAngles;
            const tolerance = getters.egyptKarnakTolerance;

            if(!correctAngles || !angles) {
                correct = false;
            } else if(correctAngles.length != 3 && angles.length != 3) {
                correct = false;
            } else {
                for(let i=0; i<3; i++) {
                    if(Math.abs(correctAngles[i] - angles[i]) > tolerance) {
                        correct = false;
                    }
                }
            } 

            if (correct) {
                commit('unlockSection', { gameID: Cases.EGYPT, sectionID: "karnak" });
                commit("saveSectionsState");
            } else {
                commit('egyptSetKarnakError');
            }
        },
        egyptTryUnlockWebmail({ getters, commit }, infos) {
            let correctInfos = getters.egyptWebmailCorrectInfos;

            let errorDetail = {
                university: correctInfos.university.findIndex(i => infos.university.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                name: correctInfos.name.findIndex(i => infos.name.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                dob: correctInfos.dob.findIndex(i => infos.dob.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                studentnum: correctInfos.studentnum.findIndex(i => infos.studentnum.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1
            }

            if (errorDetail.university && errorDetail.name && errorDetail.dob && errorDetail.studentnum) {
                commit('unlockSection', { gameID: Cases.EGYPT, sectionID: "webmail" });
                commit("saveSectionsState");
            } else {
                commit('egyptSetWebmailErrorDetail', errorDetail);
                commit('egyptSetWebmailError');
            }
        },
        egyptCheckWebmailLogin({ getters, commit }, infos) {
            let correctInfos = getters.egyptWebmailCorrectInfos;

            let errorDetail = {
                university: correctInfos.university.findIndex(i => infos.university.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                name: correctInfos.name.findIndex(i => infos.name.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                dob: correctInfos.dob.findIndex(i => infos.dob.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1,
                studentnum: correctInfos.studentnum.findIndex(i => infos.studentnum.localeCompare(i, undefined, { sensitivity: 'base' }) === 0) !== -1
            }

            commit('egyptSetWebmailErrorDetail', errorDetail);
        },
        egyptResetWebmailError({ commit }) {
            commit("egyptResetWebmailError");
        },
        egyptTryUnlockCall({ getters, commit}, secret) {
            let correctSecret = getters.egyptCallSecret;
            if(secret === correctSecret) {
                commit('unlockSection', { gameID: Cases.EGYPT, sectionID: "call" });
            }
        },
        egyptResetResolveError({ commit }) {
            commit("egyptResetResolveError");
        },        
        egyptTryUnlockResolveStep({ getters, commit }, selected) {
            let step = getters.egyptResolveStep;
            let solution = getters.egyptResolveSolutions[step];

            if (solution == selected) {
                commit('egyptResolveNextStep');
            } else {
                commit('egyptSetResolveError');
            }
        },
        egyptResolveSolve({ commit }) {
            commit("egyptResolveSolve");
        },
        egyptTutorialDone({ commit }) {
            commit("egyptTutorialDone");
            localStorage.setItem('tutorialEgyptDone', 'Y');
        }
    }
});