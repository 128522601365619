
export default {
    protagonists: [
        { id: "you", localized: true },
        { id: "officer", localized: true }
    ],
    dialogs: [
        {
            id: "hello",
            entryPoint: true,
            protagonist: "you", 
            next: "thanks"
        },
        {
            id: "thanks",
            protagonist: "officer", 
            next: "whoissmuggler"
        },
        {
            id: "whoissmuggler",            
            protagonist: "officer", 
            choices: [
                { value: "Ahmed Benali" },
                { value: "Amina Belkacem" },
                { value: "Ashley Parker" },
                { value: "Bjorn Svensson" },
                { value: "Choi Soo-yeon" },
                { value: "Claire Lecomte" },
                { value: "Corentin Pasquali" },
                { value: "David Chevalier" },
                { value: "David Lee" },
                { value: "Dylan Muller" },
                { value: "Emily Johnson" },
                { value: "Eric Perrin" },
                { value: "Esmeralda Sanchez" },
                { value: "Ethan Thompson" },
                { value: "Fatima Khelifi" },
                { value: "Fatima Mansoori" },
                { value: "Fumiko Nakamura" },
                { value: "Hamza Gharbi" },
                { value: "Isabella Müller" },
                { value: "Jacques Leroy" },
                { value: "Jason Reegan" },
                { value: "Jawad Rahaf" },
                { value: "Jean-Pierre Legrand" },
                { value: "Joris Rochat" },
                { value: "Julie Laurent" },
                { value: "Jung Eun-ji" },
                { value: "Karim Boualem" },
                { value: "Khaled Amiri" },
                { value: "Kim Ji-yoon" },
                { value: "Laura Dupont" },
                { value: "Léa Martin" },
                { value: "Léa Michaud" },
                { value: "Lee Min-ji" },
                { value: "Marie Dupuis" },
                { value: "Marion Suchet" },
                { value: "Mathieu Girard" },
                { value: "Min-joo Jung" },
                { value: "Nathalie Lefevre" },
                { value: "Olivia Lewis" },
                { value: "Pablo Rodriguez" },
                { value: "Park Eun-hye" },
                { value: "Pierre Dupont" },
                { value: "Pierre Durand" },
                { value: "Rayan Sallah" },
                { value: "Samira Amrouche" },
                { value: "Sasmira Bedjeguelal" },
                { value: "Song Hye-kyo" },
                { value: "valérie Dubois" },
                { value: "William Davis" },
                { value: "William Jackson" }
            ],
            outcomes: {
                "Corentin Pasquali": "smuggler",
                default: "smugglerFail"
            }
        },
        { id: "smugglerFail", protagonist: "officer", next: "whoissmuggler" },
        { id: "smuggler", protagonist: "officer", next: "whereismuggler" },
        { id: "whereismuggler", protagonist: "officer", 
            choices: [
                { value: "Abou El Sidi Luxor" },
                { value: "Al Fayrouz Hotel" },
                { value: "Al Gezera Appartment" },
                { value: "Al Moudira Hotel Luxor" },
                { value: "Al Sahaby Lane Restaurant" },
                { value: "Boutique Hotel Kings Valley Luxor" },
                { value: "El Tarboush Restaurant" },
                { value: "Food Court Luxor Temple" },
                { value: "Hilton Luxor Resort & Spa" },
                { value: "Home Sweet Home" },
                { value: "Iberotel Crown Emperor Luxor" },
                { value: "Isis Hotel Luxor" },
                { value: "Lantern Restaurant & Suites" },
                { value: "Riverside Palace Luxor" },
                { value: "Mövenpick Resort & Spa Luxor" },
                { value: "Nile Jewel Suites Luxor" },
                { value: "Nile Valley Hotel" },
                { value: "Oasis Hôtel Tower" },
                { value: "Oasis Restaurant" },
                { value: "Pavillon Winter Luxor" },
                { value: "Pyramisa Hotel Luxor" },
                { value: "Royal House Hotel" },
                { value: "Sheherazade Hotel" },
                { value: "Sofitel Winter Palace Luxor" },
                { value: "Sofra Hotel & Restaurant" },
                { value: "Sonesta St. George Hotel Luxor" },
                { value: "Steigenberger Nile Palace Luxor" },
                { value: "Hatshepsout Palace" },
                { value: "Thebes Hotel" },
                { value: "Villa Diletta Luxor" },
                { value: "West Bank Hotel" }
            ],
            outcomes: {
                "West Bank Hotel": "send",
                default: "sendFail"
            }
        },
        { id: "send", protagonist: "officer", next: "sendWait1" },
        { id: "sendFail", protagonist: "officer", next: "sendFailWait1" },         
        { id: "sendFailWait1", protagonist: "officer", next: "sendFailWait2", typingId: "typingNoText" },
        { id: "sendFailWait2", protagonist: "officer", next: "sendFailEnd", typingId: "typingNoText" },
        { id: "sendFailEnd", protagonist: "officer", next: "whereismuggler" },
        { id: "sendWait1", protagonist: "officer", next: "sendWait2", typingId: "typingNoText" },
        { id: "sendWait2", protagonist: "officer", next: "sendWait3", typingId: "typingNoText" },
        { id: "sendWait3", protagonist: "officer", next: "sendWait4", typingId: "typingNoText" },
        { id: "sendWait4", protagonist: "officer", next: "sendEnd", typingId: "typingNoText" },
        { id: "sendEnd", protagonist: "officer", finalDialog: true },
    ]
}