<template>
    <svg id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414.86 240.95">
        <g id="blanc">
            <polyline class="cls-3" points="142.27 174.46 41.57 174.46 .5 174.46 .5 106.07 .5 62.21 41.57 62.21 207.43 62.21"/>
            <line class="cls-3" x1="207.43" y1="174.46" x2="191.06" y2="174.46"/>
            <polyline class="cls-3" points="116.99 110.41 123.62 110.41 123.62 153.36 40.19 153.36 40.19 110.41 100.33 110.41"/>
            <g>
            <rect class="cls-3" x="92.98" y="91.19" width="31.36" height="9.87"/>
            <rect class="cls-3" x="100.33" y="101.23" width="16.65" height="16.65"/>
            <polygon class="cls-3" points="113.9 117.87 103.42 117.87 104.42 135.43 112.9 135.43 113.9 117.87"/>
            <g>
                <circle class="cls-3" cx="97.96" cy="96.09" r="2.05"/>
                <circle class="cls-3" cx="119.36" cy="96.09" r="2.05"/>
            </g>
            </g>
            <path class="cls-3" d="m207.43.5H71.75c-2.92,2.42-5.86,4.92-8.84,7.46-13.24,11.31-26.98,23.63-40.46,35.44-7.43,6.5-14.79,12.86-21.95,18.81"/>
            <polyline class="cls-3" points="160.96 127.66 167.33 127.66 167.33 110.56 158.59 110.56 137.72 110.56 129.22 110.56 129.22 127.66 137.72 127.66 137.81 127.66"/>
            <path class="cls-3" d="m190.31,136.48c2.15-.14,4.45-.3,6.92-.46,3.36-.21,6.74-.32,10.11-.32h.09"/>
            <path class="cls-3" d="m169.99,163.76c10.03,2.04,22.12,2.54,35.93,3.04.24.01.83.02,1.42.02h.09"/>
            <path class="cls-3" d="m163.75,162.19h-.01c-.4-.12-.8-.25-1.19-.38-8.9-2.94-15.69-7.8-20.08-16.11-3.09-5.88-4.34-12.23-4.66-18.04-.06-1.15-.09-2.27-.09-3.37,0-2.01.1-3.93.24-5.7h20.59c.01.11.02.22.04.34.26,2.37.85,5.6,2.37,8.73.43.89.94,1.79,1.53,2.64,2.57,3.71,5.84,5.5,10.35,6.25"/>
            <path class="cls-3" d="m207.43,77.66H32.13c-9.09,0-16.45,7.37-16.45,16.45v51.25c0,9.09,7.36,16.45,16.45,16.45h131.5"/>
            <polyline class="cls-3" points="41.57 77.66 41.57 62.2 49.93 54.76"/>
            <line class="cls-3" x1="41.57" y1="161.81" x2="41.57" y2="174.46"/>
            <path class="cls-3" d="m22.44,43.4h12.1"/>
            <path class="cls-3" d="m62.9,7.96h9.22"/>
            <path class="cls-3" d="m82.52,4.95c5.99-5.99-1.55,1.55,4.45-4.45"/>
            <path class="cls-3" d="m171.21,171.26c.37.22.81-.15.64-.55-1.32-3.26-3.03-9.14-1.52-15.98,1.74-7.83,7.73-17.01,15-17.88,2.48-.29,4.81.42,4.98-.17.24-.82-3.77-3.53-7.96-3.77-6.45-.37-11.35,5.23-12.81,6.9-5.34,6.11-9.41,17.22-4.33,25.62,1.73,2.87,4.09,4.7,5.98,5.83Z"/>
            <path class="cls-3" d="m170.94,168.13c.85.11,1.75.23,2.69.35,4.82.63,10.17,1.83,14.66,3.26,1.38.44,2.35,1.48,2.77,2.72.41,1.19.3,2.55-.45,3.72-5.94,9.31-12.67,21.89-18.17,33.68-4.23,9.08-7.54,17.7-10.15,25.62-.71,2.14-2.93,3.38-5.12,2.85-6.41-1.54-13.01-3.25-19.76-5.16-5.14-1.44-10.15-2.94-15.01-4.46-2.21-.7-3.48-3-2.89-5.24,2.54-9.65,6.32-20.89,12.07-32.91,3.14-6.57,6.73-12.71,10.69-18.1,1.92-2.6,3.93-5.02,6.01-7.23.9-.95,2.16-1.46,3.46-1.38,5.01.34,9.15.91,15.04,1.72"/>
            <path class="cls-3" d="m171.81,170.61c.26.26.43.56.49.89.19,1.09-.82,2.24-2.45,2.96-.59.27-1.26.47-1.98.6-1.82.32-3.49.06-4.48-.6-.49-.32-.81-.74-.9-1.23-.26-1.48,1.72-3.07,4.43-3.55.65-.12,1.28-.16,1.88-.13"/>
            <path class="cls-3" d="m148.93,175.46c-1.07-.06-2.11.34-2.85,1.07-5.3,5.26-7.96,12.07-11.84,19.61-4.78,9.3-7.95,17.99-10.1,25.45-.5,1.73.53,3.51,2.34,4.04,3.97,1.17,8.06,2.31,12.27,3.42,5.52,1.46,10.92,2.77,16.16,3.95,1.79.4,3.62-.56,4.21-2.21,2.19-6.12,4.96-12.79,8.48-19.81,4.58-9.13,9.16-18.86,14.1-26.06,1.27-1.85.34-4.31-1.87-4.97-3.67-1.1-7.04-2.01-10.99-2.49-8.17-.99-14.4-1.66-19.93-1.99Z"/>
            <path class="cls-3" d="m137.72,110.38v17.28"/>
            <path class="cls-3" d="m158.59,118.93v-8.46"/>
            <g>
            <g>
                <rect :class="classLeft" x="45.75" y="126.63" width="10.5" height="10.5"/>
                <g>
                <rect class="cls-3" x="45.75" y="114" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="51 116.37 47.67 122.14 54.33 122.14 51 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="45.75" y="139.26" width="10.5" height="10.5" transform="translate(102 289.02) rotate(180)"/>
                <polygon class="cls-4" points="51 147.4 54.33 141.62 47.67 141.62 51 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classLeft" x="58.63" y="126.63" width="10.5" height="10.5"/>
                <g>
                <rect class="cls-3" x="58.63" y="114" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="63.88 116.37 60.55 122.14 67.21 122.14 63.88 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="58.63" y="139.26" width="10.5" height="10.5" transform="translate(127.76 289.02) rotate(180)"/>
                <polygon class="cls-4" points="63.88 147.4 67.21 141.62 60.55 141.62 63.88 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classLeft" x="71.51" y="126.63" width="10.5" height="10.5"/>
                <g>
                <rect class="cls-3" x="71.51" y="114" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="76.76 116.37 73.43 122.14 80.1 122.14 76.76 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="71.51" y="139.26" width="10.5" height="10.5" transform="translate(153.53 289.02) rotate(180)"/>
                <polygon class="cls-4" points="76.76 147.4 80.1 141.62 73.43 141.62 76.76 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classLeft" x="84.4" y="126.63" width="10.5" height="10.5"/>
                <g>
                <rect class="cls-3" x="84.4" y="114" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="89.65 116.37 86.31 122.14 92.98 122.14 89.65 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="84.4" y="139.26" width="10.5" height="10.5" transform="translate(179.29 289.02) rotate(180)"/>
                <polygon class="cls-4" points="89.65 147.4 92.98 141.62 86.31 141.62 89.65 147.4"/>
                </g>
            </g>
            </g>
            <polyline class="cls-3" points="207.43 174.46 373.29 174.46 414.36 174.46 414.36 106.07 414.36 62.21 373.29 62.21 207.43 62.21"/>
            <g>
            <line class="cls-1" x1="207.43" y1="105.07" x2="116.99" y2="105.07"/>
            <polyline class="cls-1" points="100.33 105.07 15.68 105.07 .5 105.07"/>
            <line class="cls-1" x1="297.87" y1="105.07" x2="207.43" y2="105.07"/>
            <polyline class="cls-1" points="314.53 105.07 399.18 105.07 414.36 105.07"/>
            </g>
            <polyline class="cls-3" points="314.53 110.41 374.67 110.41 374.67 153.36 291.24 153.36 291.24 110.41 297.87 110.41"/>
            <g>
            <rect class="cls-3" x="290.52" y="91.19" width="31.36" height="9.87" transform="translate(612.4 192.25) rotate(180)"/>
            <rect class="cls-3" x="297.87" y="101.23" width="16.65" height="16.65" transform="translate(612.4 219.11) rotate(180)"/>
            <polygon class="cls-3" points="300.96 117.87 311.44 117.87 310.44 135.43 301.96 135.43 300.96 117.87"/>
            <g>
                <circle class="cls-3" cx="316.9" cy="96.09" r="2.05"/>
                <circle class="cls-3" cx="295.5" cy="96.09" r="2.05"/>
            </g>
            </g>
            <path class="cls-3" d="m207.43.5h135.68c2.92,2.42,5.86,4.92,8.84,7.46,13.24,11.31,26.98,23.63,40.47,35.44,7.43,6.5,14.79,12.86,21.95,18.81"/>
            <polyline class="cls-3" points="253.9 127.66 247.53 127.66 247.53 110.56 256.27 110.56 277.14 110.56 285.64 110.56 285.64 127.66 277.14 127.66 277.05 127.66"/>
            <path class="cls-3" d="m207.43,166.82h.09c.59,0,1.18-.01,1.42-.02,13.81-.5,25.9-1,35.93-3.04,2.18-.44,4.27-.97,6.25-1.57.4-.12.8-.25,1.19-.38,8.9-2.94,15.69-7.8,20.07-16.11,3.1-5.88,4.35-12.23,4.67-18.04.06-1.15.09-2.27.09-3.37,0-2.01-.1-3.93-.24-5.7h-20.59c-.01.11-.02.22-.04.34-.26,2.37-.86,5.6-2.37,8.73-.43.89-.94,1.79-1.53,2.64-2.58,3.71-5.84,5.5-10.36,6.25-4.43.73-10.07.44-17.46-.07-2.15-.14-4.45-.3-6.92-.46-3.36-.21-6.74-.32-10.11-.32h-.09"/>
            <path class="cls-3" d="m252.31,161.81h130.42c9.09,0,16.45-7.36,16.45-16.45v-51.25c0-9.08-7.36-16.45-16.45-16.45h-175.3"/>
            <polyline class="cls-3" points="373.29 77.66 373.29 62.2 364.93 54.76"/>
            <line class="cls-3" x1="373.29" y1="161.81" x2="373.29" y2="174.46"/>
            <path class="cls-3" d="m384.11,47.13l-41.37-39.17-1.97-1.87c-.8-.73-1.84-1.13-2.92-1.13H77.01c-1.08,0-2.12.4-2.92,1.13l-1.97,1.87L30.75,47.13c-2.87,2.65-1,7.46,2.91,7.46h347.54c3.91,0,5.78-4.81,2.91-7.46Z"/>
            <path class="cls-3" d="m392.42,43.4h-12.1"/>
            <path class="cls-3" d="m351.96,7.96h-9.22"/>
            <path class="cls-3" d="m332.34,4.95c-5.99-5.99,1.55,1.55-4.45-4.45"/>
            <path class="cls-3" d="m277.14,110.38v17.28"/>
            <path class="cls-3" d="m256.27,118.93v-8.46"/>
            <g>
            <g>
                <rect :class="classRight" x="358.61" y="126.63" width="10.5" height="10.5" transform="translate(727.72 263.76) rotate(180)"/>
                <g>
                <rect class="cls-3" x="358.61" y="114" width="10.5" height="10.5" transform="translate(727.72 238.5) rotate(180)"/>
                <polygon class="cls-4" points="363.86 116.37 367.19 122.14 360.53 122.14 363.86 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="358.61" y="139.26" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="363.86 147.4 360.53 141.62 367.19 141.62 363.86 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classRight" x="345.73" y="126.63" width="10.5" height="10.5" transform="translate(701.96 263.76) rotate(180)"/>
                <g>
                <rect class="cls-3" x="345.73" y="114" width="10.5" height="10.5" transform="translate(701.96 238.5) rotate(180)"/>
                <polygon class="cls-4" points="350.98 116.37 354.31 122.14 347.65 122.14 350.98 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="345.73" y="139.26" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="350.98 147.4 347.65 141.62 354.31 141.62 350.98 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classRight" x="332.84" y="126.63" width="10.5" height="10.5" transform="translate(676.19 263.76) rotate(180)"/>
                <g>
                <rect class="cls-3" x="332.84" y="114" width="10.5" height="10.5" transform="translate(676.19 238.5) rotate(180)"/>
                <polygon class="cls-4" points="338.1 116.37 341.43 122.14 334.76 122.14 338.1 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="332.84" y="139.26" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="338.1 147.4 334.76 141.62 341.43 141.62 338.1 147.4"/>
                </g>
            </g>
            <g>
                <rect :class="classRight" x="319.96" y="126.63" width="10.5" height="10.5" transform="translate(650.43 263.76) rotate(180)"/>
                <g>
                <rect class="cls-3" x="319.96" y="114" width="10.5" height="10.5" transform="translate(650.43 238.5) rotate(180)"/>
                <polygon class="cls-4" points="325.21 116.37 328.55 122.14 321.88 122.14 325.21 116.37"/>
                </g>
                <g>
                <rect class="cls-3" x="319.96" y="139.26" width="10.5" height="10.5"/>
                <polygon class="cls-4" points="325.21 147.4 321.88 141.62 328.55 141.62 325.21 147.4"/>
                </g>
            </g>
            </g>
            <path class="cls-3" d="m.5,137.63c16.84,0-1.2,0,15.65,0"/>
            <path class="cls-3" d="m399.18,137.63c16.84,0-1.2,0,15.65,0"/>
            <path class="cls-3" d="m174.88,164.61c-.37,1.25-.83,2.59-1.23,3.87"/>
            <path class="cls-3" d="m177.54,169.09c.06-.04.12-.09.18-.14,1.3-1.11,2.25-2.37,2.95-3.61"/>
            <path class="cls-3" d="m131.83,220.06c1.38-5.06,4.32-12.84,7.09-19.04,3.12-6.99,7.72-14.13,10.91-18.9"/>
            <path class="cls-3" d="m138.63,221.64c1.38-5.06,4.32-12.84,7.09-19.04,3.12-6.99,7.49-14.46,10.68-19.23"/>
            <path class="cls-3" d="m146.27,223.49c1.38-5.06,4.32-12.84,7.09-19.04,3.12-6.99,7.41-14.72,10.6-19.5"/>
            <path class="cls-3" d="m154.02,225.17c1.38-5.06,4.32-12.84,7.09-19.04,3.12-6.99,8.03-15.51,11.22-20.29"/>
        </g>        
    </svg>
</template>
<script>

export default {
  name: "SvgSuitcase",
  data() {
    return {
    };
  },
  props: {
    locked1: Boolean,
    locked2: Boolean,
  },
  computed: {
    isLockedLeft: function() {
        return this.locked1;
    },
    isLockedRight: function() {
        return this.locked2;
    },
    classLeft: function() {
        return this.isLockedLeft ? 'cls-2': 'cls-2-ok';
    },
    classRight: function() {
        return this.isLockedRight ? 'cls-2': 'cls-2-ok';
    }
  },
  methods: {
  },
  watch: {

  },
};
</script>

<style>
.cls-1 {
stroke-width: 3px;
}

.cls-1, .cls-2, .cls-3 {
stroke-miterlimit: 10;
}

.cls-1, .cls-3 {
fill: none;
stroke: #fff;
}

.cls-4 {
fill: #fff;
}

.cls-2 {
fill: #ffc122;
stroke: #000;
}

.cls-2-ok {
fill: #00ff29;;
stroke: #000;
}
</style>
