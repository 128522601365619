import Cases from "@/enums/Cases";

const retrieveSecretPlacesLocked = () => {
    let bLocked = true;

    try {
        const sLocked = localStorage.getItem("ghostsSecretPlacesLocked");

        if (sLocked) {
            bLocked = JSON.parse(sLocked);
        }
    }
    catch(e) {
        console.error('Could not retrieve ghostsSecretPlacesLocked from localStorage : ', e);
    }

    return bLocked;
}

const storeSecretPlacesLocked = (locked) => {
    localStorage.setItem("ghostsSecretPlacesLocked", JSON.stringify(locked));
}

export default function createGhostsPlugin() {
    return (store) => {
        store.subscribe((mutation) => {
            if (mutation.type === "initializeStore") {
                const bLocked = retrieveSecretPlacesLocked();
                store.commit("ghostsSetSecretPlacesLocked", bLocked);
            } else if ((mutation.type === "resetGame" && mutation.payload === Cases.GHOSTS) || mutation.type === 'ghostsUnlockSecretPlaces') {
                storeSecretPlacesLocked(false);
            }
        });
    }
}