<template>
  <v-container class="m-stops">
    <div class="stops-header">{{ $t("yakuza.metro.where") }}</div>
    <div class="line-header">
      <v-img 
        :src="`${casePublicImages}/metro/retour.svg`"
        class="line-header-img back"
        @click="$emit('onBack')">
      </v-img>
      <v-img 
        :src="`${casePublicImages}/metro/metro_${ line }.svg`"
        class="line-header-img"
      >
      </v-img>
      <span>{{ $t(`yakuza.metro.line_${ line }`) }}</span>
    </div>
    <div class="m-stops-icons"  v-for="(v, k, i) in $yakuzaStore.metro.lines[line].stops" :key="i">
      <div class="m-stop-icon">
        <v-img class="img-metro-stop-bg" :src="`${casePublicImages}/metro/bg_line_${ (k===0) ? 'b' : 'f' }_${ $yakuzaStore.metro.lines[line].code }.png`"></v-img>
        <v-img class="img-metro-stop" @click="selectStop(v)" :src="`${casePublicImages}/metro/metro_stop.png`"></v-img>
      </div>
      <span class="m-stop-text" @click="selectStop(v)">
        {{ v }}
      </span>
    </div>
    <Sound :src="`${casePublicAudio}/metro/moving.mp3`" ref="moveSound"></Sound>    
  </v-container>
</template>
<script>
  import Sound from "@/components/yakuza/Sound";
  import currentGameMixin from "@/mixins/currentGameMixin";

  export default {
    name: "MetroLine",
    emits: ["onStopSelected", "onBack"],
    components: { Sound },
    mixins: [currentGameMixin],
    data() {
      return { };
    },
    computed: { },
    props: {
      line: String,
    },
    mounted() { },
    methods: {
      selectStop(s) {
        this.$refs.moveSound.play();
        setTimeout(() => {
          this.$emit("onStopSelected", s);
        }, 3000);
      },
    }
  }
</script>
<style scoped lang="scss">
  @import '@/assets/css/yakuza.scss';

  .m-stops {
    text-align: center;
    background-color: #004577;
    min-height: 320px;

    div.stops-header {
      color: #fff;
      font: 600 14pt "Arimo";
      margin: 15px auto 20px auto;
    }

    div.line-header {
      color: #fff;
      font: 600 14pt "Arimo";
      padding-bottom: 15px;

      .line-header-img {
        @include size(48px, 48px);
        display: inline-block;

        &.back {
          @include size(32px, 32px);
          margin: 8px;

          @media only screen and (max-width: 600px) {
            @include size(24px, 24px); 
          }              
        }

        &.back:hover {
          cursor: pointer;
        }

        @media only screen and (max-width: 600px) {
          @include size(40px, 40px); 
        }      
      }

      span {
        @include size(220px, 48px);
        @include padding($t: 10px, $l: 10px);
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        text-align: left;
 
        @media only screen and (max-width: 600px) {
          @include size(180px, 48px);
          font-size: 13pt;  
        }  
      }
    }

    .m-stops-icons {
      height: 40px;
      .m-stop-icon {
        @include size(40px, 40px);
        display: inline-block;
        margin-left: 48px;
        cursor: pointer;

        @media only screen and (max-width: 600px) {
          margin-left: 24px;
        }      
      }

      .img-metro-stop-bg {
        @include size(40px, 40px);
        position: absolute;
      }

      span.m-stop-text {
        @include size(220px, 40px);
        @include padding($t: 8px, $l: 15px);
        display: inline-block;
        text-align: left;
        color: #fff;
        font-size: 12pt;
        vertical-align: top;
        transition: font-weight 0.1s, font-size 0.1s;

        &:hover {
          font: 600 13pt "Arimo";
          cursor: pointer;
        }

        @media only screen and (max-width: 600px) {
          @include size(180px, 40px);
          font-size: 11pt;
        }
      }
    }
  }
</style>