import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from "@/util/i18n/get-browser-locale"
import { supportedLocalesInclude } from "@/util/i18n/supported-locales"
import { getLocale } from "@/utils";

Vue.use(VueI18n)

function loadLocaleMessages () {
  const messages = {}  

  const locales = require.context(`./locales`, true, /[A-Za-z0-9-_,\s]+\.json$/i)

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  
  return messages
}

function getStartingLocale() {
  const appLocale = getLocale();
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  const defaultLocale = (appLocale) ? appLocale : browserLocale;

  if (supportedLocalesInclude(defaultLocale)) {
    return defaultLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
