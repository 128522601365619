<template>
  <v-container class="reader-container">
    <div class="w-header w-lille">
      <img :src="`${casePublicImages}/webmail/logo_lille.svg`" />
    </div> 
    <div class="w-bandeau">
      webmail université
    </div>        
    <v-card class="mx-auto" tile>
      <v-card-actions class="reader-toolbar">
        <router-link
          :to="{ name: 'egypt-webmail' }"
          class="reader-back-link"
          >
          <img :src="casePublicImages + '/webmail/back_icon.svg'" class="reader-back-icon"/>
          {{ $t("egypt.webmail.inbox") }}
        </router-link>
      </v-card-actions>
      <v-card-title>
        <h2 v-text="email.subject" class="email-subject"></h2>
      </v-card-title>
      <v-card-subtitle class="email-header-body">
        <v-row>
          <v-col cols="2" sm="1">
            <v-avatar :color="email.avatarColor">
              <span class="white--text text-h5">{{ email.fromName | initial }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="10" sm="11">
            <v-container class="email-header-container">
              <v-row dense>
                <v-col cols="12" class="email-header">
                  {{ $t("egypt.webmail.from") }}{{ email.fromName }}
                </v-col>
                <v-col cols="12" class="email-value">
                  &lt;{{ email.from }}&gt;
                </v-col>
                <v-col class="email-header">
                  {{ $t("egypt.webmail.to") }}{{ email.toName }}
                </v-col>
                <v-col cols="12" class="email-value">
                  &lt;{{ email.to }}&gt;
                </v-col>
                <v-col cols="12" class="email-header">
                  {{
                    getUTCDate(
                      new Date(Date.parse(email.timestamp))
                    ).toLocaleDateString(this.$i18n.locale, {
                      dateStyle: "medium",
                    })
                  }},&nbsp;
                  {{
                    getUTCDate(
                      new Date(Date.parse(email.timestamp))
                    ).toLocaleTimeString(this.$i18n.locale, {
                      timeStyle: "short",
                    })
                  }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text v-html="email.content" class="email-content"></v-card-text>
      <v-img :src="getSignature(email.from)" class="email-signature"></v-img>
      <template v-if="email.history">
        <v-container>
          <v-row
            v-for="(entry, index) in email.history"
            :key="`entry-${index}`"
            dense
          >
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{ $t("egypt.webmail.from") }}</span>
              <span class="entry-value">{{ entry.fromName }} &lt;{{ entry.from }}&gt;</span>
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{ $t("egypt.webmail.sent") }}</span>
              <span class="entry-value">{{ getUTCDate(
                    new Date(Date.parse(entry.timestamp))
                  ).toLocaleDateString($i18n.locale, { dateStyle: "long" })
                }}
                &nbsp;{{
                  getUTCDate(
                    new Date(Date.parse(entry.timestamp))
                  ).toLocaleTimeString($i18n.locale, { timeStyle: "short" })
                }}</span
              >
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{ $t("egypt.webmail.to") }}</span>
              <span class="entry-value">{{ entry.toName }} &lt;{{ entry.to }}&gt;</span>
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{ $t("egypt.webmail.subject") }}</span>
              <span class="entry-value">{{ entry.subject }}</span>
            </v-col>
            <v-col cols="12" class="entry-content" v-html="entry.content"></v-col>
            <v-col cols="12">
              <v-img
                :src="getSignature(entry.from)"
                class="email-signature"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    email: {},
    selected: -1,
  }),
  mixins: [currentGameMixin],
  props: {
    guid: String,
  },
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },    
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    filePath: function() {
      return `${this.dataPath}/webmail_${ this.$i18n.locale }.json`;
    },    
    signaturePath: function () {
      return `${this.publicPath}${this.caseID}/assets/images/webmail`;
    },
  },
  watch: {
    locale: function () {
      this.getLocalisedMail(); 
    },
  },  
  methods: {
    getUTCDate: function (localeDate) {
      const timeDiff = localeDate.getTimezoneOffset() * 60000;
      const adjustDate = localeDate.valueOf() + timeDiff;
      return new Date(adjustDate);
    },
    getDomainFromEmailAddress: function (address) {
      return address.split("@")[1].split(".")[0];
    },
    getSignature: function (address) {
      return `${this.signaturePath}/${this.getDomainFromEmailAddress(address)}.svg`;
    },
    getLocalisedMail: function() {
      axios.get(this.filePath).then(({ data }) => {
        this.email = data.find((email) => email.guid === this.guid);
      });
    },
  },
  mounted() {
    this.getLocalisedMail();
  },
};
</script>
<style scoped lang="scss">
.w-header {
  height: 100px;
  background: #ffffff;
  text-align: left;
  width: 100%;

  img {
    height: 50px;
    margin: 25px 0px 0px 12px; 
  } 
}
.w-bandeau {
  height: 40px;
  color: #FFF;
  text-align: center;
  font: normal 500 14px Roboto;
  text-transform: uppercase;
  background: #7A1818;
  padding-top: 10px;
  width:100%;
}
.reader-container {
  border: 1px solid #ffffff;
  border-radius: 7px;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.4);
  padding: 19px;
}
.reader-toolbar {
  background: #dbdbdb;
}
.reader-back-link {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.reader-back-icon {
  margin-left: 12px;
  margin-right: 10px;
  vertical-align: middle;
}
.email-header {
  font: normal 500 15px/19px "Plus Jakarta Sans";
  color: #000;
  letter-spacing: 0em;
  text-align: left;
}

.email-header-body {
    margin-left: 15px;
}
.email-header-container {
  padding-top: 0 !important;
}
.email-subject {
  font: normal 400 20px/25px Plus Jakarta Sans;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-word;
  padding-left: 15px;
}
.email-value {
  font: italic 400 12px/14px "Plus Jakarta Sans";
  color: #7f7f7f;
  padding-top: 0 !important;
  letter-spacing: 0em;
  text-align: left;
}
.email-content {
  font: normal 400 14px/19px "Plus Jakarta Sans";
  letter-spacing: 0em;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
  color: #000000 !important;
}
.email-signature {
  width: 80%;
  max-width: 150px;
  margin: 30px;
}
.entry-header {
  font: normal 700 13px/19px Plus Jakarta Sans;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 30px;
  color: #000;
}
.entry-value {
  font: italic 400 13px/14px Plus Jakarta Sans;
  letter-spacing: 0em;
  text-align: left;
  color: #7f7f7f;
}
.entry-content {
  font: normal 400 14px/19px Plus Jakarta Sans;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding-left: 35px !important;
  padding-top: 15px !important;
}
</style>