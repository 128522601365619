import { defineStore } from "pinia"
import { cloneObject } from '@/utils';
import { getSupportedLocales } from "@/util/i18n/supported-locales";


export const useYakuzaStore = defineStore("yakuza", {
    state: () => {
        return {
            initialized: false,
            initialConfig: {},
            availableLocales: "",
            sections: [],
            details: {},
            metro: {},
            hack: {},
            call: {},
            arcade: {},
            resolve: {}, 
            elevator: {},
            tutorialDone: false,
            metroTutoDone: false,
            callDone: false,
        }
    },
    actions: {
        initialize(config) {
            this.availableLocales = cloneObject(config.yakuza.availableLocales);
            this.sections = cloneObject(config.yakuza.sections);
            this.details = cloneObject(config.yakuza.caseDetails);
            this.metro = cloneObject(config.yakuza.metro);
            this.hack = cloneObject(config.yakuza.hack);
            this.call = cloneObject(config.yakuza.call); 
            this.arcade = cloneObject(config.yakuza.arcade);
            this.elevator = cloneObject(config.yakuza.elevator);
            this.safe = cloneObject(config.yakuza.safe);
            this.resolve = cloneObject(config.yakuza.resolve);
            this.resolve.solved = false;
            this.elevator.solved = false;
            this.initialConfig = config;
            this.initialized = true;
            this.tutorialDone = false;
            this.metroTutoDone = false;
            this.callDone = false;

            this.loadState();
        },
        getAvailableLocales() {
            const supportedLocales = getSupportedLocales();
            return this.availableLocales.map(l => supportedLocales.find(sl => sl.code === l));
        },
        findEvidence(s) {
            const evidences = this.sections.find((v) => v.id === "evidences");
            if(!evidences) {
                console.log("Section evidences not found");
                return;
            }

            const section = evidences.subsections.find((v) => v.id === s);
            if(!section) {
                console.log(`Subsection ${s} not found`);
                return;
            }

            return section
        },        
        findEnvelope(s) {
            const envelope = this.sections.find((v) => v.id === "envelopes");
            if(!envelope) {
                console.log("Section envelope not found");
                return;
            }

            const section = envelope.subsections.find((v) => v.id === s);
            if(!section) {
                console.log(`Subsection ${s} not found`);
                return;
            }

            return section
        },        
        unlockEnvelope(e) {
            let envelope = this.findEnvelope(e);
            envelope.locked = false;
            this.saveState();
        },
        enableEnvelope(e) {
            let envelope = this.findEnvelope(e);
            envelope.disabled = false;
            this.saveState();
        },
        enableEvidence(e) {
            let evidence = this.findEvidence(e);
            evidence.disabled = false;
            this.saveState();
        },        
        setStopFound(env) {
            this.metro.correctStops[env].found = true
            this.saveState()
        },
        setPlaceFound(p) {
            let place = this.metro.correctPlaces.find((v) => v.name === p);

            if(place) {
                place.found = true;
                this.saveState();                
            }
        },
        setCallDone() {
            this.callDone = true;
            this.saveState();
        },
        saveState() {
            let o = {
                sections: this.sections,
                correctStops: this.metro.correctStops,
                correctPlaces: this.metro.correctPlaces,
                authorizations: this.hack.authorizations,
                resolve: this.resolve,
                elevator: this.elevator,
                tutorialDone: this.tutorialDone,
                metroTutoDone: this.metroTutoDone,
                callDone: this.callDone,
            }
            localStorage.setItem('yakuzaStorage', JSON.stringify(o));
        },
        loadState() {
            const savedState = localStorage.getItem('yakuzaStorage');

            if (savedState) {
                let o = JSON.parse(savedState);

                this.sections = o.sections;
                this.metro.correctStops = o.correctStops;
                this.metro.correctPlaces = o.correctPlaces;
                this.hack.authorizations = o.authorizations;
                this.elevator = o.elevator;
                this.resolve = o.resolve;
                this.tutorialDone = o.tutorialDone;
                this.metroTutoDone = o.metroTutoDone;
                this.callDone = o.callDone;
            } 
        },
        resetState() {
            this.sections = cloneObject(this.initialConfig.yakuza.sections);
            this.metro.correctStops = cloneObject(this.initialConfig.yakuza.metro.correctStops);
            this.metro.correctPlaces = cloneObject(this.initialConfig.yakuza.metro.correctPlaces);
            this.hack.authorizations = cloneObject(this.initialConfig.yakuza.hack.authorizations);
            this.resolve = cloneObject(this.initialConfig.yakuza.resolve);
            this.resolve.solved = false;
            this.elevator = cloneObject(this.initialConfig.yakuza.elevator); 
            this.elevator.solved = false;
            this.tutorialDone = false;
            this.metroTutoDone = false;
            this.callDone = false;
            this.saveState();
        }
    }
})
