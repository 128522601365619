<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <hunters-post-container class="hunters-post">
          <template v-slot:title>{{ $t("ghosts.data.hunters.news.post3.date") }}</template>
          <template v-slot:content>
            <div class="hunters-post-content">
              <div class="hunters-sound-container">
                <v-overlay absolute :value="!playing">
                  <v-btn fab @click="playSqueak"
                    ><v-icon>mdi-play</v-icon></v-btn
                  >
                </v-overlay>
                <audio-bar-visualizer
                  :src="squeakSoundPath"
                  ref="visualizer"
                  class="hunters-sound-visualizer"
                  @click="pauseSqueak"
                  @play="playing = true"
                  @pause="playing = false"
                  @ended="playing = false"
                ></audio-bar-visualizer>
              </div>
              <span>{{
                $t("ghosts.data.hunters.news.post3.description")
              }}</span>
            </div>
          </template>
        </hunters-post-container>
      </v-col>
      <v-col cols="12">
        <hunters-post-container class="hunters-post">
          <template v-slot:title>{{ $t("ghosts.data.hunters.news.post2.date") }}</template>
          <template v-slot:content>
            <p>{{ $t("ghosts.data.hunters.news.post2.description") }}</p>
            <v-card tile color="#ffffff" width="100%" max-width="358">
              <v-card-title
                ><img
                  src="@/assets/images/ghosts/hunters/insta_avatar.png"
                  class="hunters-insta-avatar"
                />
                <span class="hunters-insta-user">Ghost_hunters</span>
                <v-spacer></v-spacer>
                <v-icon class="hunters-insta-extra">mdi-dots-horizontal</v-icon>
              </v-card-title>
              <v-img
                src="@/assets/images/ghosts/hunters/instagram_post.jpg"
              ></v-img>
              <v-card-text class="hunters-post-text">
                {{ $t("ghosts.data.hunters.news.post2.story") }}
              </v-card-text>
            </v-card>
          </template>
        </hunters-post-container>
      </v-col>
      <v-col cols="12">
        <hunters-post-container class="hunters-post">
          <template v-slot:title>{{ $t("ghosts.data.hunters.news.post1.date") }}</template>
          <template v-slot:content>
            <p>
              {{ $t("ghosts.data.hunters.news.post1.line1") }}
            </p>
            <p>{{ $t("ghosts.data.hunters.news.post1.line2") }}</p>
          </template>
        </hunters-post-container>
      </v-col>
    </v-row>
  </v-container>
</template> 
<script>
import HuntersPostContainer from "../../components/ghosts/HuntersPostContainer";
import AudioBarVisualizer from "@/components/ghosts/AudioBarVisualizer";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "GhostHuntersNews",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      playing: false,
    };
  },
  components: {
    HuntersPostContainer,
    AudioBarVisualizer,
  },
  mixins: [currentGameMixin],
  computed: {
    publicAssetsPath: function () {
      return `${this.publicPath}${this.caseID}/assets`;
    },
    publicAudioPath: function () {
      return `${this.publicAssetsPath}/audio/hunters`;
    },
    squeakSoundPath: function () {
      return `${this.publicAudioPath}/squeak.mp3`;
    },
  },
  methods: {
    playSqueak: function () {
      this.$refs.visualizer.playSound();
    },
    pauseSqueak: function () {
      this.$refs.visualizer.pauseSound();
    },
  },
};
</script>
<style scoped>
.hunters-post::v-deep .post-header {
  background: #ed1c2499;
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.hunters-post::v-deep .post-body {
  background: #282828;
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 26px;
  padding-bottom: 26px;
}
.hunters-post-content {
  padding-left: 20px;
  padding-right: 20px;
}
.hunters-sound-container {
  position: relative;
}
.hunters-sound-visualizer {
  cursor: pointer;
}
.hunters-insta-user {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.hunters-insta-avatar {
  width: 33px;
  height: 33px;
  margin-right: 11px;
}
.hunters-insta-extra {
  color: #000000 !important;
}
.hunters-post-text {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000 !important;
}
</style>