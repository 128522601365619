<template>
  <v-container class="files-container">
    <div class="files-frame">
      <img
        class="interpolice-logo"
        src="~@/assets/images/colossus/interpolice/interpolice_logo_complet.svg"
      />
      <div class="interpolice-header">
        <h1 class="interpolice-title">
          {{ $t("colossus.interpolice.title") }}
        </h1>
        <h2 class="interpolice-subtitle">
          {{ $t("colossus.interpolice.subtitle") }}
        </h2>
      </div>
      <v-expansion-panels accordion tile flat>
        <v-expansion-panel
          v-for="file in files"
          :key="file.id"
          class="file-panel"
          style="background: #00000000"
          active-class="file-panel-active"
        >
          <v-expansion-panel-header
            hide-actions
            class="file-header d-flex justify-center"
          >
            {{ file.fullName }} -
            {{ $t(`colossus.interpolice.labels.${file.type}`) }}
            <template v-if="'cabinNumber' in file"
              >&nbsp;- {{ $t("colossus.interpolice.labels.cabin") }}
              {{ file.cabinNumber }}</template
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content class="file-content">
            <v-row>
              <v-col cols="12"
                >{{
                  $t("colossus.interpolice.labels.dateOfBirth")
                }}:&nbsp;{{
                  new Date(file.dateOfBirth).toLocaleDateString($i18n.locale)
                }}</v-col
              >
              <v-col cols="12"
                >{{
                  $t("colossus.interpolice.labels.lastKnownLocation")
                }}:&nbsp;{{
                  $t(
                    `colossus.data.interpolice.files.${file.id}.lastKnownLocation`
                  )
                }}</v-col
              >
              <v-col cols="12" v-for="record in file.records" :key="record.id"
                >{{ record.year }}
                -
                {{
                  $t(
                    `${caseID}.data.interpolice.files.${file.id}.records.${record.id}.location`
                  )
                }}
                {{
                  $t(
                    `${caseID}.data.interpolice.files.${file.id}.records.${record.id}.description`
                  )
                }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Interpolice",
  methods: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      files: [],
    };
  },
  mixins: [currentGameMixin],
  computed: {
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/interpolice.json`;
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      axios.get(this.dataPath).then((res) => {
        this.files = res.data;
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
img {
  width: 200px;
}
.interpolice-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.interpolice-title {
  font-family: Gudea;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #113b6d;
  text-transform: uppercase;
}
.interpolice-subtitle {
  font-family: Gudea;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #113b6d;
  text-transform: uppercase;
}
.interpolice-header {
  border-top: 1px solid #17417f;
  width: 100%;
  height: 100px;
  padding-top: 10px;
  margin-bottom: 30px;
}
.files-container {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
.files-frame {
  background: #ffffff;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 6px;
  padding-top: 11px;
}
.file-header {
  background-color: #113b6d;
  text-transform: uppercase;
  color: #ffffff;
  font-family: Gudea;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
}
.file-header:hover,
.file-panel-active .file-header {
  background: #3490ce;
}
.file-content {
  background-color: #eeeff0;
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px;
}
</style>
