<template>
  <div class="hotel-container">
    <v-overlay absolute :value="!isLocked">
      <div class="dialog-frame">
        <p v-html="$t('ghosts.hotel.messages.unlock')"></p>
        <p>
          <span v-html="$t('ghosts.hotel.messages.enveloppe')"></span><br />
          <span v-html="$t('ghosts.hotel.messages.enveloppeContent')"></span>
        </p>
        <p v-html="$t('ghosts.hotel.messages.sectionEnabled')"></p>
      </div>
    </v-overlay>
    <v-overlay absolute class="text-center" :value="isError" c>
      <div class="dialog-frame">
        <p v-html="$t('ghosts.hotel.messages.error')"></p>
        <button @click="resetError">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="hotel-title">{{ $t("ghosts.hotel.title") }}</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="hotel-list-label">
          {{ $t("ghosts.hotel.labels.floors") }}
        </div>
        <select v-model="selectedFloorId" class="hotel-list">
          <option></option>
          <option v-for="floor in floors" :value="floor.id" :key="floor.id">
            {{ $t(`ghosts.data.hotel.floors.${floor.id}`) }}
          </option>
        </select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="hotel-list-label">
          {{ $t("ghosts.hotel.labels.rooms") }}
        </div>
        <select v-model="selectedRoomId" class="hotel-list">
          <option></option>
          <option v-for="room in activeRooms" :value="room.id" :key="room.id">
            {{ $t(`ghosts.data.hotel.rooms.${room.id}`) }}
          </option>
        </select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="hotel-list-label">
          {{ $t("ghosts.hotel.labels.spots") }}
        </div>
        <select v-model="selectedSpotId" class="hotel-list">
          <option></option>
          <option v-for="spot in activeSpots" :value="spot.id" :key="spot.id">
            {{ $t(`ghosts.data.hotel.spots.${spot.id}`) }}
          </option>
        </select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          rounded
          @click="tryUnlock"
          :disabled="!selectedSpotId || selectedSpotId === ''"
          class="hotel-search-button"
        >
          {{ $t("ghosts.hotel.buttons.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
  </div>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import axios from "axios";
export default {
  name: "Hotel",
  mixins: [currentGameMixin],
  data() {
    return {
      floors: [],
      selectedFloorId: "",
      selectedRoomId: "",
      selectedSpotId: "",
    };
  },
  computed: {
    isLocked: function() {
      return this.$store.getters.ghostsHotelLocked;
    },
    isError: function() {
      return this.$store.getters.ghostsHotelError;
    },
    roomsSrc: function() {
      return `${this.casePublicData}/hotel.json`;
    },
    selectedFloor: function() {
      if (this.selectedFloorId !== "")
        return this.floors.find((f) => f.id === this.selectedFloorId);

      return null;
    },
    activeRooms: function() {
      if (this.selectedFloor) return this.selectedFloor.rooms;

      return [];
    },
    selectedRoom: function() {
      if (this.selectedFloor && this.selectedRoomId !== "")
        return this.selectedFloor.rooms.find(
          (r) => r.id === this.selectedRoomId
        );

      return null;
    },
    activeSpots: function() {
      if (this.selectedRoom) return this.selectedRoom.spots;

      return [];
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/common/unlock_sound.mp3`;
    },
  },
  methods: {
    tryUnlock: function() {
      this.$store.dispatch("ghostsTryUnlockHotel", this.selectedSpotId);
    },
    resetError: function() {
      this.$store.dispatch("ghostsResetHotelError");
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    },
  },
  watch: {
    isLocked: function(newValue, previousValue) {
      if (previousValue && !newValue) this.playUnlockSound();
    },
  },
  mounted: async function() {
    const { data } = await axios.get(this.roomsSrc);
    this.floors = data;
  },
};
</script>
<style scoped>
.hotel-container {
  position: relative;
}
.hotel-title {
  font-family: Bebas Neue;
  font-size: 25px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffc122;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hotel-list-label {
  font-family: Bebas Neue;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.hotel-list {
  background: #00000080;
  font-family: Source Sans 3;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Positions background arrow image */
  background-image: url(~@/assets/images/ghosts/hotel/select_arrow.svg);
  background-repeat: no-repeat;
  background-size: 19px 11px;
  background-position: calc(100% - 10px) center;
  padding: 3px 49px 3px 22px;
  width: 265px;
}

.hotel-search-button {
  color: #ffffff;
  text-transform: uppercase;
}
</style>
