import metro from './metro'
import hack from './hack'

export default {
    availableLocales: [
        "fr"
    ],
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ], 
    sections: [
        {
            id: "envelopes",
            subsections: [
                {
                    id: "enveloppeA",
                    lockable: true,
                    locked: true,
                    route: { name: "yakuza-metro", params: { env: "A"} }
                },
                {
                    id: "enveloppeB",
                    lockable: true,
                    locked: true,
                    disabled: true,                
                    route: { name: "yakuza-safe" }
                },
                {
                    id: "enveloppeC",
                    lockable: true,
                    locked: true,
                    disabled: true,  
                    route: { name: "yakuza-metro", params: { env: "C"} }
                },
                {
                    id: "enveloppeD",
                    lockable: true,
                    locked: true,
                    disabled: true,  
                    route: { name: "yakuza-metro", params: { env: "D"} }
                },
                {
                    id: "enveloppeE",
                    lockable: true,
                    locked: true,
                    disabled: true,  
                    route: { name: "yakuza-metro", params: { env: "E"} }
                },
                {
                    id: "enveloppeF",
                    lockable: true,
                    locked: true,
                    disabled: true,  
                    route: { name: "yakuza-metro", params: { env: "F"} }
                },                                                                               
            ]
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "audio",
                    lockable: false,
                    locked: false,
                    route: { name: "yakuza-audio" }
                },
                {
                    id: "hacking",
                    lockable: false,
                    locked: false,
                    route: { name: "yakuza-hacking" }
                },
                {
                    id: "call",
                    lockable: false,
                    locked: false,
                    route: { name: "yakuza-call" }
                },                            
                {
                    id: "arcade",
                    lockable: false,
                    locked: false,
                    disabled: true,                
                    route: { name: "yakuza-arcade" }
                }
            ]
        },
        {
            id: "clues",
            route: { name: "yakuza-clues" }
        }
    ],
    metro,
    hack,
    call: {
        phoneNumber: '01125579'
    },
    arcade: {
        list: [
            "Abyssal Assault", "Astro Troopers", "BattleBots Blitz", "Blitzkrieg Brawlers", "ChronoChasers",
            "Crystal Crusaders", "Dimension Drifters", "Drone Dashers", "ElectroQuest", "Exo-Force Exterminators",
            "Fusion Frenzy", "Future Fusion Fighters", "Galactic Gladiators", "Galaxy Gauntlet", "HyperNova Heroes",
            "HydroHeat Heroes", "Infinity Impact", "Interstellar Incursion", "Jetstream Joust", "Jungle Juggernauts",
            "Kaiju Clash", "Lunar Lancers", "Lunar Legends", "Mech Mayhem", "Microverse Mayhem",
            "Neon Nitro Racers", "Nova Nebula", "Nova Nebula II", "Nova Nebula III", "Nova Nebula III Deluxe Edition",
            "Omega Outlaws", "Outlaw Outrunners", "Photon Phantoms", "Plasma Pulse", "Quantum Quest",
            "Quantum Quasar", "Robot Rebellion", "Rocket Raid", "Rocket Raid II", "Samurai Fighter",
            "Samurai Fighter Turbo", "Samurai Fighter II", "Space Sirens", "Stellar Saga", "Techno Turbo",
            "Time Titans", "Ultimate Ultra Warriors", "Ultra Armada", "Velocity Vendetta", "Virtual Vortex",
            "WarpWay Warriors", "Warp World Warriors", "XenoXtreme", "Xenon Xtinction", "Yokai Yokozuna",
            "Zero Gravity Zappers", "Zodiac Zephyrs"
        ],
        correct: "Samurai Fighter II"
    },
    elevator: {
        correctFloor: 54,
        speed: 500,
        correctAuthorization: 'Shinjuku Center Building'
    },
    safe : {
        correctCode: 5142
    },
    resolve: {
        currentStep: 0,
        solutions: ["9", "2", "7", "7"],
        solved: false,
    }
}
