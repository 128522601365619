<template>
    <audio :src="src" ref="audio"></audio>
</template>
<script> 
  export default {
    name: "Sound",
    emits: ["onPlay", "onStop", "onEnd"],
    data() {
      return {
          duration: 0,
          volumeClone: this.volume,
      };
    },

    props: {
      src: String,
      volume: {
          type: Number,
          default: 1.0,
      },
      loop: {
          type: Boolean,
          default: false,
      },
    },

    mounted() {
      this.player = this.$refs.audio;
      this.duration = this.player.duration;
      this.player.volume = this.volume;
      this.player.loop = this.loop;

      this.player.addEventListener("durationchange", () => {
        this.duration = this.player.duration;
      });

      this.player.addEventListener("play", () => {
        this.$emit("onPlay");
      });

      this.player.addEventListener("pause", () => {
          this.$emit("onStop");
      });

      this.player.addEventListener("ended", () => {
          this.$emit("onEnd");
      });      
    },

    methods: {
      play: function () {
          this.$refs.audio.play()
      },
      stop: function () {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      },
      setVolume(v) {
        this.player.volume = v;        
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  </style>