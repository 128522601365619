<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="py-4">
        <router-link :to="returnRoute" class="return-link">
          <img src="@/assets/images/mykonos/back.svg" class="return-icon" />
          &nbsp;
          <span>{{ $t("mykonos.research.links.searchAnotherPlace") }}</span>
        </router-link>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <div class="banner">
          <div class="row">
            <div class="location">{{ location }}</div>
            <div class="coords">{{ coordinates }}</div>
            <div class="icon"><slot name="icon"></slot></div>
            <div class="name">{{ title }}</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <div class="thumbnail"><slot name="thumbnail"></slot></div>
      </v-col>
      <v-col cols="12" sm="6" class="ui">
        <div class="ui-container">
          <div class="ui-content">
            <slot name="ui"></slot>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "DestinationTemplate",
  props: {
    location: String,
    coordinates: String,
    title: String,
    returnRoute: Object,
  },
};
</script>
<style scoped>
.return-link {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
}
.return-link:hover {
  color: #ffc122;
}
.return-icon {
  vertical-align: middle;
}
.banner {
  background: #000000cc;
  font-family: Bebas Neue;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
  display: table;
  border: 1px solid #ffc122;
  width: 100%;
}
.banner > div {
  display: table-row;
}

.banner > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 9px;
}

.banner .coords,
.banner .location {
  color: #ffffff;
}

.banner .name {
  color: #ffc122;
}

.banner .icon > img {
  width: 20px;
}
.thumbnail {
  border: 1px solid #ffc122;
  border-top: none;
}
.ui {
  padding: 30px !important;
}
.ui-container {
  display: table;
  height: 100%;
}
.ui-content {
  display: table-cell;
  vertical-align: middle;
}
</style>
