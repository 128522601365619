export default {
    fr: {
        maestro: {
            passwordRecovery: {
                title: "Procédure de récupération de mot de passe",
                yourPasswordIs: "Votre mot de passe est: {password}",
                buttons: {
                    submit: "Valider"
                },
                messages: {
                    wrongAnswer: "Réponse incorrecte"
                }
            },
            security: {
                title: "PC sécurité du Philharmonique de Paris",
                banner: "Notification",
                labels: {
                    email: "Email",
                    password: "Mot de passe",
                    securityQuestions: "Questions de sécurité"
                },
                buttons: {
                    login: "Connexion",
                    search: "Rechercher",
                    ok: "OK",
                    recover: "Récupérer",
                    cancel: "Annuler"
                },
                messages: {
                    connectionFailure: "Echec de connexion",
                    wrongEmailOrPassword: "Adresse email ou mot de passe incorrect",
                    recoveryFailure: "Echec de récupération",
                    wrongAnswers: "Les réponses données aux questions de sécurité suivantes sont incorrectes",
                    passwordRecovered: "Mot de passe récupéré",
                    yourPasswordIs: "Votre mot de passe est {password}",
                    noResult: "Aucun résultat.",
                    unlock1: "pc sécurité de l'opéra déverrouillé",
                    unlock2: "vous pouvez ouvrir l'enveloppe"
                },
                links: {
                    lostPassword: "Mot de passe oublié"
                }
            }
        }
    },
    en: {
        maestro: {
            passwordRecovery: {
                title: "Password recovery procedure",
                yourPasswordIs: "Your password is: {password}",
                buttons: {
                    submit: "Submit"
                },
                messages: {
                    wrongAnswer: "Wrong answer"
                }
            },
            security: {
                title: "Paris Philharmonic Security Center",
                banner: "Notification",
                labels: {
                    email: "Email",
                    password: "Password",
                    securityQuestions: "Security questions"
                },
                buttons: {
                    login: "Login",
                    search: "Search",
                    ok: "OK",
                    recover: "Recover",
                    cancel: "Cancel"
                },
                messages: {
                    connectionFailure: "Login failed",
                    wrongEmailOrPassword: "Incorrect email address or password",
                    recoveryFailure: "Recovery failed",
                    wrongAnswers: "The following security questions were answered incorrectly",
                    passwordRecovered: "Recovered password",
                    yourPasswordIs: "Your password is {password}",
                    noResult: "No results.",
                    unlock1: "Opera Security Center unlocked",
                    unlock2: "You can open the envelope"
                },
                links: {
                    lostPassword: "Forgot your password?"
                }
            }
        }
    },
    de: {
        maestro: {
            passwordRecovery: {
                title: "Verfahren zur Wiederherstellung des Passworts",
                yourPasswordIs: "Ihr Passwort ist: {password}",
                buttons: {
                    submit: "Bestätigen"
                },
                messages: {
                    wrongAnswer: "Falsche Antwort"
                }
            },
            security: {
                title: "Sicherheitsrechner der Opéra Philharmonique",
                banner: "Benachrichtigung",
                labels: {
                    email: "E-mail",
                    password: "Passwort",
                    nameOrSeat: "Nom ou un numéro de place",
                    result: "Résultat :",
                    securityQuestions: "Sicherheitsfragen"
                },
                buttons: {
                    login: "Login",
                    search: "Suchen",
                    ok: "OK",
                    recover: "Abrufen",
                    cancel: "Abbrechen"
                },
                messages: {
                    connectionFailure: "Die Anmeldung ist fehlgeschlagen",
                    wrongEmailOrPassword: "Falsche E-Mail-Adresse oder falsches Passwort.",
                    recoveryFailure: "Wiederherstellung fehlgeschlagen",
                    wrongAnswers: "Die Antworten, die auf die folgenden Sicherheitsfragen gegeben wurden, sind falsch.",
                    passwordRecovered: "Passwort wiederhergestellt",
                    yourPasswordIs: "Ihr Passwort lautet {password}",
                    noResult: "Es wurden keine Ergebnisse gefunden.",
                    unlock1: "Schließfach nicht verriegelt.",
                    unlock2: "Sie können den Umschlag öffnen."
                },
                links: {
                    lostPassword: "Passwort vergessen"
                }
            }
        }
    },
    es: {
        maestro: {
            passwordRecovery: {
                title: "Procedimiento de recuperación de la contraseña",
                yourPasswordIs: "Su contraseña es: {password}",
                buttons: {
                    submit: "Validar"
                },
                messages: {
                    wrongAnswer: "Respuesta incorrecta"
                }
            },
            security: {
                title: "PC de la seguridad de la Ópera Filarmónica",
                banner: "Notificación",
                labels: {
                    email: "Email",
                    password: "Contraseña",
                    securityQuestions: "Cuestiones de seguridad"
                },
                buttons: {
                    login: "Conexión",
                    search: "Buscar",
                    ok: "OK",
                    recover: "Recuperar",
                    cancel: "Cancelar"
                },
                messages: {
                    connectionFailure: "Error de conexión",
                    wrongEmailOrPassword: "e-mail o contraseña incorrecta",
                    recoveryFailure: "Recuperación fallida",
                    wrongAnswers: "Las respuestas a las preguntas de seguridad son incorrectas",
                    passwordRecovered: "Contraseña recuperada",
                    yourPasswordIs: "Su contraseña es  {password}",
                    noResult: "No hay resultados.",
                    unlock1: "Caja de seguridad desbloqueada",
                    unlock2: "Puede abrir el sobre."
                },
                links: {
                    lostPassword: "¿Contraseña olvidada?"
                }
            }
        }
    },
    it: {
        maestro: {
            passwordRecovery: {
                title: "Procedura di recupero della password",
                yourPasswordIs: "La tua password è: {password}",
                buttons: {
                    submit: "Convalidare"
                },
                messages: {
                    wrongAnswer: "Risposta errata"
                }
            },
            security: {
                title: "PC della sicurezza dell'Opera Filarmonica",
                banner: "Notifica",
                labels: {
                    email: "Email",
                    password: "password",
                    securityQuestions: "domande di sicurezza"
                },
                buttons: {
                    login: "Connessione",
                    search: "Cercare",
                    ok: "OK",
                    recover: "Recuperare",
                    cancel: "Cancellare"
                },
                messages: {
                    connectionFailure: "Accesso fallito",
                    wrongEmailOrPassword: "e-mail o password errato",
                    recoveryFailure: "Recupero fallito",
                    wrongAnswers: "Le seguenti domande di sicurezza hanno avuto una risposta errata",
                    passwordRecovered: "Password recuperata",
                    yourPasswordIs: "La tua password è {password}",
                    noResult: "Nessun risultato.",
                    unlock1: "Cassetta di sicurezza sbloccata",
                    unlock2: "Puoi aprire la busta."
                },
                links: {
                    lostPassword: "Password dimenticata"
                }
            }
        }
    }
}