<template>
    <div class="dc-short-text">
        <h2>Solution</h2>
        <p>
            Le cheval de course de Louis de Bavière, Lord Ravary II, monté par le célèbre jockey Lionel Larue, 
            accumule les victoires sans rencontrer d’opposition sur son passage. (<i>Résultat des courses</i>)
        </p>
        <p>
            Cependant, Louis de Bavière, homme d’affaires réputé, décide de faire courir Lord Ravary II lors d’une 
            prestigieuse course de gala à Dubaï, prévue le 10 juin 2024, malgré les réserves de son entraîneuse 
            officielle. (<i>Conversation Groupe Team LDB</i>) Lors de cet événement, un incident se produit : Luca 
            Rossi, un jockey connu pour ses nombreuses chutes, provoque un accident qui entraîne une blessure à 
            Lord Ravary II. (<i>Résultat des courses, Asymétrie locomotrice du cheval</i>)
        </p>
        <p>
            À la suite de cette blessure, le cheval voit sa vitesse diminuer significativement, une faiblesse 
            impardonnable à ce niveau de compétition. (<i>Une de Horse Mag</i>) 
        </p>
        <p>
            L’information reste confidentielle, seuls quelques initiés sont au courant de cet incident. Parmi eux, 
            Malika Jamelson, qui informe son père de cette fragilité. Celui-ci en profite pour parier sur un autre 
            cheval, Swift Arrow, au moment où la cote de ce dernier est particulièrement favorable. (<i>Évolution 
            des côtes, Reçu de paris</i>)
        </p>
        <p>
            Pendant ce temps, Nathalie Bonnard, l’entraîneuse officielle de l’écurie LDB, a été licenciée le 9 juin, 
            et elle ignore donc tout de la perte d’équilibre du cheval. (<i>Lettre de licenciement</i>) 
        </p>
        <p>
            De son côté, le contrat d’assurance de Lord Ravary II doit être réévalué lors de la prochaine visite 
            prévue par l’assureur Équine Assurance le 22 juin 2024. (<i>Contrat d’assurance</i>)
        </p>
        <p>
            Prenant une décision radicale, Louis décide de se débarrasser discrètement de Lord Ravary II en provoquant 
            une overdose d’avoine. Le 18 juin au soir, après avoir fait l’acquisition d’un nouveau cheval de classe 1,
            Lord Lyon, au Prestige Ranch, il profite de l’absence de Léa Lumet, entre 19h15 et 19h30, pour 
            s’introduire dans le stock d’aliments du ranch. Il vole deux sacs d’avoine et les donne à Lord Ravary II 
            en les glissant par la fenêtre du box, scellant ainsi le destin du cheval. (<i>Plan du Prestige Ranch, 
            Mail de Léa Lumet</i>)
        </p>
        <p>
            On retrouve les deux sacs d’avoine devant le box 8, leur logo bien reconnaissable de la marque “Race 
            Horse USA” visible également sur des documents de l’écurie. (<i>Photo finish de Westgate Derby et photo 
            de Rosedale Stakes</i>) 
        </p>
        <p>
            En observant le plan du Prestige Ranch ainsi que le déplacement de Léa Lumet, qui se rend des boxes au 
            manége, il est clair que les autres suspects potentiels, notamment les jockeys, n’auraient pas pu 
            effectuer le trajet sans croiser la jeune femme. (<i>Plan du Prestige Ranch, Mail de Léa et Liste des 
            entrées-sorties</i>)
        </p>
    </div>
</template>

<script>
export default {
  name: "LordRavaryII",
}
</script>

<style >
.dc-short-text {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>