<template>
  <v-container class="clues-container">
    <v-expansion-panels accordion tile flat>
      <v-expansion-panel
        v-for="section in clues"
        :key="section.category"
        class="section-panel"
        style="background: #00000000"
        active-class="section-panel-active"
      >
        <v-expansion-panel-header
          hide-actions
          class="category-header d-flex justify-center"
        >
          {{ $t(`${caseID}.data.clues.categories.${section.category}.label`) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="category-content"
          style="background: #00000000"
        >
          <v-expansion-panels accordion tile flat>
            <v-expansion-panel
              v-for="(clue, index) in section.clues"
              :key="index"
              style="background: #00000000"
              active-class="clue-panel-active"
            >
              <v-expansion-panel-header hide-actions class="clue-header">
                {{
                  $t(
                    `${caseID}.data.clues.categories.${section.category}.clues.${clue.id}.label`
                  )
                }}
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background: #00000000"
                ><div class="clue-content">
                  <div>
                    {{
                      $t(
                        `${caseID}.data.clues.categories.${section.category}.clues.${clue.id}.text`
                      )
                    }}
                  </div>
                  <div v-if="clue.image" class="clue-image">
                    <img :src="getClueImageSrc(clue)" />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import currentGameMixin from "../mixins/currentGameMixin";
export default {
  name: "Clues",
  data() {
    return {
      clues: [],
    };
  },
  mixins: [currentGameMixin],
  methods: {
    getClueImageSrc: function (clue) {
      return `${this.casePublicImages}/clues/${clue.image.src.replace(
        "{locale}",
        this.$i18n.locale
      )}`;
    },
  },
  mounted() {
    this.clues = require(`@/data/${this.caseID}/clues.json`);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
img {
  width: 200px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.clues-container {
  width: 100%;
  max-width: 600px;
}

.category-header {
  background-color: #00000060;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Bebas Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center !important;
}

.category-header:hover,
.section-panel-active .category-header {
  background: #ffc12266;
}

.category-content {
  background-color: #c4c4c4;
}

.clue-header {
  background-color: #00000040;
  color: #ffffff;
  text-transform: uppercase;
}

.clue-header:hover,
.clue-panel-active .clue-header {
  background: #ffc12266;
}

.clue-content {
  background-color: #00000025;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
}
.clue-image {
  text-align: center;
  padding: 10px;
}
.clue-image img {
  width: 100%;
}
</style>
