export default {
    availableLocales: ["fr", "en", "de", "es", "it"],
    // evaluateUrl: "https://www.amazon.fr/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0BBS21S8G",
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ],
    phone: {
        pins: [
            "1024",
            "1224"
        ]
    },
    dialPhone: {
        number: "07697730"
    },
    safe: {
        code: "sumperk"
    },
    sections: [
        {
            id: "envelopes",
            subsections: [
                {
                    id: "safe",
                    lockable: true,
                    locked: true,
                    route: {
                        name: "maestro-safe"
                    }
                },
                {
                    id: "phone",
                    route: {
                        name: "maestro-phone"
                    },
                    lockable: true,
                    locked: true
                },
                {
                    id: "security",
                    lockable: true,
                    locked: true,
                    route: {
                        "name": "maestro-security"
                    }
                }
            ]
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "police",
                    route: {
                        name: "maestro-police-home"
                    },
                    lockable: true,
                    locked: true
                },
                {
                    id: "voices",
                    route: {
                        name: "maestro-voices"
                    }
                },
                {
                    id: "playlist",
                    route: {
                        name: "maestro-playlist"
                    }
                },
                {
                    id: "dialphone",
                    route: {
                        name: "maestro-dialphone"
                    }
                },
            ]
        },
        {
            id: "clues",
            route: {
                name: "maestro-clues"
            }
        }
    ]
}