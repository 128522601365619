<template>
  <v-overlay absolute class="text-center">
    <div class="d-frame">
      <div class="d-title text-center">
          <span>{{ $t("yakuza.hack.update_auth").toUpperCase() }}</span>
          <span class="close-btn"><v-btn icon color="#000" x-small @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></span>
      </div>
      <div class="d-body ">{{ $t("yakuza.hack.access_ok") }}</div>
      <div class="d-ok">
            <v-btn @click="$emit('onClose')">{{ $t("yakuza.hack.ok") }}</v-btn>
          </div>
    </div>
  </v-overlay>
</template>
  
<script>
  export default {
    name: "HackOverlayFinish",
    emits: ["onClose"],
    data() {
      return { };
    },
    computed: { },
    mounted() { },
    methods: { }
  }
</script>
  
<style scoped lang="scss">
  @import '@/assets/css/yakuza.scss';

  .d-frame {
    @include size(260px, 260px);
    border: 1px solid #00ffff;
    background: rgba(0, 0, 0, 0.8);
    padding: 0;
    font: 11pt "Courier Prime";

    .d-title {
      height: 24px;
      background: #00ffff;
      color: #000;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      .close-btn {
        float:right;
        margin: -4px;
      }
    }

    .d-body {
      text-align: center;
      color: #fff;
      padding: 20px;
      padding-top: 100px;
    }

    ::v-deep .d-ok button {
      font: 10pt "Courier Prime";
      color: #fff;
      height: 28px;
      background-color: $accent-color-hack !important;
      border: 1px solid $accent-color-hack;
      border-radius: 0;
      margin-top: 20px;
      text-transform: none;      
    }
  }
</style>