<template>
  <destination-template
    :location="$t('mykonos.research.labels.location')"
    :coordinates="destination.coords"
    :title="$t(`mykonos.data.destinations.${destination.id}.title`)"
    :returnRoute="{ path: '.' }"
  >
    <template v-slot:icon
      ><img src="@/assets/images/mykonos/destinations/chronaki_pinpoint.svg" />
      ></template
    >
    <template v-slot:thumbnail
      ><v-img
        src="@/assets/images/mykonos/destinations/chronaki_thumbnail.jpg"
      ></v-img
    ></template>
    <template v-slot:ui>
      <div class="unlock-message">
        {{
          $t(
            "mykonos.data.destinations.chronakiBeachClub.messages.unlock.line1"
          )
        }}<br />
        {{
          $t(
            "mykonos.data.destinations.chronakiBeachClub.messages.unlock.line2"
          )
        }}<br />
        {{ $t("mykonos.common.messages.noMoreCluesHere") }}<br />
      </div>
    </template>
  </destination-template>
</template>
<script>
import DestinationTemplate from "../../components/mykonos/DestinationTemplate.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "ChronakiBeachClub",
  data() {
    return {
      destinationID: "chronakiBeachClub",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    destination: function () {
      return this.$store.getters.mykonosDestination(this.destinationID);
    },
  },
  components: {
    DestinationTemplate,
  },
  methods: {
    completeSearch: function () {
      this.$store.dispatch("mykonosCompleteChronakiBeachClub");
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.completeSearch();
    });
  },
};
</script>
<style scoped>
.unlock-message {
  font-family: Bebas Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
}

.unlock-link {
  color: #00ff29;
  text-decoration: underline;
  text-decoration-color: #00ff29;
  text-decoration-thickness: 2px;
}

.envelope-message {
  font-family: Bebas Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
  border: 1px solid #00ff29;
  padding: 20px;
}
</style>