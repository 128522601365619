// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ghosts/daily/daily_yorkshire_banner.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".daily-yorkshire-container[data-v-32617c6e]{background:#fff;position:relative}.daily-yorkshire-logo[data-v-32617c6e]{height:38px;vertical-align:middle}.daily-yorkshire-baseline[data-v-32617c6e]{max-width:158px;font-family:Montserrat;font-size:12px;font-style:italic;font-weight:300;line-height:15px;letter-spacing:0;text-align:left;white-space:nowrap}.daily-yorkshire-banner[data-v-32617c6e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position-y:50%;height:183px;position:relative}.daily-yorkshire-title[data-v-32617c6e]{top:50%;font-size:20px;line-height:23px;letter-spacing:.2em;text-align:center}.daily-yorkshire-subtitle[data-v-32617c6e],.daily-yorkshire-title[data-v-32617c6e]{position:absolute;left:50%;transform:translate(-50%,-50%);font-family:Arimo;font-weight:700;color:#fff;text-transform:uppercase}.daily-yorkshire-subtitle[data-v-32617c6e]{top:70%;font-size:14px;line-height:16px;letter-spacing:0;text-align:left}.daily-yorkshire-chat[data-v-32617c6e]{padding:31px}.daily-yorkshire-chat[data-v-32617c6e] .chat-log-header{background:#b6b6b6}", ""]);
// Exports
module.exports = exports;
