<template>
  <v-container>
    <div class="phone-container" v-if="!showList">
      <svg-phone-call
        class="phone-ui"
        @dial="onDial"
        @secret="onSecret"
        @digitclick="onDigitClick"
        locked
        dialMode
        :phoneNumber="phoneNumber"
        ref="dialui"
      ></svg-phone-call>
      <audio src="@/assets/audio/phone/ringing.mp3" ref="ringingSound" />
      <audio src="@/assets/audio/phone/engaged.mp3" ref="engagedSound" />
      <div class="voice-ui" v-show="showVoice">
        <voice-slider
          @onPlay="onVoicePlay"
          @onPause="onVoicePause"
          @onSeek="onVoiceSeek($event)"
          :playing="voice.playing"
          :duration="voice.duration"
          :position="voice.currentTime"
          ref="slider"
          class="slider mb-2"
        ></voice-slider>
        <av-waveform
          :audio-src="answerAudioFile"
          played-line-color="#ffc122"
          noplayed-line-color="#000000"
          playtime-slider-color="#ffffff"
          :audio-controls="false"
          :audio-class="'audio-player-voicemail'"
          :canv-width="waveformWidth"
          :key="'audioanswer'"
          v-if="renderComp"
        ></av-waveform>
      </div>
      <div
        class="message-ui"
        v-if="showMessage"
        v-text="$t(`egypt.call.notrightnumber`)"
      ></div>
    </div>
    <v-container v-if="showList" class="container-with-bg">
      <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-list color="#00000000">
            <v-list-item v-for="voice in voices" :key="voice.reference">
              <v-list-item-content>
                <v-list-item-title
                  v-text="voice.title"
                  class="voice-title mb-2"
                ></v-list-item-title>
                <v-list-item-subtitle class="mb-2 text-wrap"
                  ><span v-text="$t('egypt.call.message_date') + voice.datetime" class="voice-subtitle"></span>
                </v-list-item-subtitle>
                <voice-slider
                  @onPlay="onVoiceListPlay(voice)"
                  @onPause="onVoiceListPause(voice)"
                  @onSeek="onVoiceListSeek(voice, $event)"
                  :playing="voice.playing"
                  :duration="voice.duration"
                  :position="voice.currentTime"
                  ref="slider"
                  class="slider mb-2"
                ></voice-slider>
                <av-waveform
                  :audio-src="voice.file"
                  played-line-color="#ffc122"
                  noplayed-line-color="#000000"
                  playtime-slider-color="#ffffff"
                  :audio-controls="false"
                  :audio-class="'audio-player-' + voice.reference"
                  :canv-width="waveformWidth"
                ></av-waveform>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>  
  </v-container>
</template>
  
<script>
import SvgPhoneCall from "@/components/egypt/SvgPhoneCall.vue";
import VoiceSlider from "@/components/VoiceSlider.vue";
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Call",
  data() {
    return {   
      voicemailPlayer: null,
      voice: {
        duration: 0,
        currentTime: 0,
        playing: false,
      },
      waveformWidth: 200,
      showVoice: false,
      showMessage: false,
      showList: false,

      publicPath: process.env.BASE_URL,
      voices: [],
      answers: [],
      voiceFile: '',
      currentTrack: undefined,
      renderComp: true,
      secretMode: false,    
    };
  },
  components: { 
    SvgPhoneCall,
    VoiceSlider,
  },
  mixins: [currentGameMixin],  
  computed: {
    phoneNumber: function () {
      return this.$store.getters.egyptCallNumber;
    },
    otherPhoneNumbers: function () {
      return this.$store.getters.egyptCallOtherNumbers;
    },
    isLocked: function() {
      return this.$store.getters.egyptCallLocked;
    },
    answerAudioFile: function() {
      return this.voiceFile;
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    mediaPath: function () {
      return `${this.publicPath}${this.caseID}/assets/audio/${this.$i18n.locale}`;
    }, 
    currentTime: function() {
      return this.voice.currentTime;
    }   
  },
  watch: {
    mediaPath: function () {
      this.$router.go();
    },
    isLocked: function(newvalue) {
      if(!newvalue) {
        setTimeout(() => {
          this.initVoiceList();
          this.showList = true;
        }, 2000);
      }
    },   
    currentTime: function(newValue) {
      //console.log(newValue + " " + this.voice.duration)
      if(newValue === this.voice.duration) {
        setTimeout(() => {
          if(this.showVoice && !this.secretMode) {
            this.showVoice = false;
            this.stopVoicemail();
          }
        }, 1000)
      }
    }
  },
  methods: {
    onDial: function (dialedNumber) {
      var f = this.answers.find((v) => v.number === dialedNumber);
      if(f !== undefined) {
        this.$refs.dialui.ring();
        this.playRinging();

        setTimeout(() => {
          this.stopRinging();
          this.$refs.dialui.speak();
          this.showVoice = true;

          if(f.secret) {
            this.$refs.dialui.setSecretMode();
            this.secretMode = true;
          }

          this.voicemailPlayer = document.getElementsByClassName("audio-player-voicemail")[0];
          this.voiceFile = f.file;
          this.voicemailPlayer.preload = 'auto';
          this.voicemailPlayer.src = f.file;
          this.renderComp = false;

          this.$nextTick(() => {
            this.renderComp = true;
            this.$nextTick(() => {
              this.initVoicemailUI();
              this.playVoicemail();

              if(!f.secret) {
                this.$refs.dialui.hangUp();
              }
            });   
          });
        }, 3000);
      } else {
        this.$refs.dialui.engage();
        this.showMessage = true;
        this.playEngaged();

        setTimeout(() => {
            this.stopEngaged();
            this.$refs.dialui.hangUp();
            this.showMessage = false;
        }, 4000);
      }
    },


    onSecret: function(secret) {
      this.$store.dispatch("egyptTryUnlockCall", secret);
    },

    onDigitClick: function() {
      if(this.showVoice && !this.secretMode) {
        this.showVoice = false;
        this.stopVoicemail();
      }
    },

    onVoicePlay: function () {
        this.voicemailPlayer.play();
    },
    onVoicePause: function () {
        this.voicemailPlayer.pause();
    },
    onVoiceSeek: function (currentTime) {
        this.voicemailPlayer.currentTime = currentTime;
    },


    getVoiceListPlayer: function (reference) {
      return document.getElementsByClassName(`audio-player-${reference}`)[0];
    },
    onVoiceListPlay: function (voice) {
      const player = this.getVoiceListPlayer(voice.reference);

      player.play();
    },
    onVoiceListPause: function (voice) {
      const player = this.getVoiceListPlayer(voice.reference);

      player.pause();
    },
    onVoiceListSeek: function (voice, currentTime) {
      const player = this.getVoiceListPlayer(voice.reference);

      player.currentTime = currentTime;
    },  
    
    
    playSound: function (sound) {
        sound.play();
    },
    stopSound: function (sound) {
        sound.pause();
        sound.currentTime = 0;
    },
    initVoicemailUI: function () {
        this.voicemailPlayer = document.getElementsByClassName(
        "audio-player-voicemail"
        )[0];
        this.voice.duration = this.voicemailPlayer.duration;

        this.voicemailPlayer.addEventListener("durationchange", () => {
        this.voice.duration = this.voicemailPlayer.duration;
        });

        this.voicemailPlayer.addEventListener("play", () => {
        this.voice.playing = true;
        });

        this.voicemailPlayer.addEventListener("pause", () => {
        this.voice.playing = false;
        });

        this.voicemailPlayer.addEventListener("timeupdate", () => {
        this.voice.currentTime = this.voicemailPlayer.currentTime;
        });
    },
    playVoicemail: function () {
        this.playSound(this.voicemailPlayer);
    },
    stopVoicemail: function () {
        this.stopSound(this.voicemailPlayer);
    },
    playRinging: function () {
        this.playSound(this.$refs.ringingSound);
    },
    stopRinging: function () {
        this.stopSound(this.$refs.ringingSound);
    },
    playEngaged: function () {
        this.playSound(this.$refs.engagedSound);
    },
    stopEngaged: function () {
        this.stopSound(this.$refs.engagedSound);
    },

    initVoiceList: function() {
      this.voices = [];
      this.answers = [];

      this.$nextTick(function () {
        const self = this;
        axios
          .get(`${self.dataPath}/voices.json`)
          .then(({ data }) => {
            data.voicemail.map((v) => {
              return {
                reference: v.reference,
                file: `${self.mediaPath}/${v.file}`,
                datetime: v.datetime,
                title: self.$t(
                  `${this.caseID}.call.voices.${v.reference}`
                ),
              };
            })
            .forEach((d) => {
              self.voices.push({
                reference: d.reference,
                file: d.file,
                title: d.title,
                datetime: d.datetime,
                playing: false,
                duration: 0,
                currentTime: 0,
              });
            });

            data.numbers.map((v) => {
              return {
                number: v.number,
                file: `${self.mediaPath}/${v.file}`,
                secret: v.secret,
              };
            })
            .forEach((d) => {
              self.answers.push(d);
            });
          })
          .then(() => {
            self.voices.forEach((voice) => {
              const player = self.getVoiceListPlayer(voice.reference);

              player.addEventListener("durationchange", () => {
                voice.duration = player.duration;
              });

              player.addEventListener("play", () => {
                voice.playing = true;
              });

              player.addEventListener("pause", () => {
                voice.playing = false;
              });

              player.addEventListener("timeupdate", () => {
                voice.currentTime = player.currentTime;
              });
            });
          });
      });
    },
  },
  created() {},
  mounted() {
    this.initVoiceList();
    if(!this.isLocked) {
      this.showList = true;
    }
  } 
};
</script>
  
<style scoped>
  .phone-container {
    width: 100%;
    max-width: 100%;
    background-color: #00000000;
    position: relative;
  }
  
  .phone-ui {
    max-width: 300px;
    margin-left:auto;
    margin-right: auto;
  }

  .voice-ui {
    top: 100px;
    left: 50%;
    position: absolute;
    max-width: 200px;
    transform: rotateZ(-5deg) translate(-50%);
  }
  .voice-title,
  .voice-subtitle {
    color: #ffffff;
  }

  .message-ui {
    top: 100px;
    left: 50%;
    position: absolute;
    max-width: 200px;
    transform: rotateZ(-5deg) translate(-50%);
    color: #ffffff;
  }  
</style>