<template>
    <div class="spinner-wrapper" ref="spinnerWrap">
      <div
        class="spinner"
        ref="spinner"
        unselectable="on"
        onselectstart="return false;"
        @mousedown="startSpin"
        @mousemove="spinScroll"
        @mouseup="stopSpin"
        @touchstart="startSpin"        
        @touchend="stopSpin"
        @touchmove="spinScroll"
      >
          <img draggable="false" :src="casePublicImages + '/karnak/roue_centre.png'">
          <img draggable="false" :src="casePublicImages + '/karnak/roue_cercle1.png'" :style="{ transform: `rotate(${getAngles[0]}rad)` }">
          <img draggable="false" :src="casePublicImages + '/karnak/roue_cercle2.png'" :style="{ transform: `rotate(${getAngles[1]}rad)` }">
          <img draggable="false" :src="casePublicImages + '/karnak/roue_cercle3.png'" :style="{ transform: `rotate(${getAngles[2]}rad)` }">
      </div>
    </div>
</template>
<script>
import Vue from "vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "SpinWheel",
  emits: ["onAngleChange"],
  mixins: [currentGameMixin],
  data() {
    return {
      spinnerCenter: { x: 0, y: 0 },
      previousAngle: [0,0,0],
      startAngle: [0,0,0],      
      angle: [0,0,0],
      spinning: -1,
      reactive: [{ min:0, max:0},
                 { min:0, max:0},
                 { min:0, max:0}],
    };
  },
  computed: {
    getAngles: function() {
      return this.angle;
    },   
  },
  props: {
    initAngles: Array,
    reactiveRanges: Array,
  },
  mounted() {
    for(let i=0; i<3; i++) {
      Vue.set(this.angle, i, this.initAngles[i]);
      this.startAngle[i] = this.initAngles[i];
      this.previousAngle[i] = this.startAngle[i];

      let rmin = this.reactiveRanges[i].split(',')[0];
      let rmax = this.reactiveRanges[i].split(',')[1];

      this.reactive[i].min = this.getSpinnerSize().x * (rmin / 100);
      this.reactive[i].max = this.getSpinnerSize().x * (rmax / 100);

      this.$emit("onAngleChange", i, this.startAngle);
    }
  },
  methods: {
    startSpin (evt) {
      const currentPoint = this.getCurrentPointFromCenter(evt);
      const distance = Math.sqrt(Math.pow(currentPoint.x, 2) + Math.pow(currentPoint.y, 2));

      for(let i=0; i<3; i++) {
        if(distance >= this.reactive[i].min && distance < this.reactive[i].max) {
          this.spinning = i;
        }
      }

      this.startAngle[this.spinning] = -Math.atan2(currentPoint.y, currentPoint.x);

      evt.preventDefault();
    },
    stopSpin() {
      if(this.angle != this.previousAngle) {
        this.$emit("onAngleChange", this.spinning, this.angle);
      }

      this.previousAngle[this.spinning] = this.angle[this.spinning];
      this.spinning = -1;      
    },
    spinScroll(evt) {
      if(this.spinning == -1) {
        return;
      }

      if (evt instanceof MouseEvent && evt.buttons || evt instanceof TouchEvent) {
        const currentPoint = this.getCurrentPointFromCenter(evt);
        const newAngle = -Math.atan2(currentPoint.y, currentPoint.x);
        const angleOffset =  (newAngle - this.startAngle[this.spinning]);
        Vue.set(this.angle, this.spinning, this.previousAngle[this.spinning] + angleOffset);
      }

      evt.preventDefault();
    },
    getCurrentPointFromCenter(evt) {
      const pointClicked = this.getPointFromEvent(evt);
      this.spinnerCenter = this.getSpinnerCenterPoint();
      return {
        x: pointClicked.x - this.spinnerCenter.x,
        y: this.spinnerCenter.y - pointClicked.y,
      };
    },
    getPointFromEvent(evt) {
      if (evt instanceof MouseEvent) {
        return { x: evt.clientX, y: evt.clientY };
      } else if (evt instanceof TouchEvent) {
        return { x: evt.touches[0].clientX, y: evt.touches[0].clientY };
      } else {
        return { x: 0, y: 0 };
      }
    },
    getSpinnerCenterPoint() {
      const spinnerRef = this.$refs.spinnerWrap;
      return {
        x: spinnerRef.getBoundingClientRect().left + this.getSpinnerSize().x / 2,
        y: spinnerRef.getBoundingClientRect().top + this.getSpinnerSize().y / 2
      };
    },
    getSpinnerSize() {
      const spinnerRef = this.$refs.spinnerWrap;
      return {
        x: spinnerRef.offsetWidth*0.9,
        y: spinnerRef.offsetHeight*0.9
      };      
    }
  }
}

</script>
<style scoped lang="scss">
    .spinner-wrapper {
      height: 0;
      padding-bottom: 100%;
      width: 100%;
      margin-left: 5%;
        .spinner {
            position: relative;
            padding: 0;
            height: 0px;
            padding-bottom: 100%;
            width: 100%;

            img {
              width: 90%;
              position: absolute;
              left: 0;
            }
        }
    }
</style>