<template>
  <div class="phone-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 540 1000"
      style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
    >
      <g
        id="Calque_2"
        data-name="Calque 2"
        transform="rotate(-5 0 0) translate(0 50)"
      >
        <g id="telephone">
          <g id="telephone-2" data-name="telephone">
            <g id="Lignes_téléphone" data-name="Lignes téléphone">
              <path
                class="cls-1"
                d="M4.52,625.51q0-135,0-270.09c0-3.16.84-6.52-3.18-8.42-.93-.44-1.24-2.92-1.24-4.47C0,322.92,0,303.3.16,283.68c0-2,1.78-4,2.68-6,.61-1.39,1.69-2.88,1.58-4.24C4,268,6.8,261.69.63,257.44c-.79-.55-.58-2.76-.59-4.21,0-19.25-.11-38.5.12-57.75,0-2.16,1.92-4.26,2.8-6.44a15.16,15.16,0,0,0,1.5-5c.18-5.67.16-11.36,0-17-.09-4,1.38-8.41-3.51-11.12-.89-.5-.89-3-.91-4.64-.08-8.07,0-16.13,0-24.2,0-3.59-.72-7.31,3.54-9.6.94-.51.9-3.32.9-5.07.06-18.33,0-36.67,0-55C4.62,24.36,28.86,0,61.78,0q169.7,0,339.39,0c32.47,0,57.08,24.39,57.2,57,.18,48.78,0,97.55.06,146.32,0,3.35-.8,6.79,2.9,9.32,1.15.79,1.14,3.74,1.14,5.69.06,33.56.09,67.11-.08,100.66,0,2.43-1.68,4.86-2.6,7.28a15.86,15.86,0,0,0-1.3,3.32,45.81,45.81,0,0,0-.07,5.5q0,277.5,0,555c0,28-15.79,50.06-41.09,57a65.17,65.17,0,0,1-16.83,2q-169.14.17-338.29.06c-33.5,0-57.63-24-57.66-57.45Q4.45,758.62,4.52,625.51Zm2.66-151.6v53.91q0,182.05,0,364.13c0,30.56,22.55,54.45,52.26,54.5q171.89.28,343.78-.14a51.89,51.89,0,0,0,21.66-5c21.29-10.24,30.89-28.09,30.88-51.49q0-277.77,0-555.55,0-138.06,0-276.12c0-32.35-23.28-55.52-55.47-55.52Q231.14,2.63,62,2.71A60.7,60.7,0,0,0,46.8,4.54c-24.07,6.31-39.6,27.17-39.6,53Q7.15,265.72,7.18,473.91Zm-4-129.28h.73V281.2H3.16Zm0-89.14H4V192.88H3.13Zm456.29-39.9h-.61V321.91h.61ZM4,122.39l-.74,0V154l.74,0Z"
              />
              <path
                class="cls-1"
                d="M444.42,474.76c0,137.52-.31,275,.28,412.56.11,25.87-18.87,47.3-47.18,47.18-111.48-.45-223-.17-334.45-.2-21.08,0-38.12-12.62-43.3-32.23a53.87,53.87,0,0,1-1.65-13.57Q18,474.56,18.07,60.62c0-27,18.54-45.63,45.58-45.8,14.67-.09,29.34.19,44-.11,5.77-.12,8.43,2.27,9.27,7.66a105.19,105.19,0,0,0,2.48,12.36c2.26,8,7.76,12.67,16,13.84a75.35,75.35,0,0,0,10.39.79q85.55.07,171.08,0a66.1,66.1,0,0,0,9.84-.73c9.39-1.43,14.66-6.34,16.82-15.68.95-4.09,1.5-8.28,2.34-12.41.7-3.41,2.63-5.7,6.35-5.68,18,.11,36-.55,53.87.65,21.39,1.43,37.79,19.78,38.25,41.19.26,12.28.07,24.57.07,36.85Zm-2.67.33q0-207.36,0-414.73c0-25.68-17-42.6-42.75-42.7-11.92,0-23.84,0-35.75,0-13.74,0-13.73,0-16.5,13.13-.37,1.79-.84,3.57-1.37,5.31-2.62,8.55-8.43,13.56-17.17,15a63.24,63.24,0,0,1-10.38.89q-86.35.07-172.71,0a63.51,63.51,0,0,1-10.93-.94c-8.7-1.52-14.64-6.54-17.2-15a87.34,87.34,0,0,1-2.52-12.36c-.63-4.3-2.43-6.22-7.18-6.12-14.66.29-29.34.05-44,.12-25.14.11-42.56,17.45-42.57,42.57q0,414.45,0,828.91c0,24.78,17.59,42.35,42.25,42.36q168,0,336.08,0a51.15,51.15,0,0,0,12-1.22c18.83-4.59,30.7-20.54,30.7-41Q441.77,682.18,441.75,475.09Z"
              />
              <path
                class="cls-1"
                d="M222.12,37.23H196.33c-3.44,0-6.54-.87-6.48-4.94s3.1-4.86,6.58-4.86q26.07.06,52.13,0c3.46,0,6.44.85,6.44,5s-3.18,4.8-6.54,4.8Zm.13-7.13c-8.22,0-16.45-.11-24.67.1-1.68,0-3.33,1.27-5,1.95,1.58.78,3.15,2.21,4.74,2.23q25.21.24,50.43,0c1.48,0,3-1.28,4.42-2-1.57-.78-3.13-2.18-4.72-2.22C239.06,30,230.65,30.1,222.25,30.1Z"
              />
              <path
                class="cls-1"
                d="M281.61,24.39a8.42,8.42,0,0,0-8.18,7.9,8.15,8.15,0,0,0,8,8,8,8,0,0,0,8.13-7.81A8.22,8.22,0,0,0,281.61,24.39Zm-.17,11c-1.05-1.16-2.76-2.23-2.95-3.51-.13-.94,1.77-2.15,3.46-4,1.2,2.11,2.59,3.45,2.43,4.57S282.48,34.44,281.44,35.41Z"
              />
            </g>
            <g id="touches">
              <g id="touche_0" data-name="touche 0">
                <g
                  id="Touche_0_neutre"
                  data-name="Touche 0 neutre"
                  class="digit"
                  @click="onDigitClick(0)"
                >
                  <circle class="cls-2" cx="231.98" cy="826.06" r="45.7" />
                  <path
                    class="cls-1"
                    d="M232.31,804.39a8.71,8.71,0,0,1,7.25,3.31q3.1,3.91,3.11,13t-3.14,13a8.7,8.7,0,0,1-7.22,3.26,9.12,9.12,0,0,1-7.51-3.58Q222,829.82,222,820.63q0-9,3.13-13A8.71,8.71,0,0,1,232.31,804.39Zm0,5.07a3.06,3.06,0,0,0-2,.71A4.77,4.77,0,0,0,229,812.7a52.19,52.19,0,0,0-.07,15.71,5.42,5.42,0,0,0,1.43,2.79,3.12,3.12,0,0,0,3.95,0,4.73,4.73,0,0,0,1.35-2.53A51.55,51.55,0,0,0,235.7,813a5.47,5.47,0,0,0-1.42-2.81A3,3,0,0,0,232.31,809.46Z"
                  />
                </g>
              </g>
              <g id="touche_9" data-name="touche 9">
                <g
                  id="touche_9_neutre"
                  data-name="touche 9 neutre"
                  class="digit"
                  @click="onDigitClick(9)"
                >
                  <circle class="cls-2" cx="360.45" cy="712.42" r="45.7" />
                  <path
                    class="cls-1"
                    d="M340.22,739.12l-2.38-10h2.06l1.5,6.85,1.83-6.85h2.39l1.75,7,1.53-7h2l-2.42,10h-2.13l-2-7.46-2,7.46Z"
                  />
                  <path
                    class="cls-1"
                    d="M353.16,739.12l3.41-5.21-3.09-4.77h2.35l2,3.21,2-3.21h2.33L359,734l3.41,5.13H360l-2.21-3.45-2.22,3.45Z"
                  />
                  <path
                    class="cls-1"
                    d="M368.3,739.12v-4.2l-3.65-5.78H367l2.35,4,2.29-4H374l-3.66,5.79v4.19Z"
                  />
                  <path
                    class="cls-1"
                    d="M376.33,739.12V737.3l5.24-6.47h-4.65v-1.69h7.3v1.57l-5.46,6.73h5.67v1.68Z"
                  />
                  <path
                    class="cls-1"
                    d="M350.4,710.14l5.92-.65a4.3,4.3,0,0,0,1.13,2.67,3.37,3.37,0,0,0,2.42.87,4,4,0,0,0,3.22-1.74q1.32-1.74,1.7-7.22a7.31,7.31,0,0,1-5.77,2.67,8.61,8.61,0,0,1-6.5-2.9,10.6,10.6,0,0,1-2.73-7.56,10.74,10.74,0,0,1,2.89-7.83,9.79,9.79,0,0,1,7.34-3,9.9,9.9,0,0,1,8,3.75q3.11,3.76,3.11,12.36t-3.24,12.62a10.51,10.51,0,0,1-8.45,3.87,8.93,8.93,0,0,1-6.05-2A9.6,9.6,0,0,1,350.4,710.14Zm13.84-13.36a7,7,0,0,0-1.36-4.6,4,4,0,0,0-3.14-1.63,3.54,3.54,0,0,0-2.82,1.34,6.81,6.81,0,0,0-1.12,4.39,6.89,6.89,0,0,0,1.22,4.53,3.9,3.9,0,0,0,6,.06A6.11,6.11,0,0,0,364.24,696.78Z"
                  />
                </g>
              </g>
              <g id="touche_8" data-name="touche 8">
                <g
                  id="touche_8_neutre"
                  data-name="touche 8 neutre"
                  class="digit"
                  @click="onDigitClick(8)"
                >
                  <circle class="cls-2" cx="231.98" cy="712.42" r="45.7" />
                  <path
                    class="cls-1"
                    d="M218.47,739.12v-8.29h-2.95v-1.69h7.92v1.69h-2.95v8.29Z"
                  />
                  <path
                    class="cls-1"
                    d="M228.54,729.14h2v5.4a11.43,11.43,0,0,0,.07,1.67,1.58,1.58,0,0,0,.62,1,2.12,2.12,0,0,0,1.33.37,2,2,0,0,0,1.29-.35,1.37,1.37,0,0,0,.52-.86,11,11,0,0,0,.09-1.69v-5.52h2v5.24a13.26,13.26,0,0,1-.17,2.54,2.86,2.86,0,0,1-.6,1.25,3,3,0,0,1-1.17.81,5.15,5.15,0,0,1-1.92.3,5.54,5.54,0,0,1-2.17-.33,3,3,0,0,1-1.16-.85,2.68,2.68,0,0,1-.57-1.11,12.13,12.13,0,0,1-.2-2.53Z"
                  />
                  <path
                    class="cls-1"
                    d="M245,739.12l-3.56-10h2.18l2.52,7.38,2.45-7.38h2.13l-3.57,10Z"
                  />
                  <path
                    class="cls-1"
                    d="M226.3,700.3a7.15,7.15,0,0,1-3.45-2.75,7.27,7.27,0,0,1-1.07-3.84,7.73,7.73,0,0,1,2.49-5.9q2.49-2.32,7.08-2.33a10,10,0,0,1,7.07,2.33,7.71,7.71,0,0,1,2.51,5.9,7,7,0,0,1-1.15,3.95,7.31,7.31,0,0,1-3.25,2.64,8.56,8.56,0,0,1,4,3.11,8.27,8.27,0,0,1,1.38,4.73,9.61,9.61,0,0,1-2.82,7.18,10.22,10.22,0,0,1-7.49,2.76,11.34,11.34,0,0,1-7.25-2.28,8.92,8.92,0,0,1-3.42-7.4,9.14,9.14,0,0,1,1.29-4.76A8.29,8.29,0,0,1,226.3,700.3Zm.7,7.51a5.59,5.59,0,0,0,1.3,3.94,4.2,4.2,0,0,0,3.23,1.41,4.07,4.07,0,0,0,3.13-1.35,5.63,5.63,0,0,0,1.24-3.93,5.11,5.11,0,0,0-1.26-3.61,4.2,4.2,0,0,0-3.2-1.36,3.83,3.83,0,0,0-3.34,1.55A5.69,5.69,0,0,0,227,707.81Zm.57-13.67a3.82,3.82,0,0,0,1,2.85,3.72,3.72,0,0,0,2.75,1,3.59,3.59,0,0,0,3.83-3.9,3.56,3.56,0,0,0-3.76-3.79,3.79,3.79,0,0,0-2.81,1A3.73,3.73,0,0,0,227.57,694.14Z"
                  />
                </g>
              </g>
              <g id="touche_7" data-name="touche 7">
                <g
                  id="touche_7_neutre"
                  data-name="touche 7 neutre"
                  class="digit"
                  @click="onDigitClick(7)"
                >
                  <circle class="cls-2" cx="103.51" cy="712.42" r="45.7" />
                  <path
                    class="cls-1"
                    d="M83.66,739.12v-10h3.23a11.65,11.65,0,0,1,2.39.15,2.66,2.66,0,0,1,1.44,1,3.13,3.13,0,0,1,.58,1.94,3.32,3.32,0,0,1-.34,1.55,2.71,2.71,0,0,1-.85,1,2.92,2.92,0,0,1-1,.47,11.15,11.15,0,0,1-2.09.14H85.67v3.77Zm2-8.29v2.83h1.1a4.92,4.92,0,0,0,1.59-.16A1.24,1.24,0,0,0,89,733a1.34,1.34,0,0,0,.23-.77,1.32,1.32,0,0,0-.32-.9,1.41,1.41,0,0,0-.81-.44,9,9,0,0,0-1.45-.07Z"
                  />
                  <path
                    class="cls-1"
                    d="M102.51,737.85a6.5,6.5,0,0,0,1.61.84l-.74,1.43a5.29,5.29,0,0,1-.89-.38l-1.34-.88a5.45,5.45,0,0,1-2.17.42,4.77,4.77,0,0,1-3.6-1.35,6.2,6.2,0,0,1,0-7.6A4.71,4.71,0,0,1,98.93,729a4.65,4.65,0,0,1,3.51,1.36,5.26,5.26,0,0,1,1.3,3.8,6.55,6.55,0,0,1-.36,2.27A4.7,4.7,0,0,1,102.51,737.85Zm-1.62-1.14a3,3,0,0,0,.58-1.1,5.1,5.1,0,0,0,.2-1.48,3.82,3.82,0,0,0-.76-2.58,2.77,2.77,0,0,0-4,0,3.77,3.77,0,0,0-.77,2.58,3.84,3.84,0,0,0,.77,2.61,2.55,2.55,0,0,0,2.75.73,5.58,5.58,0,0,0-1.24-.63l.56-1.15A6.87,6.87,0,0,1,100.89,736.71Z"
                  />
                  <path
                    class="cls-1"
                    d="M106.86,739.12v-10h4.24a7.28,7.28,0,0,1,2.33.27,2.35,2.35,0,0,1,1.16,1,2.87,2.87,0,0,1,.43,1.57,2.64,2.64,0,0,1-.66,1.85,3.13,3.13,0,0,1-2,.92,5.32,5.32,0,0,1,1.08.84,11.82,11.82,0,0,1,1.14,1.62l1.22,2h-2.41L112,737a14.16,14.16,0,0,0-1.06-1.47,1.76,1.76,0,0,0-.61-.42,3.47,3.47,0,0,0-1-.11h-.41v4.17Zm2-5.76h1.49a7.89,7.89,0,0,0,1.81-.12,1,1,0,0,0,.56-.42,1.28,1.28,0,0,0,.21-.75,1.17,1.17,0,0,0-.27-.81,1.19,1.19,0,0,0-.76-.39,13.35,13.35,0,0,0-1.47,0h-1.57Z"
                  />
                  <path
                    class="cls-1"
                    d="M118,735.87l2-.19a2.41,2.41,0,0,0,.72,1.45,2.17,2.17,0,0,0,1.46.46,2.28,2.28,0,0,0,1.47-.41,1.22,1.22,0,0,0,.49-1,.88.88,0,0,0-.21-.6,1.71,1.71,0,0,0-.72-.44c-.24-.08-.78-.22-1.61-.43a5.57,5.57,0,0,1-2.28-1,2.6,2.6,0,0,1-.91-2,2.55,2.55,0,0,1,.43-1.43,2.72,2.72,0,0,1,1.24-1,5,5,0,0,1,2-.35,4.19,4.19,0,0,1,2.83.83,2.91,2.91,0,0,1,1,2.19l-2,.09a1.68,1.68,0,0,0-.55-1.1,2,2,0,0,0-1.28-.34,2.3,2.3,0,0,0-1.37.36.74.74,0,0,0-.32.62.77.77,0,0,0,.3.61,5.33,5.33,0,0,0,1.85.66,9.5,9.5,0,0,1,2.17.72,2.76,2.76,0,0,1,1.1,1,2.93,2.93,0,0,1,.4,1.59,3,3,0,0,1-.47,1.61,2.85,2.85,0,0,1-1.35,1.11,5.69,5.69,0,0,1-2.17.36,4.31,4.31,0,0,1-2.9-.87A3.77,3.77,0,0,1,118,735.87Z"
                  />
                  <path
                    class="cls-1"
                    d="M92.78,691.73v-5.68H113.7v4.44a32.71,32.71,0,0,0-5.27,7.31,43,43,0,0,0-4.08,10.13,37.59,37.59,0,0,0-1.38,9.59h-5.9A41,41,0,0,1,99.8,704a45.05,45.05,0,0,1,6.89-12.29Z"
                  />
                </g>
              </g>
              <g id="touche_6" data-name="touche 6">
                <g
                  id="touche_6_neutre"
                  data-name="touche 6 neutre"
                  class="digit"
                  @click="onDigitClick(6)"
                >
                  <circle class="cls-2" cx="360.45" cy="598.77" r="45.7" />
                  <path
                    class="cls-1"
                    d="M344.48,624.88v-10h3l1.81,6.8,1.79-6.8h3v10h-1.87V617l-2,7.85h-1.94l-2-7.85v7.85Z"
                  />
                  <path
                    class="cls-1"
                    d="M357.63,624.88v-10h2l4.08,6.66v-6.66h1.87v10h-2l-4-6.5v6.5Z"
                  />
                  <path
                    class="cls-1"
                    d="M368.76,620a6.24,6.24,0,0,1,.46-2.56,4.65,4.65,0,0,1,.92-1.37,4,4,0,0,1,1.29-.89,5.36,5.36,0,0,1,2.15-.4,4.69,4.69,0,0,1,3.53,1.37,6.12,6.12,0,0,1,0,7.58,4.66,4.66,0,0,1-3.51,1.36,4.72,4.72,0,0,1-3.54-1.35A5.18,5.18,0,0,1,368.76,620Zm2.07-.07a3.76,3.76,0,0,0,.79,2.57,2.69,2.69,0,0,0,4,0,3.81,3.81,0,0,0,.77-2.6,3.73,3.73,0,0,0-.75-2.55,2.77,2.77,0,0,0-4,0A3.72,3.72,0,0,0,370.83,619.89Z"
                  />
                  <path
                    class="cls-1"
                    d="M370.52,579.66l-5.92.65a4.28,4.28,0,0,0-1.13-2.69,3.3,3.3,0,0,0-2.37-.88,4,4,0,0,0-3.28,1.75q-1.33,1.74-1.68,7.24a7.16,7.16,0,0,1,5.68-2.7,8.69,8.69,0,0,1,6.56,2.92,10.6,10.6,0,0,1,2.73,7.53,10.81,10.81,0,0,1-2.87,7.86,10.48,10.48,0,0,1-15.33-.79q-3.11-3.76-3.11-12.31,0-8.78,3.24-12.65a10.49,10.49,0,0,1,8.43-3.87,9,9,0,0,1,6,2A9.57,9.57,0,0,1,370.52,579.66ZM356.66,593a6.91,6.91,0,0,0,1.37,4.61,4,4,0,0,0,3.13,1.62A3.55,3.55,0,0,0,364,597.9c.76-.88,1.13-2.33,1.13-4.35A7,7,0,0,0,363.9,589a3.91,3.91,0,0,0-6-.07A6,6,0,0,0,356.66,593Z"
                  />
                </g>
              </g>
              <g id="touche_5" data-name="touche 5">
                <g
                  id="Touche_5_neutre"
                  data-name="Touche 5 neutre"
                  class="digit"
                  @click="onDigitClick(5)"
                >
                  <circle class="cls-2" cx="231.98" cy="598.77" r="45.7" />
                  <path
                    class="cls-1"
                    d="M219.83,614.91h2v6.31a6.71,6.71,0,0,1-.21,1.91,2.78,2.78,0,0,1-1.06,1.4,3.56,3.56,0,0,1-2,.52,3,3,0,0,1-2.27-.82,3.42,3.42,0,0,1-.81-2.43l1.9-.22a2.64,2.64,0,0,0,.25,1.21,1.09,1.09,0,0,0,1,.54,1.11,1.11,0,0,0,1-.38,3.11,3.11,0,0,0,.28-1.6Z"
                  />
                  <path
                    class="cls-1"
                    d="M228.72,624.88v-10h2v4.43l4.07-4.43h2.71l-3.76,3.88,4,6.09h-2.6l-2.74-4.68-1.64,1.67v3Z"
                  />
                  <path class="cls-1" d="M243.53,624.88V615h2v8.21h5v1.68Z" />
                  <path
                    class="cls-1"
                    d="M221.15,595.55l6.09-.63a5.25,5.25,0,0,0,1.54,3.28,4.23,4.23,0,0,0,3,1.2,4.14,4.14,0,0,0,3.25-1.55,7.11,7.11,0,0,0,1.33-4.69,6.42,6.42,0,0,0-1.32-4.41,4.39,4.39,0,0,0-3.43-1.47,6.24,6.24,0,0,0-4.72,2.33l-5-.72L225,572.28h16.17V578H229.66l-1,5.42a9.33,9.33,0,0,1,11.08,1.93,10.71,10.71,0,0,1,2.83,7.69,11.56,11.56,0,0,1-2.29,7,10.14,10.14,0,0,1-8.64,4.22,10.7,10.7,0,0,1-7.2-2.37A9.75,9.75,0,0,1,221.15,595.55Z"
                  />
                </g>
              </g>
              <g id="touche_4" data-name="touche 4">
                <g
                  id="touche_4_neutre"
                  data-name="touche 4 neutre"
                  class="digit"
                  @click="onDigitClick(4)"
                >
                  <circle class="cls-2" cx="103.51" cy="598.77" r="45.7" />
                  <path
                    class="cls-1"
                    d="M92.59,621.22v-1.68h4.34v4a5.39,5.39,0,0,1-1.83,1.08,6.67,6.67,0,0,1-2.43.46,5.51,5.51,0,0,1-2.73-.65,4.19,4.19,0,0,1-1.75-1.88,6.09,6.09,0,0,1-.58-2.66,5.72,5.72,0,0,1,.65-2.77,4.38,4.38,0,0,1,1.91-1.85,5.19,5.19,0,0,1,2.39-.5,4.76,4.76,0,0,1,2.9.78,3.55,3.55,0,0,1,1.34,2.15l-2,.38a2.08,2.08,0,0,0-.79-1.16,2.35,2.35,0,0,0-1.45-.43,2.73,2.73,0,0,0-2.1.84,3.51,3.51,0,0,0-.78,2.48,3.88,3.88,0,0,0,.79,2.66,2.62,2.62,0,0,0,2.07.89,3.42,3.42,0,0,0,1.27-.25,4.21,4.21,0,0,0,1.09-.6v-1.26Z"
                  />
                  <path
                    class="cls-1"
                    d="M104.16,624.88v-10h2v3.93h3.94v-3.93h2v10h-2v-4.36h-3.94v4.36Z"
                  />
                  <path class="cls-1" d="M119.52,624.88v-10h2v10Z" />
                  <path
                    class="cls-1"
                    d="M104.78,603.76v-6.43H91.72V592l13.84-20.26h5.14V592h4v5.37h-4v6.43Zm0-11.8V581.05L97.44,592Z"
                  />
                </g>
              </g>
              <g id="touche_3" data-name="touche 3">
                <g
                  id="touche_3_neutre"
                  data-name="touche 3 neutre"
                  class="digit"
                  @click="onDigitClick(3)"
                >
                  <circle class="cls-2" cx="360.45" cy="485.13" r="45.7" />
                  <path
                    class="cls-1"
                    d="M344.5,503h3.68a7,7,0,0,1,1.89.19,3.33,3.33,0,0,1,1.51.92,4.52,4.52,0,0,1,.95,1.61,7.34,7.34,0,0,1,.33,2.36,6.63,6.63,0,0,1-.31,2.12,4.44,4.44,0,0,1-1.07,1.77,3.54,3.54,0,0,1-1.41.79,6.17,6.17,0,0,1-1.79.21H344.5Zm2,1.69v6.6H348a5.84,5.84,0,0,0,1.22-.09,1.74,1.74,0,0,0,.81-.42,2.05,2.05,0,0,0,.53-1,6.43,6.43,0,0,0,.2-1.83,5.8,5.8,0,0,0-.2-1.77,2.4,2.4,0,0,0-.57-1,2,2,0,0,0-.93-.47,9.75,9.75,0,0,0-1.66-.09Z"
                  />
                  <path
                    class="cls-1"
                    d="M358.38,513V503h7.4v1.69H360.4v2.21h5v1.68h-5v2.71H366V513Z"
                  />
                  <path
                    class="cls-1"
                    d="M371.51,513V503h6.83v1.69h-4.82v2.36h4.16v1.68h-4.16V513Z"
                  />
                  <path
                    class="cls-1"
                    d="M349.58,481.53l5.92-.72a5.6,5.6,0,0,0,1.53,3.46,4.32,4.32,0,0,0,6.19-.24,5.59,5.59,0,0,0,1.29-3.88,5.18,5.18,0,0,0-1.24-3.65,3.93,3.93,0,0,0-3-1.35,10.52,10.52,0,0,0-2.81.46l.68-5a5.32,5.32,0,0,0,3.78-1.08,3.83,3.83,0,0,0,1.31-3A3.3,3.3,0,0,0,359.7,463a3.66,3.66,0,0,0-2.67,1.09,5.12,5.12,0,0,0-1.35,3.18l-5.64-1a12.48,12.48,0,0,1,1.78-4.63,8,8,0,0,1,3.3-2.72,11.14,11.14,0,0,1,4.76-1,9.5,9.5,0,0,1,7.23,2.88,7.49,7.49,0,0,1,2.24,5.31q0,4.2-4.6,6.7a7.45,7.45,0,0,1,4.39,2.63,7.71,7.71,0,0,1,1.64,4.95,9.58,9.58,0,0,1-3.07,7.16,11.44,11.44,0,0,1-14.82.46A9.81,9.81,0,0,1,349.58,481.53Z"
                  />
                </g>
              </g>
              <g id="touche_2" data-name="touche 2">
                <g
                  id="touche_2_neutre"
                  data-name="touche 2 neutre"
                  class="digit"
                  @click="onDigitClick(2)"
                >
                  <circle class="cls-2" cx="231.98" cy="485.13" r="45.7" />
                  <path
                    class="cls-1"
                    d="M225.22,513H223l-.87-2.26h-4l-.82,2.26h-2.13l3.88-10h2.13Zm-3.71-3.94-1.37-3.7-1.35,3.7Z"
                  />
                  <path
                    class="cls-1"
                    d="M229,503h4a11,11,0,0,1,1.77.1,2.7,2.7,0,0,1,1,.41,2.64,2.64,0,0,1,.76.83,2.23,2.23,0,0,1,.31,1.17,2.35,2.35,0,0,1-.38,1.29,2.28,2.28,0,0,1-1,.87,2.63,2.63,0,0,1,1.4.91,2.37,2.37,0,0,1,.49,1.5,3,3,0,0,1-.32,1.32,2.65,2.65,0,0,1-.86,1,2.76,2.76,0,0,1-1.35.47c-.34,0-1.15.06-2.43.07H229Zm2,1.66V507h1.32c.78,0,1.27,0,1.46,0a1.32,1.32,0,0,0,.81-.35,1.06,1.06,0,0,0,.3-.78,1.13,1.13,0,0,0-.26-.76,1.2,1.2,0,0,0-.75-.35c-.2,0-.78,0-1.72,0Zm0,4v2.66h1.86a9.19,9.19,0,0,0,1.38-.06,1.19,1.19,0,0,0,1-1.24,1.37,1.37,0,0,0-.22-.77,1.28,1.28,0,0,0-.63-.45,6.67,6.67,0,0,0-1.79-.14Z"
                  />
                  <path
                    class="cls-1"
                    d="M248.05,509.33,250,510a4.44,4.44,0,0,1-1.49,2.42,4.26,4.26,0,0,1-2.65.79,4.31,4.31,0,0,1-3.27-1.35,5.23,5.23,0,0,1-1.27-3.72,5.44,5.44,0,0,1,1.28-3.86,4.4,4.4,0,0,1,3.38-1.38,4.14,4.14,0,0,1,3,1.08,3.84,3.84,0,0,1,1,1.84l-2,.47a2.07,2.07,0,0,0-2.1-1.67,2.27,2.27,0,0,0-1.8.8,3.9,3.9,0,0,0-.69,2.58,4.2,4.2,0,0,0,.68,2.69,2.21,2.21,0,0,0,1.77.8,2,2,0,0,0,1.38-.51A3,3,0,0,0,248.05,509.33Z"
                  />
                  <path
                    class="cls-1"
                    d="M243.12,484.31V490H221.68a14.7,14.7,0,0,1,2.09-6.1,40,40,0,0,1,6.88-7.65,43.86,43.86,0,0,0,5.07-5.23,6.73,6.73,0,0,0,1.26-3.74,4.3,4.3,0,0,0-1.1-3.15,4.11,4.11,0,0,0-3-1.1,4.06,4.06,0,0,0-3,1.16,5.9,5.9,0,0,0-1.31,3.83l-6.09-.61c.36-3.37,1.5-5.78,3.41-7.25A11.53,11.53,0,0,1,233,458a10.37,10.37,0,0,1,7.42,2.55,8.35,8.35,0,0,1,2.7,6.33,11.16,11.16,0,0,1-.77,4.11A17,17,0,0,1,239.9,475a39.65,39.65,0,0,1-4,4.07q-2.89,2.66-3.67,3.52a11,11,0,0,0-1.25,1.7Z"
                  />
                </g>
              </g>
              <g id="touche_1" data-name="touche 1">
                <g
                  id="Touche_1_neutre"
                  data-name="Touche 1 neutre"
                  class="digit"
                  @click="onDigitClick(1)"
                >
                  <circle class="cls-2" cx="103.51" cy="485.13" r="45.7" />
                  <path
                    class="cls-1"
                    d="M108.43,490h-6.11V466.94a21.13,21.13,0,0,1-7.9,4.64V466a17.64,17.64,0,0,0,5.2-3,11.32,11.32,0,0,0,3.85-5.11h5Z"
                  />
                </g>
              </g>
            </g>
            <g id="zone_texte" data-name="zone texte">
            </g>
            <g>
              <text
                class="dial-text"
                transform="translate(69.66 55)"
                v-if="composing || ringing"
              >
                <tspan
                  x="160"
                  y="85"
                  text-anchor="middle"
                  v-text="dialString"
                  class="dial-text"
                ></tspan>
              </text>
              <svg x="350" y="100">
                <g
                  id="dial_backspace"
                  class="icone-backspace"
                  v-if="dialNumber.length > 0 && composing"
                  @click="onBackspace"
                >
                  <path
                    d="M490.677,64.005H133.088c-7.189,0-13.888,3.627-17.856,9.643L3.488,244.315c-4.651,7.104-4.651,16.277,0,23.381
			l111.744,170.667c3.968,6.016,10.667,9.643,17.856,9.643h357.589c11.797,0,21.333-9.536,21.333-21.333V85.339
			C512.011,73.541,502.475,64.005,490.677,64.005z M399.093,356.421c-4.16,4.16-9.621,6.251-15.083,6.251
			c-5.461,0-10.923-2.091-15.083-6.251l-70.251-70.251l-70.251,70.251c-4.16,4.16-9.621,6.251-15.083,6.251
			c-5.461,0-10.923-2.091-15.083-6.251c-8.341-8.341-8.341-21.824,0-30.165l70.251-70.251l-70.251-70.251
			c-8.341-8.341-8.341-21.824,0-30.165s21.824-8.341,30.165,0l70.251,70.251l70.251-70.251c8.341-8.341,21.824-8.341,30.165,0
			s8.341,21.824,0,30.165l-70.251,70.251l70.253,70.251C407.434,334.597,407.434,348.08,399.093,356.421z"
                  />
                </g>
              </svg>
              <g
                v-if="dialNumber.length > 0 && composing"
                class="call-button"
                @click="onDial"
              >
                <rect
                  x="80"
                  y="200"
                  width="300"
                  height="100"
                  rx="50"
                  ry="50"
                  fill="green"
                />
                <svg x="100" y="220">
                  <g class="icone-call">
                    <path
                      id="Shape"
                      d="M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407l-1.032-.056a.662.662,0,0,1-.579-.437,9.327,9.327,0,0,1,0-6.5.662.662,0,0,1,.579-.437l1.032-.109a.7.7,0,0,0,.589-.394L7.03,2.446l.331-.662a.708.708,0,0,0,.07-.308.692.692,0,0,0-.179-.467A3,3,0,0,0,4.693.017l-.235.03L4.336.063A1.556,1.556,0,0,0,4.17.089l-.162.04C1.857.679.165,4.207,0,8.585V9.83c.165,4.372,1.857,7.9,4,8.483l.162.04a1.556,1.556,0,0,0,.165.026l.122.017.235.03a3,3,0,0,0,2.558-.993.692.692,0,0,0,.179-.467.708.708,0,0,0-.07-.308Z"
                      transform="translate(3.887 6.093) rotate(-30)"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <text class="call-label" transform="translate(69.66 55)">
                  <tspan x="180" y="210" text-anchor="middle" class="dial-text">
                    {{ $t('yakuza.call.call') }}
                  </tspan>
                </text>
              </g>
            </g>
            <g v-if="ringing">
              <text class="call-label" transform="translate(60.66 55)">
                <tspan x="180" y="210" text-anchor="middle" class="dial-text">
                  {{ $t('yakuza.call.inprogress') }}
                </tspan>
              </text>
            </g>
            <g v-if="engaged">
              <text class="call-label" transform="translate(60.66 55)">
                <tspan x="180" y="210" text-anchor="middle" class="dial-text">
                  {{ $t('yakuza.call.incorrect') }}
                </tspan>
              </text>
            </g>            
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import DialPhoneStatusEnum from "../../enums/DialphoneStatusEnum";

export default {
  name: "SvgPhoneCall",
  emits: ["onDigitClick", "onDial"],
  data() {
    return {
      dialNumber: [],
      status: DialPhoneStatusEnum.IDLE,
    };
  },
  props: {
    error: Boolean,
    dialMode: Boolean,
  },
  computed: {
    dialString: function () {
      return this.dialNumber.join("");
    },
    composing: function () {
      return this.status === DialPhoneStatusEnum.COMPOSING;
    },
    ringing: function () {
      return this.status === DialPhoneStatusEnum.RINGING;
    },
    engaged: function () {
      return this.status === DialPhoneStatusEnum.ENGAGED;
    }
  },
  methods: {
    onDigitClick: function (digit) {
      if (this.status === DialPhoneStatusEnum.IDLE)
        this.status = DialPhoneStatusEnum.COMPOSING;

      if (this.dialNumber.length < 10) this.dialNumber.push(digit);

      this.$emit("onDigitclick", digit);
    },
    onBackspace: function () {
      if (this.dialNumber.length > 0) {
        this.dialNumber.splice(this.dialNumber.length - 1, 1);
      }
    },
    onDial: function () {
      this.status = DialPhoneStatusEnum.DIALING;
    },
    hangUp: function () {
      this.status = DialPhoneStatusEnum.IDLE;
    },
    engage: function () {
      this.status = DialPhoneStatusEnum.ENGAGED;
    },
    ring: function () {
      this.status = DialPhoneStatusEnum.RINGING;
    },
    clearComposedNumber: function () {
      this.dialNumber = [];
    },
  },
  watch: {
    status: function (newValue) {
      switch (newValue) {
        case DialPhoneStatusEnum.IDLE:
          this.clearComposedNumber();
          break;
        case DialPhoneStatusEnum.DIALING:
          this.$emit("onDial", this.dialString);
          break;
      }
    },
  },
};
</script>
<style scoped>
.phone-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.cls-1 {
  fill: #fff;
  stroke-width: 1px;
}
.cls-2,
.cls-4,
.cls-5,
.cls-6 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke-width: 2.79px;
}
.cls-3 {
  fill: #f2c351;
}
.cls-4 {
  stroke-width: 2.98px;
}
.cls-5 {
  stroke-width: 3.33px;
}
.cls-6 {
  stroke-width: 13.56px;
}
.digit {
  pointer-events: all;
  transition: 0.6s fill;
}
.digit:hover circle {
  cursor: pointer;
  fill: #f2c351;
  stroke: none;
}
.digit:hover path {
  cursor: pointer;
  fill: #000000;
}
.phone-text {
  fill: #67DD52;
  font-size: 32px;
  font-family: ArialMT, Arial;
}
.phone-text.pin-prompt {
  fill: #ffffff;
}
.phone-text.error {
  fill: #E84949;
}
.icone-check {
  fill: #67DD52;
}
.icone-backspace {
  fill: #ffffff;
}
.icone-backspace {
  transform: scale(0.1);
  pointer-events: all;
  cursor: pointer;
}
.icone-backspace:hover {
  fill: #f2c351;
}

.icone-call {
  transform: scale(3);
  stroke: #ffffff;
  fill: none;
}
.dial-text {
  fill: #fff;
  font-size: 40px;
  font-family: ArialMT, Arial;
}
.call-button {
  pointer-events: all;
  cursor: pointer;
}
</style>
