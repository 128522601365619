// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ghosts/police/police_banner.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".police-container[data-v-6f92b73f]{background:#fff;position:relative}.police-headline[data-v-6f92b73f]{display:table}.police-headline>div[data-v-6f92b73f]{display:table-row}.police-headline>div>div[data-v-6f92b73f]{display:table-cell;vertical-align:middle;padding-top:22px;padding-bottom:22px}.police-emblem[data-v-6f92b73f]{height:54px}.police-emblem[data-v-6f92b73f],.police-logo[data-v-6f92b73f]{vertical-align:middle;margin-left:31px}.police-logo[data-v-6f92b73f]{height:39px}.police-banner[data-v-6f92b73f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position-y:40%;height:183px;position:relative}.police-baseline[data-v-6f92b73f]{position:absolute;left:50%;bottom:22px;transform:translate(-50%);font-size:17px;line-height:20px;color:#fff;max-width:284px;background:rgba(17,17,17,.6);padding:8px 23px 6px}.police-baseline[data-v-6f92b73f],.police-title[data-v-6f92b73f]{font-family:Arimo;font-weight:700;letter-spacing:0;text-align:center;text-transform:uppercase}.police-title[data-v-6f92b73f]{font-size:18px;line-height:21px;color:#3263aa;margin-top:21px}.police-chat[data-v-6f92b73f]{padding:31px}.police-chat[data-v-6f92b73f] .chat-log-header{background:#3263aa}", ""]);
// Exports
module.exports = exports;
