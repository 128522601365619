// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/colossus/webmail/pandamail_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".webmail-banner[data-v-7f257818]{background-size:30%;background-position:50% 50%}.webmail-banner[data-v-7f257818],.webmail-banner-mobile[data-v-7f257818]{width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:0;padding:0;padding-bottom:12.39669%}.webmail-banner-mobile[data-v-7f257818]{background-size:50%;background-position:10% 50%}.webmail-title[data-v-7f257818]{font-size:22px;font-weight:700;line-height:28px;color:#3490ce;text-transform:uppercase}.webmail-field[data-v-7f257818],.webmail-title[data-v-7f257818]{font-family:Plus Jakarta Sans;letter-spacing:.025em;text-align:center}.webmail-field[data-v-7f257818]{font-size:16px;font-weight:400;line-height:20px;color:#000;border:1px solid #000;padding:10px;width:80%}.webmail-error[data-v-7f257818],.webmail-field[data-v-7f257818]{max-width:300px;margin:0 auto}.user-icon[data-v-7f257818]{width:106px;margin:0 auto}.webmail-user[data-v-7f257818]{font-size:19px;line-height:24px;color:#000}.login-button[data-v-7f257818],.webmail-user[data-v-7f257818]{font-family:Plus Jakarta Sans;font-weight:700;letter-spacing:.025em;text-align:center}.login-button[data-v-7f257818]{background:#3490ce;font-size:17px;line-height:21px;text-transform:uppercase;color:#fff;padding:7px 20px;margin-bottom:25px}.email-container[data-v-7f257818]{padding:2px}.email-item[data-v-7f257818]{background:#f0f0f0}.email-excerpt[data-v-7f257818]{word-break:keep-all}", ""]);
// Exports
module.exports = exports;
