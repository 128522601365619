// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ghosts/tarot/selection_arrow.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/ghosts/tarot/selection_arrow_hover.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".tarot-carousel-container,.tarot-container{position:relative}.tarot-carousel{width:260px!important;border:1pt solid #ffc122;border-radius:12px}.tarot-carousel-arrow{width:39px;height:39px;position:absolute!important;top:50%;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.tarot-carousel-left{left:0;transform:translate(-50%,-50%) rotate(-90deg)}.tarot-carousel-arrow.hovered{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.tarot-carousel-right{right:0;transform:translate(50%,-50%) rotate(90deg)}.card-title,.tarot-submit-button,.tarot-warning-text{color:#fff;text-transform:uppercase}.card-title,.tarot-warning-text{margin-top:10px;font-family:Source Sans 3;font-size:16px;font-weight:400;letter-spacing:0;text-align:center}.arrow{position:absolute;width:34px;height:34px;top:50%}.left-arrow{left:0;transform:translate(-50%,-50%) rotate(-90deg)}.right-arrow{left:100%;transform:translate(-50%,-50%) rotate(90deg)}", ""]);
// Exports
module.exports = exports;
