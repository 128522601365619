<template>
  <v-container class="tarot-container">
    <v-overlay absolute class="text-center" :value="isError" c>
      <div class="dialog-frame">
        <p v-html="$t('ghosts.tarot.messages.error')"></p>
        <button @click="resetError">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <v-overlay absolute class="text-center" :value="!isLocked">
      <div class="dialog-frame">
        <h1>{{ $t("ghosts.tarot.messages.unlock.title") }}</h1>
        <p v-html="$t('ghosts.tarot.messages.unlock.desc')"></p>
      </div>
    </v-overlay>
    <v-row justify="center" v-if="slotCount > 0">
      <v-col cols="12" class="tarot-warning-text">{{
        $t("ghosts.tarot.messages.noOrder")
      }}</v-col>
      <v-col
        v-for="i in slotCount"
        :key="`slot-${i - 1}`"
        class="d-flex flex-column justify-center align-center"
      >
        <div class="tarot-carousel-container">
          <v-carousel
            v-model="selection[i - 1]"
            class="tarot-carousel"
            height="300"
            hide-delimiters
            :show-arrows="false"
          >
            <v-carousel-item
              v-for="card in cards"
              :key="card.id"
              :src="getCardImage(card)"
            ></v-carousel-item>
          </v-carousel>
          <v-hover v-slot="{ hover }">
            <div
              class="tarot-carousel-arrow tarot-carousel-left"
              :class="{ hovered: hover }"
              @click="onChangeCarrousel(i - 1, -1)"
            ></div>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <div
              class="tarot-carousel-arrow tarot-carousel-right"
              :class="{ hovered: hover }"
              @click="onChangeCarrousel(i - 1, 1)"
            ></div>
          </v-hover>
        </div>

        <div class="card-title" v-if="selection[i - 1] < cards.length">
          {{
            $t(`${caseID}.data.tarot.cards.${cards[selection[i - 1]].id}.title`)
          }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn rounded @click="tryUnlock" class="tarot-submit-button">{{
          $t("ghosts.tarot.buttons.submit")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Tarot",
  data() {
    return {
      cards: [],
      selection: [],
    };
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.ghostsTarotLocked;
    },
    isError: function() {
      return this.$store.getters.ghostsTarotError;
    },
    cardsDataPath: function() {
      return `${this.casePublicData}/tarot.json`;
    },
    arrowImagePath: function() {
      return require(`@/assets/images/${this.caseID}/tarot/selection_arrow.svg`);
    },
    cardsImagePath: function() {
      return `${this.casePublicImages}/tarot`;
    },
    slotCount: function() {
      return this.$store.getters.ghostsTarotSlotCount;
    },
    selectedIds: function() {
      return this.selection.map((i) => this.cards[i].id);
    },
    defaultPositions: function() {
      return this.$store.getters.ghostsTarotDefaultPositions;
    },
  },
  methods: {
    getCardImage: function(card) {
      return `${this.cardsImagePath}/${card.res}.jpg`;
    },
    onChangeCarrousel: function(index, increment) {
      let value = this.selection[index];

      value += increment;
      if (value < 0) value = this.cards.length - 1;
      value = value % this.cards.length;
      this.$set(this.selection, index, value);
    },
    setDefaultPositions: function() {
      this.selection = [...this.defaultPositions];
    },
    tryUnlock: function() {
      this.$store.dispatch("ghostsTryUnlockTarot", this.selectedIds);
    },
    resetError: function() {
      this.$store.dispatch("ghostsResetTarotError");
    },
  },
  async mounted() {
    const { data } = await axios.get(this.cardsDataPath);
    this.cards = data;
    this.setDefaultPositions();
    this.resetError();
  },
  watch: {
    // selectedIds: {
    //   deep: true,
    //   handler(newValue) {
    //     this.$store.dispatch("ghostsTryUnlockTarot", newValue);
    //   },
    // },
  },
};
</script>
<style>
.tarot-container {
  position: relative;
}
.tarot-carousel-container {
  position: relative;
}
.tarot-carousel {
  width: 260px !important;
  border: 1pt solid #ffc122;
  border-radius: 12px;
}
.tarot-carousel-arrow {
  width: 39px;
  height: 39px;
  position: absolute !important;
  top: 50%;
  cursor: pointer;
  background-image: url(~@/assets/images/ghosts/tarot/selection_arrow.svg);
}
.tarot-carousel-left {
  left: 0;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.tarot-carousel-arrow.hovered {
  background-image: url(~@/assets/images/ghosts/tarot/selection_arrow_hover.svg);
}
.tarot-carousel-right {
  right: 0;
  transform: translate(50%, -50%) rotate(90deg);
}
.tarot-submit-button {
  color: #ffffff;
  text-transform: uppercase;
}
.tarot-warning-text,
.card-title {
  margin-top: 10px;
  font-family: Source Sans 3;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.arrow {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
}
.left-arrow {
  left: 0px;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.right-arrow {
  left: 100%;
  transform: translate(-50%, -50%) rotate(90deg);
}
</style>
