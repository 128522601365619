<template>
  <div v-if="!locked">
    <div class="flight-frame fill-height">
      <div class="d-flex flex-column fill-height">
        <div class="flex-grow-0 flex-shrink-1 flight-banner">
          <div class="logo-container">
            <img
              src="@/assets/images/mykonos/flightaway/flightaway_logo.svg"
              class="logo"
            />
          </div>
        </div>
        <div class="flex-grow-0 flex-shrink-1 banner-title">
          {{ $t("mykonos.flightAway.myAccount.title") }}
        </div>
        <div class="flex-grow-1 flex-shrink-0 flight-content">
          <template v-if="!lookupResultOK">
            <p class="flight-prompt">
              {{ $t("mykonos.flightAway.myAccount.messages.prompt") }}
            </p>
            <p>
              <input
                type="text"
                v-model="myName"
                :placeholder="
                  $t('mykonos.flightAway.myAccount.placeholders.myName')
                "
                :class="{ 'lookup-error': lookupErrors.name }"
              />
            </p>
            <p>
              <input
                type="text"
                v-model="flightNumber"
                :placeholder="
                  $t('mykonos.flightAway.myAccount.placeholders.flightNumber')
                "
                :class="{ 'lookup-error': lookupErrors.flightNumber }"
              />
            </p>
            <p>
              <input
                type="text"
                v-model="creditCardNumber"
                :placeholder="
                  $t(
                    'mykonos.flightAway.myAccount.placeholders.creditCardNumber'
                  )
                "
                :class="{ 'lookup-error': lookupErrors.cardNumber }"
              />
            </p>
            <p>
              <button class="submit-button" @click="tryLookup">
                {{ $t("common.buttons.submit") }}
              </button>
            </p>
          </template>
          <template v-else-if="showTravels || showInfos">
            <v-container>
              <v-row>
                <v-col>
                  <v-btn text @click="backToMenu"
                    >&lt;{{
                      $t("mykonos.flightAway.myAccount.buttons.back")
                    }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
            <template v-if="showTravels">
              <p>
                <img
                  src="@/assets/images/mykonos/flightaway/flightaway_travels.svg"
                  class="my-travels-icon"
                />
              </p>
              <p class="my-travels-title">
                {{ $t("mykonos.flightAway.myAccount.links.myTravels") }}
              </p>
              <v-container class="flights-container">
                <v-row
                  v-for="(travel, index) in travels"
                  :key="`travel-${index}`"
                  class="flight-row"
                >
                  <v-col
                    ><div class="airport-code">{{ travel.from.code }}</div>
                    <div class="airport-name">{{ travel.from.name }}</div>
                  </v-col>
                  <v-col class="d-flex align-middle">
                    <img
                      src="@/assets/images/mykonos/flightaway/flight_icon.svg"
                    />
                  </v-col>
                  <v-col>
                    <div class="airport-code">{{ travel.to.code }}</div>
                    <div class="airport-name">{{ travel.to.name }}</div>
                  </v-col>
                  <v-col>
                    <div class="schedule">
                      {{ travel.departureDate | longDate }}&nbsp;{{
                        $t("mykonos.flightAway.myAccount.labels.on")
                      }}&nbsp;{{ travel.departureDate | shortTime }}
                    </div>
                    <div class="details-number">
                      {{
                        $t("mykonos.flightAway.myAccount.labels.flight")
                      }}&nbsp;:&nbsp;{{ travel.flightNumber }}
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      {{ $t("mykonos.flightAway.myAccount.labels.seat") }}
                    </div>
                    <div class="details-number">{{ travel.seatNumber }}</div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-else-if="showInfos">
              <p>
                <img
                  src="@/assets/images/mykonos/flightaway/flightaway_infos.svg"
                  class="my-travels-icon"
                />
              </p>
              <p class="my-travels-title">
                {{ $t("mykonos.flightAway.myAccount.links.myDetails") }}
              </p>
              <div class="my-details-container">
                <span class="details-label">{{
                  $t("mykonos.flightAway.myAccount.labels.passportNumber")
                }}</span>
                <div class="details-field">{{ passportNumber }}</div>
                <span class="details-label">{{
                  $t("mykonos.flightAway.myAccount.labels.dateOfBirth")
                }}</span>
                <div class="details-field">{{ dateOfBirth | shortDate }}</div>
                <span class="details-label">{{
                  $t("mykonos.flightAway.myAccount.labels.placeOfBirth")
                }}</span>
                <div class="details-field">{{ placeOfBirth }}</div>
              </div>
            </template>
          </template>

          <template v-else>
            <p class="flight-greetings">
              {{ $t("mykonos.flightAway.myAccount.messages.hello") }}
            </p>
            <p class="flight-fullname">{{ fullName }}</p>
            <div class="d-flex justify-center pb-4">
              <div
                class="
                  section-card
                  d-flex
                  flex-column
                  align-center
                  justify-center
                  mr-5
                "
                @click="showTravels = true"
              >
                <img
                  src="@/assets/images/mykonos/flightaway/flightaway_travels.svg"
                />
                <span>{{
                  $t("mykonos.flightAway.myAccount.links.myTravels")
                }}</span>
              </div>
              <div
                class="
                  section-card
                  d-flex
                  flex-column
                  align-center
                  justify-center
                  ml-5
                "
                @click="showInfos = true"
              >
                <img
                  src="@/assets/images/mykonos/flightaway/flightaway_infos.svg"
                />
                <span>{{
                  $t("mykonos.flightAway.myAccount.links.myDetails")
                }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LookupResultEnum from "../../enums/LookupResultEnum";
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "FlightAway",

  data() {
    return {
      sectionID: "flightaway",
      myName: "",
      flightNumber: "",
      creditCardNumber: "",
      travels: [],
      showTravels: false,
      showInfos: false,
      publicPath: process.env.BASE_URL,
    };
  },
  mixins: [currentGameMixin],
  computed: {
    locked: function () {
      return this.$store.getters.mykonosFlightAwayLocked;
    },
    lookupResult: function () {
      return this.$store.state.mykonos.flightAway.lookupResult;
    },
    lookupResultUnknown: function () {
      return this.lookupResult === LookupResultEnum.UNKNOWN;
    },
    lookupResultOK: function () {
      return this.lookupResult === LookupResultEnum.RESULT;
    },
    lookupNoResult: function () {
      return this.lookupResult === LookupResultEnum.NORESULT;
    },
    lookupErrors: function () {
      return this.$store.state.mykonos.flightAway.lookupErrors;
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/travels.json`;
    },
    fullName: function () {
      return this.$store.state.mykonos.flightAway.fullName;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    passportNumber: function () {
      return this.$store.state.mykonos.flightAway.passportNumber;
    },
    dateOfBirth: function () {
      return this.$store.state.mykonos.flightAway.dateOfBirth;
    },
    placeOfBirth: function () {
      return this.$store.state.mykonos.flightAway.placeOfBirth;
    },
  },
  methods: {
    tryLookup: function () {
      this.$store.dispatch("mykonosTryLookupFlightAway", {
        name: this.myName,
        flightNumber: this.flightNumber,
        cardNumber: this.creditCardNumber,
      });
      this.lookingUp = true;
    },
    resetLookup: function () {
      this.$store.dispatch("mykonosResetLookupResult");
    },
    loadTravels: function () {
      axios.get(this.dataPath).then(({ data }) => {
        this.travels = data;
      });
    },
    backToMenu: function () {
      this.showTravels = false;
      this.showInfos = false;
    },
  },
  watch: {
    myName: function () {
      this.resetLookup();
    },
    flightNumber: function () {
      this.resetLookup();
    },
    creditCardNumber: function () {
      this.resetLookup();
    },
    showTravels: function (newValue) {
      if (newValue) this.loadTravels();
    },
  },
};
</script>
<style scoped>
.flight-frame {
  background: #00000066;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
.flight-banner {
  background-image: url(~@/assets/images/mykonos/flightaway/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 60%;
  height: 197px;
}

.logo-container {
  text-align: center;
}

.logo {
  margin-top: 24px;
  max-width: 277px;
}
.banner-title {
  background: #61b2ef;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.065em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 3px;
  padding-bottom: 3px;
}

.flight-greetings {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.flight-fullname {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #61b2ef;
}

.flight-content {
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.flight-content p {
  text-align: center;
}

.flight-content input {
  background: #edeff1;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  max-width: 404px;
  height: 31px;
}

.flight-content input.lookup-error {
  background: #ff0000;
}

.flight-content button {
  width: 100%;
  max-width: 404px;
  height: 31px;
}

.flight-prompt {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.submit-button {
  background: #61b2ef;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.065em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.section-card {
  width: 170px;
  height: 149px;
  background: #dfdfdf;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  pointer-events: all;
  cursor: pointer;
}

.section-card img {
  max-width: 50%;
}

.section-card span {
  margin-top: 10px;
}

.section-card:hover span {
  color: #61b2ef;
}

.my-travels-icon {
  width: 35px;
  margin-top: 35px;
}

.my-travels-title {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.flights-container {
  max-width: 486px;
}

.flight-row {
  border-radius: 6px;
  background: #135a90;
  margin-bottom: 10px;
  white-space: nowrap;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.flight-row .col {
  padding: 8px;
}

.flight-row img {
  width: 59px;
}

.flight-row .airport-code {
  font-size: 16px;
}

.flight-row .airport-name {
  text-transform: uppercase;
}

.flight-row .schedule {
  color: #61b2ef;
  text-transform: capitalize;
}

.flight-row .details-number {
  text-transform: uppercase;
}

.my-details-container {
  max-width: 444px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.details-label {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.details-field {
  border-radius: 29px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #676767;
  background: #e5e5e5;
  padding: 7px;
  margin-bottom: 3px;
}
</style>