<template>
  <div class="grow">
    <DialogOverlay :text="$t('yakuza.arcade.incorrect')" :show="showError" @onOk="showError = false"></DialogOverlay>

    <div v-if="currentStep === 0">
      <div class="arcade-choose pt-2">{{ $t('yakuza.arcade.choose') }}</div>
      <div class="arcade-list pt-2 mb-10">
        <select v-model="currentArcade">
          <option v-for="(v, k) in $yakuzaStore.arcade.list" :key="k">{{ v }}</option>
        </select>
      </div>
      <v-img
        class="arcade-img"
        :src="`${casePublicImages}/arcade/borne_arcade.svg`"
      ></v-img>
    </div>
    <div class="arcade-face" v-if="currentStep === 1">
      <v-img
        class="arcade-img scale-in-center"      
        :src="`${casePublicImages}/arcade/borne_arcade_face.svg`"
      ></v-img>
      <img class="play-img  scale-in-center" :src="`${casePublicImages}/arcade/play.png`" @click="playVideo" />
      <video controls autoplay class="arcade-video scale-in-center" v-if="showVideo" ref="video">
        <source :src="`${casePublicVideos}/arcade2.mp4`" type="video/mp4" />
      </video>
    </div>
  </div>
</template>
  
<script>
  import DialogOverlay from "@/components/DialogOverlay";
  import currentGameMixin from "@/mixins/currentGameMixin";


  export default {
    name: "Hacking",

    components: {
      DialogOverlay
    },

    mixins: [currentGameMixin],

    data: () => ({
      currentStep: 0,
      showError: false,
      showVideo: false,
      currentArcade: '',
    }),

    methods: {
      playVideo() {
        this.showVideo = true;
        this.$refs.video.play();
      }
    },

    watch: {
      currentArcade(newVal) {
        if(newVal !== this.$yakuzaStore.arcade.correct) {
          this.showError = true;
        } else {
          this.currentStep = 1;
        }
      }
    },

    computed: { },
    created() { }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';
  .arcade-choose {
    text-align: center;
    color: #fff;
    font-size: 14pt;
    font-family: "Arimo";
  }

  .arcade-list {
    text-align: center;
  }

  select {
    /* Reset */
    @include small_white_uppercase_text();
    @include customized_select($color: $accent-color-yellow, $bgcolor: $accent-color-yellow-trans, $caretimg: '/yakuza/assets/images/caret_down.png', $optionbg: #000);
  }

  .arcade-face {
    position: relative;
  }

  .arcade-img {
    width: 250px;
    margin: auto;
  }

  .play-img {
    position: absolute;
    top: 118px;
    left: calc(50% - 87px);
    cursor: pointer;
  }

  .arcade-video {
    position: absolute;
    top: 80px;
    width: 500px;
    left: calc(50% - 250px);
    border: 2px solid #fff;

    @media only screen and (max-width: 500px) {
      width: 400px;
      left: calc(50% - 200px);
    }  

    @media only screen and (max-width: 400px) {
      width: 300px;
      left: calc(50% - 150px);
    }    
  

    cursor: pointer;   
  }
</style>
  