<template>
  <v-overlay absolute  class="text-center">
    <div :class="`d-frame ${shake ? 'shake-horizontal' : ''}`" id="d-frame">
      <div class="d-title text-center">
        <span>{{ $t("yakuza.hack.hacking").toUpperCase() }}</span>
        <span class="close-btn"><v-btn icon color="#000" x-small @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></span>
      </div>
      <div class="d-body">
        <!-- Caractères aléatoires -->
        <div v-if="currentStep === 0">
          <div class="chars">
            <div v-for="(v, k) in lines" :key="k">{{ v }}</div>
          </div>
          <div class="d-hack">
            <v-btn @click="currentStep = 1">{{ $t("yakuza.hack.hack_access") }}</v-btn>
          </div>
        </div>

        <!-- Numpad -->
        <div v-if="currentStep === 1">
          <div>{{ $t("yakuza.hack.serialnumber") }}</div>
          <div class="d-serialnum">{{ serialnum }}</div>
          <div class="d-numpad">
            <v-hover v-for="(v,k) in numpad" :key="k" @click="numpadClick(v)" >
              <v-img
                slot-scope="{ hover }"
                v-if="hover"
                :src="v.imgHover.src"
                @click="numpadClick(v)"
                class="img"
              ></v-img>
              <v-img
                v-else
                :src="v.img.src"
                @click="numpadClick(v)"
                class="img"
              ></v-img>              
            </v-hover>
          </div>
        </div>

        <!-- Defilement texte aléatoire -->
        <div v-if="currentStep === 2">
          <div class="chars">
            <div v-for="(v, k) in lines" :key="k" v-html="v"></div>
          </div>
          <div v-if="!isLocked" class="d-hack-ok blink">{{ $t("yakuza.hack.succeeded") }}</div>
        </div>        
      </div>
    </div>
  </v-overlay>
</template>

<script>
  import Vue from 'vue'
  import currentGameMixin from "@/mixins/currentGameMixin";

  export default {
    name: "HackOverlayHack",
    emits: ["onClose", "onSucceed"],
    mixins: [ currentGameMixin ],
    data() {
      return { 
          isLocked: true,
          lineCount: 7,
          colCount: 13,
          lines: [],
          currentStep: 0,
          showSucceeded: false,
          maxSerialLength: 8,
          numpadKeys: ["1","2","3","4","5","6","7","8","9","cancel","0","enter"],
          numpad: [],
          serialnum: '',
          shake: false,
      };
    },
    computed: { },
    mounted() { 
      // Préchargement touches clavier
      this.numpadKeys.forEach((k) => {
        const img = new Image();
        const imgHover = new Image();
        img.src = `${this.casePublicImages}/hack/numpad/${k}.svg`;
        imgHover.src = `${this.casePublicImages}/hack/numpad/${k}_survol.svg`;

        let o = {
          key: k,
          img,
          imgHover
        };

        this.numpad.push(o);
      });

      // Caractères aléatoires initiaux
      const seed = this.$yakuzaStore.hack.characters;

      for(let i=0; i<this.lineCount; i++) {
          let s = '';
          for(let j=0; j<this.colCount; j++) {
              s += seed.charAt(Math.floor(Math.random() * seed.length));
          }
          this.lines.push(s);
      }

      setInterval(this.flipChars, this.$yakuzaStore.hack.charSpeed);
    },
    methods: { 
      flipChars() {
        // Step 0 : Caractères aléatoires
        if(this.currentStep === 0) {
          const seed = this.$yakuzaStore.hack.characters;
          const col = Math.floor(Math.random() * this.colCount);
          const line = Math.floor(Math.random() * this.lineCount);
          const c = seed.charAt(Math.floor(Math.random() * seed.length));
          const s = this.lines[line];
          const newS = s.substring(0, col) + c + s.substring(col+1);
          Vue.set(this.lines, line, newS);
        }

        // Step 1: Défilement de texte
        if(this.currentStep === 2 && this.isLocked) {
          const seed = this.$yakuzaStore.hack.characters;
          const len = Math.floor(Math.random() * this.colCount);

          // Décalage des lignes
          for(let i = 1; i<this.lineCount; i++) {
            Vue.set(this.lines, i-1, this.lines[i]);
          }

          let s = '';
          for(let i=0; i<len; i++) {
            const color = Math.floor(Math.random() * 4);
            s += `<span class="color-rand${color}">`;
            s += seed.charAt(Math.floor(Math.random() * seed.length));
            s += '</span>';
          }

          Vue.set(this.lines, this.lineCount-1, s);     
        }
      },
      numpadClick(k) {
        if(k.key === 'cancel') {
          this.serialnum = '';
          return;
        }
        if(k.key === 'enter') {
          if(this.serialnum.length > 0) {
            this.tryUnlock();
          }
          return;
        }

        if(this.serialnum.length < this.maxSerialLength) {
          this.serialnum += k.key;
        }
      },
      tryUnlock() {
        if(this.serialnum === this.$yakuzaStore.hack.correctSerialnum) {
          this.currentStep = 2;
          setTimeout(this.setUnlocked, 5000);
        } else {
          this.shake = true;
          setTimeout(() => { this.shake = false; this.serialnum = ''; }, 1000);
        }
      },
      setUnlocked() {
        this.isLocked = false;
        setTimeout(() => { this.$emit("onSucceed"); }, 4000);
      }
    }
  }
</script>
    
<style scoped lang="scss">
  @import '@/assets/css/yakuza.scss';

  @media only screen and (min-width: 600px) {
    ::v-deep .v-overlay__content {
      position: absolute;
      right: 50px;
    }
  }

  ::v-deep .d-hack button {
    font: 10pt "Courier Prime";
    color: #fff;
    height: 28px;
    background-color: $accent-color-hack-red-trans !important;
    border: 1px solid $accent-color-hack-red-trans;
    border-radius: 0;
    margin-top: 20px;
    text-transform: none;      
  }

  .d-frame {
    @include size(240px, 285px);
    border: 1px solid $accent-color-hack-red;
    background: rgba(0, 0, 0, 0.8);
    padding: 0;
    font: 10.5pt "Courier Prime";

    .d-title {
      height: 24px;
      background: $accent-color-hack-red;
      color: #000;
      padding: 5px 5px 5px 5px;
      .close-btn {
        float:right;
        margin: -4px;
      }
    }

    .d-body {
      text-align: center;
      color: #fff;
      padding: 10px 20px 20px 20px;

      .chars {
        text-align: center;
      }

      .d-serialnum {
        @include size(90%, 32px);
        border: 1px solid $accent-color-hack-red;
        margin: 5px auto 5px auto;
        text-align: center;
        color: $accent-color-hack-red;
        font-size: 20pt;
        padding-top: 2px;
      }

      .d-numpad {
        padding-left: 30px;
        padding-right: 30px;
        .img {
          @include size(36px, 36px);
          margin: 3px;
          cursor: pointer;
          display: inline-block;
        }
      }

      .d-hack-ok {
        color: $accent-color-hack-red;
        margin-top: 20px;
      }
    }
  }
</style>