<template>
  <div @click="$emit('click')">
    <canvas ref="canvas"></canvas>
    <audio ref="audio"></audio>
    <br />
  </div>
</template>
<script>
export default {
  name: "AudioBarVisualizer",
  data() {
    return {};
  },
  methods: {
    loadSound: function () {
      const audio = this.$refs.audio;
      audio.src = this.src;

      audio.load();

      const context = new AudioContext();
      const src = context.createMediaElementSource(audio);
      const analyser = context.createAnalyser();

      const canvas = this.$refs.canvas;
      canvas.width = this.$el.clientWidth;
      canvas.height = this.$el.clientHeight;
      var ctx = canvas.getContext("2d");

      src.connect(analyser);
      analyser.connect(context.destination);

      analyser.fftSize = 256;

      var bufferLength = analyser.frequencyBinCount;
      console.log(bufferLength);

      var dataArray = new Uint8Array(bufferLength);

      var WIDTH = canvas.width;
      var HEIGHT = canvas.height;

      var barWidth = (WIDTH / bufferLength) * 2.5;
      var barHeight;
      var x = 0;

      function renderFrame() {
        requestAnimationFrame(renderFrame);

        x = 0;

        analyser.getByteFrequencyData(dataArray);

        ctx.fillStyle = "#000";
        ctx.fillRect(0, 0, WIDTH, HEIGHT);

        for (var i = 0; i < bufferLength; i++) {
          barHeight = dataArray[i];

          // var r = barHeight + 25 * (i / bufferLength);
          // var g = 250 * (i / bufferLength);
          // var b = 50;

          //ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
          ctx.fillStyle = "#49D657";
          ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

          x += barWidth + 1;
        }
      }

      renderFrame();
    },
    playSound: function () {
      this.$refs.audio.play();
    },
    pauseSound: function () {
      this.$refs.audio.pause();
    }
  },
  props: {
    src: String,
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.audio.addEventListener("play", () => {this.$emit("play")});
    this.$refs.audio.addEventListener("pause", () => {this.$emit("pause")});
    this.$refs.audio.addEventListener("ended", () => {this.$emit("ended")});
    this.loadSound();
  },
};
</script>