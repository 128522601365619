<template>
  <v-overlay absolute  :class="`text-center ${extraClass}`">
    <div class="d-frame">
      <div class="d-title text-center">
          <span>{{ $t("yakuza.hack.please_wait").toUpperCase() }}</span>
          <span class="close-btn"><v-btn icon color="#000" x-small @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></span>
      </div>
      <div class="d-body ">
        <div>
          <v-img class="logo" contain :src="`${casePublicImages}/hack/TGS_logo.svg`"></v-img>
        </div>
        <div class="progress">
          <div class="progress-text">{{ progress + "%" }}</div>
          <div>
            <v-progress-linear
              v-model="progress"
              color="#fff"
              height="16"
              class="progress-bar"
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </div>
  </v-overlay>
</template>
  
<script>
  import currentGameMixin from "@/mixins/currentGameMixin";

  export default {
    name: "HackOverlayLoading",
    emits: ["onClose", "onFinish"],
    mixins: [ currentGameMixin ],
    data() {
      return { 
        progress: 0
      };
    },
    computed: { },
    props: {
      extraClass: String
    },
    mounted() { 
      setInterval(() => {
        if(this.progress >= 100) {
          this.$emit("onFinish");
        } else {
          this.progress++;
        }
      }, this.$yakuzaStore.hack.loadingSpeed);
    },
    methods: { }
  }
</script>
  
<style scoped lang="scss">
  .d-frame {
    border: 1px solid #00ffff;
    background: rgba(0, 0, 0, 0.8);
    height: 260px;
    width: 260px;
    padding: 0;
    font: 11pt "Courier Prime";

    .d-title {
      height: 24px;
      background: #00ffff;
      color: #000;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      .close-btn {
        float:right;
        margin: -4px;
      }
    }

    .d-body {
      text-align: left;
      color: #fff;
      padding: 20px;
      padding-top: 30px;

      .logo {
        padding-bottom: 50px;
      }

      .progress {
        margin-top: 30px;
      }
      
      .progress-bar {
        border: 1px solid #00ffff;
        padding: 3px;
      }

      ::v-deep .v-progress-linear__determinate {
        height: 8px;
        left: 3px;
        top: 3px;
      }

      ::v-deep .v-progress-linear__background {
        opacity: 0 !important;
      }
    }
  }
</style>