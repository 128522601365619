<template>
  <div class="voice-ui">
    <div class="play-button">
      <v-btn color="#ffc122" fab light @click="btnClick">
        <v-icon>{{ (voice.playing) ? 'mdi-pause' : 'mdi-play' }}</v-icon>
      </v-btn>
    </div>
    <av-waveform
      :audio-src="src"
      played-line-color="#ffc122"
      noplayed-line-color="#000000"
      playtime-slider-color="#ffffff"
      :audio-controls="false"
      :audio-class="'audio-player'"
      :canv-width="width"
      :key="'audioanswer'"
      ref="player"
      class="waveform"
    ></av-waveform>
  </div>
</template>
<script>
  import currentGameMixin from "@/mixins/currentGameMixin";  

  export default {
    name: "SoundPlayer",
    emits: ["onPlay", "onPause"],
    components: { },

    mixins: [currentGameMixin],

    data() {
      return {
        player: null,
        voice: {
          duration: 0,
          currentTime: 0,
          playing: false,
        },       
      };
    },

    computed: { },

    props: {
      src: String,
      width: {
        type: Number,
        default: 300
      }
    },

    mounted() {
      this.player = this.$refs.player.audio;
      this.voice.duration = this.player.duration;

      this.player.addEventListener("durationchange", () => {
        this.voice.duration = this.player.duration;
      });

      this.player.addEventListener("play", () => {
        this.voice.playing = true;
      });

      this.player.addEventListener("pause", () => {
        this.voice.playing = false;
      });

      this.player.addEventListener("timeupdate", () => {
        this.voice.currentTime = this.player.currentTime;
      });      
    },

    methods: {
      onVoicePlay: function () {
        this.player.play();
        this.$emit("onPlay");
      },
      onVoicePause: function () {
        this.player.pause();
        this.$emit("onPause");
      },
      onVoiceSeek: function (currentTime) {
          this.player.currentTime = currentTime;
      },
      btnClick: function() {
        if(this.voice.playing) {
          this.onVoicePause();
        } else {
          this.onVoicePlay();
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .voice-ui {
    height: 80px;

    .play-button {
      display: inline-block;
      height: 80px;
      vertical-align: top;
      padding: 12px;
    }

    .waveform {
      display: inline-block;
      height: 80px;      
    }
  }
</style>