<template>
  <div class="controls-container">
    <div class="pause-icon-container" v-if="playing">
      <v-btn icon><v-icon color="#ffffff" @click.prevent.stop="onPauseClick">mdi-pause</v-icon></v-btn>
    </div>
    <div class="play-icon-container" v-else>
      <v-btn icon><v-icon color="#ffffff" @click.prevent.stop="onPlayClick">mdi-play</v-icon></v-btn>
    </div>

    <div class="slider-container">
      <input type="range" class="voice-slider" min="0" :max="duration" :value="position" @input="onSliderInput" ref="slider"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    playing: Boolean,
    duration: Number,
    position: Number
  },
  methods: {
      onPlayClick: function() {
          this.$emit("onPlay");
      },
      onPauseClick: function() {
          this.$emit("onPause");
      },
      onSliderInput: function() {
          this.$emit("onSeek", this.$refs.slider.value);
      }
  }
};
</script>
<style scoped>
.controls-container {
  background: #00000080;
  border-radius: 10px;
  padding: 0;
}
.pause-icon-container,
.play-icon-container {
  height: 42px;
  float: left;
  padding: 2px 0;
}
* .slider-container {
  float: left;
  padding: 0;
  width: calc(100% - 50px);
}
.voice-slider {
  height: 42px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background: #00000000;
  padding-left: 17px;
  padding-right: 17px;
  float: left;
  margin-top: 0;
  margin-bottom: 0;
}
.voice-slider:focus {
  outline: none;
}
.voice-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #ffffff80;
}
.voice-slider::-webkit-slider-thumb {
  height: 20px;
  width: 4px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.5px;
  border-radius: 1px;
}
.voice-slider:focus::-webkit-slider-runnable-track {
  background: #ffffff80;
}
.voice-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #ffffff80;
}
.voice-slider::-moz-range-thumb {
  height: 20px;
  width: 4px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 1px;
}
.voice-slider::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  color: transparent;
}
.voice-slider::-ms-fill-lower {
  background: #ffffff80;
}
.voice-slider::-ms-fill-upper {
  background: #ffffff80;
}
.voice-slider::-ms-thumb {
  margin-top: 1px;
  height: 20px;
  width: 4px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 1px;
}
.voice-slider:focus::-ms-fill-lower {
  background: #ffffff80;
}
.voice-slider:focus::-ms-fill-upper {
  background: #ffffff80;
}
</style>