const AUTH_EMAIL = 'jl.roux@philharmonique.fr';
const AUTH_PASSWORD = 'philha411';
const AUTH_TOKEN = ')yMfwWewsAJEr9F~';
const AUTH_TOKEN_KEY = 'authToken';
const POLICE_CRYPTO = '0T+';
const POLICE_TOKEN = 'jAndGVf*]N[V_3b"';
const POLICE_TOKEN_KEY = 'policeToken';

export function resetGame() {
    if (isLoggedIn())
        logoutUser();

    if (isPoliceLoggedIn())
        logoutPolice();

    clearSurvey();
}

export function clearSurvey() {
    localStorage.removeItem('survey');
}

export function loginUser(username, password) {
    if (username.toLowerCase().trim() === AUTH_EMAIL.toLowerCase().trim() && password.toLowerCase().trim() === AUTH_PASSWORD.toLowerCase().trim()) {
        setAuthToken(AUTH_TOKEN);
        return true;
    }

    return false;
}

export function loginPolice(crypto) {
    if (crypto.toLowerCase().trim() === POLICE_CRYPTO.toLowerCase().trim()) {
        setPoliceToken(POLICE_TOKEN);
        return true;
    }
}

export function logoutUser() {
    clearAuthToken();
}

export function logoutPolice() {
    clearPoliceToken();
}

function setToken(key, token) {
    localStorage.setItem(key, token);
}

function setAuthToken(token) {
    setToken(AUTH_TOKEN_KEY, token);
}

function setPoliceToken(token) {
    setToken(POLICE_TOKEN_KEY, token);
}

function getToken(key) {
    return localStorage.getItem(key);
}

function getAuthToken() {
    return getToken(AUTH_TOKEN_KEY);
}

function getPoliceToken() {
    return getToken(POLICE_TOKEN_KEY);
}

export function getLocale() {
    return localStorage.getItem('locale')
}

export function setLocale(locale) {
    localStorage.setItem('locale', locale)
}

function clearToken(key) {
    localStorage.removeItem(key);
}

export function clearAuthToken() {
    clearToken(AUTH_TOKEN_KEY);
}

export function clearPoliceToken() {
    clearToken(POLICE_TOKEN_KEY);
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && authToken === AUTH_TOKEN;
}

export function isPoliceLoggedIn() {
    let policeToken = getPoliceToken()
    return !!policeToken && policeToken === POLICE_TOKEN;
}

export function categoryIcon(category) {
    let icon = '';

    switch (category) {
        case 'testimony':
            icon = 'card-account-details-outline';
            break;
        case 'suspect':
            icon = 'card-account-details-outline';
            break;
        case 'voice':
            icon = 'waveform';
            break;
        case 'document':
            icon = 'file-document-multiple';
            break;
        case 'toxicology':
            icon = 'needle';
            break;
        case 'analysis':
            icon = 'microscope';
            break;
        case 'transaction':
            icon = 'bank-transfer';
            break;
        default:
            icon = 'help';
    }

    return 'mdi-' + icon;
}

export function cloneObject(o) {
    const json = JSON.stringify(o);
    const clone = JSON.parse(json);

    return clone;
}

export function compareSolutionPersonChoices(ch1, ch2) {
    return ch1.value.split(' ').reverse().join(' ').localeCompare(ch2.value.split(' ').reverse().join(' '));
}

export class solutionChoicesComparisonFunctions {
    static personsByLastnameAsc = compareSolutionPersonChoices;
}

export const toto = "toto";
