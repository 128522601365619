import { render, staticRenderFns } from "./SvgPhoneCall.vue?vue&type=template&id=20355906&scoped=true&"
import script from "./SvgPhoneCall.vue?vue&type=script&lang=js&"
export * from "./SvgPhoneCall.vue?vue&type=script&lang=js&"
import style0 from "./SvgPhoneCall.vue?vue&type=style&index=0&id=20355906&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20355906",
  null
  
)

export default component.exports