<template>
  <v-overlay absolute  :class="`text-center ${extraClass}`" :value="show" z-index="3">
    <div class="dialog-frame">
      <p v-html="text"></p>
      <button v-if="!hideOK" @click="$emit('onOk')">
        {{ $t(OKtext) }}
      </button>
    </div>
  </v-overlay>
</template>

<script>
  export default {
    name: "DialogOverlay",
    emits: ["onOk"],
    data() {
      return { };
    },
    computed: { },
    props: {
      text: String,
      show: Boolean,
      extraClass: String,
      hideOK: {
        type: Boolean,
        default() { return false; }
      },
      OKtext: {
        type: String,
        default() { return "common.buttons.ok"; }
      }
    },
    mounted() { },
    methods: { }
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-overlay__content {
    max-width: 800px;
  }
</style>