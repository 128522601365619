<template>
  <v-item-group>
    <v-container class="pa-0">
      <v-row no-gutters align="center" class="menu-row">
        <v-col
          v-for="(menu, index) in menus"
          :key="index"
          :id="`menu-${menu.id}`"
          :class="{
            'pl-1': index > 0 && index < menus.length - 1,
            'pr-1': index > 0 && index < menus.length - 1,
          }"
        >
          <v-item v-slot="{ active, toggle }">
            <v-menu
              offset-y
              v-if="menu.subsections && menu.subsections.length > 0"
              tile
            >
              <template v-slot:activator="{ on, attrs }">
                <menu-button
                  v-bind="attrs"
                  v-on="on"
                  class="parent-menu justify-center"
                  :id="`menu-${menu.id}`"
                  :class="{
                    selected: active,
                  }"
                  :disabled="menu.disabled"
                  >{{ menu.title }}</menu-button
                >
              </template>
              <div
                v-for="(submenu, subindex) in menu.subsections"
                :key="subindex"
              >
                <menu-button
                  :lockable="submenu.lockable"
                  :locked="submenu.locked"
                  @click="onMenuClick(submenu, toggle)"
                  class="child-menu"
                  :disabled="submenu.disabled"
                  >{{ submenu.title }}</menu-button
                >
              </div>
            </v-menu>
            <menu-button
              :lockable="menu.lockable"
              :locked="menu.locked"
              :disabled="menu.disabled"
              v-else
              @click="onMenuClick(menu, toggle)"
              class="parent-menu justify-center"
              :class="{ selected: active }"
              >{{ menu.title }}</menu-button
            >
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
import MenuButton from "./MenuButton.vue";

export default {
  components: {
    MenuButton,
  },
  props: {
    menus: Array,
  },
  data() {
    return {};
  },
  methods: {
    onMenuClick: function (menu, toggleFunction) {
      toggleFunction();
      this.$router.push(menu.route);
    },
  },
};
</script>
<style scoped>
.button-layout {
  margin: auto;
  width: 80%;
}

.menu-row {
  border-top: 1px solid white;
}

.menu-item {
  background: #cccccc;
}

.parent-menu {
  background: #00000099;
  font-family: "Bebas Neue";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 56px;
}

.parent-menu:hover,
.parent-menu.selected {
  color: #ffc122;
}

.child-menu {
  background: #000000cc;
  text-align: left;
  color: #ffffff;
  font-family: Source Sans 3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: left;
  height: 41px;
}

.child-menu:hover {
  background: #ffc122;
  color: #000000;
  text-align: left;
  font-family: Source Sans 3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: left;
}
</style>