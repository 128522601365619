export default {
    availableLocales: [
        "fr", "en", "it", "de", "es"
    ],
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ],
    darkweb: {
        secret: "bparty"
    },
    cabins: {
        cabinA: {
            firstName: "John",
            lastName: "Leblanc",
            cabinNumber: "7056"
        },
        cabinB: {
            firstName: "Nikos",
            lastName: "Angelopoulos",
            cabinNumber: "8014"
        },
        cabinC: {
            firstName: "Chiara",
            lastName: "Rosini",
            cabinNumber: "7058"
        },
        cabinD: {
            firstName: "Conrad",
            lastName: "Dean",
            cabinNumber: "5020"
        }
    },
    webmail: {
        email: "john.leblanc@minnesota-account.com",
        fullName: "John LEBLANC",
    },
    sections: [
        {
            id: "cabins",
            subsections: [
                {
                    id: "cabinA",
                    lockable: true,
                    locked: true,
                    disabled: false,
                    route: {
                        name: "colossus-cabins",
                        params: {
                            cabinID: "A"
                        }
                    }
                },
                {
                    id: "cabinB",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: {
                        name: "colossus-cabins",
                        params: {
                            cabinID: "B"
                        }
                    }
                },
                {
                    id: "cabinC",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: {
                        name: "colossus-cabins",
                        params: {
                            cabinID: "C"
                        }
                    }
                },
                {
                    id: "cabinD",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: {
                        name: "colossus-cabins",
                        params: {
                            cabinID: "D"
                        }
                    }
                }
            ]
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "darkweb",
                    lockable: true,
                    locked: true,
                    route: {
                        name: "colossus-darkweb"
                    }
                },
                {
                    id: "webmail",
                    lockable: true,
                    locked: true,
                    route: {
                        name: "colossus-webmail"
                    }
                },
                {
                    id: "interpolice",
                    route: {
                        name: "colossus-interpolice"
                    }
                }
            ]
        },
        {
            id: "clues",
            route: {
                name: "colossus-clues"
            }
        }
    ]
}
