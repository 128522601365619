// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ghosts/hotel/select_arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".hotel-container[data-v-a1b8d538]{position:relative}.hotel-title[data-v-a1b8d538]{font-size:25px;color:#ffc122;margin-top:20px}.hotel-list-label[data-v-a1b8d538],.hotel-title[data-v-a1b8d538]{font-family:Bebas Neue;font-weight:400;line-height:22px;letter-spacing:0;text-align:center;text-transform:uppercase;margin-bottom:20px}.hotel-list-label[data-v-a1b8d538]{font-size:22px;color:#fff}.hotel-list[data-v-a1b8d538]{background:rgba(0,0,0,.5019607843137255);font-family:Source Sans 3;font-size:16px;font-weight:400;line-height:30px;letter-spacing:0;text-align:left;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:19px 11px;background-position:calc(100% - 10px) 50%;padding:3px 49px 3px 22px;width:265px}.hotel-list[data-v-a1b8d538],.hotel-search-button[data-v-a1b8d538]{text-transform:uppercase;color:#fff}", ""]);
// Exports
module.exports = exports;
