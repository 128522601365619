// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ghosts/hunters/history.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".hunters-story-container[data-v-d74b7bb8]{padding-left:46px;padding-right:46px}.hunters-story-title[data-v-d74b7bb8]{font-family:Eater;font-size:16px;line-height:30px}.hunters-story-subtitle[data-v-d74b7bb8],.hunters-story-title[data-v-d74b7bb8]{font-weight:400;letter-spacing:0;text-align:center;color:#fff;text-transform:uppercase}.hunters-story-subtitle[data-v-d74b7bb8]{font-family:Fredoka;font-size:18px;line-height:22px}.hunters-story-banner[data-v-d74b7bb8]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position-y:40%;height:280px}.hunters-story-content[data-v-d74b7bb8]{font-family:Fredoka;font-size:14px;font-weight:400;line-height:17px;letter-spacing:0;text-align:left;color:#fff}", ""]);
// Exports
module.exports = exports;
