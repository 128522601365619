<template>
  <div class="chat-container fill-height">
    <template v-if="initialChatEntry">
      <div>
        {{
          $t(
            `ghosts.data.chats.${chatId}.dialogs.${initialChatEntry.id}.message`
          )
        }}
      </div>
      <div>
        <select
          v-model="chatAnswer"
          @change="answerCurrentQuestion"
          v-if="!initialAnswer"
          class="choice-list"
        >
          <option
            v-for="choice in getLocalizedChoices(initialChatEntry)"
            :value="choice.value"
            :key="choice.value"
          >
            {{ choice.localizedValue }}
          </option>
        </select>
      </div>
    </template>
    <template v-if="otherChatEntries.length > 0">
      <div class="chat-log-header">
        <div>{{ $t("ghosts.chat.labels.yourMessage") }}</div>
        <div>
          <div>
            {{ $t("ghosts.chat.labels.to") }}:&nbsp;{{
              initialLocalizedAnswer
            }}
          </div>
        </div>
      </div>
      <div class="chat-log-body">
        <div
          v-for="(entry, index) in otherChatEntries"
          :key="index"
          class="chat-log-entry"
        >
          <label class="protagonist-label">{{
            getProtagonistDisplayName(entry.protagonist)
          }}</label>:&nbsp;
          <template v-if="entry.choices">
            <span v-if="questionHasAnswer(entry)">
              {{ getLocalizedChoice(entry, getAnswer(entry)) }}
            </span>
            <select
              v-model="chatAnswer"
              @change="answerCurrentQuestion"
              class="choice-list"
              v-else
            >
              <option
                v-for="choice in getLocalizedChoices(entry)"
                :key="choice.value"
                :value="choice.value"
              >
                {{ choice.localizedValue }}
              </option>
            </select>
          </template>
          <template v-else>
            {{ $t(`ghosts.data.chats.${chatId}.dialogs.${entry.id}.message`) }}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "RpgChat",
  data() {
    return {
      chatAnswer: null,
      queue: [],
      displayedLog: [],
    };
  },
  mixins: [currentGameMixin],
  props: {
    chatId: String,
  },
  computed: {
    protagonists: function () {
      return this.$store.getters.ghostsChatProtagonists(this.chatId);
    },
    chatVariables: function () {
      return this.$store.getters.ghostsChatVariables(this.chatId);
    },
    chatLog: function () {
      return this.$store.getters.ghostsChatLog(this.chatId);
    },
    initialChatEntry: function () {
      return this.displayedLog.find((e) => e.entryPoint);
    },
    otherChatEntries: function () {
      return this.displayedLog.filter((e) => e !== this.initialChatEntry);
    },
    initialAnswer: function () {
      return this.getChatEntryAnswer(this.initialChatEntry);
    },
    initialLocalizedAnswer: function () {
      return this.getLocalizedChoice(this.initialChatEntry, this.initialAnswer);
    },
    chatComplete: function () {
      return this.$store.getters.ghostsChatComplete(this.chatId);
    },
    chatDeadend: function () {
      return this.displayedLog.findIndex((entry) => entry.deadEnd) !== -1;
    },
  },
  methods: {
    resumeChat: function() {
      if ((this.chatLog?.length || 0) === 0) {
        this.resetChat();
      } else {
        this.displayedLog = [...this.chatLog];
      }
    },
    resetChat: function () {
      this.queue = [];
      this.displayedLog = [];
      this.chatAnswer = null;
      this.$store.dispatch("ghostsResetChat", { chatId: this.chatId });
    },
    processQueue: function () {
      if (this.queue.length > 0) {
        const entry = this.queue.shift();

        if (entry.protagonist) {
          this.displayedLog.push({
            id: "typing",
            protagonist: entry.protagonist,
          });
          setTimeout(() => {
            this.displayedLog.splice(this.displayedLog.length - 1, 1);
            this.displayedLog.push(entry);

            setTimeout(this.processQueue, 500);
          }, 1000);
        } else {
          this.displayedLog.push(entry);
          setTimeout(this.processQueue, 500);
        }
      } else {
        setTimeout(this.processQueue, 500);
      }
    },
    answerCurrentQuestion: function () {
      this.$store.dispatch("ghostsTryAnswerChat", {
        chatId: this.chatId,
        answer: this.chatAnswer,
      });

      this.chatAnswer = null;
    },
    getChatEntryAnswer: function (entry) {
      return entry.choices.find((c) => c.selected);
    },
    questionHasAnswer: function (entry) {
      return entry.choices.findIndex((c) => c.selected) !== -1;
    },
    getAnswer: function (entry) {
      return entry.choices.find((c) => c.selected);
    },
    getLocalizedChoice: function (entry, choice) {
      if (entry.localizedChoices) {
        return this.$t(
          `ghosts.data.chats.${this.chatId}.dialogs.${entry.id}.choices.${choice.value}`
        );
      }

      return choice.value;
    },
    getLocalizedChoices: function (entry) {
      const choices = entry.choices.map((ch) => {
        return {
          ...ch,
          localizedValue: this.getLocalizedChoice(entry, ch),
        };
      });

      if (entry.sorted) {
        if (entry.sortFunc) {
          return choices.sort(entry.sortFunc);
        }

        return choices.sort((ch1, ch2) => {
          return ch1.localizedValue.localeCompare(ch2.localizedValue);
        });
      }

      return choices;
    },
    getProtagonistDisplayName: function (id) {
      const protagonist = this.protagonists.find((p) => p.id === id);

      if (protagonist === undefined) return "";

      if (protagonist.variable) return this.chatVariables[protagonist.id];

      if (protagonist.localized)
        return this.$t(
          `ghosts.data.chats.${this.chatId}.protagonists.${id}.name`
        );

      if (protagonist.name) return protagonist.name;

      return protagonist.id;
    },
  },
  mounted() {
    this.processQueue();
  },
  watch: {
    chatLog: {
      deep: true,
      handler: function (newLog) {
        if (newLog.length > this.displayedLog.length) {
          for (let i = this.displayedLog.length; i < newLog.length; i++) {
            const entry = newLog[i];

            this.queue.push(entry);
          }
        }
        // setTimeout(() => {

        // }, 1000);
      },
    },
    chatDeadend: function (newValue) {
      if (newValue) this.$emit("chatDeadend");
    },
    chatComplete: function (newValue) {
      if (newValue) this.$emit("chatComplete");
    },
  },
};
</script>
<style scoped>
.chat-container {
  background: #ffffff;
}
.chat-log-header {
  background: #b6b6b6;
  font-family: Arimo;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding: 11px 18px 7px;
}
.chat-log-header > div {
  margin-top: 6px;
}
.chat-log-header > div > div {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 400;
}
.chat-log-body {
  margin: 0;
  background: #eeeeee;
  font-family: Arimo;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-family: Arimo;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.chat-log-entry {
  padding: 9px;
}
.protagonist-label {
  text-transform: capitalize;
  font-weight: bold;
}
.choice-list {
  border: 1px solid black;
  -moz-appearance: auto;
  -webkit-appearance: menulist;
  appearance: auto;
}
.entries-enter-active,
.entries-leave-active {
  transition: all 1s ease;
}
.entries-enter-from,
.entries-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
