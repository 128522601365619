export default [
    {
      "value": "Angelo Fellinito"
    },
    {
      "value": "Francesca Rossini"
    },
    {
      "value": "Dean Aberdeen"
    },
    {
      "value": "John Cruise"
    },
    {
      "value": "Damian Hammer"
    },
    {
      "value": "Edward Hurley"
    },
    {
      "value": "Hannah Katleen"
    },
    {
      "value": "Carrie Kryston"
    },
    {
      "value": "Branwell Linton"
    },
    {
      "value": "Jane Linton"
    },
    {
      "value": "Larry Linton"
    },
    {
      "value": "Leo Mankiewicz"
    },
    {
      "value": "Clark Nerdis"
    },
    {
      "value": "Christopher Price"
    },
    {
      "value": "Peyton Reed"
    },
    {
      "value": "Basil Hills"
    },
    {
      "value": "Catherine Stirwell"
    }
  ];