<template>
  <div>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="4" v-if="showVolume">
        <v-row>
          <v-col cols="8">
            <v-slider
              v-model="volume"
              @input="updateVolume(volume)"
              max="1"
              step="0.1"
            ></v-slider>
          </v-col>
          <v-col cols="2">
            {{ this.volume * 100 + "%" }}
          </v-col>
          <v-col cols="2">
            <v-btn text icon @click="toggleMute">
              <template v-if="!this.muted">
                <v-icon v-if="this.volume >= 0.5">mdi-volume-high</v-icon>
                <v-icon v-else-if="this.volume > 0">mdi-volume-low</v-icon>
                <v-icon v-else>mdi-volume-medium</v-icon>
              </template>
              <v-icon v-show="this.muted">mdi-volume-off</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-center d-flex flex-column align-center justify-center">
        <div>
          <v-btn
            outlined
            fab
            small
            :color="buttonColor"
            @click="skipTrack('prev')"
            class="mx-1"
          >
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn
            outlined
            fab
            small
            :color="buttonColor"
            @click="stopTrack"
            class="mx-1"
          >
            <v-icon>mdi-stop</v-icon>
          </v-btn>
          <v-btn
            outlined
            fab
            :color="buttonColor"
            @click="playTrack()"
            class="mx-1"
          >
            <v-icon large>mdi-play</v-icon>
          </v-btn>
          <v-btn
            outlined
            fab
            small
            :color="buttonColor"
            @click="pauseTrack"
            class="mx-1"
          >
            <v-icon>mdi-pause</v-icon>
          </v-btn>
          <v-btn
            outlined
            fab
            small
            :color="buttonColor"
            @click="skipTrack('next')"
            class="mx-1"
          >
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
          <v-btn plain text @click="toggleLoop" v-if="showLoop">
            <v-icon color="buttonColor" v-if="this.loop"
              >mdi-repeat-once</v-icon
            >
            <v-icon color="blue-grey" v-else>mdi-repeat-once</v-icon>
          </v-btn>
          <v-btn plain text @click="toggleShuffle" v-if="showShuffle">
            <v-icon color="buttonColor" v-if="this.shuffle">mdi-shuffle</v-icon>
            <v-icon color="blue-grey" v-else>mdi-shuffle</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row text dark v-if="showProgress">
      <v-progress-linear
        height="40"
        v-model="trackProgress"
        @change="updateSeek($event)"
      ></v-progress-linear>
    </v-row>
  </div>
</template>
<script>
import { Howler } from "howler";

export default {
  name: "PlayerControlBars",
  data() {
    return {
      volume: 0.5,
      muted: false,
    };
  },
  props: {
    loop: Boolean,
    shuffle: Boolean,
    progress: Number,
    buttonColor: String,
    showLoop: Boolean,
    showShuffle: Boolean,
    showVolume: Boolean,
    showProgress: Boolean,
  },
  methods: {
    playTrack(index) {
      this.$emit("playtrack", index);
    },
    pauseTrack() {
      this.$emit("pausetrack");
    },
    stopTrack() {
      this.$emit("stoptrack");
    },
    skipTrack(direction) {
      this.$emit("skiptrack", direction);
    },
    updateVolume(volume) {
      Howler.volume(volume);
    },
    toggleMute() {
      Howler.mute(!this.muted);
      this.muted = !this.muted;
    },
    toggleLoop() {
      this.$emit("toggleloop", !this.loop);
    },
    toggleShuffle() {
      this.$emit("toggleshuffle", !this.shuffle);
    },
    updateSeek(percent) {
      this.$emit("updateseek", percent);
    },
  },
  computed: {
    trackProgress() {
      return this.progress * 100;
    },
  },
  created: function () {
    Howler.volume(this.volume);
  },
};
</script>
