import { render, staticRenderFns } from "./Karnak.vue?vue&type=template&id=736471b5&scoped=true&"
import script from "./Karnak.vue?vue&type=script&lang=js&"
export * from "./Karnak.vue?vue&type=script&lang=js&"
import style0 from "./Karnak.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Karnak.vue?vue&type=style&index=1&id=736471b5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736471b5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VCol,VContainer,VOverlay,VRadio,VRadioGroup,VRow,VSimpleTable,VTextField})
