<template>
  <v-container class="m-place">
    <div class="m-place-back" v-if="showBack" @click="back()">
      <v-img 
        :src="`${casePublicImages}/metro/retour.svg`"
        class="m-place-back-img"
        @click="back()">
      </v-img>
      <span>{{ $t("yakuza.metro.back") }}</span>
    </div>
    <div class="m-place-header">{{ stop }}</div>
    <div class="row m-place-row">
      <div class="m-place-img col-md-6 col-12">
        <MetroGallery 
          :place="stop" 
          :imageCount="$yakuzaStore.metro.placeImageCount" 
          :speed="$yakuzaStore.metro.placeImageCycleSpeed">
        </MetroGallery>
      </div>
      <div class="m-place-list col-md-6 col-12">
        <div class="m-place-list-header">{{ $t('yakuza.metro.place_where') }}</div>
        <div class="m-place-list-select">
          <select v-model="selectedPlace">
            <option v-for="(v,k) in listForCurrentStop" :key="k">{{ v }}</option>
          </select>
        </div>
        <div class="m-place-list-validate">
          <v-btn class="btn" 
            @click="validate">
            {{ $t('yakuza.metro.validate') }}
          </v-btn>
        </div>
      </div>
    </div>
    <Sound :src="`${casePublicAudio}/metro/${stop}.mp3`" loop ref="bgSound"></Sound>    
  </v-container>
</template>
<script>
import MetroGallery from "@/components/yakuza/MetroGallery";
import Sound from "@/components/yakuza/Sound";
import currentGameMixin from "@/mixins/currentGameMixin";

export default {
  name: "MetroStop",
  emits: ["onPlaceSelected", "onBack"],
  components: { MetroGallery, Sound },
  mixins: [currentGameMixin],
  data() {
    return {
      selectedPlace: '',
      showBack: true,
    };
  },
  computed: {
    listForCurrentStop() {
      return this.$yakuzaStore.metro.correctStops[this.env].places;
    }   
  },
  props: {
    stop: String,
    env: String,
  },
  mounted() {
    this.$refs.bgSound.play();

    if(this.$yakuzaStore.metro.correctStops[this.env].found) {
      this.showBack = false
    }
  },
  methods: {
    validate() {
      this.$emit("onPlaceSelected", this.selectedPlace);
    },
    back() {
      this.$emit("onBack");
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/yakuza.scss';

.m-place {
  .m-place-back {
    text-align: left;
    margin: 5px auto;

    .m-place-back-img {
      @include size(20px, 20px);
      display: inline-block;
      &:hover { 
        cursor: pointer 
      }
    }

    span {
      @include small_white_uppercase_text();
      display: inline-block;
      height: 20px;
      vertical-align: top;
      padding-left: 5px; 
      &:hover { cursor: pointer;}
    }
  }
  .m-place-header {
    width: 100%;
    background-color: #000;
    color: $accent-color-yellow;
    font: normal 20pt "Bebas Neue";
    border: 1px solid $accent-color-yellow;
    padding: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 16pt;
    }    
  }
  .m-place-img {
    padding:0;
    border: 1px solid $accent-color-yellow;
    border-top: 0;
  }
  .m-place-row {
    margin: 0;
  }

  .m-place-list {
    .m-place-list-header {
      @include small_white_uppercase_text();
      @media only screen and (min-width: 600px) {
        @include padding($t: 30px, $b: 30px);
      }
    }

    select {
      /* Reset */
      @include small_white_uppercase_text();
      @include customized_select($color: $accent-color-yellow, $bgcolor: $accent-color-yellow-trans, $caretimg: '/yakuza/assets/images/caret_down.png', $optionbg: #000);
    }

    ::v-deep .m-place-list-validate button {
      font: normal 12pt "Bebas Neue";
      color: #000;
      background-color: $accent-color-yellow;
      border: 1px solid $accent-color-yellow;
      border-radius: 0;
      margin-top: 30px;
    }
  }
}
</style>