<template>
<div>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="
      ($vuetify.breakpoint.name === 'xs') ? '100 0 300 394' : '0 0 622.4 394.99'
    "
  >
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <g id="lignes_coffre" data-name="lignes coffre">
          <path
            class="cls-1"
            d="M117,395a.73.73,0,0,1-.52-.21.75.75,0,0,1-.25-.56V68.41a.78.78,0,0,1,.74-.77L621.6,45.93a.81.81,0,0,1,.56.21.8.8,0,0,1,.24.56V350.35a.77.77,0,0,1-.71.77L117.08,395Zm.77-325.84V393.39l503.07-43.75V47.5Zm503.84,281.2h0Z"
          />
          <path
            class="cls-1"
            d="M117,395a.79.79,0,0,1-.42-.12L.62,320.39a.78.78,0,0,1-.35-.65L0,18.25a.78.78,0,0,1,.35-.65.77.77,0,0,1,.72-.06L117.32,67.71a.77.77,0,0,1,.47.7V394.22a.77.77,0,0,1-.4.67A.71.71,0,0,1,117,395ZM1.81,319.32l114.44,73.49V68.92L1.54,19.42Z"
          />
          <path
            class="cls-1"
            d="M117,69.18a.81.81,0,0,1-.31-.06L.46,19a.77.77,0,0,1,.28-1.47L471.9,0a.72.72,0,0,1,.25,0L621.85,46a.77.77,0,0,1,.54.84.78.78,0,0,1-.73.67L117.05,69.18ZM4.23,18.9,117.16,67.64,617.08,46.11,471.82,1.54Z"
          />
          <path
            class="cls-1"
            d="M621.63,351.12a.76.76,0,0,1-.77-.77V46.7a.77.77,0,0,1,1.54,0V350.35A.77.77,0,0,1,621.63,351.12Z"
          />
          <path
            class="cls-1"
            d="M149.4,362.26a4.9,4.9,0,0,1-4.9-4.9V98.84A4.92,4.92,0,0,1,149.09,94l443.6-23.42a4.81,4.81,0,0,1,3.66,1.31,4.89,4.89,0,0,1,1.54,3.58V320.64a4.86,4.86,0,0,1-4.45,4.88h0l-443.6,36.72ZM593,72.05h-.22L149.17,95.49A3.36,3.36,0,0,0,146,98.84V357.36a3.38,3.38,0,0,0,3.67,3.35L593.3,324a3.34,3.34,0,0,0,3.05-3.35V75.42A3.36,3.36,0,0,0,593,72.05Zm.37,252.71h0Z"
          />
          <g id="poignee">
            <path
              class="cls-1"
              d="M145.56,275.89l-.58-1.12a46.31,46.31,0,0,0,3.09-80.39c-1-.62-2-1.18-3.09-1.77l.58-1.12c1.16.6,2.17,1.18,3.12,1.77a47.59,47.59,0,0,1-3.12,82.63Z"
            />
            <path
              class="cls-1"
              d="M145.6,272.59l-.66-1.08a46,46,0,0,0,22.3-39.6,46.5,46.5,0,0,0-19.17-37.53l-.08-.06c-1.07-.75-2.06-1.41-3.05-2l.66-1.08c1,.62,2,1.29,3.11,2.05a47.8,47.8,0,0,1,19.79,38.63A47.26,47.26,0,0,1,145.6,272.59Z"
            />
          </g>
        </g>
        <g id="écran">
          <path
            class="cls-2"
            d="M376.89,134.73v23.88a4.86,4.86,0,0,1-5.12,4.56l-163,10.26a6.12,6.12,0,0,1-1.14-.11,4.32,4.32,0,0,1-1.45-3.19V146.24a4.86,4.86,0,0,1,5.12-4.56l163-10.25a5.24,5.24,0,0,1,1.14.12A4.28,4.28,0,0,1,376.89,134.73Z"
          />
          <path
            d="M376.89,134.73v23.88a4.86,4.86,0,0,1-5.12,4.56l-163,10.26a6.12,6.12,0,0,1-1.14-.11,4.32,4.32,0,0,1-1.45-3.19V146.24a4.86,4.86,0,0,1,5.12-4.56l163-10.25a5.24,5.24,0,0,1,1.14.12A4.28,4.28,0,0,1,376.89,134.73Z"
          />
          <path
            class="cls-1"
            d="M208.77,173.93a6.17,6.17,0,0,1-1.25-.12l-.14,0-.1-.09a4.81,4.81,0,0,1-1.61-3.56V146.24a5.37,5.37,0,0,1,5.62-5.06l163-10.25a4.76,4.76,0,0,1,1.27.13l.14,0,.1.09a4.79,4.79,0,0,1,1.62,3.55v23.88a5.37,5.37,0,0,1-5.62,5.06l-163,10.26Zm-.91-1.08a5.29,5.29,0,0,0,.9.08l163-10.25c2.58,0,4.66-1.83,4.66-4.07V134.73A3.78,3.78,0,0,0,375.2,132a5.4,5.4,0,0,0-.91-.08l-163,10.25a4.38,4.38,0,0,0-4.65,4.06v23.89A3.78,3.78,0,0,0,207.86,172.85Z"
          />
          <path
            class="cls-1"
            d="M212.32,176.69a6,6,0,0,1-4.14-1.59,5.15,5.15,0,0,1-1.66-3.76V146.71a5.58,5.58,0,0,1,5.77-5.35l163-10.57h0a6.66,6.66,0,0,1,1.3.13,5.48,5.48,0,0,1,4.49,5.22v24.62a5.58,5.58,0,0,1-5.77,5.35l-163,10.58Zm163-44.61-163,10.56a4.31,4.31,0,0,0-4.52,4.07v24.63a3.84,3.84,0,0,0,1.25,2.82,4.73,4.73,0,0,0,3.23,1.25l163-10.58a4.31,4.31,0,0,0,4.52-4.07V136.14a4.19,4.19,0,0,0-3.47-4A4.74,4.74,0,0,0,375.29,132.08Z"
          />
          <path
            d="M376.89,134.73v23.88a4.86,4.86,0,0,1-5.12,4.56l-163,10.26a6.12,6.12,0,0,1-1.14-.11,4.32,4.32,0,0,1-1.45-3.19V146.24a4.86,4.86,0,0,1,5.12-4.56l163-10.25a5.24,5.24,0,0,1,1.14.12A4.28,4.28,0,0,1,376.89,134.73Z"
          />
          <path
            class="cls-1"
            d="M208.77,173.93a6.17,6.17,0,0,1-1.25-.12l-.14,0-.1-.09a4.81,4.81,0,0,1-1.61-3.56V146.24a5.37,5.37,0,0,1,5.62-5.06l163-10.25a4.76,4.76,0,0,1,1.27.13l.14,0,.1.09a4.79,4.79,0,0,1,1.62,3.55v23.88a5.37,5.37,0,0,1-5.62,5.06l-163,10.26Zm-.91-1.08a5.29,5.29,0,0,0,.9.08l163-10.25c2.58,0,4.66-1.83,4.66-4.07V134.73A3.78,3.78,0,0,0,375.2,132a5.4,5.4,0,0,0-.91-.08l-163,10.25a4.38,4.38,0,0,0-4.65,4.06v23.89A3.78,3.78,0,0,0,207.86,172.85Z"
          />
          <text
            x="210"
            y="152"
            class="lcd error"
            transform="rotate(-3.6) translate(-10 15)"
            v-if="showError"
          >
            <tspan x="210" v-text="$t('yakuza.safe.error_message_1')"></tspan>
            <tspan x="210" dy="1.2em" v-text="$t('yakuza.safe.error_message_2')"></tspan>
          </text>
          <text
            x="210"
            y="163"
            class="lcd"
            transform="rotate(-3.6) translate(-10 15)"
            v-text="inputCode"
            v-else-if="$yakuzaStore.findEnvelope('enveloppeB').locked"
          ></text>
          <text
            x="210"
            y="152"
            class="lcd unlocked"
            transform="rotate(-3.6) translate(-10 15)"
            v-else
          >
            <tspan x="210" v-text="$t('yakuza.safe.unlock_message_1')"></tspan>
            <tspan x="210" dy="1.2em" v-text="$t('yakuza.safe.unlock_message_2')"></tspan>
          </text>
        </g>
        <g
          id="touche_1"
          data-name="touche 1"
          class="digit"
          @click.stop.prevent="onInput('1')"
        >
          <path
            class="cls-1"
            d="M258.23,188.75a6.07,6.07,0,0,0-4.55-1.55L212.29,190a6.74,6.74,0,0,0-6.31,6.49v13.8a5.66,5.66,0,0,0,4.42,5.53l1.9.46-1.44-1.32a4.56,4.56,0,0,1-1.5-3.42v-13.8a5.71,5.71,0,0,1,5.36-5.49l41.4-2.77a5.1,5.1,0,0,1,1.64.14l2,.53Z"
          />
          <g id="survol">
            <path
              id="BG_touche-2"
              data-name="BG touche"
              class="cls-4"
              d="M261.92,194.16V208a6.21,6.21,0,0,1-5.83,6l-41.4,2.77a5.5,5.5,0,0,1-4.16-1.41h0a5.07,5.07,0,0,1-1.66-3.79v-13.8a6.21,6.21,0,0,1,5.83-6l41.4-2.77a5.6,5.6,0,0,1,1.8.16A5.15,5.15,0,0,1,261.92,194.16Z"
            />
            <path
              id="contour_touche"
              data-name="contour touche"
              class="cls-1"
              d="M256.5,190a4.39,4.39,0,0,1,1.16.14,4.15,4.15,0,0,1,3.26,4.07V208a5.2,5.2,0,0,1-4.9,5l-41.4,2.77h-.35a4.48,4.48,0,0,1-3.06-1.15l-.22-.21a4.1,4.1,0,0,1-1.13-2.85v-13.8a5.2,5.2,0,0,1,4.9-5L256.15,190h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.07,5.07,0,0,0,1.66,3.79h0a5.42,5.42,0,0,0,3.74,1.42h.42l41.4-2.77a6.21,6.21,0,0,0,5.83-6v-13.8a5.15,5.15,0,0,0-4-5,5.24,5.24,0,0,0-1.39-.17Z"
            />
            <path
              class="digit-text"
              d="M237.51,193.93v8.74l-2.41.17v-5.73a6.81,6.81,0,0,1-1.14.8,9.49,9.49,0,0,1-1.37.63v-2a6.58,6.58,0,0,0,1.89-1.07,4.35,4.35,0,0,0,1.06-1.44Z"
            />
          </g>
        </g>
        <g
          id="touche_2"
          data-name="touche 2"
          class="digit"
          @click.stop.prevent="onInput('2')"
        >
          <path
            id="BG_touche-3"
            data-name="BG touche"
            class="cls-4"
            d="M320.23,189.75v13.8a6.2,6.2,0,0,1-5.84,6L273,212.31a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8V193.3a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,320.23,189.75Z"
          />
          <g id="relief_touche" data-name="relief touche">
            <path
              class="cls-1"
              d="M317.06,185.14a5.8,5.8,0,0,0-1.78-.15l-41.39,2.77a6.22,6.22,0,0,0-5.84,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5V192.5a6.21,6.21,0,0,1,5.84-6l41.39-2.77A5.51,5.51,0,0,1,317.06,185.14Z"
            />
            <path
              class="cls-1"
              d="M271.49,212.29l-1.9-.46a5.65,5.65,0,0,1-4.41-5.53V192.5a6.74,6.74,0,0,1,6.31-6.49l41.39-2.77a6,6,0,0,1,4.52,1.53l1.44,1.33-1.9-.48a5.45,5.45,0,0,0-1.63-.13l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.56,4.56,0,0,0,1.5,3.42Zm41.84-28.06h-.39L271.55,187a5.71,5.71,0,0,0-5.37,5.49v13.8a4.61,4.61,0,0,0,2,3.8,5.66,5.66,0,0,1-.6-2.55v-13.8a6.74,6.74,0,0,1,6.31-6.49L315,184.5A5.37,5.37,0,0,0,313.33,184.23Z"
            />
          </g>
          <path
            id="BG_touche-4"
            data-name="BG touche"
            class="cls-1"
            d="M315.69,186a4.43,4.43,0,0,1,.83.07l.32.07a4.15,4.15,0,0,1,3.28,4.07V204a5.2,5.2,0,0,1-4.91,5l-41.39,2.78h-.34a4.52,4.52,0,0,1-3.1-1.17,4.07,4.07,0,0,1-1.33-3v-13.8a5.2,5.2,0,0,1,4.91-5L315.35,186h.34m0-1h-.41l-41.39,2.77a6.22,6.22,0,0,0-5.84,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.49,5.49,0,0,0,3.77,1.43h.41L315.28,210a6.21,6.21,0,0,0,5.84-6v-13.8a5.15,5.15,0,0,0-4.05-5,0,0,0,0,0,0,0,5.53,5.53,0,0,0-1.37-.16Z"
          />
          <path
            class="digit-text"
            d="M298.61,197.9l-7.16.5a4.91,4.91,0,0,1,.75-2.05,11.48,11.48,0,0,1,2.34-2.37,7.69,7.69,0,0,0,1.34-1.27,1.42,1.42,0,0,0,.3-.79.84.84,0,0,0-.29-.65.94.94,0,0,0-.73-.23,1.06,1.06,0,0,0-.75.34,1.92,1.92,0,0,0-.39,1l-2.39,0a4.07,4.07,0,0,1,.52-1.61,2.74,2.74,0,0,1,1.05-.94,6.53,6.53,0,0,1,3.85-.28,2.16,2.16,0,0,1,1.1.8,2.22,2.22,0,0,1,.41,1.31,3,3,0,0,1-.46,1.53,7.11,7.11,0,0,1-1.68,1.69c-.48.36-.8.62-1,.76s-.35.33-.57.57l3.72-.27Z"
          />
         </g>
        <g
          id="touche_3"
          data-name="touche 3"
          class="digit"
          @click.stop.prevent="onInput('3')"
        >
          <path
            id="BG_touche-5"
            data-name="BG touche"
            class="cls-4"
            d="M379.23,185.75v13.8a6.2,6.2,0,0,1-5.84,6L332,208.31a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8V189.3a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,379.23,185.75Z"
          />
          <g id="relief_touche-2" data-name="relief touche">
            <path
              class="cls-1"
              d="M376.27,181.18a5.54,5.54,0,0,0-1.79-.16l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5v-13.8a6.22,6.22,0,0,1,5.84-6l41.4-2.77A5.5,5.5,0,0,1,376.27,181.18Z"
            />
            <path
              class="cls-1"
              d="M330.68,208.31l-1.89-.45a5.64,5.64,0,0,1-4.42-5.53v-13.8a6.74,6.74,0,0,1,6.31-6.49l41.4-2.77a6,6,0,0,1,4.53,1.54l1.47,1.37-1.94-.52a5,5,0,0,0-1.63-.14l-41.4,2.77a5.71,5.71,0,0,0-5.36,5.49v13.8a4.56,4.56,0,0,0,1.5,3.42Zm41.85-28.05h-.39L330.74,183a5.71,5.71,0,0,0-5.37,5.49v13.8a4.57,4.57,0,0,0,2,3.81,5.71,5.71,0,0,1-.6-2.56v-13.8a6.74,6.74,0,0,1,6.3-6.49l41.19-2.76A5.33,5.33,0,0,0,372.53,180.26Z"
            />
          </g>
          <path
            id="contour_touche-2"
            data-name="contour touche"
            class="cls-1"
            d="M374.89,182a4.37,4.37,0,0,1,1.15.14,4.15,4.15,0,0,1,3.28,4.07V200a5.19,5.19,0,0,1-4.91,5L333,207.79h-.34a4.5,4.5,0,0,1-3.09-1.17,4.07,4.07,0,0,1-1.33-3v-13.8a5.2,5.2,0,0,1,4.9-5L374.54,182h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.44,5.44,0,0,0,3.76,1.43h.41l41.4-2.78a6.21,6.21,0,0,0,5.84-6v-13.8a5.15,5.15,0,0,0-4.05-5,5.22,5.22,0,0,0-1.38-.17Z"
          />
          <path
            class="digit-text"
            d="M352.69,188.9l-2.26-.24a3.11,3.11,0,0,1,1.08-1.73,4,4,0,0,1,2.27-.73,3.62,3.62,0,0,1,2.43.45,1.82,1.82,0,0,1,.75,1.53,1.78,1.78,0,0,1-.31,1,2.74,2.74,0,0,1-.92.86,2.85,2.85,0,0,1,.77.23,1.79,1.79,0,0,1,.66.65,2,2,0,0,1,.24,1,3.05,3.05,0,0,1-.39,1.47,3,3,0,0,1-1.13,1.14,4.66,4.66,0,0,1-1.94.51,5,5,0,0,1-1.85-.15,2.46,2.46,0,0,1-1.12-.73,3.45,3.45,0,0,1-.67-1.28l2.39-.49a1.72,1.72,0,0,0,.43,1,1,1,0,0,0,.76.23,1.12,1.12,0,0,0,.8-.41,1.44,1.44,0,0,0,.32-1,1.23,1.23,0,0,0-.31-.9,1,1,0,0,0-.84-.27,2.89,2.89,0,0,0-.77.19l.12-1.71h.31a1.19,1.19,0,0,0,.79-.36,1,1,0,0,0,.31-.73.82.82,0,0,0-.23-.61.86.86,0,0,0-.65-.19,1,1,0,0,0-.69.3A1.72,1.72,0,0,0,352.69,188.9Z"
          />
        </g>
        <g
          id="touche_4"
          data-name="touche 4"
          class="digit"
          @click.stop.prevent="onInput('4')"
        >
          <path
            id="BG_touche-6"
            data-name="BG touche"
            class="cls-4"
            d="M261.29,225.13v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8v-13.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,261.29,225.13Z"
          />
          <g id="relief_touche-3" data-name="relief touche">
            <path
              class="cls-1"
              d="M257.89,220.6a5.6,5.6,0,0,0-1.8-.16l-41.4,2.77a6.21,6.21,0,0,0-5.83,6V243a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5V228a6.22,6.22,0,0,1,5.84-6l41.39-2.78A5.55,5.55,0,0,1,257.89,220.6Z"
            />
            <path
              class="cls-1"
              d="M212.3,247.74l-1.9-.47a5.64,5.64,0,0,1-4.42-5.52V228a6.74,6.74,0,0,1,6.31-6.49l41.39-2.78a6.08,6.08,0,0,1,4.55,1.55l1.49,1.38-2-.53a5.1,5.1,0,0,0-1.64-.14l-41.4,2.77a5.71,5.71,0,0,0-5.36,5.49V243a4.56,4.56,0,0,0,1.5,3.42Zm41.83-28.07h-.39l-41.39,2.78A5.71,5.71,0,0,0,207,228v13.8a4.57,4.57,0,0,0,2,3.8,5.63,5.63,0,0,1-.6-2.55V229.2a6.74,6.74,0,0,1,6.3-6.49l41.2-2.76A5.23,5.23,0,0,0,254.13,219.67Z"
            />
          </g>
          <path
            id="contour_touche-3"
            data-name="contour touche"
            class="cls-1"
            d="M256.5,221.43a4.39,4.39,0,0,1,1.16.14,4.15,4.15,0,0,1,3.26,4.07v13.8a5.2,5.2,0,0,1-4.9,5l-41.4,2.77h-.35a4.48,4.48,0,0,1-3.06-1.15l-.22-.21a4.1,4.1,0,0,1-1.13-2.85V229.2a5.2,5.2,0,0,1,4.9-5l41.39-2.77h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6V243a5.07,5.07,0,0,0,1.66,3.79h0a5.42,5.42,0,0,0,3.74,1.42h.42l41.4-2.77a6.21,6.21,0,0,0,5.83-6v-13.8a5.15,5.15,0,0,0-4-5,5.24,5.24,0,0,0-1.39-.17Z"
          />
          <path
            class="digit-text"
            d="M236.2,232.64l-4.34.3v-2l4.34-5.47,2.08-.15v5.28l1.08-.08v1.86l-1.08.07v1.61l-2.08.14Zm0-1.86v-2.7L233.91,231Z"
          />
        </g>
        <g
          id="touche_5"
          data-name="touche 5"
          class="digit"
          @click.stop.prevent="onInput('5')"
        >
          <path
            id="BG_touche-7"
            data-name="BG touche"
            class="cls-4"
            d="M320.7,221.22V235a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8v-13.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,320.7,221.22Z"
          />
          <g id="relief_touche-4" data-name="relief touche">
            <path
              class="cls-1"
              d="M317.06,216.62a5.8,5.8,0,0,0-1.78-.15l-41.39,2.77a6.22,6.22,0,0,0-5.84,6V239a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5V224a6.21,6.21,0,0,1,5.84-6l41.39-2.77A5.51,5.51,0,0,1,317.06,216.62Z"
            />
            <path
              class="cls-1"
              d="M271.49,243.77l-1.9-.47a5.63,5.63,0,0,1-4.41-5.52V224a6.74,6.74,0,0,1,6.31-6.49l41.39-2.77a6,6,0,0,1,4.52,1.53l1.43,1.33-1.89-.48a5.45,5.45,0,0,0-1.63-.13l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49V239a4.56,4.56,0,0,0,1.5,3.42Zm41.84-28.06h-.39l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.61,4.61,0,0,0,2,3.8,5.66,5.66,0,0,1-.6-2.55v-13.8a6.74,6.74,0,0,1,6.31-6.49L315,216A5.37,5.37,0,0,0,313.33,215.71Z"
            />
          </g>
          <path
            id="contour_touche-4"
            data-name="contour touche"
            class="cls-1"
            d="M315.69,217.46a4.43,4.43,0,0,1,.83.07l.32.07a4.15,4.15,0,0,1,3.28,4.07v13.8a5.2,5.2,0,0,1-4.91,5l-41.39,2.78h-.34a4.52,4.52,0,0,1-3.1-1.17,4.07,4.07,0,0,1-1.33-3.05v-13.8a5.2,5.2,0,0,1,4.91-5l41.39-2.77h.34m0-1h-.41l-41.39,2.77a6.22,6.22,0,0,0-5.84,6V239a5.07,5.07,0,0,0,1.66,3.79,5.49,5.49,0,0,0,3.77,1.43h.41l41.39-2.78a6.21,6.21,0,0,0,5.84-6v-13.8a5.15,5.15,0,0,0-4.05-5h0a5.53,5.53,0,0,0-1.37-.16Z"
          />
          <path
            class="digit-text"
            d="M292.6,222.21l5.66-.4v1.91l-3.84.27-.2,1.3A5.88,5.88,0,0,1,295,225a3,3,0,0,1,.77-.14,2.71,2.71,0,0,1,2.09.63,2.46,2.46,0,0,1,.8,1.91,3.54,3.54,0,0,1-.41,1.62,3.16,3.16,0,0,1-1.17,1.26,4.49,4.49,0,0,1-1.94.54,5.59,5.59,0,0,1-1.46-.06,2.82,2.82,0,0,1-1-.41,2.6,2.6,0,0,1-.69-.67,3.57,3.57,0,0,1-.44-1l2.41-.43a1.4,1.4,0,0,0,.41.85,1,1,0,0,0,.77.25,1.13,1.13,0,0,0,.82-.44,1.77,1.77,0,0,0,.33-1.15,1.52,1.52,0,0,0-.33-1.1,1.09,1.09,0,0,0-.87-.3,1.56,1.56,0,0,0-.67.22,2.19,2.19,0,0,0-.53.48l-2-.15Z"
          />
        </g>
        <g
          id="touche_6"
          data-name="touche 6"
          class="digit"
          @click.stop.prevent="onInput('6')"
        >
          <path
            id="BG_touche-8"
            data-name="BG touche"
            class="cls-4"
            d="M379.75,217v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8v-13.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,379.75,217Z"
          />
          <g id="relief_touche-5" data-name="relief touche">
            <path
              class="cls-1"
              d="M376.27,212.66a5.54,5.54,0,0,0-1.79-.16l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.1,5.1,0,0,0,1.67,3.8,5.16,5.16,0,0,1-4.05-5.05V220a6.22,6.22,0,0,1,5.84-6l41.4-2.77A5.5,5.5,0,0,1,376.27,212.66Z"
            />
            <path
              class="cls-1"
              d="M330.69,239.79l-1.88-.44a5.64,5.64,0,0,1-4.44-5.54V220a6.74,6.74,0,0,1,6.31-6.49l41.4-2.77a6,6,0,0,1,4.53,1.54l1.46,1.37-1.93-.52a5.07,5.07,0,0,0-1.63-.14l-41.4,2.77a5.71,5.71,0,0,0-5.36,5.49v13.8a4.61,4.61,0,0,0,1.51,3.43Zm41.84-28h-.39l-41.4,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.6,4.6,0,0,0,2,3.82,5.6,5.6,0,0,1-.61-2.57v-13.8a6.74,6.74,0,0,1,6.3-6.49L374.24,212A5.33,5.33,0,0,0,372.53,211.74Z"
            />
          </g>
          <path
            id="contour_touche-5"
            data-name="contour touche"
            class="cls-1"
            d="M374.89,213.49a4.37,4.37,0,0,1,1.15.14,4.15,4.15,0,0,1,3.28,4.07v13.8a5.19,5.19,0,0,1-4.91,5L333,239.27h-.34a4.53,4.53,0,0,1-3.08-1.16,4.12,4.12,0,0,1-1.34-3.06v-13.8a5.2,5.2,0,0,1,4.9-5l41.39-2.77h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.1,5.1,0,0,0,1.67,3.8,5.48,5.48,0,0,0,3.75,1.42h.41l41.4-2.78a6.21,6.21,0,0,0,5.84-6V217.7a5.15,5.15,0,0,0-4.05-5,5.22,5.22,0,0,0-1.38-.17Z"
          />
          <path
            class="digit-text"
            d="M357.28,219.51l-2.37.46a1.14,1.14,0,0,0-.32-.68.71.71,0,0,0-.54-.17,1.11,1.11,0,0,0-.9.65,5.51,5.51,0,0,0-.35,1.83,3.42,3.42,0,0,1,.88-.7,2.47,2.47,0,0,1,1.05-.28,2.41,2.41,0,0,1,1.94.68,2.67,2.67,0,0,1,.79,2,3.24,3.24,0,0,1-.4,1.59,3,3,0,0,1-1.11,1.14,4.2,4.2,0,0,1-1.79.48,4.09,4.09,0,0,1-2.06-.29,2.82,2.82,0,0,1-1.25-1.32,5.69,5.69,0,0,1-.47-2.52,5.24,5.24,0,0,1,1-3.47,3.74,3.74,0,0,1,2.72-1.27,4.37,4.37,0,0,1,1.62.12,2.32,2.32,0,0,1,1,.62A2.82,2.82,0,0,1,357.28,219.51Zm-4.39,4.13a1.45,1.45,0,0,0,.35,1.07,1,1,0,0,0,.86.34,1.08,1.08,0,0,0,.78-.41,1.63,1.63,0,0,0,.32-1.09,1.56,1.56,0,0,0-.33-1.09,1,1,0,0,0-.81-.32,1.16,1.16,0,0,0-.84.43A1.61,1.61,0,0,0,352.89,223.64Z"
          />
        </g>
        <g
          id="touche_9"
          data-name="touche 9"
          class="digit"
          @click.stop.prevent="onInput('9')"
        >
          <path
            id="BG_touche-9"
            data-name="BG touche"
            class="cls-4"
            d="M379.67,248.71v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8v-13.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,379.67,248.71Z"
          />
          <g id="relief_touche-6" data-name="relief touche">
            <path
              class="cls-1"
              d="M376.27,244.14a5.54,5.54,0,0,0-1.79-.16l-41.4,2.78a6.19,6.19,0,0,0-5.83,6v13.8a5.1,5.1,0,0,0,1.67,3.8,5.16,5.16,0,0,1-4.05-5v-13.8a6.22,6.22,0,0,1,5.84-6l41.4-2.77A5.5,5.5,0,0,1,376.27,244.14Z"
            />
            <path
              class="cls-1"
              d="M330.69,271.28l-1.89-.45a5.64,5.64,0,0,1-4.43-5.54v-13.8a6.74,6.74,0,0,1,6.31-6.49l41.4-2.77a6,6,0,0,1,4.53,1.54l1.47,1.37-1.94-.52a5,5,0,0,0-1.63-.14l-41.4,2.78a5.69,5.69,0,0,0-5.36,5.48v13.8a4.6,4.6,0,0,0,1.51,3.43Zm41.84-28.06h-.39L330.74,246a5.71,5.71,0,0,0-5.37,5.49v13.8a4.6,4.6,0,0,0,2,3.82,5.6,5.6,0,0,1-.61-2.57v-13.8a6.71,6.71,0,0,1,6.3-6.48l41.19-2.77A5.33,5.33,0,0,0,372.53,243.22Z"
            />
          </g>
          <path
            id="contour_touche-6"
            data-name="contour touche"
            class="cls-1"
            d="M374.89,245a4.37,4.37,0,0,1,1.15.14,4.16,4.16,0,0,1,3.28,4.08V263a5.19,5.19,0,0,1-4.91,5L333,270.75h-.34a4.53,4.53,0,0,1-3.08-1.16,4.12,4.12,0,0,1-1.34-3.06v-13.8a5.18,5.18,0,0,1,4.9-5L374.54,245h.35m0-1h-.41l-41.4,2.78a6.19,6.19,0,0,0-5.83,6v13.8a5.1,5.1,0,0,0,1.67,3.8,5.48,5.48,0,0,0,3.75,1.42h.41l41.4-2.78a6.21,6.21,0,0,0,5.84-6V249.19a5.16,5.16,0,0,0-4.05-5.05,5.22,5.22,0,0,0-1.38-.17Z"
          />
          <path
            class="digit-text"
            d="M350.48,256l2.37-.46a1.14,1.14,0,0,0,.32.68.73.73,0,0,0,.54.17,1.09,1.09,0,0,0,.9-.64,5.65,5.65,0,0,0,.35-1.84,3.31,3.31,0,0,1-.88.71,2.62,2.62,0,0,1-1,.27,2.42,2.42,0,0,1-2-.68,2.67,2.67,0,0,1-.79-2,3.24,3.24,0,0,1,.4-1.59,3,3,0,0,1,1.11-1.14,4.2,4.2,0,0,1,1.79-.48,4,4,0,0,1,2.06.3,2.75,2.75,0,0,1,1.25,1.31,5.72,5.72,0,0,1,.47,2.52,5.24,5.24,0,0,1-1,3.47,3.74,3.74,0,0,1-2.72,1.27,4.29,4.29,0,0,1-1.61-.12,2.22,2.22,0,0,1-1-.63A2.83,2.83,0,0,1,350.48,256Zm4.39-4.13a1.45,1.45,0,0,0-.35-1.07,1,1,0,0,0-.85-.34,1.08,1.08,0,0,0-.79.41,1.7,1.7,0,0,0-.32,1.1,1.55,1.55,0,0,0,.33,1.08,1,1,0,0,0,.81.32,1.16,1.16,0,0,0,.84-.43A1.61,1.61,0,0,0,354.87,251.84Z"
          />
        </g>
        <g
          id="touche_7"
          data-name="touche 7"
          class="digit"
          @click.stop.prevent="onInput('7')"
        >
          <path
            id="BG_touche-10"
            data-name="BG touche"
            class="cls-4"
            d="M262.18,257.25v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8V260.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,262.18,257.25Z"
          />
          <g id="relief_touche-7" data-name="relief touche">
            <path
              class="cls-1"
              d="M257.88,252.08a5.54,5.54,0,0,0-1.79-.16l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5v-13.8a6.22,6.22,0,0,1,5.84-6l41.39-2.77A5.52,5.52,0,0,1,257.88,252.08Z"
            />
            <path
              class="cls-1"
              d="M211.71,254.53a7.1,7.1,0,0,0-3.85,6.15v13.8c0,.18,0,.36,0,.55a4.2,4.2,0,0,1-.4-1.8v-13.8a5.18,5.18,0,0,1,4.23-4.9m42.42-3.87h-.42l-41.39,2.77a6.22,6.22,0,0,0-5.84,6v13.8a5.16,5.16,0,0,0,4,5,5.07,5.07,0,0,1-1.66-3.79v-13.8a6.21,6.21,0,0,1,5.83-6l41.4-2.77h.41a5.22,5.22,0,0,1,1.38.17,5.45,5.45,0,0,0-3.75-1.42Z"
            />
          </g>
          <path
            id="contour_touche-7"
            data-name="contour touche"
            class="cls-1"
            d="M256.5,252.91a4.37,4.37,0,0,1,1.15.14,4.15,4.15,0,0,1,3.27,4.07v13.8a5.2,5.2,0,0,1-4.9,5l-41.4,2.77h-.35a4.48,4.48,0,0,1-3.06-1.15l-.22-.21a4.1,4.1,0,0,1-1.13-2.85v-13.8a5.2,5.2,0,0,1,4.9-5l41.39-2.77h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6v13.8a5.07,5.07,0,0,0,1.66,3.79h0a5.42,5.42,0,0,0,3.74,1.42h.42l41.4-2.77a6.21,6.21,0,0,0,5.83-6v-13.8a5.15,5.15,0,0,0-4-5,5.22,5.22,0,0,0-1.38-.17Z"
          />
          <path
            class="digit-text"
            d="M232.1,256.9l7-0.5v1.6c-0.6,0.6-1.1,1.2-1.5,1.9c-0.5,0.8-0.9,1.7-1.2,2.7c-0.2,0.9-0.4,1.8-0.5,2.6
				l-2.4,0.2c0.1-1.2,0.4-2.4,0.9-3.6c0.5-1.1,1.1-2.2,1.9-3.2l-4.2,0.3L232.1,256.9z"
          />
        </g>
        <g
          id="touche_8"
          data-name="touche 8"
          class="digit"
          @click.stop.prevent="onInput('8')"
        >
          <path
            id="BG_touche-11"
            data-name="BG touche"
            class="cls-4"
            d="M320.7,253.15V267a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8V256.7a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,320.7,253.15Z"
          />
          <g id="relief_touche-8" data-name="relief touche">
            <path
              class="cls-1"
              d="M317.06,248.1a5.8,5.8,0,0,0-1.78-.15l-41.39,2.77a6.22,6.22,0,0,0-5.84,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5v-13.8a6.21,6.21,0,0,1,5.84-6l41.39-2.77A5.51,5.51,0,0,1,317.06,248.1Z"
            />
            <path
              class="cls-1"
              d="M271.49,275.25l-1.9-.46a5.65,5.65,0,0,1-4.41-5.53v-13.8a6.74,6.74,0,0,1,6.31-6.49l41.39-2.77a6,6,0,0,1,4.52,1.53l1.44,1.33-1.9-.47a5.12,5.12,0,0,0-1.63-.14l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.56,4.56,0,0,0,1.5,3.42Zm41.84-28.06h-.39L271.55,250a5.71,5.71,0,0,0-5.37,5.49v13.8a4.61,4.61,0,0,0,2,3.8,5.66,5.66,0,0,1-.6-2.55v-13.8a6.74,6.74,0,0,1,6.31-6.49L315,247.47A5.11,5.11,0,0,0,313.33,247.19Z"
            />
          </g>
          <path
            id="contour_touche-8"
            data-name="contour touche"
            class="cls-1"
            d="M315.69,248.94a4.43,4.43,0,0,1,.83.07l.32.07a4.15,4.15,0,0,1,3.28,4.07V267a5.19,5.19,0,0,1-4.91,5l-41.39,2.78h-.34a4.52,4.52,0,0,1-3.1-1.17,4.07,4.07,0,0,1-1.33-3.05v-13.8a5.2,5.2,0,0,1,4.91-5L315.35,249h.34m0-1h-.41l-41.39,2.77a6.22,6.22,0,0,0-5.84,6v13.8a5.07,5.07,0,0,0,1.66,3.79,5.49,5.49,0,0,0,3.77,1.43h.41l41.39-2.78a6.21,6.21,0,0,0,5.84-6v-13.8a5.15,5.15,0,0,0-4.05-5,0,0,0,0,0,0,0,5.53,5.53,0,0,0-1.37-.16Z"
          />
          <path
            class="digit-text"
            d="M293.1,257.1c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.7,0.4-1.4,1-1.8
				c1.4-0.9,3.2-0.9,4.6-0.2c0.5,0.4,0.8,1,0.8,1.6c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.3-0.5,0.6-0.8,0.8c0.4,0.2,0.8,0.4,1,0.8
				c0.2,0.4,0.3,0.8,0.3,1.2c0,0.8-0.4,1.6-1,2.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.9,0.2-1.4,0.2c-0.7,0.1-1.4,0-2.1-0.2
				c-0.5-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.5,0.2-0.9,0.4-1.3C292.3,257.6,292.6,257.3,293.1,257.1z M294,259
				c0,0.3,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.2,0.8-0.4c0.2-0.3,0.3-0.6,0.3-1c0-0.3-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.8,0.4C294.1,258.3,294,258.6,294,259z M294.1,255.3c0,0.3,0.1,0.5,0.3,0.7
				c0.2,0.2,0.5,0.3,0.7,0.2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.2
				C294.6,254.2,294.1,254.7,294.1,255.3L294.1,255.3z"
          />
        </g>
        <g
          id="touche_0"
          data-name="touche 0"
          class="digit"
          @click.stop.prevent="onInput('0')"
        >
          <path
            id="BG_touche-12"
            data-name="BG touche"
            class="cls-4"
            d="M321.16,284.55v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8V288.1a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,321.16,284.55Z"
          />
          <g id="relief_touche-9" data-name="relief touche">
            <path
              class="cls-1"
              d="M317.06,279.58a5.8,5.8,0,0,0-1.78-.15l-41.39,2.77a6.22,6.22,0,0,0-5.84,6V302a5.1,5.1,0,0,0,1.67,3.8,5.16,5.16,0,0,1-4-5.05v-13.8a6.21,6.21,0,0,1,5.84-6l41.39-2.77A5.51,5.51,0,0,1,317.06,279.58Z"
            />
            <path
              class="cls-1"
              d="M271.5,306.73l-1.9-.45a5.66,5.66,0,0,1-4.42-5.54v-13.8a6.74,6.74,0,0,1,6.31-6.49l41.39-2.77a6,6,0,0,1,4.52,1.53l1.43,1.33-1.89-.48a5.45,5.45,0,0,0-1.63-.13l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49V302a4.61,4.61,0,0,0,1.51,3.43Zm41.83-28.06h-.39l-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.6,4.6,0,0,0,2,3.81,5.58,5.58,0,0,1-.61-2.56v-13.8a6.74,6.74,0,0,1,6.31-6.49L315,278.94A5.37,5.37,0,0,0,313.33,278.67Z"
            />
          </g>
          <path
            id="contour_touche-9"
            data-name="contour touche"
            class="cls-1"
            d="M315.69,280.42a4.43,4.43,0,0,1,.83.07l.32.07a4.15,4.15,0,0,1,3.28,4.07v13.8a5.19,5.19,0,0,1-4.91,5l-41.39,2.78h-.34a4.55,4.55,0,0,1-3.09-1.16,4.12,4.12,0,0,1-1.34-3.06v-13.8a5.2,5.2,0,0,1,4.91-5l41.39-2.77h.34m0-1h-.41l-41.39,2.77a6.22,6.22,0,0,0-5.84,6V302a5.1,5.1,0,0,0,1.67,3.8,5.52,5.52,0,0,0,3.76,1.42h.41l41.39-2.78a6.21,6.21,0,0,0,5.84-6v-13.8a5.15,5.15,0,0,0-4.05-5h0a5.53,5.53,0,0,0-1.37-.16Z"
          />
          <path
            class="digit-text"
            d="M291.63,293.5a5.41,5.41,0,0,1,.88-3.49,3.72,3.72,0,0,1,2.69-1.17,3.82,3.82,0,0,1,1.42.12,2.33,2.33,0,0,1,.91.49,2.65,2.65,0,0,1,.55.68,3.35,3.35,0,0,1,.33.86,8,8,0,0,1,.24,2,6.13,6.13,0,0,1-.79,3.48,3.4,3.4,0,0,1-2.73,1.27,3.7,3.7,0,0,1-1.75-.22,2.53,2.53,0,0,1-1.09-.94,3.56,3.56,0,0,1-.49-1.26A9.13,9.13,0,0,1,291.63,293.5Zm2.37-.16a5.55,5.55,0,0,0,.29,2.22.82.82,0,0,0,.84.54.9.9,0,0,0,.63-.3,1.65,1.65,0,0,0,.39-.83,8.83,8.83,0,0,0,.13-1.73A5.8,5.8,0,0,0,296,291a.86.86,0,0,0-.87-.53,1,1,0,0,0-.86.66A6.37,6.37,0,0,0,294,293.34Z"
          />
        </g>
        <g
          id="touche_annuler"
          data-name="touche annuler"
          class="digit"
          @click.stop.prevent="onReset"
        >
          <g id="survol-2" data-name="survol">
            <g id="relief_touche-10" data-name="relief touche">
              <path
                class="cls-1"
                d="M257.88,283.56a5.54,5.54,0,0,0-1.79-.16l-41.4,2.77a6.21,6.21,0,0,0-5.83,6V306a5.07,5.07,0,0,0,1.66,3.79,5.16,5.16,0,0,1-4-5v-13.8a6.22,6.22,0,0,1,5.84-6l41.39-2.77A5.52,5.52,0,0,1,257.88,283.56Z"
              />
              <path
                class="cls-1"
                d="M212.29,310.69l-1.89-.45a5.64,5.64,0,0,1-4.42-5.53v-13.8a6.74,6.74,0,0,1,6.31-6.49l41.39-2.77a6,6,0,0,1,4.54,1.54l1.49,1.38-2-.53a5,5,0,0,0-1.63-.14l-41.4,2.77a5.71,5.71,0,0,0-5.36,5.49V306a4.56,4.56,0,0,0,1.5,3.42Zm41.84-28.06-.39,0-41.39,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.57,4.57,0,0,0,2,3.81,5.71,5.71,0,0,1-.6-2.56v-13.8a6.74,6.74,0,0,1,6.3-6.49l41.18-2.75A5.07,5.07,0,0,0,254.13,282.63Z"
              />
            </g>
            <path
              id="BG_touche-13"
              data-name="BG touche"
              class="cls-4"
              d="M261.92,288.6v13.8a6.21,6.21,0,0,1-5.83,6l-41.4,2.78a5.52,5.52,0,0,1-4.17-1.42,5.07,5.07,0,0,1-1.66-3.79v-13.8a6.21,6.21,0,0,1,5.83-6l41.4-2.77a5.54,5.54,0,0,1,1.79.16A5.15,5.15,0,0,1,261.92,288.6Z"
            />
            <path
              id="contour_touche-10"
              data-name="contour touche"
              class="cls-1"
              d="M256.5,284.39a4.37,4.37,0,0,1,1.15.14,4.15,4.15,0,0,1,3.27,4.07v13.8a5.2,5.2,0,0,1-4.9,5l-41.4,2.78h-.34a4.5,4.5,0,0,1-3.09-1.17,4.07,4.07,0,0,1-1.33-3.05v-13.8a5.2,5.2,0,0,1,4.9-5l41.39-2.77h.35m0-1h-.41l-41.4,2.77a6.21,6.21,0,0,0-5.83,6V306a5.07,5.07,0,0,0,1.66,3.79,5.44,5.44,0,0,0,3.76,1.43h.41l41.4-2.78a6.21,6.21,0,0,0,5.83-6V288.6a5.15,5.15,0,0,0-4-5,5.22,5.22,0,0,0-1.38-.17Z"
            />
            <path
              id="annuler"
              class="digit-cancel"
              d="M230.05,296.42v-5.63h1.81v2.26a6.22,6.22,0,1,1,.44,8.78,15.61,15.61,0,0,1,1.26-1.3,4.42,4.42,0,1,0,2.92-7.73,4.44,4.44,0,0,0-3.57,1.82h2.69v1.8Z"
            />
          </g>
        </g>
        <g
          id="touche_valider"
          data-name="touche valider"
          class="digit"
          @click.stop.prevent="validate"
        >
          <g id="survol-3" data-name="survol">
            <path
              class="cls-1"
              d="M376.27,275.62a5.54,5.54,0,0,0-1.79-.16l-41.4,2.78a6.19,6.19,0,0,0-5.83,6V298a5.1,5.1,0,0,0,1.67,3.8,5.16,5.16,0,0,1-4.05-5.05V283a6.22,6.22,0,0,1,5.84-6l41.4-2.77A5.5,5.5,0,0,1,376.27,275.62Z"
            />
            <path
              class="cls-1"
              d="M330.69,302.75l-1.88-.44a5.64,5.64,0,0,1-4.44-5.54V283a6.74,6.74,0,0,1,6.31-6.49l41.4-2.77a6,6,0,0,1,4.53,1.54l1.46,1.37-1.93-.52a5.07,5.07,0,0,0-1.63-.14l-41.4,2.78a5.69,5.69,0,0,0-5.36,5.48V298a4.6,4.6,0,0,0,1.51,3.43Zm41.84-28.05h-.39l-41.4,2.77a5.71,5.71,0,0,0-5.37,5.49v13.8a4.6,4.6,0,0,0,2,3.82,5.6,5.6,0,0,1-.61-2.57v-13.8a6.71,6.71,0,0,1,6.3-6.48L374.24,275A5.33,5.33,0,0,0,372.53,274.7Z"
            />
            <path
              id="BG_touche-14"
              data-name="BG touche"
              class="cls-4"
              d="M380.32,280.67v13.8a6.2,6.2,0,0,1-5.84,6l-41.4,2.78a5.54,5.54,0,0,1-4.16-1.41,5.1,5.1,0,0,1-1.67-3.8v-13.8a6.19,6.19,0,0,1,5.83-6l41.4-2.78a5.54,5.54,0,0,1,1.79.16A5.16,5.16,0,0,1,380.32,280.67Z"
            />
            <path
              id="BG_touche-15"
              data-name="BG touche"
              class="cls-1"
              d="M374.89,276.45a4.37,4.37,0,0,1,1.15.14,4.16,4.16,0,0,1,3.28,4.08v13.8a5.19,5.19,0,0,1-4.91,5L333,302.23h-.34a4.53,4.53,0,0,1-3.08-1.16,4.12,4.12,0,0,1-1.34-3.06v-13.8a5.18,5.18,0,0,1,4.9-5l41.39-2.78h.35m0-1h-.41l-41.4,2.78a6.19,6.19,0,0,0-5.83,6V298a5.1,5.1,0,0,0,1.67,3.8,5.48,5.48,0,0,0,3.75,1.42h.41l41.4-2.78a6.2,6.2,0,0,0,5.84-6v-13.8a5.16,5.16,0,0,0-4.05-5.05,5.22,5.22,0,0,0-1.38-.17Z"
            />
            <polygon
              class="cls-3"
              points="361.72 285.27 351.53 295.45 346.92 290.83 348.84 288.91 351.52 291.59 359.78 283.33 361.72 285.27"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
  <Sound :src="`${casePublicAudio}/safe/beep.mp3`" ref="beepSound"></Sound>
  <Sound :src="`${casePublicAudio}/safe/open.mp3`" ref="openSound"></Sound>
  <Sound :src="`${casePublicAudio}/safe/error.mp3`" ref="errorSound"></Sound>    
</div>
</template>
<script>
import Sound from "@/components/yakuza/Sound";
import currentGameMixin from "@/mixins/currentGameMixin";

export default {
  name: "SvgSafe",
  data() {
    return {
      inputCode: "",
      maxLength: 4,
      showError: false,
    };
  },
  components: { Sound },
  emits: ["onCodeSuccess"],
  mixins: [currentGameMixin],
  props: {
    correctCode: Number,
  },
  methods: {
    validate: function () {
      if(this.$yakuzaStore.findEnvelope('enveloppeB').locked === false) {
        return;
      }

      if(Number(this.inputCode) === this.correctCode) {
        this.$refs.openSound.play();
        this.$yakuzaStore.unlockEnvelope('enveloppeB');
        this.$yakuzaStore.enableEnvelope('enveloppeC');
        this.$emit("onCodeSuccess", this.inputCode);
      } else {
        this.$refs.errorSound.play();
        this.showError = true;
        this.inputCode = "";
      }
    },
    onInput: function (key) {
      if(this.$yakuzaStore.findEnvelope('enveloppeB').locked === false) {
        return;
      }

      this.showError = false;
      this.$refs.beepSound.play();
      this.inputCode = (this.inputCode + key).slice(0, this.maxLength);
    },
    onDelete: function () {
      this.inputCode = this.inputCode.slice(0, this.inputCode.length - 1);
    },
    onReset: function () {
      this.inputCode = "";
    },
  },
  watch: {}
};
</script>
<style scoped>
svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

svg text::selection {
  background: none;
}

.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: none;
}
.cls-3 {
  fill: #6db746;
}
.cls-4 {
  fill: #ffc122;
  fill-opacity: 0;
}

.digit {
  pointer-events: all;
}

.digit-text {
  fill: #ffffff;
}

.digit-cancel {
  fill: #ffc122;
}

.digit:hover path[data-name="BG touche"] {
  fill-opacity: 1;
}

.digit:hover .digit-text,
.digit:hover .digit-cancel {
  fill: #000000;
}

.lcd {
  fill: #ffffff;
  font-size: x-large;
  font-style: italic;
  text-transform: uppercase;
}

.lcd.unlocked {
  fill: #6db746;
  font-size: x-small;
  text-transform: none;
}

.lcd.error {
  fill: red;
  font-size: x-small;
  text-transform: none;
}
</style>