<template>
  <v-overlay opacity="0.2" absolute :class="`text-center ${extraClass}`" width="400">
    <div :class="currentSlide == 0 ? ['dialog-frame', 'slide' + currentSlide] : ['dialog-frame', 'smallheight', 'slide' + currentSlide]">
      <div>
        <v-carousel :height="currentSlide == 0 ? '260' : '160'" @change="slideChange" :continuous="false">
          <v-carousel-item>
            <v-sheet :height="'100%'" tile>
              <v-row class="d-slide" align="center" justify="center">
                <p class="d-title">{{ $t("tutorial.title") }}</p>
                <p class="d-text" v-html="$t('tutorial.step1')"></p>                
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item v-for="(step, i) in steps" :key="i">
            <v-sheet height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
                <div class="d-text" v-html="$t('tutorial.' + step.text)"></div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div><span class="skip-btn" @click="resetHighlight(); $emit('onClose')">{{ closeLabel }}</span></div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "TutorialOverlay",
  emits: ["onClose", "tutorialStep"],
  data() {
    return {
      steps: [
        { text: "step2", highlightIds: ["menu-cabins", "menu-envelopes", "menu-envelope", "menu-research"]  }, 
        { text: "step3", highlightIds: ["menu-evidences"] }, 
        { text: "step4", highlightIds: ["menu-clues"] }, 
        { text: "step5", highlightIds: ["resolve-button"] }
      ],
      currentSlide: -1,
    };
  },
  computed: {
    closeLabel() {
      return (this.currentSlide < 4) ? this.$t("tutorial.skip") : this.$t("tutorial.btnstart")
    }
  },
  props: {
    extraClass: String,
  },
  mounted() {},
  methods: {
    resetHighlight() {
      this.steps.forEach(v => {
        if(v.highlightIds) {
          v.highlightIds.forEach(w => {
            const c = document.getElementById(w)
            if(c) {
              c.classList.remove('highlight-menu')
            }
          })
        }
      })
    },
    slideChange(slide) {
      if(this.currentSlide != -1) {
        this.resetHighlight()
      }

      this.currentSlide = slide

      if(slide > 0) {
        const currentStep = this.steps[slide - 1]
        if(currentStep.highlightIds) {
          currentStep.highlightIds.forEach(w => {
            const c = document.getElementById(w)
            if(c) {
              c.classList.add('highlight-menu')
            }
          })
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/common.scss';

::v-deep .v-overlay__content {
  align-self: flex-start;
}

.dialog-frame {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.9);
  @include size(360px, 300px);
  padding: 0;
  border-radius: 10px !important;
  transition: all 0.25s;
  margin-top: 50px;

  &.smallheight {
    height: 200px;
    margin-top: 150px;

    &.slide4 {
      margin-top: 100px;
    }
/*
    @media only screen and (max-width: 600px) {
      margin-top: -120px;
      &.slide0 {
        margin-top: -180px;
      }
      &.slide4 {
        margin-top: 60px;
      }
    }
    @media only screen and (max-width: 400px) 
    {
      &.slide4 {
        margin-top: 240px;
      }
    }*/
  }

  &::v-deep .v-window__next,
  &::v-deep .v-window__prev {
    margin: 0;
  }

  &::v-deep .tuto-menu {
    font-family: "Bebas Neue";
    font-size: 20px;
  }

  &::v-deep button {
    margin-top: 0px;

    &.v-btn.v-btn--icon {
      color: #ffc122;
    }
  }

  &::v-deep .v-carousel__controls {
    top: 190px;
  }

  &.smallheight::v-deep .v-carousel__controls {
    top: 110px;
  }  

  .skip-btn {
    color: #fff;

    &:hover {
      color: #ffc122;      
      cursor: pointer;
    }
  }

  .d-title {
    font-family: "Bebas Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.06em;
    text-align: center;
    text-transform: uppercase;
    color: #ffc122;
  }  

  .d-slide {
    margin: 0;
    margin-top: 50px;
  }

  .d-text {
    text-align: center;
    color: #fff;
    @include padding(20px, 50px, 20px, 50px);
    font-family: "Source Sans 3";
    font-size: 14px;
    margin: 0;
    text-transform: none;
  }
}
/*
.yakuza {
  .dialog-frame {
    &.smallheight {
      @media only screen and (max-width: 600px) {
        margin-top: -220px;
        &.slide0 {
          margin-top: -180px;
        }
        &.slide4 {
          margin-top: -120px;
        }
      }
      @media only screen and (max-width: 380px) 
      {
        &.slide4 {
          margin-top: -240px;
        }
      }      
    }
  }
}

.mykonos {
  .dialog-frame {
    &.smallheight {
      @media only screen and (max-width: 380px) 
      {
        &.slide4 {
          margin-top: -240px;
        }
      }      
    }
  }
}
*/
.v-sheet {
  background: 0 !important;
}
</style>
