export default {
    fr: {
        maestro: {
            phone: {
                labels: {
                    pin: "Codice PIN",
                    pinPrompt: "Saisissez le code."
                },
                buttons: {
                    ok: "OK"
                },
                messages: {
                    wrongPin: {
                        line1: "Code faux.",
                        line2: "Réessayez."
                    },
                    unlocked: "Téléphone déverrouillé",
                    youCanOpen: {
                        line1: "Vous pouvez ouvrir",
                        line2: "l'enveloppe."
                    }
                }
            },
            dialPhone: {
                buttons: {
                    call: "Appeler"
                },
                messages: {
                    notTheRightNumber: "Ceci n'est pas le bon numéro à appeler",
                    callInProgress: "Appel en cours..."
                }
            }
        }
    },
    en: {
        maestro: {
            phone: {
                labels: {
                    pin: "PIN code",
                    pinPrompt: "Enter the code"
                },
                buttons: {
                    ok: "OK"
                },
                messages: {
                    wrongPin: {
                        line1: "Wrong code.",
                        line2: "Try again."
                    },
                    unlocked: "Phone unlocked",
                    youCanOpen: {
                        line1: "You can open",
                        line2: "the envelope."
                    }
                }
            },
            dialPhone: {
                buttons: {
                    call: "Dial"
                },
                messages: {
                    notTheRightNumber: "Wrong number, try another one.",
                    callInProgress: "Call in progress..."
                }
            }
        }
    },
    de: {
        maestro: {
            phone: {
                labels: {
                    pin: "PIN-Code",
                    pinPrompt: "Geben Sie den Code ein"
                },
                buttons: {
                    ok: "OK"
                },
                messages: {
                    wrongPin: {
                        line1: "Falscher Code.",
                        line2: "Versuchen Sie es nochmal."
                    },
                    unlocked: "Telefon ist nicht gesperrt",
                    youCanOpen: {
                        line1: "Sie können",
                        line2: "den Umschlag öffnen."
                    }
                }
            },
            dialPhone: {
                buttons: {
                    call: "Anrufen"
                },
                messages: {
                    notTheRightNumber: "Falsche Nummer, versuchen Sie eine andere.",
                    callInProgress: "Anruf in Arbeit..."
                }
            }
        }
    },
    es: {
        maestro: {
            phone: {
                labels: {
                    pin: "Código PIN",
                    pinPrompt: "Entra el código"
                },
                buttons: {
                    ok: "OK"
                },
                messages: {
                    wrongPin: {
                        line1: "Código incorrecto.",
                        line2: "Inténtalo de nuevo."
                    },
                    unlocked: "Teléfono desbloqueado",
                    youCanOpen: {
                        line1: "Puedes abrir",
                        line2: "el sobre."
                    }
                }
            },
            dialPhone: {
                buttons: {
                    call: "Llamar"
                },
                messages: {
                    notTheRightNumber: "Número equivocado, prueba con otro.",
                    callInProgress: "Llamada en progreso..."
                }
            }
        }
    },
    it: {
        maestro: {
            phone: {
                labels: {
                    pin: "codice",
                    pinPrompt: "Inserisci il codice"
                },
                buttons: {
                    ok: "OK"
                },
                messages: {
                    wrongPin: {
                        line1: "Codice sbagliato.",
                        line2: "Prova di nuovo."
                    },
                    unlocked: "Telefono sbloccato",
                    youCanOpen: {
                        line1: "Si può aprire",
                        line2: "la busta."
                    }
                }
            },
            dialPhone: {
                buttons: {
                    call: "Chiamare"
                },
                messages: {
                    notTheRightNumber: "Numero sbagliato, provare con un altro.",
                    callInProgress: "Chiamata in corso..."
                }
            }
        }
    }
}