export default {
    books: [
        {"title":"title1", "author":"Christiane Desroches Noblecourt","year":"2002","status":"Emprunté","num_borrow":"852369"},
        {"title":"title2", "author":"Robert Solé","year":"2002","status":"Emprunté","num_borrow":"852369"},
        {"title":"title3", "author":"Philippe Fragione","year":"2015","status":"Libre","num_borrow":""},
        {"title":"title4", "author":"Arielle P. Kozloff","year":"1992","status":"Emprunté","num_borrow":"728956"},
        {"title":"title5", "author":"Christiane Desroches Noblecourt","year":"1997","status":"Emprunté","num_borrow":"963214"},
        {"title":"title6", "author":"Nicolas Grimal","year":"1988","status":"Libre","num_borrow":""},
        {"title":"title7", "author":"Christian Jacq","year":"1999","status":"Libre","num_borrow":""},
        {"title":"title8", "author":"Cyril Aldred","year":"1985","status":"Libre","num_borrow":""},
        {"title":"title9", "author":"Dorothea Arnold","year":"1999","status":"Libre","num_borrow":""},
        {"title":"title10", "author":"Bernard Mathieu","year":"2011","status":"Libre","num_borrow":""},
        {"title":"title11", "author":"Lionel Casson","year":"1995","status":"Libre","num_borrow":""},
        {"title":"title12", "author":"Gisèle Clerc","year":"2010","status":"Libre","num_borrow":""},
        {"title":"title13", "author":"Florence Maruéjol","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title14", "author":"Florence Maruéjol","year":"2009","status":"Emprunté   (retard)","num_borrow":"874531"},
        {"title":"title15", "author":"Nicolas Grimal","year":"1997","status":"Libre","num_borrow":""},
        {"title":"title16", "author":"André Bernand","year":"2005","status":"Libre","num_borrow":""},
        {"title":"title17", "author":"Christian Jacq","year":"1998","status":"Libre","num_borrow":""},
        {"title":"title18", "author":"Pierre Tallet","year":"2012","status":"Libre","num_borrow":""},
        {"title":"title19", "author":"Gaston Maspero","year":"1894","status":"Libre","num_borrow":""},
        {"title":"title20", "author":"Christian Jacq","year":"1996","status":"Emprunté","num_borrow":"852369"},
        {"title":"title21", "author":"Christiane Desroches Noblecourt","year":"2003","status":"Emprunté","num_borrow":"704899"},
        {"title":"title22", "author":"Pascal Vernus","year":"1980","status":"Libre","num_borrow":""},
        {"title":"title23", "author":"Joyce Tyldesley","year":"1983","status":"Libre","num_borrow":""},
        {"title":"title24", "author":"Lionel Casson","year":"1976","status":"Emprunté","num_borrow":"124536"},
        {"title":"title25", "author":"William Kelly Simpson","year":"2003","status":"Libre","num_borrow":""},
        {"title":"title26", "author":"David Klotz","year":"2017","status":"Libre","num_borrow":""},
        {"title":"title27", "author":"Bob Brier","year":"1981","status":"Libre","num_borrow":""},
        {"title":"title28", "author":"Marianne Eaton-Krauss","year":"2012","status":"Libre","num_borrow":""},
        {"title":"title29", "author":"Veronica Ions","year":"2007","status":"Libre","num_borrow":""},
        {"title":"title30", "author":"Christian Jacq","year":"1995","status":"Emprunté","num_borrow":"852369"},
        {"title":"title31", "author":"Jean-Pierre Legrand ","year":"2004","status":"Libre","num_borrow":""},
        {"title":"title32", "author":"Jean-Pierre Legrand & Henry Wellson","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title33", "author":"Christian Jacq","year":"1995","status":"Libre","num_borrow":""},
        {"title":"title34", "author":"Gilles Dormion","year":"2008","status":"Emprunté (retard)","num_borrow":"728956"},
        {"title":"title35", "author":"Christian Leblanc","year":"1989","status":"Emprunté (retard)","num_borrow":"963214"},
        {"title":"title36", "author":"Howard Carter","year":"1923","status":"Libre","num_borrow":""},
        {"title":"title37", "author":"Rosemary Clark","year":"2000","status":"Libre","num_borrow":""},
        {"title":"title38", "author":"Nicholas Reeves","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title39", "author":"Edward F. Wente","year":"1996","status":"Libre","num_borrow":""},
        {"title":"title40", "author":"Christian Jacq","year":"1992","status":"Libre","num_borrow":""},
        {"title":"title41", "author":"Pierre Montet","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title42", "author":"Robert Steven Bianchi","year":"2007","status":"Emprunté","num_borrow":"704899"},
        {"title":"title43", "author":"Nicolas Grimal","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title44", "author":"Erik Hornung","year":"1984","status":"Emprunté","num_borrow":"124536"},
        {"title":"title45", "author":"Pierre Bourghelle","year":"1996","status":"Emprunté","num_borrow":"874531"},
        {"title":"title46", "author":"Christian Leblanc","year":"2002","status":"Libre","num_borrow":""},
        {"title":"title47", "author":"Paul Barguet","year":"1967","status":"Emprunté","num_borrow":"704899"},
        {"title":"title48", "author":"Collectif","year":"2013","status":"Libre","num_borrow":""},
        {"title":"title49", "author":"Christian Jacq","year":"1994","status":"Emprunté","num_borrow":"852369"},
        {"title":"title50", "author":"Wilbur Smith","year":"1987","status":"Emprunté","num_borrow":"874531"},
        {"title":"title51", "author":"Pascal Vernus","year":"1993","status":"Libre","num_borrow":""},
        {"title":"title52", "author":"Christian Jacq","year":"1994","status":"Emprunté","num_borrow":"963214"},
        {"title":"title53", "author":"Théophile Gautier","year":"1858","status":"Libre","num_borrow":""},
        {"title":"title54", "author":"Naguib Mahfouz","year":"1983","status":"Libre","num_borrow":""},
        {"title":"title55", "author":"Françoise Dunand","year":"2002","status":"Libre","num_borrow":""},
        {"title":"title56", "author":"Alexandre Varille","year":"1959","status":"Emprunté","num_borrow":"124536"},
        {"title":"title57", "author":"Alexandre Piankoff","year":"1979","status":"Libre","num_borrow":""},
        {"title":"title58", "author":"François Tonic","year":"2006","status":"Libre","num_borrow":""},
        {"title":"title59", "author":"Cyril Aldred","year":"1991","status":"Libre","num_borrow":""},
        {"title":"title60", "author":"Howard Carter","year":"2013","status":"Libre","num_borrow":""},
        {"title":"title61", "author":"Claude Traunecker","year":"2001","status":"Libre","num_borrow":""},
        {"title":"title62", "author":"Joyce Tyldesley","year":"2004","status":"Libre","num_borrow":""},
        {"title":"title63", "author":"Christian Jacq","year":"2009","status":"Libre","num_borrow":""},
        {"title":"title64", "author":"Christiane Desroches Noblecourt","year":"1990","status":"Libre","num_borrow":""},
        {"title":"title65", "author":"Pascal Vernus","year":"2009","status":"Libre","num_borrow":""},
        {"title":"title66", "author":"Jean-Pierre Corteggiani","year":"2004","status":"Libre","num_borrow":""},
        {"title":"title67", "author":"Jean-Luc Chappaz","year":"2010","status":"Libre","num_borrow":""},
        {"title":"title68", "author":"Franck Monnier","year":"2015","status":"Libre","num_borrow":""},
        {"title":"title69", "author":"Miroslav Verner","year":"2001","status":"Libre","num_borrow":""},
        {"title":"title70", "author":"Jean-Philippe Lauer","year":"1988","status":"Emprunté","num_borrow":"852369"},
        {"title":"title71", "author":"Christiane Desroches Noblecourt","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title72", "author":"Zahi Hawass","year":"2009","status":"Libre","num_borrow":""},
        {"title":"title73", "author":"Zahi Hawass","year":"2019","status":"Libre","num_borrow":""},
        {"title":"title74", "author":"Nicholas Reeves","year":"2015","status":"Libre","num_borrow":""},
        {"title":"title75", "author":"Christiane Desroches Noblecourt","year":"1978","status":"Emprunté","num_borrow":"852369"},
        {"title":"title76", "author":"Lise Manniche","year":"1993","status":"Libre","num_borrow":""},
        {"title":"title77", "author":"Alexandre Piankoff","year":"1955","status":"Libre","num_borrow":""},
        {"title":"title78", "author":"Marc Gabolde","year":"2014","status":"Emprunté","num_borrow":"963214"},
        {"title":"title79", "author":"David Gibbins","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title80", "author":"William Davis","year":"2001","status":"Libre","num_borrow":""},
        {"title":"title81", "author":"William Davis","year":"2005","status":"Emprunté","num_borrow":"852369"},
        {"title":"title82", "author":"William Davis","year":"2003","status":"Libre","num_borrow":""},
        {"title":"title83", "author":"William Davis","year":"1993","status":"Emprunté","num_borrow":"852369"},
        {"title":"title84", "author":"William Davis","year":"1994","status":"Libre","num_borrow":""},
        {"title":"title85", "author":"William Davis","year":"1995","status":"Libre","num_borrow":""},
        {"title":"title86", "author":"Christiane Desroches Noblecourt","year":"1993","status":"Libre","num_borrow":""},
        {"title":"title87", "author":"Didier Devauchelle","year":"2016","status":"Libre","num_borrow":""},
        {"title":"title88", "author":"Dieter Arnold","year":"2005","status":"Libre","num_borrow":""},
        {"title":"title89", "author":"Zahi Hawass","year":"2004","status":"Libre","num_borrow":""},
        {"title":"title90", "author":"Christian Jacq","year":"2008","status":"Libre","num_borrow":""},
        {"title":"title91", "author":"Bob Brier","year":"2015","status":"Emprunté","num_borrow":"874531"},
        {"title":"title92", "author":"Zahi Hawass","year":"2018","status":"Emprunté (retard)","num_borrow":"852369"},
        {"title":"title93", "author":"Bob Brier","year":"1998","status":"Emprunté","num_borrow":"874531"},
        {"title":"title94", "author":"Zahi Hawass","year":"2006","status":"Emprunté","num_borrow":"874531"},
        {"title":"title95", "author":"Christian Jacq","year":"2014","status":"Libre","num_borrow":""},
        {"title":"title96", "author":"Christian Jacq","year":"2007","status":"Libre","num_borrow":""},
        {"title":"title97", "author":"Christian Leblanc","year":"2015","status":"Libre","num_borrow":""},
        {"title":"title98", "author":"Christiane Desroches Noblecourt","year":"1992","status":"Libre","num_borrow":""},
        {"title":"title99", "author":"Christiane Desroches Noblecourt","year":"1998","status":"Libre","num_borrow":""},
        {"title":"title100", "author":"Catherine C. Bauer","year":"2001","status":"Emprunté  (retard)","num_borrow":"753159"},
        {"title":"title101", "author":"Christian Leblanc","year":"2015","status":"Emprunté (retard)","num_borrow":"753159"},
        {"title":"title102", "author":"Christiane Desroches Noblecourt","year":"1998","status":"Emprunté  (retard)","num_borrow":"753159"},
        {"title":"title103", "author":"Pierre Briant","year":"2003","status":"Emprunté","num_borrow":"874245"},
        {"title":"title104", "author":"Jean-François Champollion","year":"1828","status":"Libre","num_borrow":""}

    ]
}