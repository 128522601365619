export default {
    correctSystem: "TGS - Tokyo Guard and Security",
    correctSerialnum: "857492",

    systems: [
        "CSS - Chubu Security Systems", "FSS - Fuji Security Solutions", "HGS - Hiroshi Guard Services", 
        "IIS - ITO IT SafeGuard", "ISJ - Information Security Japan Tech Solutions", "JPC - Japan Privacy & Cybersecurity InfoSec",
        "JSI - Japan Security Innovations", "JTS - Japan Technology Secure", "KCS - Kyodo Cybersecurity", 
        "KCT - KANSAI Cyber Technology", "KIS - Kansai Information Security", "NCG - Nippon Cybersecurity Guard",
        "NSS - NEC Security Shield", "OCS - OKI CyberWave Solutions", "OSN - Osaka Secure Networking",
        "SAI - Security and AI Systems", "SID - SUNA Information Defense", "SSS - Sapphiro Security Services",
        "TGS - Tokyo Guard and Security", "TKC - Tokyo Cybersecurity SecureTech", "ZND - Zen Network Defense"
    ],

    loadingSpeed: 50,
    charSpeed: 35,
    characters: "故ツセアタ転結ぼ食流むば幌正ッどー社高ドレべて象事くほけや択析敬みわ及声ニ心郡ヤコク介昇ト獲見門ドばフ祭止よるくう靖生ぞばろゅ参座カヌマ井生費ヌリ削邦カ鹿蒙ナフ向連ひ遊サ応科げま担択団",
    
    authorizations: [
        { name: "Akihabara TechHub", enabledCommon: false, enabledPenthouse: false },
        { name: "Asakusa Grand Tower", enabledCommon: false, enabledPenthouse: false },
        { name: "Ginza Elegance Center", enabledCommon: false, enabledPenthouse: false },
        { name: "Odaiba Seaside Residence", enabledCommon: false, enabledPenthouse: false },
        { name: "Roppongi Horizon Tower", enabledCommon: false, enabledPenthouse: false },
        { name: "Sakura Tower", enabledCommon: false, enabledPenthouse: false },
        { name: "Shibuya Heights", enabledCommon: false, enabledPenthouse: false },
        { name: "Shinjuku Center Building", enabledCommon: false, enabledPenthouse: false },
        { name: "Shinjuku Skyrise", enabledCommon: false, enabledPenthouse: false },
        { name: "Tokyo Bayview Plaza", enabledCommon: false, enabledPenthouse: false },
        { name: "Ueno Cultural Tower", enabledCommon: false, enabledPenthouse: false }
    ]
}