<template>
  <div class="grow ">
    <v-container class="h-container">
      <v-container class="h-window">
        <HackOverlayError v-if="showErrorSystem" @onClose="close()"></HackOverlayError>
        <HackOverlayLoading v-if="showLoading" @onClose="close()" @onFinish="finishedLoading()"></HackOverlayLoading>
        <HackOverlayHack v-if="showHack" @onClose="close()" @onSucceed="hackSucceed()"></HackOverlayHack>
        <HackOverlayFinish v-if="showFinish" @onClose="close()"></HackOverlayFinish>

        <div class="h-window-title">{{ $t("yakuza.hack.title") }}</div>
        <div class="h-window-content">
          <!-- Choix du système -->
          <v-container class="h-system" v-if="currentStep === 0">
            <div class="h-system-header" v-html="$t('yakuza.hack.system_choice')"></div>
            <div class="h-system-list">
              <select v-model="selectedSystem">
                <option v-for="(v, k) in $yakuzaStore.hack.systems" :key="k">{{ v }}</option>
              </select>
            </div>
            <div class="h-system-validate">
              <v-btn @click="selectSystem">{{ $t("yakuza.hack.system_validate") }}</v-btn>
            </div>
          </v-container>

          <!-- Login -->
          <v-container class="h-login" v-if="currentStep === 1">
            <div class="h-login-header">
              <v-img class="logo py-3" contain :src="`${casePublicImages}/hack/TGS_logo.svg`"></v-img>
            </div>
            <div class="h-login-frame">
              <div class="h-login-title text-center">
                  <span>{{ $t("yakuza.hack.connection").toUpperCase() }}</span>
                  <span class="close-btn"><v-btn icon color="#000" x-small @click="currentStep = 0"><v-icon>mdi-close</v-icon></v-btn></span>
              </div>              
              <div>
                <div class="h-login-label mt-10">{{ $t("yakuza.hack.username") }}</div>
                <div class="h-login-field my-3">{{ username }}</div>
                <div class="h-login-label mb-3 mt-5">{{ $t("yakuza.hack.password") }}</div>
                <div class="h-login-field mb-8">{{ password }}</div>
                <div v-if="isHacked">
                    <v-progress-linear
                      indeterminate 
                      color="#00ffff"
                      height="8"
                      class="progress-bar"
                    ></v-progress-linear>
                </div>
              </div>
            </div>
          </v-container>

          <!-- Access list -->
          <v-container class="h-access" v-if="currentStep === 2">
            <div class="h-access-header">
              <v-img class="logo py-3" contain :src="`${casePublicImages}/hack/TGS_logo.svg`"></v-img>
            </div>
            <div>{{ $t("yakuza.hack.magnetic_card") }}</div>
            <div class="h-access-label" v-html="$t('yakuza.hack.activate_auth')"></div>
            <div v-if="!showFinish">
              <div v-for="(v, k) in $yakuzaStore.hack.authorizations" :key="k">
                <div>
                  <v-switch 
                    v-model="v.enabledCommon"
                    inset
                    class="h-access-switch"
                    @change="onSwitchChange('common', v)"
                  >
                    <template v-slot:label>{{ v.name }}<br class="mobile-break"> - {{ $t("yakuza.hack.elevator_common") }}</template>
                  </v-switch>
                </div>
                <div>
                  <v-switch 
                    v-model="v.enabledPenthouse"
                    inset
                    class="h-access-switch"
                    @change="onSwitchChange('penthouse', v)"              
                  >
                    <template v-slot:label>{{ v.name }}<br class="mobile-break"> - {{ $t("yakuza.hack.elevator_penthouse") }}</template>
                  </v-switch>
                </div>
              </div>
            </div>
            <div v-if="!showFinish" class="h-access-validate">
              <v-btn @click="validateAuthorizations">{{ $t("yakuza.hack.system_validate") }}</v-btn>
            </div>
          </v-container>
        </div>
      </v-container>
    </v-container>
  </div>
</template>
  
<script>
  import HackOverlayError from "@/components/yakuza/HackOverlayError";
  import HackOverlayLoading from "@/components/yakuza/HackOverlayLoading";
  import HackOverlayHack from "@/components/yakuza/HackOverlayHack";
  import HackOverlayFinish from "@/components/yakuza/HackOverlayFinish";     
  import currentGameMixin from "@/mixins/currentGameMixin";

  export default {
    name: "Hacking",

    components: {
        HackOverlayError,
        HackOverlayLoading,
        HackOverlayHack,
        HackOverlayFinish
    },

    mixins: [currentGameMixin],

    data: () => ({
      currentStep: 0,
      selectedSystem: '',
      showErrorSystem: false,
      showLoading: false,
      showFinish: false,
      showHack: false,
      isHacked: false,
      username: '',
      password: ''
    }),

    methods: {
      close: function() {
        this.selectedSystem = '';
        this.showErrorSystem = false;
        this.showLoading = false;
        this.showHack = false;
        this.showFinish = false;
        this.isHacked = false;
        this.username = '';
        this.password = '';
        this.currentStep = 0;
      },
      selectSystem: function() {
        if(this.selectedSystem !== this.$yakuzaStore.hack.correctSystem) {
          this.showErrorSystem = true;
        } else {
          this.showLoading = true;
        }
      },
      finishedLoading: function() {
        this.showLoading = false; 
        this.showHack = true;
        this.currentStep = 1;        
      },
      hackSucceed: function() {
        this.showHack = false;
        this.username = "***********";
        this.password = "***********";
        this.isHacked = true;
        setTimeout(() => { this.currentStep = 2 }, 2000);
      },
      validateAuthorizations: function() {
        this.$yakuzaStore.saveState();
        this.showFinish = true;
      },
      onSwitchChange: function(type, s) {
        this.$yakuzaStore.hack.authorizations.forEach(v => {
          v.enabledCommon = false;
          v.enabledPenthouse = false;
        })

        if(type === 'common')
          this.$yakuzaStore.hack.authorizations.find(v => v.name === s.name).enabledCommon = true;

          if(type === 'penthouse')
          this.$yakuzaStore.hack.authorizations.find(v => v.name === s.name).enabledPenthouse = true;
      }
    },

    computed: {
    },

    created() {
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';

  .h-container {
    @include base_container();
  }  

  .h-window {
    border: 1px solid #00ffff;
    background: rgb(1,40,73);
    background: linear-gradient(180deg, rgba(1,40,73,1) 0%, rgba(0,131,186,1) 100%);
    min-height: 400px;
    padding: 0;
    font: 11pt "Courier Prime";

    .h-window-title {
      height: 24px;
      background: #00ffff;
      color: #000;
      padding-top: 5px;
    }

    .h-window-content {
      text-align: left;
      color: #fff;

      ::v-deep .hl {
        color: #00ffff;
      }

      select {
        /* Reset */
        font-size: 10pt;
        margin-top: 20px;
        width:400px !important;
        @include customized_select($color: $accent-color-hack, $bgcolor: $accent-color-hack-trans, $caretimg: '/yakuza/assets/images/hack/caret_down_blue.png', $optionbg: $accent-color-hack-trans);
      
        @media only screen and (max-width: 600px) {
          width:100% !important;
        }
        @media only screen and (min-width: 600px) {
          margin-left: 100px;
        }        
      }
      
      ::v-deep .h-system-validate button, ::v-deep .h-access-validate button
       {
        font: 10pt "Courier Prime";
        color: #fff;
        height: 28px;
        background-color: $accent-color-hack-trans;
        border: 1px solid $accent-color-hack;
        border-radius: 0;
        margin-top: 20px;
        @media only screen and (min-width: 600px) {
          margin-left: 100px;
        }          
      }
      .h-login .logo, .h-access .logo {
        width: 90%;
        margin: auto;
        max-width: 300px;
      }

      .h-login {
        text-align: center;
        .h-login-frame {
          @include size(95%, 260px);
          border: 1px solid #00ffff;
          background: rgba(0, 0, 0, 0.8);
          max-width: 460px;
          padding: 0;
          font: 11pt "Courier Prime";
          margin: auto;

          .h-login-title {
            height: 24px;
            background: #00ffff;
            color: #000;
            padding-top: 5px;
            padding-left: 5px;
            padding-right: 5px;
            .close-btn {
              float:right;
              margin: -4px;
            }
          }
          
          .h-login-label {
            color: #fff;
          }

          .h-login-field {
            @include size(90%, 24px);
            max-width: 300px;
            border: 1px solid #00ffff;
            padding: 3px;
            text-align: center;
            margin: auto;
          }

          .progress-bar {
            width: 80%;
            margin-left: 10%;
          }
        }
      }

      .h-access {
        .h-access-label {
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .h-access-switch {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;

          ::v-deep .v-input--switch__track {
            color: #00ffff44 !important;
            opacity: 1;
          }

          ::v-deep .v-input--switch__track.primary--text {
            color: #00ffff !important;
            opacity: 1;
          }

          ::v-deep .v-input--switch__thumb {
            color: #fff !important;
          }          
          ::v-deep .v-input--switch__thumb.primary--text {
            color: #fff !important;
          }
          ::v-deep .v-messages {
            min-height: 0 !important;
          }

          ::v-deep .v-label {
            color: #fff;
            font-size: 11pt;
            line-height: 15px;

            @media screen and (min-width: 600px) {
              .mobile-break { display: none }
            }
          }          
        }
      }
    }
  }
</style>
  