<template>
  <svg :viewBox="`0 0 ${totalWidth} ${totalHeight}`" xmlns="http://www.w3.org/2000/svg">
    <g
      v-for="(ch, index) in inputData"
      :key="`roll-${index}`"
      :transform="`translate(${index * rollWidth + index * rollMargin}, 0)`"
    >
      <g class="roll-button" :class="{ unlocked: !locked }" @click="rollCharUp(index)">
        <rect
          :width="rollWidth"
          :height="rollHeight"
          class="roll-border cursor-frame"
        ></rect>

        <polygon
          :points="getIncrementerPoints()"
          class="cursor-symbol"
        ></polygon>
      </g>
      <g :transform="`translate(0, ${rollHeight + rollMargin})`">
        <rect
          x="0"
          y="0"
          :width="rollWidth"
          :height="rollHeight"
          class="roll-border"
        ></rect>
        <text
          :x="0.5 * (rollWidth + rollPadding)"
          :y="0.5 * (rollHeight + rollPadding)"
          text-anchor="middle"
          dominant-baseline="middle"
          class="roll-text"
        >
          {{ ch }}
        </text>
      </g>
      <g
        class="roll-button"
        :class="{ unlocked: !locked }"
        :transform="`translate(0, ${2 * (rollHeight + rollMargin)})`"
        @click="rollCharDown(index)"
      >
        <rect
          x="0"
          y="0"
          :width="rollWidth"
          :height="rollHeight"
          class="roll-border cursor-frame"
        ></rect>
        <polygon
          :points="getDecrementerPoints()"
          fill="#00FF29"
          class="cursor-symbol"
        ></polygon>
      </g>
    </g>
    >
  </svg>
</template>
<script>
import PadlockRollDirectionEnum from "@/enums/PadlockRollDirectionEnum";

export default {
  data() {
    return {
      rollWidth: 20,
      rollHeight: 20,
      rollMargin: 4,
      rollPadding: 2,
      cursorWidth: 12,
      inputCode: "",
    };
  },
  props: {
    secretLength: Number,
    secretChar: String,
    locked: Boolean
  },
  computed: {
    inputData: function() {
      return this.inputCode.split("");
    },
    validChars: function () {
      const firstChar = "a";
      const lastChar = "z";
      const chars = [];

      for (let i = firstChar.charCodeAt(0); i <= lastChar.charCodeAt(0); i++) {
        chars.push(String.fromCharCode(i));
      }

      return chars;
    },
    totalWidth: function () {
      return this.secretLength * this.rollWidth + (this.secretLength - 1) * this.rollMargin;
    },
    totalHeight: function () {
      return 3 * this.rollWidth + 2 * this.rollMargin
    }
  },
  methods: {
    getRollCursorPoints(direction) {
      const width = this.cursorWidth;
      const height = Math.sqrt(Math.pow(width, 2) - Math.pow(width/2, 2));
      const minX = this.rollWidth / 2 - width / 2;
      const minY = this.rollHeight / 2 - height / 2;
      const maxX = minX + width;
      const centerX = minX + width / 2;
      const maxY = minY + height;

      let points = [];

      switch (direction) {
        case PadlockRollDirectionEnum.DECREMENT:
          points = [
            { x: minX, y: minY },
            { x: centerX, y: maxY },
            { x: maxX, y: minY },
          ];
          break;
        case PadlockRollDirectionEnum.INCREMENT:
          points = [
            { x: minX, y: maxY },
            { x: centerX, y: minY },
            { x: maxX, y: maxY },
          ];
      }

      let pointsStr = "";

      points.forEach((point, index) => {
        if (index > 0) pointsStr += ", ";

        pointsStr += `${point.x} ${point.y}`;
      });

      return pointsStr;
    },
    getIncrementerPoints: function () {
      return this.getRollCursorPoints(PadlockRollDirectionEnum.INCREMENT);
    },
    getDecrementerPoints: function () {
      return this.getRollCursorPoints(PadlockRollDirectionEnum.DECREMENT);
    },
    getInputChar: function(index) {
      return this.inputCode[index];
    },
    rollChar: function (index, direction) {
      const currentChar = this.inputCode.charAt(index);
      const currentIndex =
        currentChar === this.secretChar
          ? -1
          : this.validChars.indexOf(currentChar);
      let newIndex = -1;

      if (direction === PadlockRollDirectionEnum.DECREMENT)
        newIndex = (currentIndex + 1) % this.validChars.length;
      else {
        if (currentIndex <= 0) newIndex = this.validChars.length - 1;
        else newIndex = currentIndex - 1;
      }

      const newChar = this.validChars[newIndex];
      const inputArray = this.inputCode.split("");

      inputArray[index] = newChar;
      this.inputCode = inputArray.join("");

      this.$emit("codeChange", this.inputCode);
    },
    rollCharUp(index) {
      this.rollChar(index, PadlockRollDirectionEnum.INCREMENT);
    },
    rollCharDown(index) {
      this.rollChar(index, PadlockRollDirectionEnum.DECREMENT);
    },
    reset() {
      this.inputCode = (new Array(this.secretLength + 1)).join(this.secretChar);
    }
  },
  watch: {
    secretLength: function (newValue) {
      if (newValue !== undefined)
        this.inputCode = new Array(newValue).fill(this.secretChar);
    },
  },
  mounted() {
    this.reset();
  },
};
</script>
<style>
.roll-border
{
  stroke: #00ff29;
}
.roll-text {
  fill: #00ff29;
}
.roll-button {
  pointer-events: all;
  cursor: pointer;
}
.roll-button.unlocked {
  pointer-events : none;
}
.cursor-symbol {
  fill: #00ff29;
}
.roll-button .cursor-frame {
  fill: #00FF294D;
}
.roll-button:hover .cursor-frame,
.roll-button.unlocked .cursor-frame {
  fill: #00ff29;
}
.roll-button:hover .cursor-symbol,
.roll-button.unlocked .cursor-symbol {
  fill: #000000;
}
</style>
