<template>
  <button
    @click.prevent="onclick"
    class="d-flex menu-button"
    :class="{ disabled: disabled, locked: locked }"
  >
    <!-- <div class="icon-container">
      <lock-icon
        class="lock-icon"
        v-if="lockable && !locked"
        :locked="locked"
      ></lock-icon>
    </div> -->
    <slot></slot>
  </button>
</template>
<script>

export default {
  data() {
    return {
      hover: false,
    };
  },
  components: {
    // LockIcon,
  },
  props: {
    lockable: Boolean,
    locked: Boolean,
    disabled: Boolean,
  },
  methods: {
    onclick: function(e) {
      this.$emit("click", e);
    },
    toggle: function() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");

.menu-button {
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-inline: 10px;
}

.icon-container {
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.lock-icon {
  position: relative;
  top: -0.2em;
  margin-right: 5px;
}

.menu-button.disabled {
  color: #ffffff5f;
  pointer-events: none;
}

.menu-button.disabled .lock-icon {
  opacity: 50%;
}
</style>
