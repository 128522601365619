<template>
  <v-container class="sec-container">
    <v-overlay absolute class="text-center" :value="isError">
      <div class="dialog-frame">
        <p v-html="$t('egypt.security.error_step' + currentStep)"></p>
        <button @click="resetError">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <v-overlay absolute class="text-center" :value="!isLocked">
      <div class="dialog-frame">
        <h1>{{ $t("egypt.security.unlock_title") }}</h1>
        <p v-html="$t('egypt.security.unlock_desc')"></p>
      </div>
    </v-overlay>
    <div class="sec-header">
      <img :src="casePublicImages + '/security/logo.svg'" />
    </div>
    <div class="sec-body">
      <div class="sec-title">{{ $t("egypt.security.title")}}</div>
      <div class="sec-panel justify-center align-center" v-if="currentStep == 1">
        <div class="sec-panel-prompt">{{ $t("egypt.security.prompt1") }}</div>
        <div class="sec-carousel-container">
          <v-carousel
            v-model="selection"
            class="sec-carousel"
            height="150"
            hide-delimiters
            :show-arrows="false"
            >
            <v-carousel-item
              v-for="logo in logos"
              :key="logo.id"
              :src="getLogoImage(logo)"
            ></v-carousel-item>
          </v-carousel>
          <v-hover v-slot="{ hover }">
            <div
              class="sec-carousel-arrow sec-carousel-left"
              :class="{ hovered: hover }"
              @click="onChangeCarrousel(-1)"
            ></div>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <div
              class="sec-carousel-arrow sec-carousel-right"
              :class="{ hovered: hover }"
              @click="onChangeCarrousel(1)"
            ></div>
          </v-hover>
        </div>
        <div>
          <v-btn @click="tryUnlockStep1" class="sec-submit-button">{{ $t("egypt.security.submit") }}</v-btn>          
        </div>        
      </div>
      <div class="sec-panel justify-center align-center" v-if="currentStep == 2">
        <div class="sec-panel-image"><img :src="getLogoImage(logos[selectedLogo])" /></div>
        <div class="sec-panel-prompt">{{ $t("egypt.security.prompt2") }}</div>
        <div class="sec-panel-employee">
          <select
              v-model="selectionPerson"
              class="choice-list"
            >
              <option
                v-for="person in persons"
                :key="person.id"
                :value="person.value"
              >
                {{ person.value }}
              </option>
            </select>          
        </div>
        <div>
          <v-btn @click="tryUnlockStep2" class="sec-submit-button">{{ $t("egypt.security.submit") }}</v-btn>          
        </div>        
      </div>      
    </div>

  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Security",
  data() {
    return {
      logos: [],
      persons: [],
      selection: null,
      selectionPerson: null
    };
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.egyptSecurityLocked;
    },
    isError: function() {
      return this.$store.getters.egyptSecurityError;
    },
    logosDataPath: function() {
      return `${this.casePublicData}/security.json`;
    },
    arrowImagePath: function() {
      return require(`${this.casePublicImages}/security/selection_arrow.svg`);
    },
    logosImagePath: function() {
      return `${this.casePublicImages}/security`;
    },
    selectedLogo: function() {
      return this.selection;
    },
    selectedName: function() {
      return this.selectionPerson;
    },    
    defaultPosition: function() {
      return this.$store.getters.egyptSecurityDefaultPosition;
    },
    currentStep: function() {
      return this.$store.getters.egyptSecurityStep;
    },    
  },
  methods: {
    getLogoImage: function(logo) {
      return `${this.logosImagePath}/${logo.res}.jpg`;
    },
    onChangeCarrousel: function(increment) {
      let value = this.selection;

      value += increment;
      if (value < 0) value = this.logos.length - 1;
      value = value % this.logos.length;
      this.selection = value;
    },
    setDefaultPositions: function() {
      this.selection = this.defaultPosition;
    },
    tryUnlockStep1: function() {
      this.$store.dispatch("egyptTryUnlockSecurity1", this.selectedLogo + 1);
    },
    tryUnlockStep2: function() {
      this.$store.dispatch("egyptTryUnlockSecurity2", this.selectedName);
    },    
    resetError: function() {
      this.$store.dispatch("egyptResetSecurityError");
    },
  },
  async mounted() {
    const { data } = await axios.get(this.logosDataPath);
    this.logos = data.logos;
    this.persons = data.persons;
    this.setDefaultPositions();
    this.resetError();
  }
};
</script>
<style>
.sec-container {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #FFFFFF;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  padding: 19px !important;
  display: block !important;
  height: auto !important;
  width: 100%;
  text-align: center;
}

.sec-header {
  height: 66px;
  background: #000000;
  border-bottom: 6px solid #C4242D;
  text-align: left;
}

.sec-header img {
  height: 40px;
  margin-top: 10px; 
  margin-left: 5px;
}

.sec-body {
  background: url(/egypt/assets/images/security/bg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 24px;
}

.sec-carousel-container {
  position: relative;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #B6B6B6;
  border-radius: 5px;
  margin: auto;
  width: 200px;
}

.sec-panel {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #B6B6B6;
  border-radius: 5px;
  max-width: 380px !important;
  position: relative;
  margin: auto;
}

.sec-panel-prompt {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 24px;
}

.sec-carousel {
  width: 150px !important;
  border: 0;
  border-radius: 0px;
}
.sec-carousel-arrow {
  width: 39px;
  height: 39px;
  position: absolute !important;
  top: 50%;
  cursor: pointer;
  background-image: url(/egypt/assets/images/security/selection_arrow.svg);
}
.sec-carousel-left {
  left: 0;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.sec-carousel-arrow.hovered {
  background-image: url(/egypt/assets/images/security/selection_arrow_hover.svg);
}
.sec-carousel-right {
  right: 0;
  transform: translate(50%, -50%) rotate(90deg);
}
.sec-submit-button {
  background: linear-gradient(180deg, #F95962 1.63%, #C4242D 48.51%, #5B171B 100%);
  border: 1px solid #000000;
  border-radius: 5px;
  width: 120px;
  height: 30px !important;
  margin-top: 16px;
  font-family: 'Arimo';
  margin-bottom: 32px;
  color: #fff !important;
  text-transform: none !important;  
}
.sec-title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Arimo';
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.sec-panel-image img {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 24px;
}
.sec-panel-employee select {
  border: 1px solid black;
  background-color: #ffffff;
  appearance: auto;
  padding: 2px;
  border-radius: 4px;
  font-family: 'Arimo';
  font-size: 14px;
}
.dialog-frame {
  border-radius: 0 !important;
}

@media only screen and (max-width: 600px) {
  .dialog-frame {
    font-size: 14px !important;
  }
}
</style>
