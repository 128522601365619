<template>
  <div class="section-frame fill-height">
    <div class="daily-yorkshire-container flex-column flex-wrap fill-height">
      <v-overlay absolute :value="advertiseUnlock">
        <div class="dialog-frame">
          <p>
            <span v-html="$t('ghosts.dailyYorkshire.messages.envelope')"></span><br />
            <span v-html="$t('ghosts.dailyYorkshire.messages.envelopeContent')"></span>
          </p>
          <p v-html="$t('ghosts.dailyYorkshire.messages.sectionEnabled')"></p>
        </div>
      </v-overlay>
      <div class="daily-yorkshire-headline">
        <v-row align="center" no-gutters class="py-5">
          <v-col cols="auto" class="pl-5">
            <img
              src="@/assets/images/ghosts/daily/daily_yorkshire_logo.svg"
              class="daily-yorkshire-logo"
            />
          </v-col>
          <v-col class="pl-5">
            <div
              class="daily-yorkshire-baseline"
              v-html="$t('ghosts.dailyYorkshire.baseline')"
            ></div>
          </v-col>
        </v-row>
      </div>
      <div class="daily-yorkshire-banner">
        <div class="daily-yorkshire-title">
          {{ $t("ghosts.dailyYorkshire.title") }}
        </div>
        <div class="daily-yorkshire-subtitle">
          {{ $t("ghosts.dailyYorkshire.subtitle") }}
        </div>
      </div>
      <div class="daily-yorkshire-chat grow">
        <rpg-chat
          :chatId="chatId"
          @chatComplete="onChatComplete"
          @chatDeadend="onChatDeadend"
          ref="chat"
        ></rpg-chat>
      </div>
      <div v-if="chatDeadend">
        <v-btn @click="resetChat">{{ $t("common.buttons.restart") }}</v-btn>
      </div>
    </div>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
  </div>
</template>
<script>
import RpgChat from "../../components/ghosts/RpgChat";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "DailyYorkshire",
  data() {
    return {
      sectionId: "daily",
      chatId: "dailyYorkshire",
      chatDeadend: false,
      advertiseUnlock: false,
    };
  },
  components: {
    RpgChat,
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.ghostsDailyYorkshireLocked;
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/common/unlock_sound.mp3`;
    },
  },
  methods: {
    resumeChat: function() {
      this.$refs.chat.resumeChat();
      this.chatDeadend = false;
    },
    resetChat: function() {
      this.$refs.chat.resetChat();
      this.chatDeadend = false;
    },
    onChatComplete: async function() {
      console.log("Chat complete");
      await this.unlock();

      setTimeout(() => {
        this.advertiseUnlock = true;
      }, 4000);
    },
    onChatDeadend: function() {
      console.log("Chat is a deadend");
      this.chatDeadend = true;
    },
    unlock: async function() {
      await this.$store.dispatch("unlockSection", {
        gameID: this.caseID,
        sectionID: this.sectionId,
      });

      await this.$store.dispatch("enableSection", {
        gameID: this.caseID,
        sectionID: 'police',
      });
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    },
  },
  watch: {
    advertiseUnlock: function(newValue, previousValue) {
      if (!previousValue && newValue) this.playUnlockSound();
    },
  },
  mounted: async function() {
    this.resumeChat();

    if (!this.isLocked) this.advertiseUnlock = true;
  },
};
</script>
<style scoped>
.daily-yorkshire-container {
  background: #ffffff;
  position: relative;
}

.daily-yorkshire-logo {
  height: 38px;
  vertical-align: middle;
}
.daily-yorkshire-baseline {
  max-width: 158px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}
.daily-yorkshire-banner {
  background-image: url(~@/assets/images/ghosts/daily/daily_yorkshire_banner.jpg);
  background-size: cover;
  background-position-y: 50%;
  height: 183px;
  position: relative;
}
.daily-yorkshire-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: Arimo;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.2em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.daily-yorkshire-subtitle {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  font-family: Arimo;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.daily-yorkshire-chat {
  padding: 31px;
}
.daily-yorkshire-chat::v-deep .chat-log-header {
  background: #b6b6b6;
}
</style>
