<template>
  <div>
    <div class="post-header">
      <slot name="title"></slot>
    </div>
    <div class="post-body">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "HuntersPostContainer",
  props: {
    headerCssClass: String,
    bodyCssClass: String
  }
}
</script>
<style scoped>
  .post-header {
    background: none;
  }

  .post-body {
    background: none;
  }
</style>
