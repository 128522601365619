var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item-group',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"menu-row",attrs:{"no-gutters":"","align":"center"}},_vm._l((_vm.menus),function(menu,index){return _c('v-col',{key:index,class:{
          'pl-1': index > 0 && index < _vm.menus.length - 1,
          'pr-1': index > 0 && index < _vm.menus.length - 1,
        },attrs:{"id":("menu-" + (menu.id))}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var active = ref.active;
        var toggle = ref.toggle;
return [(menu.subsections && menu.subsections.length > 0)?_c('v-menu',{attrs:{"offset-y":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('menu-button',_vm._g(_vm._b({staticClass:"parent-menu justify-center",class:{
                  selected: active,
                },attrs:{"id":("menu-" + (menu.id)),"disabled":menu.disabled}},'menu-button',attrs,false),on),[_vm._v(_vm._s(menu.title))])]}}],null,true)},_vm._l((menu.subsections),function(submenu,subindex){return _c('div',{key:subindex},[_c('menu-button',{staticClass:"child-menu",attrs:{"lockable":submenu.lockable,"locked":submenu.locked,"disabled":submenu.disabled},on:{"click":function($event){return _vm.onMenuClick(submenu, toggle)}}},[_vm._v(_vm._s(submenu.title))])],1)}),0):_c('menu-button',{staticClass:"parent-menu justify-center",class:{ selected: active },attrs:{"lockable":menu.lockable,"locked":menu.locked,"disabled":menu.disabled},on:{"click":function($event){return _vm.onMenuClick(menu, toggle)}}},[_vm._v(_vm._s(menu.title))])]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }