<template>
  <v-expansion-panels accordion tile flat>
    <v-expansion-panel v-for="(menu, index) in menus" :key="index" class="menu-panel pa-0 ma-0">
      <v-expansion-panel-header hide-actions class="pa-0 ma-0 menu-header">
        <menu-button
          :lockable="menu.lockable"
          :locked="menu.locked"
          :id="`menu-${menu.id}`"
          @click="onMenuClick(menu)"
          class="parent-button justify-center"
          >{{ menu.title }}</menu-button
        >
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 pa-0" >
        <v-container v-if="menu.subsections">
          <v-row>
            <v-col
              cols="12"
              v-for="(submenu, subindex) in menu.subsections"
              :key="subindex"
              class="pa-0 ma-0"
            >
              <menu-button
                :lockable="submenu.lockable"
                :locked="submenu.locked"
                :disabled="submenu.disabled"
                @click="onMenuClick(submenu)"
                class="child-button"
                >{{ submenu.title }}</menu-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import MenuButton from "./MenuButton";

export default {
  components: {
    MenuButton,
  },
  data() {
    return {};
  },
  props: {
    menus: Array,
  },
  methods: {
    onMenuClick: function (menu) {
      if (
        menu.submenus &&
        menu.submenus.length > 0 &&
        menu.expanded != undefined
      )
        this.toggleMenu(menu);

      if (menu.route) {
        this.$emit("navigate", menu.route);
        this.$router.push(menu.route);
      }
    },
    toggleMenu(menu) {
      menu.expanded = !menu.expanded;
    },
  },
  mounted() {},
};
</script>
<style scoped>

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.menu-panel {
  background: #00000000!important;
}

.menu-header {
    min-height: auto!important;
    max-height: 37px!important;
}

.parent-button {
  background: #00000099;
  font-family: "Bebas Neue";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.parent-button:hover {
  background: #00000060;
  color: #ffffff;
}

.child-button {
  background: #00000066;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #ffffff;
}

.child-button:hover {
  background: #ffc12266;
  color: #ffffff;
  text-align: left;
  font-family: "Source Sans 3";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: left;
}
</style>