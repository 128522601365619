<template>
  <div>
    <v-banner color="#00000000" v-if="!connected">
      <loader :value="progress" class="loader"></loader>
    </v-banner>
    <div class="police-frame fill-height" v-if="connected">
      <v-container class="police-container fill-height pa-0" fluid>
        <router-view></router-view>
      </v-container>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "Police",
  data() {
    return {
      connected: false,
      progress: 0,
    };
  },
  components: {
    Loader,
  },
  mounted() {
    const me = this;

    this.$nextTick(function () {
      const interval = setInterval(function () {
        const progress = me.progress + Math.random() * 10;
        me.progress = progress;

        if (me.progress >= 100) {
          clearInterval(interval);
          me.connected = true;
        }
      }, 100);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.police-container {
  background: #ffffff;
}

.police-frame {
  background: #00000066;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}

.loader {
  position: absolute;
  top: 50%;
  transform: translate(-50%, 50%);
  left: 50%;
  width: 370px;
  height: 150px;
  max-width: 100%;
  box-shadow: 8px 6px 7px 0px #0000004d;
}
</style>
