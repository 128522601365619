<template>
  <v-container>
    <v-row dense>
      <v-col>
        <game-menu :menus="sections" v-if="$vuetify.breakpoint.xs"></game-menu>
      </v-col>
      <v-col cols="12" class="justify-center d-flex d-sm-none">
        <reset-button gameID="colossus" class="setting-button">{{
          $t("homepage.reset.button")
        }}</reset-button>
      </v-col>
      <v-col cols="12" class="justify-center d-flex d-sm-none">
        <locale-switcher :locales="caseAvailableLocales" :currentLocale="currentLocale" class="setting-button">{{
          $t("homepage.localeSwitcher.title")
        }}</locale-switcher>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GameMenu from "@/components/GameMenu.vue";
import ResetButton from "@/components/ResetButton.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "App",

  components: {
    GameMenu,
    ResetButton,
    LocaleSwitcher
  },
  data: () => ({
    sections: [],
  }),
  mixins: [currentGameMixin],
  methods: {
    refreshSections: function () {
      const vm = this;

      this.sections.splice(0, this.sections.length);

      vm.$store.getters.sections(this.caseID).forEach((s) => {
        const sec = s;

        sec.title = vm.$t(`${this.caseID}.data.sections.${sec.id}.title`);

        if (sec.subsections) {
          sec.subsections = sec.subsections.map((ss) => {
            const subsec = ss;

            subsec.title = this.$t(
              `${vm.caseID}.data.sections.${subsec.id}.title`
            );

            if (sec.id === "cabins" && !subsec.locked) {
              const newTitle = `${
                subsec.title
              } (${vm.$store.getters.colossusCabinFullname(subsec.id)})`;
              subsec.title = newTitle;
            }

            return subsec;
          });
        }

        vm.sections.push(sec);
      });
    },
  },
  mounted() {
    this.refreshSections();
  },
};
</script>
<style scoped>
.setting-button {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  margin-top: 50px;
  color: #ffffff;
  text-transform: uppercase;
}

.setting-button:hover {
  color: #ffc122;
}
</style>
