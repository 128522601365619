<template>
  <v-container class="hunters-story-container">
    <v-row>
      <v-col cols="12" class="hunters-story-title">{{
        $t("ghosts.hunters.history.title")
      }}</v-col>
      <v-col cols="12" class="hunters-story-subtitle">{{
        $t("ghosts.hunters.history.subtitle")
      }}</v-col>
      <v-col cols="12">
        <v-img src="@/assets/images/ghosts/hunters/history.jpg"></v-img>
      </v-col>
      <v-col cols="12" v-html="storyHtml" class="hunters-story-content"></v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "GhostHuntersHistory",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      storyHtml: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    publicAssetsPath: function () {
      return `${this.publicPath}${this.caseID}/assets`;
    },
    publicHtmlPath: function () {
      return `${this.publicAssetsPath}/html/hunters`;
    },
    storySrc: function () {
      return `${this.publicHtmlPath}/history_${this.$i18n.locale}.html`;
    },
  },
  methods: {
    fetchStory: async function () {
      const { data } = await axios.get(this.storySrc);
      this.storyHtml = data;
    },
  },
  mounted: async function () {
    await this.$nextTick();
    this.fetchStory();
  },
};
</script>
<style scoped>
.hunters-story-container {
  padding-left: 46px;
  padding-right: 46px;
}
.hunters-story-title {
  font-family: Eater;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.hunters-story-subtitle {
  font-family: Fredoka;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.hunters-story-banner {
  background-image: url(~@/assets/images/ghosts/hunters/history.jpg);
  background-size: cover;
  background-position-y: 40%;
  height: 280px;
}
.hunters-story-content {
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
</style>