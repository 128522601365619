<template>
  <div class="phone-container">
    <svg-phone
      class="phone-ui"
      @dial="onDial"
      locked
      dialMode
      :phoneNumber="phoneNumber"
      ref="dialui"
    ></svg-phone>
    <audio src="@/assets/audio/phone/ringing.mp3" ref="ringingSound" />
    <audio src="@/assets/audio/phone/engaged.mp3" ref="engagedSound" />
    <div class="voice-ui" v-show="showVoice">
      <voice-slider
        @onPlay="onVoicePlay"
        @onPause="onVoicePause"
        @onSeek="onVoiceSeek($event)"
        :playing="voice.playing"
        :duration="voice.duration"
        :position="voice.currentTime"
        ref="slider"
        class="slider mb-2"
      ></voice-slider>
      <av-waveform
        :audio-src="voiceFile"
        played-line-color="#ffc122"
        noplayed-line-color="#000000"
        playtime-slider-color="#ffffff"
        :audio-controls="false"
        :audio-class="'audio-player-voicemail'"
        :canv-width="waveformWidth"
      ></av-waveform>
    </div>
    <div
      class="message-ui"
      v-if="showMessage"
      v-text="$t(`${this.caseID}.dialPhone.messages.notTheRightNumber`)"
    ></div>
  </div>
</template>

<script>
import SvgPhone from "@/components/maestro/SvgPhone.vue";
import VoiceSlider from "@/components/VoiceSlider.vue";
import currentGameMixin from "../../mixins/currentGameMixin";
import phoneMessages from "../../messages/maestro/phone.messages";

export default {
  components: { SvgPhone, VoiceSlider },
  name: "DialPhone",
  i18n: {
    messages: phoneMessages
  },
  data() {
    return {
      voicemailPlayer: null,
      voice: {
        duration: 0,
        currentTime: 0,
        playing: false,
      },
      waveformWidth: 200,
      showVoice: false,
      showMessage: false,
    };
  },
  mixins: [currentGameMixin],
  computed: {
    phoneNumber: function () {
      return this.$store.getters.maestroDialPhoneNumber;
    },
    voiceFile: function () {
      return require(`/public/egypt/assets/audio/phone/voicemail_${this.$i18n.locale}.mp3`);
    },
  },
  watch: {
    voiceFile: function () {
      this.$router.go();
    },
  },
  methods: {
    onDial: function (dialedNumber) {
      if (dialedNumber === this.phoneNumber) {
        this.$refs.dialui.ring();
        this.playRinging();

        setTimeout(() => {
          this.stopRinging();
          this.$refs.dialui.speak();
          this.showVoice = true;

          this.$nextTick(() => {
            this.initVoicemailUI();
            this.playVoicemail();

            // setTimeout(() => {
            //   this.stopVoiceMail();
            //   this.showVoice = false;
            //   this.$refs.dialui.hangUp();
            // }, 10000);
          });
        }, 3000);
      } else {
        this.$refs.dialui.engage();
        this.showMessage = true;
        this.playEngaged();

        setTimeout(() => {
          this.stopEngaged();
          this.$refs.dialui.hangUp();
          this.showMessage = false;
        }, 4000);
      }
    },
    onVoicePlay: function () {
      this.voicemailPlayer.play();
    },
    onVoicePause: function () {
      this.voicemailPlayer.pause();
    },
    onVoiceSeek: function (currentTime) {
      this.voicemailPlayer.currentTime = currentTime;
    },
    playSound: function (sound) {
      sound.play();
    },
    stopSound: function (sound) {
      sound.pause();
      sound.currentTime = 0;
    },
    initVoicemailUI: function () {
      this.voicemailPlayer = document.getElementsByClassName(
        "audio-player-voicemail"
      )[0];
      this.voice.duration = this.voicemailPlayer.duration;

      this.voicemailPlayer.addEventListener("durationchange", () => {
        this.voice.duration = this.voicemailPlayer.duration;
      });

      this.voicemailPlayer.addEventListener("play", () => {
        this.voice.playing = true;
      });

      this.voicemailPlayer.addEventListener("pause", () => {
        this.voice.playing = false;
      });

      this.voicemailPlayer.addEventListener("timeupdate", () => {
        this.voice.currentTime = this.voicemailPlayer.currentTime;
      });
    },
    playVoicemail: function () {
      this.playSound(this.voicemailPlayer);
    },
    stopVoiceMail: function () {
      this.stopSound(this.voicemailPlayer);
    },
    playRinging: function () {
      this.playSound(this.$refs.ringingSound);
    },
    stopRinging: function () {
      this.stopSound(this.$refs.ringingSound);
    },
    playEngaged: function () {
      this.playSound(this.$refs.engagedSound);
    },
    stopEngaged: function () {
      this.stopSound(this.$refs.engagedSound);
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.phone-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  background-color: #00000000;
}

.phone-ui {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.voice-ui {
  top: 100px;
  left: 50%;
  position: absolute;
  max-width: 200px;
  transform: rotateZ(-5deg) translate(-50%);
}

.message-ui {
  top: 100px;
  left: 50%;
  position: absolute;
  max-width: 200px;
  transform: rotateZ(-5deg) translate(-50%);
  color: #ffffff;
}
</style>
