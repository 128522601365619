export default {
    placeImageCycleSpeed: 1500,
    placeImageCount: 4,    
    correctStops: {
        A: { 
            name : "Shinjuku",
            found: false,
            places: [
                "Fuji View Residences", "Golden Pavilion Tower", "Hanabi Heights", "Hanazono Harmony Plaza", "Hanazono Park Residency",
                "Hanazono Zenith Plaza", "Harmony Heights", "Kabukicho Central Suites", "Kabukicho Grand Hotel", "Kabukicho Moonrise Residences",
                "Kabukicho Neon Oasis", "Kabukicho Starlight Suites", "Koi Pond Tower", "Metropolitan Heights", "Moonlit Towers",
                "Neon Skyline Apartments", "Nihon National Tower", "Radiant Shinjuku Residences", "Sakura Blossom Residences", "Sakura Plaza",
                "Shinjuku Center Building", "Shinto Shrine View Tower", "Sunflower Apartments", "Tokyo Sunburst Towers", "Twilight Bay Apartments",
                "Yoyogi Parkview Condos", "Zenith Skyscrapers", "Zenkoji Zen Residences"
            ]
        },
        C: {
            name: "Seibu-Shinjuku",
            found: false,
            places: [
                "Asakusa Rokku", "Cherry Blossom Lane", "Edo Heritage Avenue", "Ginza Dori", "Golden Gai Lane", "Hana Matsuri Lane", "Hanabi Way",
                "Hanazono Avenue", "Hanazono Harmony Alley", "Hanazono Zenith Avenue", "Kabuki Arts Lane", "Kabuki Boulevard",
                "Kabukicho Central Street", "Kabukicho Crossing", "Kabukicho Prism Street", "Kabukicho Shinkansen Street", "Kabukicho Starshine Street",
                "Kimono Lane", "Koi Pond Lane", "Kuyakusho-dori", "Lantern Street", "Moonlit Path",
                "Neon Lane", "Nihon Nights Avenue", "Sakura Street", "Shibuya Koen Dori", "Shinjuku Shadows Road", "Teahouse Terrace",
                "Tsuki no Michi", "Tsukiya Street", "Ukiyo Lane", "Yume Alley", "Zenkoji Zenith Road"            
            ]
        },
        D: {
            name: "Shibuya",
            found: false,
            places: [
                "Cat's Meow Bookstore", "Hachiko Hobbies", "Hanabi Fireworks Emporium", "Hanami Flower Shop", "Hanazono Health Mart",
                "Hikari Electronics", "Kabuki Couture", "Kamé Sennin Café", "Kimono Komono", "Kitsune Curiosities",
                "Neon Dreams Cafe", "Nihon Ninja Supplies", "Origami Oasis", "Pixel Palace Gaming", "Robotropolis Toy Emporium",
                "Sake Symphony", "Sakura Petal Boutique", "Shibuya Jazz Club", "Shibuya Serenity Spa", "Shibuya Skyview Art Gallery",
                "Shibuya Starlight Cinema", "Shibuya Stationery Studio", "Shibuya Sweets Haven", "Sushi Sensation", "Tokyo Trendy Fashion",
                "Tsuki Noodle House", "Ukiyo Souvenir Mart", "Vintage Vinyl Records", "Yoyogi Yoga Studio", "Zen Den Tea House",
                "Zenkoji Zen Gifts"
            ]
        },
        E: {
            name: "Akabanebashi",
            found: false,
            places: [
                "Akabane Antique Treasures", "Akabane Art Gallery", "Akabane Serenade Music Store", "Akabane Skyline Electronics", "Akabane Starlight Theater",
                "Akabane Sushi Delights", "Akabane Tech Haven", "Akabane Wellness Pharmacy", "Crimson Café", "Edo Eats Food Market",
                "Fuji Fashions Boutique", "Hanabi Fireworks Gallery", "Hanami Houseplants", "Hanazono Health Haven", "Kabuki Kabobs & Sweets",
                "Kabuki Konfections", "Kimono Kreation Studio", "Kozuko Inkworks", "Lantern Lights Home Decor", "Nihon Nature Bookstore",
                "Origami Oasis Crafts", "Rising Sun Martial Arts Supplies", "Sake Serenity Lounge", "Sakura Stationery Emporium", "Shibui Scented Candles",
                "Tempura Temptations", "Tsuki Tea Emporium", "Ukiyo Urban Outdoors", "Yozakura Nightclub", "Zen Garden Supplies", "Zenkoji Zen Wellness Center"            
            ]
        },
        F: {
            name: "Ogikubo",
            found: false,
            places: [
                "Aster Avenue 765", "Azalea Avenue 987", "Bamboo Road 101", "Camellia Court 876", "Carnation Road 888",
                "Cherry Blossom Lane 234", "Chome Minamiogikubo 4", "Daffodil Lane 876", "Dahlia Drive 555", "Hyacinth Square 654",
                "Iris Street 222", "Jasmine Lane 432", "Lavender Avenue 765", "Lily Court 444", "Lotus Lane 111",
                "Magnolia Street 210", "Maple Lane 789", "Marigold Lane 321", "Orchid Way 666", "Peony Road 543",
                "Petunia Street 210", "Pine Avenue 456", "Plum Square 567", "Poppy Lane 432", "Rose Terrace 890",
                "Rosemary Road 109", "Sakura Street 123", "Sunflower Avenue 333", "Tulip Street 777", "Violet Avenue 999",
                "Zinnia Way 543"                
            ]           
        }
    },
    correctPlaces: [
        {
            envelope: 'A',
            name: 'Shinjuku Center Building',
            textid: 'solved_shinjuku',
            section_unlocked: 'enveloppeA',
            section_enabled: [],
            found: false,
        },
        {
            envelope: 'C',
            name: 'Kuyakusho-dori',
            textid: 'solved_seibushinjuku',
            section_unlocked: 'enveloppeC',
            section_enabled: ['arcade', 'enveloppeD'],
            found: false,
        },
        {
            envelope: 'D',
            name: "Kamé Sennin Café",
            textid: "solved_shibuya",
            section_unlocked: "enveloppeD",
            section_enabled: ['enveloppeE'],
            found: false,
        },
        {
            envelope: 'E',
            name: 'Kozuko Inkworks',
            textid: 'solved_akabanebashi',
            section_unlocked: 'enveloppeE',
            section_enabled: ['enveloppeF'],            
        },
        {
            envelope: 'F',
            name: 'Chome Minamiogikubo 4',
            textid: 'solved_ogikubo',
            section_unlocked: 'enveloppeF',
            section_enabled: [],    
        }

    ],
    lines: {
        A: {
            code: "A",
            color: "#ef5ca1",
            stops: [
                "Nishi-magome", "Magome", "Nakanobu", "Togoshi", "Gotanda", 
                "Takanawadai", "Sengakuji", "Mita", "Daimon", "Shimbashi",
                "Higashi-ginza", "Takaracho", "Nihombashi", "Ningyocho", "Higashi-Nihombashi",
                "Asakusabashi", "Kuramae", "Asakusa", "Honjo-azumabashi", "Oshiage"
            ]
        },
        C: {
            code: "C",
            color: "#00a64f",
            stops: [
                "Yoyogi-huehara", "Yoyogi-koen", "Meiji-jingumae", "Omote-sando", "Nogizaka",
                "Akasaka", "Kokkai-gijidomae", "Kasumigaseki", "Hibiya", "Nijubashimae",
                "Otemachi", "Shin-ochanomizu", "Yushima", "Nezu", "Sendagi",
                "Nishi-nippori", "Machiya", "Kita-senju", "Ayase", "Kita-Ayase"
            ]
        },
        E: {
            code: "E",
            color: "#b51e82",
            stops: [
                "Shinjuku-nishiguchi", "Higashi-shinjuku", "Wakamatsu-kawada", "Ushigome-yanagicho", "Ushigome-kagurazaka",
                "Iidabashi", "Kasuga", "Hongo-sanchome", "Ueno-okachimachi", "Shin-okachimachi",
                "Kuramae", "Ryogoku", "Morishita", "Kiyosumi-shirakawa", "Monzen-nakacho", 
                "Tsukishima", "Kachidoki", "Tsukijishijo", "Shiodome", "Daimon",
                "Akabanebashi", "Azabu-juban", "Roppongi", "Aoyama-ichome", "Kokuritsu-kyogijo",
                "Yoyogi", "Shinjuku", "Tochomae", "Nichi-shinjuku-gochome", "Nakano-Sakaue",
                "Higashi-nakano", "Nakai", "Ochiai-minami-nagasaki", "Shin-egota", "Nerima",
                "Toshimaen", "Nerima-kasugacho", "Hikarigaoka"
            ]
        },
        F: {
            code: "F",
            color: "#ba6831",
            stops: [
                "Wakoshi", "Chikatetsu-Narimasu", "Chikatetsu-akatsuka", "Heiwadai", "Hikawadai",
                "Kotake-mukaihara", "Senkawa", "Kanamecho", "Ikebukiro", "Zoshigaya",
                "Nishi-waseda", "Higashi-shinjuku", "Shinjuku-sanchome", "Kita-sando", "Meiji-jingumae",
                "Shibuya"
            ]
        },
        G: {
            code: "G",
            color: "#f4911c",
            stops: [
                "Shibuya", "Omote-sando", "Gaiemmae", "Aoymama-itchome", "Akasaka-mitsuke",
                "Tameike-sanno", "Toranomon", "Shimbashi", "Ginza", "Kyobashi",
                "Nihonbashi", "Mitsukoshimae", "Kanda", "Suehirocho", "Ueno-hirokoji",
                "Ueno", "Inaricho", "Tawaramachi", "Asakusa"
            ]
        },
        H: {
            code: "H",
            color: "#c7beb3",
            stops: [
                "Naka-Meguro", "Ebisu", "Hiroo", "Roppongi", "Kamiyacho",
                "Kasumigaseki", "Hibiya", "Ginza", "Higashi-Ginza", "Tsukiji",
                "Hatchobori", "Kayabacho", "Ningyocho", "Kodemmacho", "Akihabara",
                "Naka-Okachimachi", "Ueno", "Iriya", "Minowa", "Minami-Senju",
                "Kita-Senju"
            ]
        },
        I: {
            code: "I",
            color: "#0072bc",
            stops: [
                "Meguro", "Fudomae", "Naka-Meguro", "Shirokanedai", "Ebisu",
                "Kamiyama", "Hiroo", "Roppongi-itchome", "Roppongi", "Akasaka",
                "Tameike-Sanno", "Toranomon", "Kasumigaseki", "Hibiya", "Otemachi",
                "Jimbocho", "Suidobashi", "Ochanomizu", "Sendagi", "Hakusan",
                "Sengoku", "Sugamo", "Sakaecho", "Hasune", "Shimura-sakaue"                
            ]
        },
        M: {
            code: "M",
            color: "#ee2628",
            stops: [
                "Ikebukuro", "Shin-Otsuka", "Myogadani", "Korakuen", "Hongosanchome",
                "Ochanomizu", "Awajicho", "Ogawamachi", "Shinjuku-sanchome", "Shinjuku",
                "Shinjuku-nishiguchi", "Nishi-Shinjuku", "Nakano-Sakaue", "Nakanosakaue",
                "Nakano-Fujimicho", "Araiyakushimae", "Ogikubo"
            ]
        },
        N: {
            code: "N",
            color: "#02b69b",
            stops: [
                "Meguro", "Shirokanedai", "Takanawadai", "Sengakuji", "Mita",
                "Shirokane-Takanawa", "Azabu-Juban", "Roppongi", "Kamiyacho", "Toranomon",
                "Kasumigaseki", "Nagatacho", "Akasaka-Mitsuke", "Aoyama-itchome", "Omotesando",
                "Gaienmae", "Aoyama-gofukutcho", "Shibuya"                
            ]
        },
        S: {
            code: "S",
            color: "#abba41",
            stops: [
                "Shinjuku", "Akebonobashi", "Ogawamachi", "Iidabashi", "Ichigaya",
                "Kudanshita", "Jimbocho", "Ochanomizu", "Suidobashi", "Korakuen",
                "Kasuga", "Hongo-sanchome", "Hongo", "Hon-komagome", "Komagome",
                "Sugamo", "Sengoku", "Takinogawa", "Ushigome-kagurazaka", "Iidabashi",
                "Ichigaya"                
            ]
        },
        T: {
            code: "T",
            color: "#00afef",
            stops: [
                "Nakano", "Higashi-Nakano", "Nishi-Shinjuku-Gochome", "Tochomae", "Nishi-Shinjuku",
                "Shinjuku-Sanchome", "Koenji", "Asagaya", "Ogikubo", "Nishi-Ogikubo",
                "Nishi-Eifuku", "Eifukucho", "Kami-Kitazawa", "Shimokitazawa", "Ikenoue",
                "Higashi-Matsubara", "Matsubara", "Nishidai", "Nakano-Shimbashi", "Numabukuro",
                "Araiyakushimae"                
            ]
        },
        Y: {
            code: "Y",
            color: "#d1a662",
            stops: [
                "Wakoshi", "Chikatetsu-Narimasu", "Heiwadai", "Kotake-Mukaihara", "Senkawa",
                "Kanamecho", "Ikebukuro", "Higashi-Ikebukuro", "Shintomicho", "Ginza-itchome",
                "Yurakucho", "Shimbashi", "Toyosu", "Tatsumi", "Shin-Kiba",
                "Minami-Sunamachi", "Sumiyoshi", "Kiba"                
            ]
        },
        Z: {
            code: "Z",
            color: "#8c7dba",
            stops: [
                "Shibuya", "Omote-sando", "Aoyama-itchome", "Nagatacho", "Hanzomon",
                "Kudanshita", "Jimbocho", "Otemachi", "Mitsukoshimae", "Nihombashi",
                "Suitengumae", "Kiyosumi-Shirakawa", "Sumiyoshi", "Kinshicho"               
            ]
        },
        SS: {
            code: "SS",
            color: "#00a6be",
            stops: [
                "Seibu-Shinjuku", "Takadanobaba", "Shimo-Ochiai", "Nakai", "Araiyakushimae",
                "Numabukuro", "Nogata", "Toritsukasei", "Saginomiya", "Shimo-Igusa",
                "Iogi", "Kami-Igusa", "Kami-Shakujii", "Musashiseki", "Higashi-Fushimi",
                "Seibu-Yagisawa", "Tanashi", "Hanakoganei", "Kodaira", "Kumegawa",
                "Higashi-Murayama"
            ]
        }                         
    }
}