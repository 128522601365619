<template>
  <v-overlay absolute  :class="`text-center ${extraClass}`">
    <div class="d-frame">
      <div class="d-title text-center">
          <span>{{ $t("yakuza.hack.access_denied").toUpperCase() }}</span>
          <span class="close-btn"><v-btn icon color="#000" x-small @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></span>
      </div>
      <div class="d-body ">{{ $t("yakuza.hack.cannot_hack") }}</div>
    </div>
  </v-overlay>
</template>
  
<script>
  export default {
    name: "HackOverlayError",
    emits: ["onClose"],
    data() {
      return { };
    },
    computed: { },
    props: {
      extraClass: String
    },
    mounted() { },
    methods: { }
  }
</script>
  
<style scoped lang="scss">
  .d-frame {
    border: 1px solid #00ffff;
    background: rgba(0, 0, 0, 0.8);
    height: 260px;
    width: 260px;
    padding: 0;
    font: 11pt "Courier Prime";

    .d-title {
      height: 24px;
      background: #00ffff;
      color: #000;
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      .close-btn {
        float:right;
        margin: -4px;
      }
    }

    .d-body {
      text-align: center;
      color: #ED1D25;
      padding: 20px;
      padding-top: 100px;
    }


  }
</style>