<template>
  <div class="section-frame fill-height">
    <div class="police-container" >
      <v-overlay absolute location="top center" :value="advertiseUnlock" id="police-overlay">
        <div class="dialog-frame">
          <p>
            <span v-html="$t('egypt.police.unlock_title')"></span><br />
            <span v-html="$t('egypt.police.unlock_desc')"></span>
          </p>
        </div>
      </v-overlay>
      <div class="police-banner">
          <img :src="casePublicImages + '/police/bg.jpg'" />
      </div>
      <div class="police-title">
        {{ $t("egypt.police.title") }}
      </div>
      <div class="police-contact">
        {{ $t("egypt.police.contact_us") }}
      </div>
      <div class="police-chat-header">
        <div class="from">
          {{ $t("egypt.police.from") }}
        </div>
        <div class="from">
          {{ $t("egypt.police.to") }}{{ $t("egypt.police.officer") }}
        </div>
      </div>
      <div class="police-chat">
        <rpg-chat
          :chatId="chatId"
          @chatComplete="onChatComplete"
          @chatDeadend="onChatDeadend"
          ref="chat"
        ></rpg-chat>
      </div>
    </div>
  </div>
</template>
<script>
import RpgChat from "../../components/egypt/RpgChat";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Police",
  data() {
    return {
      sectionId: "police",
      chatId: "police_egypt",
      chatDeadend: false,
      advertiseUnlock: false,
    };
  },
  components: {
    RpgChat,
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.egyptPoliceLocked;
    },
  },
  methods: {
    resumeChat: function() {
      this.$refs.chat.resumeChat();
      this.chatDeadend = false;
    },
    resetChat: function() {
      this.$refs.chat.resetChat();
      this.chatDeadend = false;
    },
    onChatComplete: async function() {
      console.log("Chat complete");
      await this.unlock();

      setTimeout(() => {
        this.advertiseUnlock = true;
        this.$nextTick(() =>
          document.getElementById('police-overlay').scrollIntoView({behavior: 'smooth'})
        );
      }, 4000);
    },
    onChatDeadend: function() {
      console.log("Chat is a deadend");
      this.chatDeadend = true;
    },
    unlock: async function() {
      await this.$store.dispatch("unlockSection", { gameID: this.caseID, sectionID: this.sectionId });
      await this.$store.dispatch("enableSection", { gameID: this.caseID, sectionID: 'suitcase' });
    },
  },
  mounted: async function() {
    this.resumeChat();

    if (!this.isLocked) {
      this.advertiseUnlock = true;
    }
  },
};
</script>
<style>
#app {
  background-position: top !important;
}
</style>
<style scoped>
.police-container {
  background: #E8E8E8;
  position: relative;
  font-family: Arimo;
  padding-bottom: 30px;
}
.police-banner {
  width: 100%;
  position: relative;
}
.police-banner img {
  width: 100%;
  position: relative;  
  display: block;
}
.police-title {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  background: #2B345D;
  padding: 10px 23px 6px;
  height: 42px;
}

@media only screen and (max-width: 600px) {
  .police-title {
    font-size: 14px !important;
  }
}
.police-contact {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2B345D;
  width: 80%;
  border-bottom: 2px solid #2B345D;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.police-chat-header {
  width: 80%;
  margin: auto;
  background: #2B345D;
  color: #ffffff;
  padding: 10px;
}


.police-chat {
  padding: 31px;
  width: 80%;
  margin: auto;
  padding: 30px;
  background: #ffffff;
}
.police-chat::v-deep .chat-log-header {
  background: #3263aa;
}
.dialog-frame {
  border-radius: 0;
}

.v-overlay {
  align-items: start !important;
}

::v-deep .v-overlay__content {
  top: 100px;
}
</style>
