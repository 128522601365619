<template>
  <div class="grow container">
    <SvgSafe 
      :correctCode = "$yakuzaStore.safe.correctCode"
      class="safe"
    ></SvgSafe>
  </div>
</template>
  
<script>
  import SvgSafe from "@/components/yakuza/SvgSafe";
  import currentGameMixin from "@/mixins/currentGameMixin";
  
  export default {
    name: "Safe",

    components: {
      SvgSafe
    },

    mixins: [currentGameMixin],

    data: () => ({ }),
    methods: { },
    computed: { },
    watch: { },
    created() { }
  };
</script>

<style lang="scss" scoped>
  .safe {
    width: 600px;
    margin: auto;
  }
</style>
  