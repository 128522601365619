<template>
  <v-container class="loader-container">
    <v-row align="center">
      <v-col cols="12">
        {{ $t("loader.title") }}
      </v-col>
      <v-col cols="12">
        <v-progress-linear
          class="progress-bar"
          color="#57585A"
          height="20"
          :value="value"
          striped
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
    props: {
        caseID: String,
        value: Number
    }
}
</script>
<style scoped>
.loader-container {
  background: #00000040;
  border-radius: 7px;
  border: 1px solid #ffffff;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.progress-bar {
  border: 4px solid #57585a;
  border-radius: 10px;
}
</style>