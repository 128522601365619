<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col xs="12">
        <svg-safe
          :locked="locked"
          :error="error"
          :unlockMessage1="$t(`${this.caseID}.safe.messages.unlock1`)"
          :unlockMessage2="$t(`${this.caseID}.safe.messages.unlock2`)"
          :errorMessage1="$t(`${this.caseID}.safe.messages.error1`)"
          :errorMessage2="$t(`${this.caseID}.safe.messages.error2`)"
          @codeValidated="onCodeValidated"
          @input="onInput"
        ></svg-safe>
      </v-col>
    </v-row>
    <audio src="@/assets/audio/safe_open.mp3" ref="openSound" />
  </v-container>
</template>

<script>
import SvgSafe from "@/components/maestro/SvgSafe.vue";
import currentGameMixin from "../../mixins/currentGameMixin";
import safeMessages from "../../messages/maestro/safe.messages";

export default {
  components: { SvgSafe },
  name: "Safe",
  i18n: {
    messages: safeMessages
  },
  mixins: [currentGameMixin],
  computed: {
    locked: function () {
      return this.$store.getters.maestroSafeLocked;
    },
    error: function () {
      return this.$store.getters.maestroSafeError;
    },
  },
  methods: {
    onInput() {
      if (this.error) this.$store.dispatch("maestroResetSafe");
    },
    onCodeValidated(code) {
      this.$store.dispatch("maestroTryUnlockSafe", code);
    },
    playOpenSound() {
      this.$refs.openSound.play();
    },
  },
  watch: {
    locked: function (newValue) {
      if (!newValue) this.playOpenSound();
    },
  },
};
</script>
